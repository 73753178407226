/**
 * Copyright 2020 Nametag Inc.
 *
 * All information contained herein is the property of Nametag Inc.. The
 * intellectual and technical concepts contained herein are proprietary, trade
 * secrets, and/or confidential to Nametag, Inc. and may be covered by U.S.
 * and Foreign Patents, patents in process, and are protected by trade secret or
 * copyright law. Reproduction or distribution, in whole or in part, is
 * forbidden except by express written permission of Nametag, Inc.
 */

import React from "react";
import { RouteProps } from "react-router";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { NotFoundPage } from "./not_found";
import { SigninErrorPage } from "./sign-in-error";

let routePropsCallbacks:
  | Array<() => Array<RouteProps> | RouteProps>
  | undefined;

export function RegisterRoute(
  routePropsCallback: () => Array<RouteProps> | RouteProps,
) {
  if (routePropsCallbacks === undefined) {
    routePropsCallbacks = [];
  }
  routePropsCallbacks.push(routePropsCallback);
}

export const AppRouter: React.FunctionComponent = () => {
  const routeElements = new Array<React.ReactElement>();
  for (const routePropsCallback of routePropsCallbacks || []) {
    const r = routePropsCallback();
    if (Array.isArray(r)) {
      for (const routeProps of r) {
        routeElements.push(
          <Route key={routeProps.path!.toString()} {...routeProps} />,
        );
      }
    } else {
      routeElements.push(<Route key={r.path!.toString()} {...r} />);
    }
  }

  return (
    <BrowserRouter basename="/">
      <Routes>
        {routeElements}
        <Route path="/signin/error" element={<SigninErrorPage />} />
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </BrowserRouter>
  );
};
