/**
 * Copyright 2020 Nametag Inc.
 *
 * All information contained herein is the property of Nametag Inc.. The
 * intellectual and technical concepts contained herein are proprietary, trade
 * secrets, and/or confidential to Nametag, Inc. and may be covered by U.S.
 * and Foreign Patents, patents in process, and are protected by trade secret or
 * copyright law. Reproduction or distribution, in whole or in part, is
 * forbidden except by express written permission of Nametag, Inc.
 */

import { API } from "./index";
import { QueryKeys } from "./querykey";
import assert from "assert";
import {
  NewOrgMember,
  Org,
  OrgMember,
  OrgMemberUpdate,
  OrgReportOpts,
  OrgReportResponse,
  OrgSAML,
  OrgUpdate,
} from "./types";
import { Role } from "./schema";

export class OrgsAPI {
  parent: API;
  constructor(parent: API) {
    this.parent = parent;
  }

  async Get(): Promise<Org> {
    const req = new Request("/api/org", {
      method: "GET",
    });
    const resp = await this.parent.fetch(req);
    return (await resp.json()) as Org;
  }

  async Update(update: OrgUpdate): Promise<void> {
    return await this.parent.wrapMutation([QueryKeys.Org.Get], async () => {
      this.parent.queryClient.setQueryData(
        QueryKeys.Org.Get,
        (oldOrg: Org | undefined) => {
          assert(!!oldOrg, "org is undefined");
          const newOrg: Org = {
            ...oldOrg,
            id: oldOrg.id,
            name: update.name ?? oldOrg.name,
            role: oldOrg.role,
            envs: oldOrg.envs,
            plan: oldOrg.plan,
            trial_expires_at: oldOrg.trial_expires_at,
            signin_method: oldOrg.signin_method,
            saml: oldOrg.saml,
            features: oldOrg.features,
            allowed_ip_addresses:
              update.allowed_ip_addresses ?? oldOrg.allowed_ip_addresses,
          };
          if (update.saml !== undefined && newOrg.saml) {
            if (update.saml.groups) {
              newOrg.saml.groups = update.saml.groups;
            }
          }
          return newOrg;
        },
      );

      const req = new Request("/api/org", {
        method: "PATCH",
        body: JSON.stringify(update),
      });
      await this.parent.fetch(req);
    });
  }

  async Delete(): Promise<void> {
    try {
      const req = new Request("/api/org", {
        method: "DELETE",
      });
      await this.parent.fetch(req);
    } finally {
      this.parent.State.idToken = null; // sign out too, because this org is gone
      this.parent.queryClient.resetQueries(); // all queries are now invalid
    }
  }

  async ListMembers(): Promise<Array<OrgMember>> {
    const req = new Request("/api/org/members", {
      method: "GET",
    });
    const resp = await this.parent.fetch(req);
    const body = await resp.json();
    return body["members"] as Array<OrgMember>;
  }

  async InviteMember(newOrgMember: NewOrgMember): Promise<Response> {
    return await this.parent.wrapMutation(
      [QueryKeys.Org.ListMembers],
      async () => {
        const req = new Request("/api/org/members", {
          method: "POST",
          body: JSON.stringify(newOrgMember),
        });
        return await this.parent.fetch(req);
      },
    );
  }

  async RemoveMember(memberID: string): Promise<Response> {
    return await this.parent.wrapMutation(
      [QueryKeys.Org.ListMembers],
      async () => {
        this.parent.queryClient.setQueryData(
          QueryKeys.Org.ListMembers,
          (oldData: OrgMember[] | undefined) => {
            return (oldData ?? []).filter((m) => m.member_id !== memberID);
          },
        );
        const req = new Request("/api/org/members/" + encodeURI(memberID), {
          method: "DELETE",
        });
        return await this.parent.fetch(req);
      },
    );
  }

  async UpdateMember(
    memberID: string,
    update: OrgMemberUpdate,
  ): Promise<Response> {
    return await this.parent.wrapMutation(
      [QueryKeys.Org.ListMembers],
      async () => {
        this.parent.queryClient.setQueryData(
          QueryKeys.Org.ListMembers,
          (oldData: OrgMember[] | undefined) => {
            return (oldData ?? []).map((m) => {
              if (m.member_id !== memberID) {
                return m;
              }
              const newMember: OrgMember = {
                member_id: m.member_id,
                role: update.role ?? m.role,
                name: m.name,
                email: m.email,
                profile_picture: m.profile_picture,
                is_self: m.is_self,
                invite_pending: m.invite_pending,
                envs: update.envs ?? m.envs,
              };
              return newMember;
            });
          },
        );

        const req = new Request("/api/org/members/" + encodeURI(memberID), {
          method: "PATCH",
          body: JSON.stringify({
            role: update.role,
            envs: update.envs,
          }),
        });
        return await this.parent.fetch(req);
      },
    );
  }

  async AcceptInvitation(code: string): Promise<void> {
    const req = new Request("/api/orgs/join", {
      method: "POST",
      body: JSON.stringify({ code: code }),
    });
    await this.parent.fetch(req);
  }

  async ConfigureSigninNametag(): Promise<void> {
    return await this.parent.wrapMutation([QueryKeys.Org.Get], async () => {
      const req = new Request(`/api/org/signin/nametag/configure`, {
        method: "POST",
      });
      await this.parent.fetch(req);
    });
  }

  async ConfigureSigninEmail(): Promise<void> {
    return await this.parent.wrapMutation([QueryKeys.Org.Get], async () => {
      const req = new Request(`/api/org/signin/email/configure`, {
        method: "POST",
      });
      await this.parent.fetch(req);
    });
  }

  async ConfigureSigninSAMLStart(
    saml: OrgSAML,
  ): Promise<ConfigureSAMLResponse> {
    return await this.parent.wrapMutation([QueryKeys.Org.Get], async () => {
      const req = new Request(`/api/org/signin/saml/configure-start`, {
        method: "POST",
        body: JSON.stringify(saml),
      });
      const resp = await this.parent.fetch(req);
      const respBody = await resp.json();
      return respBody as ConfigureSAMLResponse;
    });
  }

  async ConfigureSigninSAMLFinish(): Promise<void> {
    return await this.parent.wrapMutation([QueryKeys.Org.Get], async () => {
      const req = new Request(`/api/org/signin/saml/configure-finish`, {
        method: "POST",
      });
      await this.parent.fetch(req);
    });
  }

  async ConfigureSAMLAbort(): Promise<void> {
    return await this.parent.wrapMutation([QueryKeys.Org.Get], async () => {
      const req = new Request(`/api/org/signin/saml/configure-abort`, {
        method: "POST",
      });
      await this.parent.fetch(req);
    });
  }

  async Report(opts: OrgReportOpts): Promise<OrgReportResponse> {
    const req = new Request(`/api/org/report`, {
      method: "POST",
      body: JSON.stringify(opts),
    });
    const resp = await this.parent.fetch(req);
    const respBody = await resp.json();
    return respBody as OrgReportResponse;
  }
}

export function RoleGe(role: Role | undefined, minRole: Role): boolean {
  if (!role) {
    return false;
  }

  switch (minRole) {
    case Role.RoleOwner:
      return role === Role.RoleOwner;
    case Role.RoleAdmin:
      return role === Role.RoleOwner || role === Role.RoleAdmin;
    case Role.RoleUser:
      return (
        role === Role.RoleOwner ||
        role === Role.RoleAdmin ||
        role === Role.RoleUser
      );
    case Role.RoleLimitedPlus:
      return (
        role === Role.RoleOwner ||
        role === Role.RoleAdmin ||
        role === Role.RoleUser ||
        role === Role.RoleLimitedPlus
      );
    case Role.RoleLimited:
      return role == Role.RoleLimited;
  }
}

// TODO(ross): this is part of the signup API which is not converted to
//   openapi yet. Remove this when it is.
export interface ConfigureSAMLResponse {
  error?: string;
  url?: string;
}
