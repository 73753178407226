/**
 * Copyright 2020 Nametag Inc.
 *
 * All information contained herein is the property of Nametag Inc.. The
 * intellectual and technical concepts contained herein are proprietary, trade
 * secrets, and/or confidential to Nametag, Inc. and may be covered by U.S.
 * and Foreign Patents, patents in process, and are protected by trade secret or
 * copyright law. Reproduction or distribution, in whole or in part, is
 * forbidden except by express written permission of Nametag, Inc.
 */

import "../csp_nonce";

import React from "react";
import ReactDOM from "react-dom";
import { Toaster } from "react-hot-toast";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { normalize, setupPage } from "csstips";

import "./routes";
import { AppRouter } from "../routes";

import "../index.css";
import { showGenericError } from "../lib/feedback";

import { hotjar } from "react-hotjar";
import { GetTag } from "../lib/tags";

try {
  const hotjarVersionOrSomething = 6;
  const hotjarID = GetTag("hotjar-id");
  if (hotjarID) {
    hotjar.initialize(parseInt(hotjarID), hotjarVersionOrSomething);
  }
} catch (e) {
  console.error("failed to initialize hotjar:", e);
}

const queryClient = new QueryClient({
  defaultOptions: {
    mutations: {
      onError: showGenericError,
    },
  },
});

ReactDOM.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <AppRouter />
      <Toaster />
    </QueryClientProvider>
  </React.StrictMode>,
  document.getElementById("root"),
);
normalize();
setupPage("#root");
