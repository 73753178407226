/**
 * Copyright 2023 Nametag Inc.
 *
 * All information contained herein is the property of Nametag Inc.. The
 * intellectual and technical concepts contained herein are proprietary, trade
 * secrets, and/or confidential to Nametag, Inc. and may be covered by U.S.
 * and Foreign Patents, patents in process, and are protected by trade secret or
 * copyright law. Reproduction or distribution, in whole or in part, is
 * forbidden except by express written permission of Nametag, Inc.
 */

import { classes } from "typestyle";
import { typedStylesheet } from "../../lib/style";
import { useEffect, useState } from "react";
import { t } from "../../i18n";
import { pxToRem } from "../../lib/utils";

export interface AuthCheckProps {
  hidden: boolean;
}

export function AuthCheck({ hidden }: AuthCheckProps) {
  const [showWarning, setShowWarning] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowWarning(true);
    }, 5000);

    return () => clearTimeout(timer);
  }, []);

  return (
    <div className={classes(css.auth, hidden && css.authHidden)}>
      <div
        className={classes(css.warning, !showWarning && css.hidden)}
        aria-hidden={!showWarning}
      >
        {t("csr_GenericError")}
      </div>
    </div>
  );
}

export const css = typedStylesheet({
  auth: {
    position: "fixed",
    inset: 0,
    display: "grid",
    alignContent: "center",
    justifyContent: "center",
    transition: "opacity 200ms ease-in-out",
    backgroundColor: "white",
    zIndex: 1,
  },
  authHidden: {
    opacity: 0,
    pointerEvents: "none",
  },
  hidden: {
    display: "none",
  },
  warning: {
    fontSize: pxToRem(36),
  },
});
