/**
 * Copyright 2022 Nametag Inc.
 *
 * All information contained herein is the property of Nametag Inc.. The
 * intellectual and technical concepts contained herein are proprietary, trade
 * secrets, and/or confidential to Nametag, Inc. and may be covered by U.S.
 * and Foreign Patents, patents in process, and are protected by trade secret or
 * copyright law. Reproduction or distribution, in whole or in part, is
 * forbidden except by express written permission of Nametag, Inc.
 */

import { ReactNode } from "react";
import {
  DialogStateReturn,
  DialogBackdrop,
  Dialog as ReakitDialog,
} from "reakit/Dialog";
import { VisuallyHidden } from "reakit/VisuallyHidden";
import { classes, style } from "typestyle";
import { t } from "../../i18n";
import { CloseIcon } from "../assets/close-icon";
import { Button } from "../button/button";
import { COLORS } from "../../lib/colors";

type Props = {
  dialog: DialogStateReturn;
  children: ReactNode;
  ariaLabel: string;
  closeBtnClassName?: string;
  backdropClassName?: string;
  className?: string;
  modalContentsClassName?: string;
  showCloseBtn?: boolean;
};

export const Dialog: React.FC<Props> = ({
  dialog,
  children,
  ariaLabel,
  backdropClassName,
  closeBtnClassName,
  className,
  modalContentsClassName,
  showCloseBtn = true,
}) => {
  return (
    <DialogBackdrop
      {...dialog}
      className={classes(
        css.backdrop,
        backdropClassName,
        !!dialog.animated && css.animated,
      )}
    >
      <ReakitDialog
        {...dialog}
        aria-label={ariaLabel}
        className={classes(
          css.modal,
          className,
          !!dialog.animated && css.animated,
        )}
      >
        <div className={classes(css.modalContents, modalContentsClassName)}>
          {showCloseBtn && (
            <Button
              onClick={dialog.hide}
              className={classes(css.closeBtn, closeBtnClassName)}
            >
              <CloseIcon />
              <VisuallyHidden>{t("CloseModal")}</VisuallyHidden>
            </Button>
          )}
          {children}
        </div>
      </ReakitDialog>
    </DialogBackdrop>
  );
};

const css = {
  backdrop: style({
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    width: "100vw",
    height: "100vh",
    position: "fixed",
    top: 0,
    display: "grid",
    placeItems: "center",
  }),
  modal: style({
    backgroundColor: "#FFFFFF",
    minWidth: 300,
    maxWidth: "90vw",
    borderRadius: 8,
    overflow: "hidden",
  }),
  closeBtn: style({
    position: "absolute",
    color: COLORS.textLight,
    top: 20,
    right: 16,
  }),
  // wrapper for modal contents, so that the scrollbar respects border radius
  modalContents: style({
    overflowY: "auto",
    maxHeight: "80vh",
    position: "relative",
  }),
  animated: style({
    transition: "opacity 150ms",
    opacity: 0,
    $nest: {
      "&[data-enter]": {
        opacity: 1,
      },
    },
  }),
};
