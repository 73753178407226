/**
 * Copyright 2020 Groove ID, Inc.
 *
 * All information contained herein is the property of Groove ID, Inc. The
 * intellectual and technical concepts contained herein are proprietary, trade
 * secrets, and/or confidential to Groove ID, Inc. and may be covered by U.S.
 * and Foreign Patents, patents in process, and are protected by trade secret or
 * copyright law. Reproduction or distribution, in whole or in part, is
 * forbidden except by express written permission of Groove ID, Inc.
 */

import * as React from "react";

import { classes, style } from "typestyle";
import { useEffect, useState } from "react";
import { COLORS } from "./colors";

interface ProgressProps {
  estimated: number;
  className?: string;
}

export const ProgressBar = (props: ProgressProps) => {
  const [startTime] = useState(new Date());

  const [currentTime, setCurrentTime] = useState(new Date());
  const requestRef = React.useRef<number>();
  const animate = () => {
    setCurrentTime(new Date());
    requestRef.current = requestAnimationFrame(animate);
  };
  useEffect(() => {
    requestRef.current = requestAnimationFrame(animate);
    return () => cancelAnimationFrame(requestRef.current!);
  }, []);

  let value = 0;
  const elapsed = currentTime.getTime() - startTime.getTime();
  if (props.estimated) {
    if (elapsed < props.estimated) {
      value = (elapsed / props.estimated) * 80;
    } else {
      value = 80;
    }
  } else {
    value = (elapsed / 100) % 100;
  }

  return (
    <div className={classes(css.container, props.className)}>
      <div className={css.filler} style={{ width: value + "%" }} />
    </div>
  );
};

const css = {
  container: style({
    height: 5,
    width: "100%",
    backgroundColor: COLORS.border,
  }),
  filler: style({
    height: "100%",
    backgroundColor: COLORS.actionPrimary,
    transition: "width 0.1s ease-in-out",
  }),
};
