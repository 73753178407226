/**
 * Copyright 2023 Nametag Inc.
 *
 * All information contained herein is the property of Nametag Inc.. The
 * intellectual and technical concepts contained herein are proprietary, trade
 * secrets, and/or confidential to Nametag, Inc. and may be covered by U.S.
 * and Foreign Patents, patents in process, and are protected by trade secret or
 * copyright law. Reproduction or distribution, in whole or in part, is
 * forbidden except by express written permission of Nametag, Inc.
 */

import React from "react";
import { style } from "typestyle";

const css = {
  header: style({
    display: "flex",
    height: "100vh",
    justifyContent: "center",
    alignItems: "center",
  }),
};

export default function ErrorPage() {
  return <h1 className={css.header}>Not implemented</h1>;
}
