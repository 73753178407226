{
  "AcceptInvitation": "Aceptar la invitacion",
  "Access_control": "Control de acceso",
  "Accounts": "Cuentas",
  "Add a birthday": "Agregar un cumpleaños",
  "AddALogo": "Agregar un logotipo",
  "AddAPaymentMethod": "Agregue un método de pago para eliminar esta advertencia",
  "Add_a_group": "Agregar un grupo",
  "All_accounts": "Todas las cuentas",
  "All_other_accounts": "Todas las demás cuentas",
  "Allowed": "Permitido",
  "AlreadyInvited": "Parece que ya te invitaron a unirte a una organización de Nametag. Revisa tu bandeja de entrada para ver la invitación.",
  "Anonymous": "Anónimo",
  "AppealRejectReason_Blurry": "Rechazado porque una o más imágenes están demasiado borrosas para aceptarlas",
  "AppealRejectReason_Damaged": "Rechazado porque el documento está dañado.",
  "AppealRejectReason_Glare": "Rechazado porque hay demasiado brillo para aceptar el documento.",
  "AppealRejectReason_InjectionFraud": "Rechazado por fraude de inyección",
  "AppealRejectReason_MiscFraud": "Rechazado por fraude",
  "AppealRejectReason_NonCompliant": "Rechazado porque la persona no cumplió, es decir, no tomó una fotografía de un documento de identidad o de una cara.",
  "AppealRejectReason_Occluded": "Rechazado porque el documento o selfie está oscurecido",
  "AppealRejectReason_OutOfFrame": "Rechazado porque el documento o selfie no está completamente en el marco",
  "AppealRejectReason_Paper": "Rechazado porque el documento es una copia en papel o una impresión",
  "AppealRejectReason_PresentationFraud": "Rechazado por fraude de presentación",
  "AppealRejectReason_Redacted": "Rechazado porque la persona redactó intencionalmente partes del documento o su rostro",
  "AppealRejectReason_RepeatFraud": "Rechazado por fraude repetido",
  "AppealRejectReason_Screen": "Rechazado porque el documento o selfie es una captura de pantalla",
  "AppealRejectReason_SelfieLiveness": "Rechazada porque la selfie no es de una persona viva",
  "AppealRejectReason_SelfieMismatch": "Rechazado porque el selfie no coincide",
  "AppealRejectReason_UnacceptableDocType": "Rechazado porque el tipo de documento no es aceptable",
  "Back": "atrás",
  "BackTo": "Volver a %{appName}",
  "Begin_typing": "Comience a escribir...",
  "Button": "Botón",
  "CallbackURL": "URL de devolución de llamada",
  "Cancel": "Cancelar",
  "CannotEditInSamlModeToast_graf": "Puede ver la configuración en esta página, pero no puede editarla porque el inicio de sesión único SAML está habilitado.",
  "CannotEditInSamlModeToast_hed": "Solo lectura cuando SAML SSO está habilitado",
  "Catch-all rule for others": "Regla general para los demás",
  "CautionBanner": "Bandera de precaución",
  "CloseModal": "Cerrar modal",
  "Configure": "Configurar",
  "Configure self-service recovery permissions": "Configurar permisos de recuperación de autoservicio",
  "CopiedToClipboard": "Copiado al portapapeles",
  "CopyHTML": "Copiar HTML",
  "CopyToClipboard": "Copiar al portapapeles",
  "CreateEnvironmentSuccess": "Creó un nuevo entorno",
  "CreateOrganization": "Crear organización",
  "Create_environment": "crear ambiente",
  "CustomizeBilling": "Elimina este banner **seleccionando un plan**",
  "CustomizeLogo": "Actualiza tu logo en **Personalización**",
  "Days": "Dias",
  "Delete": "Borrar",
  "Delete_members": "Eliminar miembros",
  "Delete_members_graf": "¿Estás seguro de que deseas eliminar %{n} miembros? Esto no se puede revertir, pero siempre puedes enviar una nueva invitación.",
  "Delete_n_members": "Eliminar %{n} miembros",
  "DiscardChanges": "Descartar los cambios",
  "Dismiss": "Despedir",
  "EmailAuthError_graf": "El enlace en el que hizo clic no es válido. Inténtalo de nuevo.",
  "EmailAuthError_hed": "Enlace no válido",
  "EmailPending_graf": "Le hemos enviado un correo electrónico con un enlace para verificar su dirección de correo electrónico. Haga clic en el enlace del correo electrónico para continuar.",
  "EmailPending_hed": "Consultar su correo electrónico",
  "Enabled": "Activado",
  "EntityID": "identificación de la entidad",
  "EntityIDGraf": "Copie esta información de su proveedor de identidad.",
  "EnvironmentInTrialMode": "Este entorno está en modo de prueba. Cualquiera que envíe una solicitud verá nuestro cartel de precaución.",
  "FirstGradient": "Primer gradiente",
  "GeneralPreviewHed": "Vista previa de solicitud móvil",
  "GeneralPreviewSignup": "Regístrese para eliminar el banner de precaución",
  "GeneralPreviewSubhed": "Esto es lo primero que verán sus destinatarios cuando comiencen su solicitud",
  "GenericError": "Algo salió mal. Inténtalo de nuevo.",
  "Group_Name": "Nombre del grupo",
  "Hellothere": "¡Hola!",
  "Hours": "Horas",
  "InstallLogoTrial": "Actualmente estás en modo de prueba. Este logotipo no se puede utilizar hasta que Nametag verifique este entorno.",
  "InviteCode": "Codigo de invitacion",
  "InviteRequired": "Su organización está usando Nametag, pero aún no ha sido invitado. Pídale al administrador de Nametag de su organización que le envíe una invitación por correo electrónico.",
  "Invite_another_member": "Invitar a otro miembro",
  "Invite_members": "Invitar a nuevos miembros",
  "Invite_members_graf_1": "Seleccione el rol apropiado para este miembro, así como los entornos a los que desea que pueda acceder.",
  "Invite_pending": "Invitación pendiente",
  "InvitedBy": "Invitado por: %{invitar}",
  "Loading_groups": "Cargando grupos...",
  "MFA": "MFA",
  "MFA_Resets": "Restablecimientos de MFA",
  "Minutes": "Minutos",
  "MobileBanner": "Este sitio web está optimizado para uso de escritorio. Visite esta página en una computadora de escritorio para obtener la mejor experiencia.",
  "Move_down": "Mover hacia abajo",
  "Move_up": "Ascender",
  "MustBeAdminToEditBanner_graf": "Puede ver la configuración en esta página, pero no puede editarla porque no es administrador.",
  "MustBeAdminToEditBanner_hed": "Se requiere acceso de administrador para editar",
  "MustBeOwnerToEditBanner_graf": "Puede ver la configuración en esta página, pero no puede editarla porque no es propietario.",
  "MustBeOwnerToEditBanner_hed": "Se requiere acceso de propietario para editar",
  "NametagEntityIDGraf": "También llamado a veces **URI de audiencia** o **ID de entidad de proveedor de servicios**, es posible que deba proporcionar este valor a su proveedor de identidad.",
  "NametagEntityIDHed": "ID de entidad de la Nametag",
  "NineDigitCode": "Ingrese el código de invitación de 9 dígitos para continuar.",
  "No": "No",
  "No_preference": "Sin preferencias",
  "None": "Ninguno",
  "Not_allowed": "No permitido",
  "OrganizationDomain": "Dominio de la organización",
  "OrganizationDomainGraf": "Especifique el dominio de correo electrónico de su organización. Cualquier persona que proporcione una dirección de correo electrónico que termine con su dominio será enviada a su proveedor de identidad para su autenticación.",
  "Password": "Contraseña",
  "Password_Resets": "Restablecimiento de contraseña",
  "PermissionsView_hed": "Política de recuperación de autoservicio",
  "PermissionsView_introGraf": "Utilice esta lista de control de acceso para deshabilitar los restablecimientos o establecer niveles mínimos de seguridad para los diferentes grupos en su directorio.",
  "PermissionsView_introGraf2": "Tenga en cuenta: evaluamos a cada usuario individual con el factor de seguridad más alto disponible. Si una cuenta de usuario tiene disponible un factor de seguridad superior al mínimo establecido para el grupo en el que se encuentra, se verifica automáticamente. [Más información](https://getnametag.com/docs/configuring-account-binding/)",
  "PermissionsView_mfa_hed": "Reglas de reinicio de MFA",
  "PermissionsView_password_hed": "Reglas de restablecimiento de contraseña",
  "PermissionsView_unlock_hed": "Reglas de desbloqueo de cuenta bloqueada",
  "Preview": "Avance",
  "PreviewTrialWarning": "Proceda con precaución. Esta empresa aún no ha sido validada por Nametag.",
  "Priority": "Prioridad",
  "ProceedWithCaution": "Proceda con precaución. Esta empresa aún no ha sido validada por Nametag.",
  "RecoveryEligibility_AZURE_OAUTH_PRINCIPAL_CANNOT_RESET_graf": "Este usuario actualmente no es elegible para %{factor} porque esta cuenta se usó para autorizar el acceso de Nametag a Entra ID. Esta es una limitación de Entra ID.",
  "RecoveryEligibility_COMMON_NAME_graf": "Este usuario actualmente no es elegible para %{factor} porque %{groupExpr} en **%{dir_name}** permite la coincidencia de nombres, pero el nombre es muy común, por lo que se requiere una fecha de nacimiento o una foto coincidente.",
  "RecoveryEligibility_DIR_ERROR_graf": "No pudimos determinar si este usuario es elegible para %{factor} debido a un error con **%{dir_name}**.",
  "RecoveryEligibility_ELIGIBLE_graf": "Este usuario es elegible para %{factor} porque %{groupExpr} en **%{dir_name}** lo permite%{policyExpr}",
  "RecoveryEligibility_ERROR_graf": "No pudimos determinar si este usuario es elegible para %{factor} debido a un error interno. Por favor, inténtelo de nuevo más tarde.",
  "RecoveryEligibility_GroupExpr": "el grupo &#39;%{grupo}&#39;",
  "RecoveryEligibility_GroupExpr_Default": "la política predeterminada",
  "RecoveryEligibility_NAME_AND_BIRTH_DATE": " y el usuario tiene una fecha de nacimiento o una foto registrada.",
  "RecoveryEligibility_NAME_MATCH": ".",
  "RecoveryEligibility_NEED_BIRTH_DATE_OR_PHOTO_graf": "Este usuario actualmente no es elegible para %{factor} porque %{groupExpr} en **%{dir_name}** requiere una fecha de nacimiento o una foto coincidente.",
  "RecoveryEligibility_NEED_ENROLLED_MFA_graf": "Este usuario actualmente no es elegible para %{factor} porque la cuenta no está inscrita en MFA.",
  "RecoveryEligibility_NEED_PHOTO_graf": "Este usuario actualmente no es elegible para %{factor} porque %{groupExpr} en **%{dir_name}** requiere una foto coincidente.",
  "RecoveryEligibility_NON_PERSON_NAME_graf": "Este usuario actualmente no es elegible para %{factor} porque %{groupExpr} en **%{dir_name}** permite la coincidencia de nombres, pero el nombre parece no ser una persona, por lo que se requiere una fecha de nacimiento o una foto coincidente.",
  "RecoveryEligibility_NOT_LOCKED_graf": "Este usuario no puede desbloquear su cuenta porque la cuenta no está bloqueada.",
  "RecoveryEligibility_PHOTO": " y el usuario tiene una foto archivada.",
  "RecoveryEligibility_PROHIBITED_graf": "Este usuario actualmente no es elegible para %{factor} porque %{groupExpr} en **%{dir_name}** lo prohíbe.",
  "RecoveryPolicy_BIRTH_DATE_MISMATCH_graf": "Actualmente, el usuario no es elegible para %{factor} porque la verificación de identidad produjo una fecha de nacimiento verificada que no coincide con la fecha de nacimiento esperada en **%{dir_name}**.",
  "RecoveryPolicy_DISABLED": "desactivado",
  "RecoveryPolicy_NAME_AND_BIRTH_DATE": "un nombre y fecha de nacimiento",
  "RecoveryPolicy_NAME_AND_BIRTH_DATE_description": "La elegibilidad requiere que el administrador importe el cumpleaños de ese usuario, lo que aumenta la seguridad.",
  "RecoveryPolicy_NAME_MATCH": "un nombre",
  "RecoveryPolicy_NAME_MATCH_description": "El nombre en la identificación debe coincidir con la cuenta. Se desactiva automáticamente para nombres muy comunes. Es posible que no funcione para usuarios con nombres legales y elegidos diferentes.",
  "RecoveryPolicy_NAME_MISMATCH_graf": "Actualmente, el usuario no es elegible para %{factor} porque la verificación de identidad produjo un nombre legal verificado que no es lo suficientemente similar al nombre en **%{dir_name}**.",
  "RecoveryPolicy_NEED_BIRTH_DATE_FOR_BIRTH_DATE_MATCH_graf": "Actualmente, el usuario no es elegible para %{factor} porque su verificación de identidad no produjo una fecha de nacimiento verificada.",
  "RecoveryPolicy_NEED_NAME_FOR_MATCH_graf": "Actualmente, el usuario no es elegible para %{factor} porque su verificación de identidad no produjo un nombre legal verificado.",
  "RecoveryPolicy_NEED_SELFIE_FOR_PHOTO_MATCH_graf": "Actualmente, este usuario no es elegible para %{factor} porque su verificación de identidad no produjo una selfie verificada.",
  "RecoveryPolicy_PHOTO": "una foto de perfil",
  "RecoveryPolicy_PHOTO_MISMATCH_graf": "Actualmente, el usuario no es elegible para %{factor} porque la verificación de identidad produjo una selfie verificada que no coincide con la foto de perfil esperada en **%{dir_name}**.",
  "RecoveryPolicy_PHOTO_description": "La elegibilidad requiere que el administrador importe una foto de perfil para ese usuario, lo que aumenta la seguridad y permite que los usuarios con diferentes nombres elegidos y legales coincidan.",
  "RecoveryPolicy_SAML": "enlace SAML preventivo realizado",
  "RecoveryPolicy_SAML_description": "La elegibilidad requiere inscripción. El usuario debe visitar una URL (consulte la pestaña de autoservicio) para iniciar sesión en el directorio y luego verificar su identidad (uniendo las cuentas) antes de que se bloquee.",
  "RemoveLogo": "Quitar logotipo",
  "ReplaceLogo": "Reemplazar logotipo",
  "Required": "Requerido",
  "Revert to current settings": "Revertir a la configuración actual",
  "SamlNametagACSURLGraf": "También llamado a veces **URL de ACS** o **URL de inicio de sesión único**, aquí es donde se dirigirá a sus usuarios después de que hayan completado el proceso SAML con su proveedor de identidad y estén listos para ser enviados de regreso a Nametag. Es posible que deba proporcionar esta URL a su proveedor de identidad.",
  "SamlNametagACSURLHed": "URL de inicio de sesión de la Nametag",
  "Sandbox": "Salvadera",
  "Save": "Salvar",
  "SaveError": "No pudimos guardar la URL de devolución de llamada",
  "SaveSignInMethod": "Guardar método de inicio de sesión",
  "SecondGradient": "Segundo gradiente",
  "Seconds": "Segundos",
  "Select_a_group": "Seleccione un grupo...",
  "Send a request": "Enviar una solicitud",
  "Send_n_invitations": "Enviar %{n} invitaciones",
  "Set permissions for a group": "Establecer permisos para un grupo",
  "Set recovery rules for specific people by creating a group rule and selecting one of your imported Entra groups from the dropdown menu.": "Establezca reglas de recuperación para personas específicas creando una regla de grupo y seleccionando uno de sus grupos Entra importados en el menú desplegable.",
  "SignIn": "Registrarse",
  "SignInToNametag": "Iniciar sesión en la Nametag",
  "SignInWithEmail": "Iniciar sesión con correo electrónico",
  "SignOut": "desconectar",
  "SigninURL": "URL de inicio de sesión",
  "SigninURLGraf": "Dónde se dirigirá a los miembros al iniciar sesión. Su proveedor de identidad proporciona esta URL.",
  "SigninWithID": "Iniciar sesión",
  "SigninwithNametag": "Iniciar sesión con la Nametag",
  "SigninwithSAMLSSO": "Iniciar sesión con la Nametag",
  "TestConfiguration": "Configuración de prueba",
  "TrialDaysLeft": "%{n} días",
  "TrialExpired": "Tu periodo de prueba ha caducado; contacte a sales@nametag.co para ampliarlo.",
  "TrialLeftInTrial": "dejado en juicio",
  "TrialWarning1": "Actualmente se encuentra en modo de prueba y todas las solicitudes salientes tendrán un aviso de precaución.",
  "TrialWarning2": "Seleccione un plan para eliminar este banner.",
  "TrialWarningEnv": "Su marca no se ha verificado en el entorno **%{env}**, por lo que los usuarios verán una advertencia. No se le cobrará por las solicitudes en este entorno.",
  "Try again": "Intentar otra vez",
  "Unknown": "Desconocido",
  "Unlock": "desbloquear",
  "Unnamed": "(Sin nombre)",
  "User may only reset if account has...": "El usuario sólo puede restablecer si la cuenta tiene...",
  "UserData_ShowExpiredAndRevoked": "Mostrar caducado y revocado",
  "Verifications": "Verificaciones",
  "View rules": "Ver reglas →",
  "WaitingForReview": "Su identificación ha sido enviada para revisión. Una vez que se haya aprobado, deberá regresar a %{envName} para iniciar sesión.",
  "WrongInviteCode": "El código de invitación que ingresaste no es correcto",
  "Yes": "Sí",
  "account unlock": "desbloqueo de cuenta",
  "account_AccountBound": "**%{name}** ha verificado con éxito su identidad y se ha vinculado a su cuenta de directorio **%{directoryName}** %{directoryKind}",
  "account_ConnectDirectory": "Conectar un directorio",
  "account_Created": "**%{name}** se creó automáticamente a partir del directorio **%{directoryName}** %{directoryKind}",
  "account_CreatedBy": "Verificación creada por %{creator}",
  "account_Header": "Cuenta",
  "account_ResetMFA": "**%{name}** restableció su MFA %{directoryKind} (%{directoryName})",
  "account_ResetPassword": "**%{name}** restableció su contraseña %{directoryKind} (%{directoryName})",
  "account_SelfServiceDisabled": "No elegible para la recuperación del servicio de selfies",
  "account_SelfServiceEnabled": "Recuperación de autoservicio habilitada",
  "account_Unlock": "**%{name}** desbloqueó su cuenta %{directoryKind} (%{directoryName})",
  "account_Verified": "Identidad verificada exitosamente",
  "accounts_AllDirectories": "Todos los directorios",
  "accounts_Directory": "Directorio",
  "accounts_EligibleForReset": "Elegible para restablecer",
  "accounts_Email": "Correo electrónico",
  "accounts_EmptyState": "Recomendamos conectar un directorio para comenzar. Una vez que esté conectado, verá que las cuentas se completan aquí y podrá revisar los restablecimientos de autoservicio haciendo clic en cualquier fila.",
  "accounts_EmptyStateTitle": "Conecte un directorio para comenzar",
  "accounts_Error": "Algo salió mal al cargar tus cuentas. Por favor, inténtelo de nuevo más tarde.",
  "accounts_LastVerified": "Última verificación",
  "accounts_Name": "Nombre",
  "accounts_Never": "Nunca",
  "accounts_NoAccountsFound": "No se encontraron cuentas. Si tiene filtros configurados, intente eliminarlos.",
  "accounts_Only": "Solo",
  "accounts_PageCount": "%{página} de %{total}",
  "accounts_SearchForAnything": "Busca cualquier cosa...",
  "accounts_SelectAllDirectories": "Seleccionar todos los directorios",
  "accounts_UserCount": "%{count} miembros",
  "accounts_UserCountOne": "1 miembro",
  "accounts_VerifiedAll": "Verificado o no",
  "accounts_VerifiedNo": "Solo cuentas sin información verificada",
  "accounts_VerifiedTooltip": "Nombre y foto verificados vía DNI",
  "accounts_VerifiedYes": "Solo cuentas con información verificada",
  "all_environments": "Todos los ambientes",
  "all_envs": "Todos los ambientes",
  "apikey_disable_key": "Deshabilitar clave API",
  "apikey_disabled_success": "Se inhabilitó la clave API",
  "apikey_enable_key": "Habilitar clave API",
  "apikey_enabled_success": "Se habilitó la clave API",
  "apikeys_all_envs": "Todos los entornos",
  "apikeys_new_key_graf_1": "Asigne un nombre a esta clave para que pueda identificarla más tarde y luego copie la clave API.",
  "apikeys_new_key_graf_2": "Solo se mostrará una vez.",
  "apikeys_new_key_hed": "Nueva clave API",
  "apikeys_new_key_name_hint": "Agregar un nombre único",
  "apikeys_new_key_no_copy_warning": "Parece que aún no has copiado esta clave",
  "apikeys_new_key_secret_hed": "Clave API",
  "apikeys_select_env": "Seleccionar entorno",
  "apikeys_th_Created": "Fecha de creación",
  "apikeys_th_Enabled": "¿Activado?",
  "apikeys_th_Env": "Medioambiente",
  "apikeys_th_Name": "Nombre",
  "apikeys_th_Prefix": "Prefijo",
  "apikeys_th_Role": "Role",
  "apikeys_unnamed_key": "Clave sin nombre",
  "billing_Amount": "Cantidad",
  "billing_Cancel": "Cancelar",
  "billing_ContactUs": "Póngase en contacto con nuestro equipo para conocer los precios",
  "billing_CopilotPlus": "copiloto plus",
  "billing_CurrentPlan": "Plan actual",
  "billing_CurrentTotal": "Total actual para el ciclo de facturación que finaliza el %{date}",
  "billing_CustomerSupport": "Atención al cliente dedicada",
  "billing_Date": "Fecha",
  "billing_Description": "Descripción",
  "billing_DownloadPDF": "Descargar PDF",
  "billing_EnterPayment": "Ingrese un método de pago para registrarse",
  "billing_EnterPaymentSubhed": "A esta tarjeta se le cobrará **$100** hoy",
  "billing_Enterprise": "Empresa",
  "billing_EnterpriseInfo": "Obtenga acceso a las funciones premium de Copilot, que incluyen:",
  "billing_EnterpriseSaml": "Inicio de sesión único SAML",
  "billing_Expires": "Caduca el %{fecha}",
  "billing_Failed": "Fallido",
  "billing_Header": "Facturación",
  "billing_Labels": "Etiquetas de solicitud únicas",
  "billing_LoadPaymentMethodFailed": "No se puede cargar el método de pago",
  "billing_MultipleEnvs": "Múltiples entornos",
  "billing_MultipleTemplates": "Múltiples plantillas de solicitud",
  "billing_Onboarding": "Incorporación y capacitación guiadas",
  "billing_Overdue": "Atrasado",
  "billing_Paid": "Pagado",
  "billing_PayAsYouGo": "Paga sobre la marcha",
  "billing_PayAsYouGoInfo1": "La tarifa inicial de $100 incluye la validación de la marca y la eliminación del cartel de advertencia en sus solicitudes salientes.",
  "billing_PayAsYouGoInfo2": "A partir de ese momento, solo paga por las verificaciones que envíes. Su cuenta se facturará en un ciclo de 30 días, comenzando tan pronto como se registre.",
  "billing_PayAsYouGoUsage": "Pago por uso",
  "billing_PayNow": "Pagar ahora",
  "billing_PaymentMethod": "Método de pago",
  "billing_Pending": "Procesando",
  "billing_PendingBalance": "Balance pendiente",
  "billing_PerVerification": "por verificación",
  "billing_ProofOfVerification": "Prueba avanzada de verificación",
  "billing_SignUpForPayAsYouGo": "Regístrese en Pago por uso",
  "billing_SignUpNow": "Regístrate ahora",
  "billing_StartupFee": "tarifa de inicio",
  "billing_Status": "Estado",
  "billing_Subheader": "Revise la cantidad de verificaciones que ha enviado, actualice su plan Copilot y la información de facturación, y descargue los recibos y facturas de todos los pagos.",
  "billing_UnexpectedError": "Ocurrió un error inesperado.",
  "billing_UpdatePaymentMethod": "Actualizar método de pago",
  "billing_Verifications": "Verificaciones",
  "billing_VerificationsBalanceError": "Error al cargar el uso del plan",
  "billing_VerificationsBalanceInfo": "Solo paga por las verificaciones que has enviado. Su cuenta se facturará en un ciclo de 30 días a partir del %{date}.",
  "billing_ViewReceipt": "Ver recibo",
  "billing_VolumeBasedPricing": "Precios basados en volumen",
  "choose_allowed_envs": "Elija entornos permitidos",
  "choose_report_timeframe": "Elija el período de tiempo del informe",
  "countDays": "%{smart_count} día |||| %{smart_count} días",
  "countHours": "%{smart_count} hora |||| %{smart_count} horas",
  "countMinutes": "%{smart_count} minuto |||| %{smart_count} minutos",
  "countSeconds": "%{smart_count} segundo |||| %{smart_count} segundos",
  "createNewAccount": "No pudimos encontrar una cuenta existente con esa dirección de correo electrónico. ¿Te gustaría crear uno ahora?",
  "csr_AllTeamMembers": "Todos los miembros del equipo",
  "csr_BornAt": "Nacido %{birthDate}",
  "csr_CancelRequest": "Cancelar petición",
  "csr_CancelledInfo": "Esta solicitud ha sido cancelada y ya no puede ser aceptada por el usuario.",
  "csr_CompletedAt": "Completado %{relativeTime}",
  "csr_CompletedRequestInfo": "Esta solicitud se ha completado con éxito.",
  "csr_Configure": "Configurar",
  "csr_ConfigureInsufficientPermissions": "No tienes permiso para ver esta página.",
  "csr_ConfirmCancel": "Confirmar cancelación de solicitud",
  "csr_ConfirmCancelMsg": "¿Está seguro de que desea cancelar esta solicitud? Ya no será válido y el destinatario no podrá completar el proceso de verificación.",
  "csr_Copied": "copiado",
  "csr_Copy": "Copiar enlace único",
  "csr_CopyTrackingLink": "Copiar enlace de seguimiento",
  "csr_CreateNewLink": "Crear nuevo enlace",
  "csr_DeleteRequest": "Borrar petición",
  "csr_Description": "Se le pedirá a esta persona que inicie nuestro Clip de aplicación (o que descargue la aplicación Nametag) y siga rápidamente los pasos para verificar su identidad.",
  "csr_EmailProvidedUnverified": "Dirección de correo electrónico proporcionada (no verificada)",
  "csr_EmailVerified": "Correo electrónico verificado",
  "csr_EmptyState": "¡Aún no has enviado ninguna solicitud! Cree un enlace único o envíe un mensaje de texto con un enlace arriba y comenzará a ver los datos de su solicitud en esta tabla.",
  "csr_EmptyStateTitle": "Aún no has enviado ninguna solicitud de verificación.",
  "csr_EnsureMatch": "Asegúrate de que la información que han proporcionado coincida con quiénes dicen ser.",
  "csr_EnterLabel": "Ingrese una etiqueta corta para ayudarlo a encontrar esta solicitud más tarde (opcional)",
  "csr_EnterLabelPlaceholder": "Introduce una etiqueta corta",
  "csr_ErrorState": "Algo salió mal al cargar sus solicitudes. Por favor, inténtelo de nuevo más tarde.",
  "csr_ExpiredAt": "Caducó %{relativeTime}",
  "csr_ExpiredFilterHide": "Ocultar solicitudes caducadas",
  "csr_ExpiredFilterInclude": "Incluir solicitudes vencidas",
  "csr_ExpiredFilterOnly": "Mostrar solo solicitudes vencidas",
  "csr_ExpiredRequest": "Venció",
  "csr_ExpiredRequestInfo": "Esta solicitud ha caducado y ya no se puede aceptar.",
  "csr_ExpiredScopes": "Venció",
  "csr_ExpiredScopesInfo": "Los datos proporcionados por este usuario han caducado. Para acceder a él, deberá enviar una nueva solicitud.",
  "csr_FilterByAllActivity": "Toda la actividad",
  "csr_FilterByMyActivity": "Mi actividad",
  "csr_FilterByRequestor": "Costumbre",
  "csr_FilterByRequestorAPI": "Reinicios de autoservicio",
  "csr_FilterByRequestorAPIShort": "Autoservicio",
  "csr_FilterByRequestorSelectAll": "Seleccionar todos los usuarios",
  "csr_GenericError": "Algo salió mal. Inténtalo de nuevo.",
  "csr_Header": "Estado de las solicitudes",
  "csr_Hello": "¡Hola!",
  "csr_HelloName": "¡Hola %{name}!",
  "csr_Hide": "Esconder",
  "csr_IdDetails": "detalles de identificación",
  "csr_IdPending": "la identificación está pendiente",
  "csr_IdVerified": "Identificación verificada, selfie coincidente con la foto de identificación",
  "csr_InAppTitle": "Título en la aplicación",
  "csr_InAppTitleDisclaimer": "(mostrado anteriormente):",
  "csr_InProgressRequestInfo": "El usuario está actualmente en el proceso de completar esta solicitud.",
  "csr_InReviewInfo": "El escaneo de ID del usuario ha sido rechazado y está en proceso de revisión.",
  "csr_InvalidPhoneNumber": "Por favor ingrese un número de teléfono válido",
  "csr_ItemsRequested": "Artículos solicitados:",
  "csr_LabelTooLong": "La etiqueta no puede tener más de 64 caracteres.",
  "csr_LastUpdated": "Última actualización %{relativeTime}",
  "csr_LegalName": "Nombre legal",
  "csr_LinkButton": "Crear enlace de una sola vez",
  "csr_LinkCopied": "Enlace copiado al portapapeles",
  "csr_LinkCreated": "Se ha creado correctamente un enlace y está listo para ser enviado.",
  "csr_LinkNote": "NOTA: Este es un enlace de un solo uso y solo debe enviarse a un cliente.",
  "csr_LoadMore": "Carga más",
  "csr_NeverMind": "No importa",
  "csr_NewVerification": "Nueva verificación",
  "csr_NewVerificationRequest": "Nueva solicitud de verificación",
  "csr_No": "No",
  "csr_NoResultsFound": "Ningún contenido coincide con estos filtros.",
  "csr_NoResultsFoundClear": "Limpiar filtros",
  "csr_NoSMSToCountry1": "No se admiten SMS a %{country}.",
  "csr_NoSMSToCountry2": "Utilice un enlace de una sola vez o WhatsApp.",
  "csr_NotOver18": "No mayor de 18 años",
  "csr_NotOver21": "No mayor de 21 años",
  "csr_OneTimeLinkButton": "Copiar enlace único",
  "csr_Or": "o",
  "csr_Over18": "mayores de 18 años",
  "csr_Over21": "Mayores de 21 años",
  "csr_PendingRequestInfo": "Esta solicitud ha sido creada y está lista para que el usuario la acepte.",
  "csr_PersonDeletedInfo": "La persona asociada a esta solicitud ha sido eliminada.",
  "csr_PhoneButton": "Texto de un enlace",
  "csr_PhoneButtonSending": "Enviando pedido",
  "csr_PhoneEntered": "Número de teléfono ingresado:",
  "csr_PhonePending": "El número de teléfono está pendiente",
  "csr_PhoneVerified": "Número de teléfono verificado",
  "csr_PreferredName": "Nombre Preferido",
  "csr_ProfilePicVerified": "La foto de perfil coincide con la foto de identificación",
  "csr_Progress": "Progreso",
  "csr_ProgressMessage": "Verás su progreso a continuación.",
  "csr_ProgressStatusAndroidApp": "aplicación de Android abierta",
  "csr_ProgressStatusAndroidInstantApp": "Aplicación instantánea de Android abierta",
  "csr_ProgressStatusApp": "aplicación abierta",
  "csr_ProgressStatusDataShared": "datos compartidos",
  "csr_ProgressStatusIDScanned": "identificación escaneada",
  "csr_ProgressStatusIOSApp": "aplicación iOS abierta",
  "csr_ProgressStatusIOSAppClip": "Clip de la aplicación iOS abierto",
  "csr_ProgressStatusLinkClicked": "Enlace en el que se hizo clic",
  "csr_ProgressStatusRequestSent": "Solicitud enviada",
  "csr_ProgressStatusSelfieScanned": "Selfie escaneado",
  "csr_Recipient": "Recipiente",
  "csr_RejectedAt": "Rechazado %{relativeTime}",
  "csr_RejectedFraudInfo": "El escaneo de ID del usuario ha sido rechazado e identificado como un intento de fraude.",
  "csr_RejectedInfo": "Este usuario no ha podido verificarse a sí mismo",
  "csr_RequestActionsMenu": "Solicitar menú de acciones",
  "csr_RequestIssuerExplanation": "Utilice esta pantalla para ver las solicitudes de verificación recientes enviadas por usted y sus compañeros de equipo, ver los resultados y enviar nuevas solicitudes de verificación para verificar a los usuarios.",
  "csr_RequestLabel": "Solicitar etiqueta",
  "csr_RequestLabelDisclaimer": "(incluido si se envía por mensaje de texto o Whatsapp):",
  "csr_RequestProof": "Solicitar Prueba de Identidad",
  "csr_RequestStatusCancelled": "Cancelado",
  "csr_RequestStatusCompleted": "Terminado",
  "csr_RequestStatusExpired": "Venció",
  "csr_RequestStatusInProgress": "En progreso",
  "csr_RequestStatusPending": "Pendiente",
  "csr_RequestStatusPersonDeleted": "Persona eliminada",
  "csr_RequestStatusRejectedFraud": "Rechazado, fraude",
  "csr_RequestStatusRejectedInReview": "Rechazado, en revisión",
  "csr_RequestStatusRejectedUnusable": "Rechazado, inutilizable",
  "csr_RequestStatusRevoked": "Eliminado por el usuario",
  "csr_RequestTemplate": "Plantilla de solicitud",
  "csr_RequestedAt": "Solicitado %{relativeTime}",
  "csr_RequestedBy": "Solicitado por",
  "csr_Requests": "Peticiones",
  "csr_RequestsInfo": "Revise todas las solicitudes que su organización ha enviado en la siguiente tabla y haga clic en cualquier fila para ver los datos de identificación de la persona que completó la solicitud.",
  "csr_RevokedAt": "Revocado %{relativeTime}",
  "csr_RevokedInfo": "Este usuario ha dejado de compartir su información.",
  "csr_Search": "Busca cualquier cosa...",
  "csr_SelectTemplate": "Seleccionar plantilla de solicitud",
  "csr_Send": "Enviar",
  "csr_SignInTo": "Iniciar sesión en %{name}",
  "csr_SmsSent": "Una solicitud de SMS ha sido enviada con éxito.",
  "csr_SortCreated": "Recientemente creado",
  "csr_SortUpdated": "Actualizado recientemente",
  "csr_StatusInfo": "Verá que aparece una solicitud a continuación una vez que haya creado un enlace único o enviado un mensaje de texto. El estado de la solicitud se actualizará una vez que su destinatario haya intentado verificar su identidad.",
  "csr_TextButton": "Enviar por mensaje de texto",
  "csr_TextButtonTooltip": "Esta característica no está habilitada para su organización.",
  "csr_TimeFrameFilterAll": "Todos los marcos de tiempo",
  "csr_TimeFrameFilterLastMonth": "Actualizado el mes pasado",
  "csr_TimeFrameFilterMonth": "Actualizado este mes",
  "csr_TimeFrameFilterToday": "Actualizado hoy",
  "csr_TimeFrameFilterWeek": "Actualizado esta semana",
  "csr_TimeFrameFilterYear": "Actualizado año hasta la fecha",
  "csr_TrackingLinkCopied": "Enlace de seguimiento copiado",
  "csr_TrySending": "Intenta enviar uno ahora",
  "csr_UnusableInfo": "El escaneo de ID del usuario ha sido rechazado.",
  "csr_UserDataLink": "Haga clic aquí para ver todas las solicitudes de su equipo",
  "csr_ValidFor": "Válido por %{duration}",
  "csr_VarificationCount": "%{count} verificaciones",
  "csr_VarificationCountOne": "1 verificación",
  "csr_ViewStatus": "Vea el estado de esta solicitud a continuación",
  "csr_WelcomeBack": "¡Bienvenido de nuevo!",
  "csr_WelcomeBackName": "¡Bienvenido de nuevo, %{name}!",
  "csr_WhatsappButton": "Enviar a través de WhatsApp",
  "csr_WhatsappSent": "Un mensaje de WhatsApp ha sido enviado con éxito.",
  "csr_Yes": "Sí",
  "csr_requestIssuerTitle": "Estás viendo **Verificaciones** en **%{envName}**",
  "delete_members": "Eliminar miembros",
  "delete_n_members": "Eliminar %{n} miembros",
  "delete_one_member": "Eliminar miembro",
  "devNotes": "Notas de desarrollo",
  "developerOAuthConfigurationError": "Parece que el desarrollador a cargo de la configuración de OAuth de su organización cometió un error.",
  "dir_AddFirstIntegration": "Añade tu primer directorio",
  "dir_AddIntegration": "Agregar un directorio",
  "dir_AddIntegrationSubhed": "Elija qué tipo de directorio desea conectarse. La integración de un directorio creará a sus miembros como personas en Nametag y les permitirá realizar sus propias recuperaciones de cuentas.",
  "dir_AllowMfaResets": "Permitir restablecimientos de autenticación multifactor",
  "dir_AllowPasswordResets": "Permitir restablecimiento de contraseña",
  "dir_Authorize": "Autorizar",
  "dir_AzureAD": "Entra ID",
  "dir_ConnectAzureBtn": "Conéctese a Microsoft Entra ID",
  "dir_ConnectAzureHed": "Conéctese a Microsoft Entra ID (anteriormente Azure Active Directory)",
  "dir_ConnectAzureSubhed": "Establezca permisos y haga clic en **Conectar a Entra ID** para conectarse a Microsoft, donde habilitará la recuperación de cuenta de autoservicio para todos los miembros del directorio.",
  "dir_ConnectOktaSubhed": "Establezca permisos y recopile dos cosas de Okta para permitir la recuperación de cuentas de autoservicio para todos los miembros del directorio",
  "dir_ConnectToOkta": "Conéctate a Okta",
  "dir_Connect_Duo": "Conectar dúo",
  "dir_Connect_Okta": "Conectar Okta",
  "dir_Connected": "Conectado",
  "dir_Connecting": "Conectando...",
  "dir_DeleteDirectory": "Eliminar directorio",
  "dir_DeleteDirectoryConfirmGraf": "¿Está seguro de que desea eliminar este directorio?",
  "dir_DeleteDirectoryConfirmHed": "Confirmar eliminar directorio",
  "dir_Disconnected": "Desconectado",
  "dir_DuoHostInvalid": "Asegúrese de haber ingresado un nombre de host API válido (por ejemplo, api-33091yc9.duosecurity.com)",
  "dir_EmptyStateHed": "Aún no has configurado ningún directorio",
  "dir_EmptyStateSubhed": "Conecte Nametag a los directorios de su empresa para que los miembros de su equipo puedan realizar sus propias recuperaciones de cuentas después de bloqueos, en menos de 30 segundos.",
  "dir_Error": "¡Ups! Algo salió mal al cargar tus integraciones. Por favor, inténtelo de nuevo más tarde.",
  "dir_ExampleReset": "Alice Smith restablece su contraseña de Okta",
  "dir_ExampleResetTime": "Completado: ayer, 5:01 a.m.",
  "dir_FailedToRetrieveRedirectUrl": "No se pudo recuperar la URL de redireccionamiento",
  "dir_Integrations": "Directorios",
  "dir_IntegrationsSubhed": "Conecte Nametag con sus directorios para brindarles a sus empleados una recuperación de cuentas de autoservicio sin problemas. Los miembros del directorio podrán restablecer sus propias contraseñas y autenticación multifactor.",
  "dir_LastSyncAt": "Última sincronización %{time}",
  "dir_NotSyncedYet": "Aún no sincronizado",
  "dir_OktaURL": "URL correcta",
  "dir_OktaURLInvalid": "Asegúrese de haber ingresado una URL de Okta válida (por ejemplo, https://example.okta.com)",
  "dir_OneLoginHostInvalid": "Asegúrese de haber ingresado un nombre de host válido (por ejemplo, ejemplo.onelogin.com)",
  "dir_Reconnect": "Reconectar",
  "dir_RecoveryEnabled": "Recuperación de autoservicio habilitada",
  "dir_Remove": "Eliminar directorio",
  "dir_RemoveConfirmHed": "¿Está seguro de que desea eliminar este directorio?",
  "dir_RemoveConfirmSubhed": "La recuperación de cuenta de autoservicio ya no será posible. Esto también eliminará todas las cuentas, miembros del directorio y registros de recuperación de autoservicio de Nametag.",
  "dir_RemoveConfirmSubhed2": "**Esto no se puede revertir.** ¿Estás seguro de que deseas continuar?",
  "dir_RemoveSubhed": "Al eliminar esto, se eliminarán todos los miembros del directorio y sus datos de Nametag. La recuperación de la cuenta de autoservicio ya no será posible.",
  "dir_RemoveThisIntegration": "Eliminar este directorio",
  "dir_SelfService": "Autoservicio",
  "dir_SelfServicePermissions": "Permisos de autoservicio",
  "dir_SelfServicePermissionsSubhed": "Elija los restablecimientos de autoservicio que se pueden realizar para las cuentas de este directorio",
  "dir_Settings": "Ajustes",
  "dir_SyncFailedAt": "Error de sincronización %{relTime}",
  "dir_SyncFailedExplanation": "Reconfigurar la autenticación para este directorio puede resolver este problema.",
  "dir_SyncNeedsReconnectInfo1": "Nuevo:",
  "dir_SyncNeedsReconnectInfo2": "Hemos mejorado aún más la sincronización de directorios con una búsqueda de fotografías más inteligente. Para habilitarla, vuelva a configurarla con unos pocos clics.",
  "dir_SyncNow": "sincronizar ahora",
  "dir_SyncReconfigure": "Reconfigurar directorio",
  "dir_SyncStatus": "Estado de sincronización",
  "dir_UnnamedDirectory": "directorio sin nombre",
  "dir_UpdateFailed": "Algo salió mal al actualizar su directorio. Por favor, inténtelo de nuevo más tarde.",
  "dir_UsersCount": "(%{count} usuarios)",
  "dir_UsersCountOne": "1 miembro",
  "dir_duo_authorize_graf": "Establezca permisos y recopile tres cosas de Duo para permitir la recuperación de cuentas de autoservicio para todos los miembros del directorio",
  "dir_duo_authorize_title": "Conéctate a Dúo",
  "dir_invalid_credentials": "Tu configuración no es válida",
  "dir_okta_authorize_graf": "Para conectarse a Okta, necesitará conocer su URL de Okta y un token API.",
  "dir_okta_authorize_title": "Conéctate a Okta",
  "dir_onelogin_authorize_graf": "Establezca permisos y recopile tres cosas de OneLogin para permitir la recuperación de cuentas de autoservicio para todos los miembros del directorio",
  "dir_onelogin_authorize_title": "Conéctese a OneLogin",
  "directory_MicrositeConfigure_certificate": "El certificado está configurado correctamente",
  "directory_MicrositeConfigure_certificate_error": "El certificado no está configurado correctamente",
  "directory_MicrositeConfigure_check_again": "Revisar otra vez",
  "directory_MicrositeConfigure_checking": "Comprobando su configuración",
  "directory_MicrositeConfigure_dns": "DNS está configurado correctamente",
  "directory_MicrositeConfigure_dns_error": "DNS no está configurado correctamente",
  "directory_MicrositeConfigure_dns_graf": "Para habilitar el autoservicio, apunte el registro DNS para %{domain} a *nametaghosted.com*. Por ejemplo:",
  "directory_MicrositeConfigure_dns_theSite": "el sitio",
  "directory_MicrositeConfigure_graf": "Permita que sus usuarios restablezcan sus contraseñas y la autenticación multifactor después de validar su identidad con Nametag. El micrositio de recuperación está alojado por Nametag bajo un dominio que usted controla.",
  "directory_MicrositeConfigure_hed": "Configurar la recuperación de autoservicio",
  "dismissAlert": "Descartar alerta",
  "duo_api_instructions_1": "Inicie sesión en la consola de administración de Duo en https://admin.duosecurity.com/",
  "duo_api_instructions_2": "Vaya a **Aplicaciones** &gt; **Proteger una aplicación**",
  "duo_api_instructions_3": "Haga clic en **Proteger una aplicación**, busque **Duo Admin API** y presione **Proteger**",
  "duo_api_instructions_4": "En las secciones **Permisos**, habilite todos los permisos.",
  "duo_api_instructions_5": "Copie la **Clave de integración**, la **Clave secreta** y el **nombre de host de API** y péguelos en los campos siguientes",
  "duo_api_instructions_hed": "Instrucciones para conectarse a Duo",
  "duo_api_instructions_intro": "Para conectarse a Duo, proporcione la siguiente información desde el Panel de administración de Duo",
  "duo_host": "nombre de host API",
  "duo_host_placeholder": "Pegue el nombre de host de la API desde el panel de administración de Duo",
  "duo_ikey": "Clave de integración",
  "duo_ikey_placeholder": "Pegue la clave de integración desde el panel de administración de Duo",
  "duo_skey": "Llave secreta",
  "duo_skey_placeholder": "Pegue la clave secreta desde la consola de administración de Duo",
  "edit_members": "Editar miembros",
  "edit_n_members": "Editar %{n} miembros",
  "edit_one_member": "Editar miembro",
  "enterYourEmailAddress": "Ingrese su dirección de correo electrónico",
  "env_TimeBetween": "Introduzca un tiempo entre 1 y %{max}",
  "envs_3DLiveness": "Detección de vida 3D",
  "envs_API": "Claves API",
  "envs_Acceptverifiedemailsonlyfrom": "Acepte correos electrónicos verificados solo de:",
  "envs_Activeusers": "Usuarios activos",
  "envs_Activeusersgraph": "Gráfico de usuarios activos",
  "envs_AddAScope": "Agregar un alcance",
  "envs_AddDomain": "Agregar dominio",
  "envs_AddNew": "Agregar nuevo",
  "envs_AddScope": "Agregar alcance",
  "envs_AddScopeError": "Lo sentimos, no pudimos crear una propiedad",
  "envs_Address": "Dirección",
  "envs_AddressDetails": "La dirección de la persona, tal como está impresa en su identificación (no todas las identificaciones contienen esta información)",
  "envs_AgeOver18": "Mayores de 18 años",
  "envs_AgeOver18Details": "Confirmar que una persona es mayor de 18 años",
  "envs_AgeOver21": "Mayores de 21 años",
  "envs_AgeOver21Details": "Confirmar que una persona es mayor de 21 años",
  "envs_AnyEmailAddress": "Cualquier dirección de correo electrónico",
  "envs_Billing": "Facturación",
  "envs_BiometricConsent": "Persona consentida datos biométricos",
  "envs_BirthDate": "Fecha de nacimiento",
  "envs_BirthDateDetails": "La fecha de nacimiento de la persona.",
  "envs_BrandColors": "colores de la marca",
  "envs_BrandColorsGuidance": "Incluya hasta dos colores de marca para que sus solicitudes destaquen realmente.",
  "envs_Branding": "Marca",
  "envs_CallbackURL": "URL de devolución de llamada",
  "envs_CallbackURLHelp": "Esta es la URL a la que se redirige a un usuario después de la autenticación",
  "envs_Cancel": "Cancelar",
  "envs_ClientID": "Identificación del cliente",
  "envs_ClientIDHelp": "El ID de cliente es la parte pública de las credenciales de la API que autentica a su cliente en la API y autoriza los usos de la API por parte de su cliente.",
  "envs_ClientID_graf": "Esta es la parte pública de las credenciales de la API que autentica a su cliente en la API y autoriza los usos de la API por parte de ese cliente.",
  "envs_ClientSecret": "Secreto del cliente",
  "envs_ClientSecret_graf": "Esta es la parte privada de las credenciales de la API. Cree una nueva clave de API o utilice una existente como secreto de cliente.",
  "envs_ClientSecret_view_keys": "Ver claves de API existentes",
  "envs_Confidence": "Confianza %{confianza}",
  "envs_Configure": "Personalización de aplicaciones",
  "envs_Configure_Subhed": "Cree un aspecto único para su entorno e incluya el logotipo de su marca para que sus destinatarios sepan exactamente de dónde provienen sus solicitudes.",
  "envs_ConfirmDeleteAccept": "Eliminar alcance",
  "envs_ConfirmDeleteCancel": "mantener alcance",
  "envs_ConfirmDeleteGraf": "¿Está seguro de que desea eliminar el alcance %{scope}? Puede perder el acceso a los datos.",
  "envs_ConfirmDeleteHed": "Confirmar eliminación",
  "envs_ConfirmDeleteWebhookGraf": "¿Está seguro de que desea eliminar el webhook?",
  "envs_ConfirmUpdate": "Confirmar actualización",
  "envs_Copied": "copiado",
  "envs_Copy": "Copiar",
  "envs_CreateAPIKey": "Crear una clave de API",
  "envs_CsrLink": "Haga clic aquí para crear una nueva solicitud",
  "envs_DangerZone": "Zona peligrosa",
  "envs_Dangerzone": "Zona peligrosa",
  "envs_Datarequests": "Solicitudes de datos",
  "envs_Delete": "Borrar",
  "envs_DeleteConfirmGraph": "¿Está seguro de que desea eliminar este entorno? Puede perder el acceso a los datos.",
  "envs_DeleteDescription1": "Esto eliminará todo el entorno y todas sus actividades, historial y configuraciones.",
  "envs_DeleteDescription2": "Esta acción no se puede deshacer.",
  "envs_DeleteDescription3": "Si solo tiene un entorno, no se puede eliminar.",
  "envs_DeleteEnv": "Eliminar entorno",
  "envs_DeleteError": "Lo sentimos, no pudimos eliminar este entorno.",
  "envs_DeleteNamedEnv": "Eliminar el entorno &#39;%{appName}&#39;",
  "envs_DeliveryURL": "URL de entrega",
  "envs_Description": "Descripción",
  "envs_DescriptionHelp": "Este es el texto del título que aparece debajo de la aplicación.",
  "envs_DeviceCryptographicCoprocessor": "Coprocesador criptográfico",
  "envs_DeviceIntegrity": "Integridad del dispositivo",
  "envs_DeviceNotRooted": "no rooteado",
  "envs_DevicePublicKey": "Llave pública",
  "envs_DeviceRooted": "Arraigado",
  "envs_DeviceUserAgent": "Agente de usuario",
  "envs_DeviceVerificationFailed": "La verificación del dispositivo falló",
  "envs_DeviceVerified": "Dispositivo de confianza verificado",
  "envs_DigitalManipulationCheck": "Comprobación de manipulación digital",
  "envs_DocumentNumber": "Número del Documento",
  "envs_DocumentVerificationFailed": "No se pudo verificar la identificación auténtica emitida por el gobierno",
  "envs_DocumentVerified": "Identificación auténtica emitida por el gobierno verificada",
  "envs_Done": "Hecho",
  "envs_DriverLicense": "Licencia de conducir",
  "envs_Edit": "Editar",
  "envs_EditBranding": "Editar marca",
  "envs_Email": "Correo electrónico",
  "envs_EmailDetails": "La dirección de correo electrónico de la persona. Para que este valor esté presente, la persona habrá validado la dirección haciendo clic en un enlace que le enviamos o demostrando que puede iniciar sesión en la cuenta.",
  "envs_EmailDomains": "Restringir dominios de correo electrónico",
  "envs_EmailUnverified": "Correo electrónico (no verificado)",
  "envs_EmailUnverifiedDetails": "La dirección de correo electrónico que la persona dice tener. Es posible que hayan demostrado o no que son propietarios de la dirección de correo electrónico. No debe confiar en este valor y, desde luego, no enviar correos electrónicos utilizando esta dirección, a menos que lo haya validado usted mismo.",
  "envs_Empty": "(cuerpo vacío)",
  "envs_Enabled": "activado",
  "envs_Env": "Medioambiente",
  "envs_EnvID": "Identificación del entorno",
  "envs_EnvIDHelp": "El ID de entorno se utiliza en algunas solicitudes de API para indicar a qué entorno de la organización se aplica la solicitud. No es parte de la autenticación o autorización.",
  "envs_EnvLogo": "logotipo de medio ambiente",
  "envs_EnvName": "Nombre del entorno",
  "envs_Errors": "errores",
  "envs_Errorsgraph": "Gráfico de errores",
  "envs_ExpirationDate": "Fecha de caducidad",
  "envs_Expires": "Caduca",
  "envs_Expiresin": "Expira en",
  "envs_FaceMatched": "Rostro coincidente con ID",
  "envs_FirstName": "Primer nombre",
  "envs_FirstNameDetails": "El primer nombre (de pila) de la persona. Este nombre puede derivarse inicialmente de un documento de identidad, pero cada persona tiene la oportunidad de personalizar este valor. Si es posible, debería preferir usar el alcance &quot;Nombre&quot; sobre este porque no todas las culturas componen nombres de un nombre seguido de un apellido. Este valor puede estar presente incluso cuando el documento de identidad del que se deriva aún no haya sido autenticado. Para validar que la persona ha presentado un documento de identidad válido, utilice &quot;Nombre Legal&quot;.",
  "envs_GenericError": "Error al guardar los cambios",
  "envs_Genuine": "Genuino",
  "envs_GenuineApp": "aplicación genuina",
  "envs_GovtidData": "Datos de identificación del gobierno",
  "envs_GovtidDetails": "Detalles del documento de identificación del gobierno",
  "envs_GovtidDetailsDetails": "Detalles sobre el documento de identificación gubernamental de la persona. El valor es un documento JSON que contiene el tipo de documento, la autoridad emisora y el número de documento.",
  "envs_GovtidDetailsShort": "Detalles de identificación del gobierno",
  "envs_Graf1": "Para solicitar datos de un usuario, deberá definir un conjunto de *ámbitos* que definen los datos particulares que necesita y durante cuánto tiempo los necesitará. El usuario da su consentimiento a cada ámbito solicitado.",
  "envs_Graf2": "Aquí está definiendo el conjunto **máximo** de ámbitos que puede solicitar. Cuando realice una solicitud de autorización, deberá especificar el subconjunto de ámbitos que necesita para una persona en particular.",
  "envs_Header": "Encabezamiento",
  "envs_Home": "Casa",
  "envs_IPLocation": "Ubicación IP",
  "envs_IdType": "tipo de identificación",
  "envs_IdVerifiedAt": "Identificación emitida por el gobierno verificada a las %{time}",
  "envs_IdentityValidated": "Identidad validada con éxito",
  "envs_ImpersonationProtection": "Protección contra la suplantación de identidad",
  "envs_InternalName": "Nombre del entorno",
  "envs_InternalNameHelp": "El nombre interno de este entorno. (No se muestra a los usuarios)",
  "envs_IntroGraf": "Los webhooks te permiten recibir notificaciones de eventos relacionados con tus usuarios en tu aplicación. Cuando registra una URL, Nametag realizará una solicitud `POST` cuando ocurran ciertos eventos.",
  "envs_IntroGrafUserData": "Revise todas las solicitudes que su equipo ha enviado en la siguiente tabla. Podrá ver sus resultados y todos los datos verificados compartidos por sus usuarios. Ordene esta tabla por una determinada pieza de datos haciendo clic en los encabezados de las columnas. Haga clic y arrastre para cambiar el tamaño de las columnas.",
  "envs_InvalidUrl": "Por favor introduzca un URL válido",
  "envs_InviteError": "No puedo invitar a un nuevo miembro",
  "envs_Issuer": "Emisor",
  "envs_Items": "%{contar} elementos",
  "envs_KeepEnv": "mantener el medio ambiente",
  "envs_LastName": "Apellido",
  "envs_LastNameDetails": "El apellido (apellido) de la persona. Este nombre puede derivarse inicialmente de un documento de identidad, pero cada persona tiene la oportunidad de personalizar este valor. Si es posible, debería preferir usar el alcance &quot;Nombre&quot; sobre este porque no todas las culturas componen nombres de un nombre seguido de un apellido. Este valor puede estar presente incluso cuando el documento de identidad del que se deriva aún no haya sido autenticado. Para validar que la persona ha presentado un documento de identidad válido, utilice &quot;Nombre Legal&quot;.",
  "envs_LegalFirstName": "Primer nombre legal",
  "envs_LegalLastName": "Apellido legal",
  "envs_LegalName": "Nombre legal",
  "envs_LegalNameDetails": "El nombre legal de la persona derivado de un documento de identidad (licencia de conducir, pasaporte, etc.). Este valor no estará presente a menos y hasta que hayamos validado la autenticidad del documento de identidad. Este valor puede diferir del nombre preferido de la persona. Debe usar este valor cuando sea necesario para una transacción, pero prefiera &quot;Nombre&quot; para saludar a una persona o mostrar su nombre en su aplicación.",
  "envs_Log": "Registros",
  "envs_LoginDetails": "Permitir que una persona inicie sesión sin compartir más información que un asunto de etiqueta de identificación",
  "envs_Logo": "Logo",
  "envs_LogoGuidance": "Preferimos un logotipo simple sin el nombre de su empresa o aplicación. El logotipo se representará con un recorte circular como se muestra.",
  "envs_LogoQRGuidance": "El logotipo se mostrará dentro del código QR de escaneo para iniciar sesión; el logo debe tener un fondo transparente.",
  "envs_LogoTooLarge": "Logotipo no válido: el archivo debe tener menos de 5 MB",
  "envs_Match": "Coincidir con %{confianza}",
  "envs_Matched": "emparejado",
  "envs_Material": "Material",
  "envs_Members": "miembros",
  "envs_Name": "Nombre",
  "envs_NameDetails": "El nombre completo de la persona. Este nombre puede derivarse inicialmente de un documento de identidad, pero cada persona tiene la oportunidad de personalizar este valor. Si es posible, debería preferir usar este ámbito en lugar de &quot;Nombre&quot; y &quot;Apellido&quot; porque no todas las culturas tienen nombres compuestos por un nombre seguido de un apellido. Este valor puede estar presente incluso cuando el documento de identidad del que se deriva aún no haya sido autenticado. Para validar que la persona ha presentado un documento de identidad válido, utilice &quot;Nombre Legal&quot;.",
  "envs_Nametag": "Etiqueta de nombre",
  "envs_NotGenuine": "No original",
  "envs_NotMatched": "No coinciden",
  "envs_Notifications": "Notificaciones",
  "envs_Notifications_Subhed": "Selecciona las notificaciones que cada miembro del equipo recibirá a medida que avancen sus solicitudes de verificación.",
  "envs_OAuth": "OAuth",
  "envs_OAuth_callback_delete_accept": "Eliminar URL de devolución de llamada",
  "envs_OAuth_callback_delete_graf": "¿Está seguro de que desea eliminar esta URL de devolución de llamada? Cualquier aplicación que use esta URL dejará de funcionar.",
  "envs_OAuth_callback_delete_hed": "Confirmar la eliminación de la URL de devolución de llamada",
  "envs_OAuth_callback_invalid": "URL invalida",
  "envs_OAuth_create_new_api_key": "Crear nueva clave API",
  "envs_OAuth_description": "Nametag puede actuar como un proveedor de identidad compatible con OAuth 2.0. Use esta configuración para implementar &quot;iniciar sesión con ID&quot; con una biblioteca o paquete compatible con OAuth 2.0.",
  "envs_OAuth_save": "Guardar cambios",
  "envs_OIDC": "Requerido para configurar OpenID Connect (OIDC)",
  "envs_OIDCDetails": "Debe habilitar este ámbito si está configurando Nametag como proveedor de identidad de OpenID Connect (IdP de OIDC); no hay otra razón para habilitar este alcance",
  "envs_OnlyTheSpecifiedDomains": "Solo los dominios especificados",
  "envs_Org": "Organización",
  "envs_Other": "Otro",
  "envs_Overview": "Descripción general",
  "envs_Passport": "Pasaporte",
  "envs_Payload": "Carga útil",
  "envs_PersonConsented": "Persona que dio su consentimiento para compartir datos",
  "envs_Phone": "Teléfono",
  "envs_PhoneDetails": "El número de teléfono de la persona. Para que este valor esté presente la persona habrá comprobado que puede recibir mensajes de texto en este número tecleando un código de confirmación que le enviamos. El valor no estará presente a menos y hasta que la persona haya ingresado un código de confirmación.",
  "envs_ProfilePicture": "Foto de perfil",
  "envs_ProfilePictureDetails": "La foto de perfil verificada de la persona. Esto se representa como una URL a una imagen elegida por la persona. La imagen debe mostrar su rostro y su rostro debe coincidir con su identificación emitida por el gobierno.",
  "envs_Provided": "Proporcionó",
  "envs_PublicName": "Nombre público",
  "envs_PublicNameHelp": "Este es el nombre público de la aplicación, tal como se muestra a los usuarios. Este debe ser el nombre de su empresa u organización.",
  "envs_QRLogo": "Escanear para iniciar sesión logotipo",
  "envs_RecentDeliveries": "Entregas recientes",
  "envs_Region": "Región",
  "envs_RegionIreland": "Irlanda",
  "envs_RegionProcessing": "Procesando: %{región}",
  "envs_RegionStorage": "Almacenamiento: %{región}",
  "envs_RegionUS": "Estados Unidos",
  "envs_Replace": "Reemplazar",
  "envs_Request": "Solicitud",
  "envs_RequestCreated": "Solicitud creada",
  "envs_RequestCreatedBy": "Solicitud creada por %{name}",
  "envs_RequestCreatedSelfService": "Solicitud iniciada desde el sitio de autoservicio",
  "envs_RequestLog": "Solicitar registro",
  "envs_RequestMethod": "Método",
  "envs_RequestTemplates": "Solicitar plantillas",
  "envs_RequestURL": "URL",
  "envs_Response": "Respuesta",
  "envs_Save": "Guardar cambios",
  "envs_SaveButton": "Salvar",
  "envs_SaveError": "No pudimos guardar tus cambios.",
  "envs_SaveError2": "No se pueden guardar los cambios",
  "envs_SaveSuccess": "Salvado",
  "envs_Saving": "Ahorro...",
  "envs_Scope": "Alcance",
  "envs_SeeScopeDetails": "Ver detalles del alcance",
  "envs_SelfieMatched": "La selfie de la persona coincide con la foto de identificación",
  "envs_SelfieNotMatched": "El selfie de la persona no coincide con la foto de identificación",
  "envs_SendCompletedEmailHelp": "Cuando el usuario final completa una solicitud de verificación, el miembro del equipo que envió la solicitud recibirá un correo electrónico con información relevante, pero no de identificación personal.",
  "envs_SendCompletedEmailName": "Cuando se completa una solicitud de verificación",
  "envs_SendInProgressEmailHelp": "Cuando el usuario final inicia el proceso de verificación, el miembro del equipo que envió la solicitud será notificado por correo electrónico.",
  "envs_SendInProgressEmailName": "Cuando una solicitud de verificación está en progreso",
  "envs_SendLinkOpenedEmailHelp": "Cuando el enlace de la solicitud de verificación es abierto por el usuario final, el miembro del equipo que envió la solicitud será notificado por correo electrónico.",
  "envs_SendLinkOpenedEmailName": "Cuando se abre el enlace de la solicitud de verificación",
  "envs_SendRequestCancelledEmailHelp": "Cuando una solicitud de verificación es cancelada, el miembro del equipo que la envió será notificado por correo electrónico.",
  "envs_SendRequestCancelledEmailName": "Cuando una solicitud de verificación ha sido cancelada",
  "envs_SendRequestRejectedFraudEmailHelp": "Cuando se rechaza una solicitud debido a una sospecha de fraude, se notificará por correo electrónico al miembro del equipo que envió la solicitud. Los administradores y propietarios también recibirán esta notificación.",
  "envs_SendRequestRejectedFraudEmailName": "Cuando se rechaza una solicitud de verificación por sospecha de fraude",
  "envs_SendRequestRejectedUnusableEmailHelp": "Cuando se rechaza una solicitud debido a una mala calidad de imagen o información inutilizable, se notificará por correo electrónico al miembro del equipo que envió la solicitud.",
  "envs_SendRequestRejectedUnusableEmailName": "Cuando una solicitud de verificación es rechazada debido a información inutilizable",
  "envs_SendRevokedEmailHelp": "Cuando el usuario final revoca el consentimiento para una solicitud de verificación, se notificará al miembro del equipo que envió la solicitud.",
  "envs_SendRevokedEmailName": "Cuando el consentimiento para la solicitud de verificación ha sido revocado",
  "envs_SharedSecret": "Secreto compartido",
  "envs_SharedSecretGraf": "Cada solicitud de webhook contendrá un encabezado de solicitud `X-Nametag-Signature`. Este encabezado es un HMAC con codificación hexadecimal generado mediante la función hash SHA-256 y el secreto anterior. Su servidor debe validar que el cuerpo de la solicitud coincida con el secreto proporcionado para verificar que la solicitud realmente provino de Nametag.",
  "envs_Signins": "inicios de sesión",
  "envs_Signinsgraph": "Gráfico de inicios de sesión",
  "envs_Status": "Estado",
  "envs_Structure": "Estructura",
  "envs_TemplateConsistency": "Consistencia de la plantilla",
  "envs_TermsOfServiceURL": "URL de condiciones de servicio",
  "envs_TermsOfServiceURLGuidance": "Proporcione un enlace a sus términos de servicio. Esto les aparecerá a sus usuarios en la pantalla de inicio de sesión.",
  "envs_UnnamedEnv": "(Entorno sin nombre)",
  "envs_UnnamedOrg": "equipo sin nombre",
  "envs_UploadPlaceholder": "Elija una imagen PNG o JPEG",
  "envs_UserData": "Datos del usuario",
  "envs_Verified": "Verificado",
  "envs_VerifiedAt": "Verificado %{time}",
  "envs_VisitURL": "URL de visita",
  "envs_VisitURLGuidance": "Una URL de una página de cuenta o similar, para mostrar a los usuarios que ya han compartido información con su aplicación.",
  "envs_Webhooks": "Webhooks",
  "envs_WebhooksHelp": "Los webhooks te permiten recibir notificaciones de eventos relacionados con tus usuarios en tu aplicación.",
  "envs_acceptanyverifiedemailaddress": "Aceptar cualquier dirección de correo electrónico verificada",
  "envs_addCallbackButtonText": "Crear una URL de devolución de llamada",
  "envs_addWebhookButtonText": "Agregar un webhook",
  "envs_configureInstallLogo_graf": "Recomendamos utilizar un logotipo rectangular con fondo transparente. Esta versión del logotipo aparece en la parte superior izquierda de su página de instalación. La imagen debe tener menos de 5 MB y ser PNG o JPEG.",
  "envs_configureInstallLogo_hed": "Logotipo de la página de inicio de sesión",
  "envs_debugWebhooks": "Depurar webhooks",
  "envs_descriptionMissing": "(Sin descripción)",
  "envs_emailDomainPlaceholder": "ejemplo.com",
  "envs_noScope": "(elegir un alcance)",
  "envs_publicNameMissing": "(sin nombre público)",
  "envs_recoverDescription": "Un usuario completa la recuperación de cuenta de autoservicio",
  "envs_rejectDescription": "Los datos compartidos con tu aplicación están revocados",
  "envs_removeCallbackURL": "Eliminar URL de devolución de llamada",
  "envs_removeWebhook": "Eliminar webhook",
  "envs_requestDescription": "Se crea o actualiza una solicitud",
  "envs_shareDescription": "Los datos se comparten con su aplicación",
  "envs_termsOfServiceMissing": "(sin términos de servicio)",
  "envs_viewDetails": "Ver detalles",
  "footer_Company": "Compañía",
  "footer_Copyright": "© 2023 Nametag Todos los derechos reservados",
  "footer_Privacy": "Política de privacidad",
  "footer_TermsOfService": "Términos de servicio",
  "invite_member": "Miembro invitado",
  "justAddYourOrganizationNameToProceed": "Simplemente agregue el nombre de su **Organización** para continuar.",
  "lastAccessedRelTime": "Último acceso %{relTime}",
  "learn_more": "Aprende más",
  "memberOfMultipleOrganizations": "Parece que eres miembro de varias organizaciones. ¿A cuál te gustaría acceder?",
  "microsite_AreYouSure": "¿Estás seguro de que quieres continuar?",
  "microsite_Details": "Detalles",
  "microsite_DoneForNow": "hecho por ahora",
  "microsite_DuoMFAResetHed": "Sus credenciales de autenticación se han restablecido correctamente. Utilice el siguiente código para iniciar sesión en Duo y configurar nuevas opciones de MFA.",
  "microsite_DuoResetCode": "Código de reinicio de dúo",
  "microsite_DuoResetCodeTooltip": "Copie el código de reinicio en su portapapeles para continuar",
  "microsite_EnrollInMFA": "Inscríbase en MFA en %{link}",
  "microsite_EnterEmail": "Ingrese su dirección de correo electrónico del trabajo",
  "microsite_GenericError": "Algo salió mal. Por favor, inténtelo de nuevo más tarde.",
  "microsite_MFAResetHed": "Esto eliminará todos sus tokens de autenticación (mensajes SMS, aplicaciones de autenticación, claves de seguridad, etc.) para que pueda acceder a su cuenta y crear otras nuevas.",
  "microsite_MFAResetSuccessful": "Sus credenciales de autenticación se han restablecido correctamente. La próxima vez que inicie sesión en su cuenta %{directoryProvider}, deberá inscribirse nuevamente en MFA.",
  "microsite_NoDirectories": "Parece que no tienes ningún directorio conectado en este momento. Pídale ayuda a su administrador.",
  "microsite_OktaPasswordResetHed": "Tu contraseña ha sido restablecida. Continúe con Okta y cree una nueva contraseña para su cuenta. Este proceso no finalizará hasta que establezca una nueva contraseña.",
  "microsite_OneLoginPasswordResetHed": "Tu contraseña ha sido restablecida. Continúe con OneLogin y cree una nueva contraseña para su cuenta. Este proceso no finalizará hasta que establezca una nueva contraseña.",
  "microsite_ProceedToOkta": "Continúe hasta Okta",
  "microsite_ProceedToOneLogin": "Continúe con OneLogin",
  "microsite_ResetCodeMissing": "No se pudo recuperar el código de restablecimiento.",
  "microsite_ResetHed": "Restablezca contraseñas y autenticación multifactor (MFA) para sus cuentas laborales. Si tiene algún problema durante este proceso, comuníquese con el administrador de su equipo.",
  "microsite_ResetMFA": "Restablecer la autenticación multifactor",
  "microsite_ResetMFADuo": "Generar código de bypass",
  "microsite_ResetMFANo": "No, no reinicies los tokens",
  "microsite_ResetMFAYes": "Sí, restablecer tokens",
  "microsite_ResetPassword": "Restablecer su contraseña",
  "microsite_ResetSuccess": "¡Éxito! Has restablecido tus credenciales y deberías poder acceder a tu cuenta ahora.",
  "microsite_ResettingMFA": "Restableciendo su MFA...",
  "microsite_ResettingPassword": "Restableciendo su contraseña...",
  "microsite_ScanQR": "Escanea este código QR",
  "microsite_SecuredByNametag": "Asegurado por Nametag",
  "microsite_SigninSubhed": "Verifique su identidad con Nametag para continuar.",
  "microsite_TempPassword": "Contraseña temporal",
  "microsite_TempPasswordHed": "Utilice esta contraseña temporal para iniciar sesión en su cuenta. Una vez que obtenga acceso, cree una contraseña segura de inmediato.",
  "microsite_TempPasswordMissing": "No se pudo recuperar la nueva contraseña.",
  "microsite_TempPasswordTooltip": "Copie la contraseña temporal en su portapapeles para continuar",
  "microsite_Unlock": "Desbloquea tu cuenta",
  "microsite_UnlockSuccess": "¡Éxito! Has desbloqueado tu cuenta y deberías poder acceder a ella ahora.",
  "microsite_Unlocking": "Desbloqueando tu cuenta...",
  "microsite_UpdatePassword": "Actualice su contraseña en %{link}",
  "microsite_Welcome": "Bienvenido, %{nombre}",
  "multi-factor authentication reset": "restablecimiento de autenticación multifactor",
  "n_envs": "%{n} entornos",
  "navbar_configure": "Configurar",
  "navbar_request_proof": "Solicitar prueba",
  "neverAccessed": "Nunca accedido",
  "next": "Próximo",
  "next_page": "Siguiente página",
  "no_envs": "Sin ambientes",
  "not_found_Graph": "Lo sentimos, pero no se encontró la página que está buscando.",
  "not_found_Headline": "Extraviado",
  "not_found_Title": "404",
  "oauth_AlreadyInstalled": "¿Ya instaló la Nametag?",
  "oauth_BackHed": "Volver a %{appName}",
  "oauth_BackSubhed": "Cancela solicitud",
  "oauth_Cancel": "Cancelar",
  "oauth_CancelRequestConfirm_Cancel": "No importa",
  "oauth_CancelRequestConfirm_Confirm": "Cancelar petición",
  "oauth_CancelRequestConfirm_Graf": "¿Está seguro de que desea cancelar esta solicitud? La empresa que lo envió recibirá un mensaje de que usted se ha negado a responder.",
  "oauth_CancelRequestConfirm_Hed": "Si abandona esta página, se cancelará esta solicitud.",
  "oauth_DesktopHed": "Escanea este código QR con tu teléfono",
  "oauth_DesktopHelpDialog_Android": "Androide",
  "oauth_DesktopHelpDialog_Android_0": "¿No quieres instalar una aplicación? Utilice la aplicación instantánea Nametag.",
  "oauth_DesktopHelpDialog_Android_1": "Asegúrese de que su teléfono use Android 8 o superior para usar la aplicación Nametag Instant.",
  "oauth_DesktopHelpDialog_Android_2": "(Nota: no todos los dispositivos Android admiten InstantApps; si el tuyo no lo hace, descarga la aplicación Nametag desde Play Store)",
  "oauth_DesktopHelpDialog_Android_3": "Es posible que también necesites ajustar algunas de las configuraciones de tu dispositivo:",
  "oauth_DesktopHelpDialog_Android_4": "Abra la aplicación Google Play Store",
  "oauth_DesktopHelpDialog_Android_5": "En la parte superior derecha, toca el ícono de perfil.",
  "oauth_DesktopHelpDialog_Android_6": "Toca **Configuración &gt; General &gt; Google Play Instant**",
  "oauth_DesktopHelpDialog_Android_7": "Activa **Actualizar enlaces web** o **Google Play Instant**",
  "oauth_DesktopHelpDialog_Close": "estoy listo",
  "oauth_DesktopHelpDialog_Hed": "¿Necesito ayuda?",
  "oauth_DesktopHelpDialog_Subhed": "Diferentes dispositivos tienen conjuntos de instrucciones ligeramente diferentes",
  "oauth_DesktopHelpDialog_iPhone": "iPhone",
  "oauth_DesktopHelpDialog_iPhone_1": "Asegúrese de que su teléfono use iOS 14 o superior para usar Nametag App Clip.",
  "oauth_DesktopHelpDialog_iPhone_2": "Si no está utilizando esta versión, acceda a **Configuración &gt; General &gt; Actualización de software** en su teléfono y descargue las actualizaciones disponibles.",
  "oauth_DesktopSubhed2": "%{envName} desea verificar su identidad.",
  "oauth_EnvLogo": "logotipo de medio ambiente",
  "oauth_Explanation": "%{appName} usa Nametag para solicitar una prueba de que realmente eres tú. Nametag es una forma segura de probar rápidamente su identidad y proteger su información personal.",
  "oauth_GetTheApp": "Obtenga la aplicación Nametag para completar el inicio de sesión",
  "oauth_HelpPrompt": "¿Necesito ayuda?",
  "oauth_InfoText": "Cuando abra la aplicación por primera vez, accederá directamente al portal de inicio de sesión de %{appName}.",
  "oauth_LaunchingAppClip": "Ejecutando clip de aplicación...",
  "oauth_LearnMore": "Aprende más",
  "oauth_NametagDescription": "Nametag es un autenticador basado en la identidad que asegura sus cuentas y lo ayuda a proteger su información personal en línea.",
  "oauth_OpenApp": "Abre la aplicación",
  "oauth_ProductName": "Etiqueta de nombre",
  "oauth_QrAlt": "Escanea este código QR para continuar",
  "oauth_QrCaption": "Escanea con la cámara de tu teléfono para continuar",
  "oauth_SecuredBy": "Asegurada por",
  "oauth_SigningIn": "Iniciando sesión...",
  "oauth_TapToSignIn": "Toque &#39;ABRIR&#39; para iniciar sesión",
  "oauth_genericErrorMessage": "Algo salió mal",
  "oauth_oauthErrorDetail": "Motivo: %{mensaje}.",
  "oauth_oauthErrorHed": "Error del desarrollador",
  "oauth_oauthErrorSubhed": "Debido a una mala configuración por parte del desarrollador de la aplicación, no podemos continuar. Notifique al desarrollador de la aplicación o vuelva a intentarlo en unos minutos.",
  "oauth_rejectedHed": "Rechazado",
  "oauth_rejectedSubhed": "Parece que ha rechazado la solicitud de %{appName}",
  "oauth_requestExpired": "La solicitud ha caducado",
  "oauth_requestRejected": "La solicitud fue rechazada",
  "oauth_theEnvironment": "la aplicación",
  "okta_HowToConnect": "Instrucciones para conectarse a Okta",
  "okta_api_instructions_1": "Inicie sesión en el portal de administración de Okta",
  "okta_api_instructions_2": "Vaya a *Seguridad* &gt; *API*",
  "okta_api_instructions_3": "Seleccione la pestaña *Tokens*",
  "okta_api_instructions_4": "Presione *Crear token*",
  "okta_api_instructions_5": "Ingrese **Nametag** para el nombre del token y presione *Crear token*",
  "okta_api_instructions_6": "Copia el token y pégalo a continuación.",
  "okta_api_instructions_intro": "Para crear un token API:",
  "okta_api_token": "Ficha API",
  "okta_api_token_placeholder": "Pega tu token API de Okta",
  "okta_url": "URL correcta",
  "okta_url_placeholder": "https://tu-empresa.okta.com",
  "one_env": "Un entorno",
  "onelogin_api_instructions_1": "Inicie sesión en la consola de administración de OneLogin en https://onelogin.com/",
  "onelogin_api_instructions_2": "Vaya a Desarrolladores &gt; **Credenciales API**",
  "onelogin_api_instructions_3": "Haga clic en **Nueva credencial**",
  "onelogin_api_instructions_4": "Ingrese **Etiqueta de nombre** como nombre, seleccione **Administrar todo** y presione **Guardar**",
  "onelogin_api_instructions_5": "Copie **ID de cliente**, **Secreto de cliente** y péguelo a continuación",
  "onelogin_api_instructions_hed": "Instrucciones para conectarse a OneLogin",
  "onelogin_api_instructions_intro": "Para conectarse a OneLogin, proporcione la siguiente información desde la consola OneLogin",
  "onelogin_client_id": "Identificación del cliente",
  "onelogin_client_id_placeholder": "Pegue el ID del cliente desde la consola de administración de OneLogin",
  "onelogin_client_secret": "Secreto del cliente",
  "onelogin_client_secret_placeholder": "Pegue el secreto del cliente desde la consola de administración de OneLogin",
  "onelogin_host": "Nombre de host de OneLogin",
  "onelogin_host_placeholder": "Pegue el nombre de host desde la consola de administración de OneLogin",
  "organizationNameExample": "por ejemplo, Corporación ACME",
  "orgs_APIKeys": "Claves API",
  "orgs_APIKeys_description": "Use claves API para integraciones con otros servicios, OAuth 2.0 o su propio código.",
  "orgs_APIKeys_link": "Referencia de la API",
  "orgs_AcceptInvitation": "Aceptar la invitacion",
  "orgs_Admin": "Administración",
  "orgs_AdminDescription": "Acceso completo a sus entornos y su configuración.",
  "orgs_AlreadyAMemberError": "Ya eres miembro de este equipo",
  "orgs_Cancel": "Cancelar",
  "orgs_CancelInvitation": "Cancelar invitación",
  "orgs_CloseConfirmRemoveMember": "Cerrar eliminar modal de confirmación de miembro",
  "orgs_ConfirmCancelGraf": "¿Está seguro de que desea cancelar la invitación de esta persona al equipo?",
  "orgs_ConfirmCancelInvitation": "Confirmar cancelar invitación",
  "orgs_ConfirmDeleteAbort": "Mantener miembro",
  "orgs_ConfirmDeleteAccept": "Quitar miembro",
  "orgs_ConfirmDeleteGraf": "¿Estás seguro de que quieres eliminar a esta persona del equipo?",
  "orgs_ConfirmDeleteHed": "Confirmar eliminación",
  "orgs_ConfirmRemoveMember": "Confirmar eliminar miembro",
  "orgs_DeleteConfirmGraph": "Está seguro de que desea eliminar esta organización y todos sus entornos? Perderá el acceso a estos datos y esto no se puede deshacer. ",
  "orgs_DeleteConfirmTitle": "Confirmar eliminación",
  "orgs_DeleteDescription1": "Esto eliminará toda la organización, todos sus entornos y las claves de API. Los miembros ya no podrán acceder a esta organización. ",
  "orgs_DeleteDescription2": "Esta acción no se puede deshacer",
  "orgs_DeleteError": "Lo sentimos, no pudimos eliminar a esta persona.",
  "orgs_DeleteOrg": "Eliminar organización",
  "orgs_DeleteThisOrg": "Eliminar esta organización",
  "orgs_Emailaddress": "Introduzca la dirección de correo electrónico",
  "orgs_Error": "Algo salió mal al aceptar la invitación. Si aún no lo ha aceptado, comuníquese con la persona que le envió la invitación.",
  "orgs_Invite": "Enviar invitacion",
  "orgs_InviteAction": "Agregar nuevo miembro",
  "orgs_InviteError": "No puedo invitar a un nuevo miembro",
  "orgs_InviteGraf": "Ha sido invitado a unirse a un equipo de Nametag:",
  "orgs_InviteSuccess": "Invitación enviada",
  "orgs_Limited": "Limitado",
  "orgs_LimitedDescription": "Acceso únicamente a sus propias solicitudes y resultados simplificados en sus entornos.",
  "orgs_LimitedPlus": "Limitado+",
  "orgs_LimitedPlusDescription": "Puede emitir solicitudes y ver resultados simplificados para todas las solicitudes.",
  "orgs_Manage": "Gestionar",
  "orgs_Members": "miembros",
  "orgs_MembersSubtitle": "Los miembros de su organización podrán ver y editar todas las etapas y actividades del lanzamiento. Solo los administradores pueden agregar y eliminar a otros miembros.",
  "orgs_MembersSubtitleSAML": "Los miembros de su organización podrán ver y editar todas las etapas y actividades del lanzamiento. Debido a que el inicio de sesión único de SAML está habilitado, su proveedor de identidad controla la membresía.",
  "orgs_Name": "Nombre",
  "orgs_NameHelp": "El nombre de tu equipo. Solo visible para los miembros del equipo",
  "orgs_Neworg": "Nueva organización",
  "orgs_Optional": "opcional",
  "orgs_Org": "Organización",
  "orgs_Orgs": "Organizaciones",
  "orgs_Owner": "Dueño",
  "orgs_OwnerDescription": "Acceso completo a la organización, todos los entornos y su configuración.",
  "orgs_Remove": "Eliminar",
  "orgs_RemoveMember": "Quitar miembro",
  "orgs_RestrictAdd": "Agregar rango de direcciones IP",
  "orgs_RestrictAllow": "Permitir el acceso de Nametag a los siguientes rangos de direcciones IP...",
  "orgs_RestrictInfo": "Restrinja el acceso a console.nametag.co a determinadas direcciones IP. Los miembros de su equipo solo podrán iniciar sesión en Nametag desde las direcciones IP que haya ingresado a continuación. Cualquiera que inicie sesión desde fuera de los rangos de IP especificados recibirá un mensaje genérico de error de inicio de sesión.",
  "orgs_RestrictInstructions": "Instrucciones: Ingrese direcciones IPv4 o IPv6 o bloques CIDR. Si la lista está vacía, se permitirán todas las direcciones IP.",
  "orgs_RestrictInvalidIp": "Una o más direcciones IP no son válidas.",
  "orgs_RestrictTitle": "Restringir los inicios de sesión con etiquetas de nombre por dirección IP",
  "orgs_Save": "Salvar",
  "orgs_SaveError": "No se pudo guardar el equipo",
  "orgs_SaveError2": "No se pueden guardar los cambios",
  "orgs_SaveSuccess": "Salvado",
  "orgs_SetRoleError": "No se puede establecer el rol",
  "orgs_SignInCTA": "Iniciar sesión con identificación",
  "orgs_Success": "Invitación aceptada",
  "orgs_User": "Usuario",
  "orgs_UserDescription": "Acceso a todas las solicitudes y resultados detallados en sus entornos.",
  "orgs_confirmDeleteAbort": "No importa",
  "orgs_confirmDeleteAccept": "Eliminar clave API",
  "orgs_confirmDeleteGraf": "¿Está seguro de que desea eliminar esta clave de API? Se desconectarán todas las aplicaciones o integraciones que estén conectadas con esta clave.",
  "orgs_confirmDeleteHead": "Confirmar la eliminación de la clave API",
  "orgs_copied": "copiado",
  "orgs_copy": "Copiar",
  "orgs_createAPIKey": "Crear nueva clave API",
  "orgs_createdTime": "Creado en",
  "orgs_deleteError": "Lo sentimos, no pudimos eliminar esta clave API",
  "orgs_onlyShownOnce": "Esto solo se mostrará una vez",
  "orgs_prefix": "Prefijo",
  "orgs_remove": "Eliminar",
  "orgs_secretMessage": "Su nueva clave API es %{secret}",
  "orgs_unnamedorg": "(Organización sin nombre)",
  "orgs_you": "usted",
  "orgs_youWillOnlySeeThisOnce": "Solo verás esto una vez",
  "page_n_of_c": "Página %{n} de %{c}",
  "parens_optional": "(opcional)",
  "password reset": "restablecimiento de contraseña",
  "past_n_days": "Últimos %{n} días",
  "past_n_months": "Últimos %{n} meses",
  "pasteYourX509CertificateHere": "Pegue su certificado x509 aquí",
  "pending": "Pendiente",
  "pleaseContactYourDeveloperForHelp": "Póngase en contacto con su desarrollador para obtener ayuda.",
  "previous_page": "Pagina anterior",
  "reports_Header": "Informes",
  "reports_Subheader": "Revise el uso general de Nametag por parte de su equipo y vea los resultados de las solicitudes que se enviaron durante un tiempo determinado. Personalice el período de tiempo y haga clic en ciertos informes para ver detalles adicionales.",
  "request_AcceptedManually": "La auditoría confirmó que la identidad se validó correctamente",
  "request_AddressBusiness": "Dirección de Negocios",
  "request_AddressPoBox": "apartado de correos",
  "request_AddressResidential": "Dirección residencial",
  "request_BarcodeChecks": "%{contar} cheques",
  "request_BarcodeDetails": "Detalles del código de barras",
  "request_BarcodeViewDetails": "Ver detalles adicionales",
  "request_Beta": "Beta",
  "request_Cancelled": "La solicitud fue cancelada",
  "request_Cancelled2": "solicitud cancelada",
  "request_CancelledAt": "Esta solicitud fue cancelada a las %{time}",
  "request_CreatedAt": "Solicitud creada a las %{time}",
  "request_DataPoint": "Punto de datos",
  "request_DeviceInfoUnavailable": "No hay información adicional disponible para este dispositivo",
  "request_DeviceLocation": "Ubicación del dispositivo",
  "request_DeviceLocationInfo": "La ubicación del dispositivo utilizado para escanear el documento, según lo determinado por la dirección IP del dispositivo.",
  "request_Distance": "Distancia",
  "request_DistanceInfo": "La distancia entre la ubicación del dispositivo y la dirección del documento de identidad.",
  "request_DistanceValue": "%{distancia} millas",
  "request_DocumentAddress": "dirección del documento de identidad",
  "request_DocumentAddressInfo": "La dirección validada en el documento de identificación, si está presente.",
  "request_ErrorCaption": "¿Sigues teniendo problemas? Póngase en contacto con help@nametag.co",
  "request_ErrorHed": "¡Huy! Algo salió mal!",
  "request_ErrorSubhed": "Intentemoslo de nuevo.",
  "request_ErrorSubhedRetryBusy": "Intentando otra vez...",
  "request_ErrorSubhedRetryDelay": "Intentemoslo de nuevo. Refrescante en...",
  "request_Expired": "La solicitud ha caducado",
  "request_Expired2": "Solicitud caducada",
  "request_ExpiredAt": "La solicitud expiró a las %{time}",
  "request_ExpiredScopes": "La solicitud caducó y se eliminaron los datos",
  "request_ExpiredScopesAt": "Los datos se eliminaron automáticamente %{time}",
  "request_GeolocationHeader": "Información de geolocalización calculada",
  "request_IdInfoUnavailable": "No hay información adicional disponible para esta identificación emitida por el gobierno",
  "request_IdLabel": "Identificador de solicitud",
  "request_InProgress": "La solicitud está en curso",
  "request_PersonDeleted": "La persona fue eliminada",
  "request_PersonDeletedExplanation": "La persona asociada a esta solicitud ha eliminado sus datos",
  "request_ProofOfVerification": "Prueba de verificación",
  "request_RejectReasonDevice": "porque no se pudo verificar el dispositivo",
  "request_RejectReasonDocument": "porque no se pudo verificar la identificación emitida por el gobierno",
  "request_RejectReasonSelfie": "debido a una coincidencia de selfie fallida",
  "request_Rejected": "No se pudo verificar la identidad",
  "request_RejectedAt": "Rechazado %{reason} a las %{time}",
  "request_RejectedFraud": "Rechazado porque la detección automatizada de fraude detectó un alto riesgo de fraude",
  "request_RejectedInReview": "Rechazado porque la detección automática de fraude detectó un alto riesgo de fraude. El uso ha apelado la decisión y está bajo revisión.",
  "request_RejectedManually": "La auditoría confirmó que no se puede verificar la identidad",
  "request_RejectedUnusable": "Rechazado porque la detección automática determinó que el documento era inutilizable.",
  "request_RejectedWithReason": "Solicitud rechazada %{motivo}",
  "request_Revoked": "La solicitud fue revocada",
  "request_Revoked2": "El destinatario revocó la solicitud y dejó de compartir datos",
  "request_RevokedAt": "El destinatario revocó esta solicitud %{time}",
  "request_SelfieInfoUnavailable": "No hay información adicional disponible para este selfie",
  "request_String": "Cadena",
  "request_TooltipAppVersion": "Esta es la versión de la aplicación Nametag que se utiliza para completar la solicitud.",
  "request_TooltipBarcodeDetails": "Estos son los datos del código de barras que se leen del documento, lo que ayuda a verificar la autenticidad del documento.",
  "request_TooltipBiometricConsentRegion": "Esta es la región donde se almacenan y procesan los datos biométricos.",
  "request_TooltipDataConsentRegion": "Esta es la región donde se almacenan y procesan los datos.",
  "request_TooltipDeviceIntegrity": "Esto garantiza que el proceso de escaneo de identificación sea seguro al confirmar que Nametag está utilizando una aplicación instalada legítimamente en un dispositivo móvil genuino y no en un simulador, un dispositivo liberado o un entorno comprometido de otra manera.",
  "request_TooltipDeviceUserAgent": "Este es el agente de usuario del dispositivo que esta persona utiliza para completar la solicitud. Así es como el dispositivo se identifica en la aplicación Nametag.",
  "request_TooltipDocumentAppAttestation": "Esto garantiza que la imagen del documento provenga del dispositivo del usuario y no sea una imagen manipulada.",
  "request_TooltipDocumentMaterial": "Así de seguro está Nametag de que este documento consta del material esperado, lo que ayuda a verificar que el documento no es una falsificación.",
  "request_TooltipDocumentStructure": "Así de seguro está Nametag de que este documento tiene la estructura esperada, lo que ayuda a verificar que el documento no es una falsificación.",
  "request_TooltipDocumentTemplate": "Así de seguro está Nametag de que este documento tiene el formato esperado, lo que ayuda a verificar que el documento no es una falsificación.",
  "request_TooltipPublicKey": "Esta es la clave pública del coprocesador criptográfico del dispositivo, que ayuda a verificar la autenticidad del dispositivo.",
  "request_TooltipRequestId": "Este ID de solicitud se puede utilizar para hacer referencias cruzadas de datos de clientes API y webhooks.",
  "request_TooltipSelfieAppAttestation": "Esto garantiza que la selfie provenga de una cámara del teléfono del usuario y no de una imagen manipulada.",
  "request_TooltipSelfieChain": "Esto garantiza que la misma persona real esté vinculada con una identidad gubernamental, incluso en múltiples inscripciones y tipos de identificación.",
  "request_TooltipSelfieDepthMap": "Ésta es la confianza de que el rostro del selfie es una persona real y no una imagen.",
  "request_TooltipSelfieFaceMatch": "Esta es la confianza de que el rostro del selfie es el mismo que el del documento.",
  "request_completed": "Solicitud completada",
  "request_rejected": "Solicitud rechazada",
  "samlCertHelpGraf": "Este certificado permite que Nametag verifique las credenciales emitidas por su proveedor de identidad. Este valor lo proporciona su proveedor de identidad.",
  "samlCertLabel": "Certificado público",
  "samlCheckOkGraf1": "Su organización ahora puede usar SAML SSO para iniciar sesión en Nametag.",
  "samlCheckOkGraf2": "Esto transferirá todos los controles de los miembros a su proveedor de identidad SAML. Esto incluye invitaciones, eliminaciones y funciones.",
  "samlCheckOkHed": "¡Felicidades! Su prueba pasó con éxito.",
  "samlErrorGraf": "Parece que el desarrollador a cargo de la configuración de SAML de su organización cometió un error.",
  "samlErrorHed": "Algo anda mal con SAML",
  "samlUpdatePending": "Esta actualización está pendiente hasta que pase una prueba con éxito.",
  "saml_group_name": "Nombre del grupo SAML",
  "saml_groups_graf": "Su proveedor de identidad SAML nos indicará un grupo para cada usuario. Debe asignar estos grupos a los permisos de Nametag.",
  "saml_groups_hed": "Mapeo de grupo",
  "saveThisSignInMethod": "Guardar este método de inicio de sesión",
  "search_all_members": "Buscar todos los miembros",
  "shared_empty": "Nadie ha compartido datos con esta aplicación todavía.",
  "shared_empty_with_query": "No se encontraron registros coincidentes",
  "shared_error": "Error al cargar datos",
  "shared_reload": "Recargar",
  "shared_search": "Buscar en todos los campos...",
  "sidenav_APIKeys": "claves API",
  "sidenav_Billing": "Facturación",
  "sidenav_Billing_Disabled": "Debes ser propietario para acceder a la facturación.",
  "sidenav_DangerZone": "Zona peligrosa",
  "sidenav_Directories": "Directorios",
  "sidenav_Directories_Disabled": "Debe ser propietario para acceder a los directorios.",
  "sidenav_Help": "¿Necesitas ayuda?",
  "sidenav_Members": "miembros",
  "sidenav_OAuth": "OAuth",
  "sidenav_Organization": "Organización",
  "sidenav_Reports": "Informes",
  "sidenav_RequestTemplates": "Solicitar plantillas",
  "sidenav_Scopes": "Solicitudes de datos",
  "sidenav_Webhooks": "Webhooks",
  "signIn": "Iniciar sesión",
  "signInWithSSO": "Iniciar sesión con SSO",
  "signOut_Header": "Has sido desconectado.",
  "signOut_SignBackIn": "Vuelva a iniciar sesión para acceder a Nametag.",
  "signinEmailGraf1": "Esto requerirá que los miembros de su equipo hagan clic en un enlace en un correo electrónico para acceder a su organización.",
  "signinEmailGraf2": "Deberá invitar a cada persona con su dirección de correo electrónico del trabajo. Si necesita agregar una gran cantidad de miembros del equipo a la vez, le sugerimos utilizar SAML SSO.",
  "signinEmailHed": "Iniciar sesión con Nametag",
  "signinNametagGraf1": "Esto requerirá que los miembros de su equipo escaneen un código QR y verifiquen su identidad usando Nametag para acceder a su organización. ¡Recomendamos utilizar este método de inicio de sesión!",
  "signinNametagGraf2": "Deberá invitar a cada persona con su dirección de correo electrónico del trabajo. Si necesita agregar una gran cantidad de miembros del equipo a la vez, le sugerimos usar SAML SSO.",
  "signinNametagHed": "Iniciar sesión con la Nametag",
  "signin_Email_NoOrg_Graf1": "No pudimos encontrar una cuenta con esta dirección de correo electrónico.",
  "signin_Email_NoOrg_Graf2": "Regístrese para enviar solicitudes de identificación.",
  "signin_Email_NoOrg_Graf3": "¿Está tratando de responder a la solicitud de identificación de una empresa? [Haga clic aquí](https://getnametag.com/respond-to-a-request).",
  "signin_Error_Page": "Fallo al iniciar sesion",
  "signin_Error_Page_info_1": "No parece que seas elegible para iniciar sesión en esta cuenta.",
  "signin_Error_Page_info_2": "¿Crees que esto es un error? Comuníquese con el administrador de su equipo para obtener más información.",
  "signin_graf": "Seleccione un método de inicio de sesión para su organización. Los miembros deberán firmar de esta manera.",
  "signin_hed": "Método de inicio de sesión",
  "signin_marketing_graf_1": "Envíe solicitudes de verificación a sus clientes y revise análisis detallados",
  "signin_marketing_graf_2": "Los clientes tocan para compartir su identificación de una manera que preserva la privacidad",
  "signin_marketing_graf_3": "Reciba la confirmación de validación y los datos del cliente de forma segura y eficiente",
  "signin_marketing_hed_1": "Prevención de fraude impulsada por IA",
  "signin_marketing_hed_2": "Experiencia móvil perfecta",
  "signin_marketing_hed_3": "Empoderar e informar a los CSR",
  "signup1_autopilot1": "Recuperación de cuentas de autoservicio para su fuerza laboral ",
  "signup1_autopilot2": "Proporcionar autoservicio de restablecimiento de contraseñas y MFA a los empleados",
  "signup1_autopilot3": "Integre con el directorio de su empresa y sincronice cuentas",
  "signup1_birdseed": "Al continuar, reconoce y acepta la [Política de privacidad] (https://getnametag.com/privacy) y los [Términos de servicio] (https://getnametag.com/terms) de Nametag.",
  "signup1_caption": "Regístrese para una prueba gratuita de 14 días para ver Nametag en acción",
  "signup1_click_to_signin": "Clic aquí para ingresar",
  "signup1_copilot1": "Verificación del servicio de asistencia técnica para sus agentes de soporte",
  "signup1_copilot2": "Permitir a los agentes verificar a las personas que se comunican con el servicio de asistencia técnica.",
  "signup1_copilot3": "Integre directamente con su sistema de venta de entradas",
  "signup1_email_already_exists": "¡Parece que ya tienes una cuenta Nametag!",
  "signup1_email_label": "Correo electrónico del trabajo",
  "signup1_email_placeholder": "Ingrese su dirección de correo electrónico",
  "signup1_footer_bullet1": "Funciona en cualquier dispositivo iOS o Android",
  "signup1_footer_bullet2": "Los usuarios pueden controlar y revocar sus propios datos.",
  "signup1_footer_bullet3": "Agregue miembros del equipo y personalice la marca",
  "signup1_fullname_label": "Nombre completo",
  "signup1_hed": "¿Qué te trae por aquí? (opcional)",
  "signup1_info": "Le preguntamos para poder indicarle la dirección correcta mientras comienza. ¡Su prueba incluye Autopilot y Copilot!",
  "signup1_org_label": "Nombre de empresa",
  "signup1_submit": "Inscribirse",
  "signup2_did_not_get_the_email": "¿No recibiste el correo electrónico?",
  "signup2_email_verification": "Verifique su dirección de correo electrónico",
  "signup2_email_verification_info": "¡Solo una cosa más! Revise su bandeja de entrada para recibir un correo electrónico de Nametag y haga clic en el botón Activar su prueba de Nametag para iniciar sesión.",
  "signup2_send_it_again": "Envialo de nuevo",
  "signup3_create_nametag": "Crea tu Nametag",
  "signup3_footer": "¡No se requiere aplicación! Podrá completar todo el proceso de verificación en segundos. [¿Necesita ayuda?](https://getnametag.com/help)",
  "signup3_graf1": "Lo usará para iniciar sesión en Nametag. Es totalmente privado, imposible de perder y solo requiere escanear tu identificación la primera vez.",
  "signup3_graf2": "Escanea el código QR para continuar.",
  "signup3_graf2_mobile": "Haz click para continuar.",
  "signup3_h1": "Por último, cree su propia etiqueta de identificación",
  "signup_existing_account_nosaml": "Ya tienes una cuenta. Por favor, registrese.",
  "signup_existing_account_saml": "Su organización ya está configurada. Por favor, registrese.",
  "somethingWrongWithOAuth": "Algo anda mal con OAuth",
  "template_CreateFailed": "No se pudo crear la plantilla",
  "templates_AcceptTextHed": "Copia del botón Compartir",
  "templates_AcceptTextLengthError": "El texto de aceptación debe tener menos de %{maxLength} caracteres",
  "templates_AcceptTextPlaceholder": "Introduce un mensaje corto",
  "templates_AcceptTextSubhed": "Lo que los usuarios ven en el botón en el que hacen clic para completar la solicitud",
  "templates_AcceptedTextHed": "Mensaje de solicitud completada",
  "templates_AcceptedTextLengthError": "El texto aceptado debe tener menos de %{maxLength} caracteres",
  "templates_AcceptedTextPlaceholder": "Introduce un mensaje corto",
  "templates_AcceptedTextSubhed": "Lo que ven los usuarios después de completar la solicitud",
  "templates_ActionsMenu": "Menú de acciones de plantilla",
  "templates_AddSmartField": "Agregar un campo inteligente",
  "templates_BlankHed": "Empezar desde el principio",
  "templates_BlankSubhed": "Cree una plantilla en blanco y personalice una solicitud según sus necesidades específicas",
  "templates_CivHed": "Verificación de identidad integral",
  "templates_CivSubhed": "Recopile puntos de datos reveladores, como la fecha de nacimiento y la dirección, y solicite una nueva verificación cada vez.",
  "templates_ConfirmDelete": "Confirmar eliminación de plantilla",
  "templates_ConfirmDeleteGraf": "¿Está seguro de que desea eliminar esta plantilla? No podrás recuperarlo más tarde.",
  "templates_Create": "Crear plantilla",
  "templates_CreateHed": "Crear una plantilla de solicitud",
  "templates_CreateSubhed": "Cada plantilla se puede personalizar para recopilar puntos de datos específicos, almacenar esos datos durante un período de tiempo determinado y proporcionar instrucciones al destinatario. Comience con una plantilla prefabricada o comience desde cero.",
  "templates_CreateTemplate": "crear una plantilla",
  "templates_CsrHed": "Solicitud de atención al cliente",
  "templates_CsrSubhed": "Verifique rápidamente la identidad de un cliente por teléfono o correo electrónico, reverificación fácil sin selfie",
  "templates_DefaultTemplate": "Plantilla predeterminada",
  "templates_Delete": "Borrar",
  "templates_DeleteDefaultTooltip": "Su plantilla predeterminada no se puede eliminar.",
  "templates_DeleteTemplate": "Eliminar plantilla",
  "templates_Disabled": "Plantilla deshabilitada",
  "templates_Duplicate": "Duplicar",
  "templates_Edit": "Editar",
  "templates_Editor": "Editor de plantillas",
  "templates_Enabled": "Plantilla habilitada",
  "templates_EnabledOff": "Esta plantilla está desactivada",
  "templates_EnabledOn": "Esta plantilla está en",
  "templates_ErrorSummaryHed": "Corrija los siguientes errores antes de guardar:",
  "templates_EvHed": "Verificación de empleados",
  "templates_EvSubhed": "Confirmar la identidad del nuevo empleado utilizando datos gubernamentales y una foto de perfil.",
  "templates_Expiration": "Tiempo de expiración",
  "templates_ExpirationDetails": "Todos los datos de su solicitud vencerán en este momento y perderá el acceso a los datos. Esto nos ayuda a proteger a las personas que comparten su información privada con usted.",
  "templates_ExpirationInfo": "Caduca en %{count} días",
  "templates_ExpirationInvalidError": "Las plantillas deben tener una fecha de caducidad.",
  "templates_ExpirationUnitSelect": "Seleccione la unidad de tiempo de caducidad",
  "templates_HeadlineHed": "Solicitar título en el dispositivo móvil del usuario",
  "templates_HeadlineLengthError": "El título debe tener menos de %{maxLength} caracteres",
  "templates_HeadlineMissingTagError": "Las solicitudes de título deben incluir el campo inteligente Nombre público",
  "templates_HeadlinePlaceholder": "Explique su solicitud en la aplicación móvil",
  "templates_HeadlineSubhed": "Lo que ven los usuarios cuando comienzan el proceso",
  "templates_HvtHed": "Transacciones de alto valor",
  "templates_HvtSubhed": "Reúna datos demográficos clave y solicite una nueva verificación cada vez que se realice la transacción.",
  "templates_Info": "Cree plantillas personalizadas para enviar solicitudes de verificación personalizadas. Elija los tipos de datos que está recopilando para confirmar identidades, escriba su propio mensaje para mantener la voz de su marca y desactive las plantillas para eliminarlas de las listas de plantillas de su equipo.",
  "templates_InstallMessageSMSHed": "Solicitud de datos de usuario por mensaje de texto",
  "templates_InstallMessageSMSLengthError": "El mensaje de texto debe tener menos de %{maxLength} caracteres",
  "templates_InstallMessageSMSMissingTagError": "Las solicitudes de mensajes de texto deben incluir los campos inteligentes Nombre público y Enlace de solicitud",
  "templates_InstallMessageSMSPlaceholder": "Explique su solicitud en un mensaje de texto y envíe un enlace",
  "templates_InstallMessageSMSSubhed": "Lo que ven los usuarios cuando abren el mensaje de texto",
  "templates_LoadFailed": "No se pudo cargar la plantilla",
  "templates_MakeDefault": "Hacer por defecto",
  "templates_Messaging": "Mensajería",
  "templates_MessagingSubhed": "Escriba mensajes personalizados para tipos únicos de solicitudes para asegurarse de que las personas sepan a qué están dando su consentimiento cuando completan una solicitud suya.",
  "templates_OtherTemplates": "Otras plantillas",
  "templates_PublicName": "Nombre público",
  "templates_QrHeadlineHed": "Solicitud de enlace única para datos de usuario",
  "templates_QrHeadlineLengthError": "Las solicitudes de enlace únicas deben tener menos de %{maxLength} caracteres",
  "templates_QrHeadlineMissingTagError": "Las solicitudes de enlace únicas deben incluir el campo inteligente Nombre público",
  "templates_QrHeadlinePlaceholder": "Introduce un mensaje corto",
  "templates_QrHeadlineSubhed": "Lo que ven los usuarios cuando hacen clic en el enlace único",
  "templates_Rename": "Rebautizar",
  "templates_RequestLink": "Solicitar enlace",
  "templates_RequestorLabel": "Solicitar etiqueta",
  "templates_Save": "Guardar plantilla",
  "templates_ScopeCount": "%{count} ámbitos",
  "templates_ScopeCountSingular": "1 Alcance",
  "templates_Scopes": "Ámbitos",
  "templates_ScopesInfo": "Las plantillas deben tener **al menos un** alcance. Los alcances representan los tipos de información de identificación que solicita en cada solicitud.",
  "templates_ScopesMissingError": "Las plantillas deben tener al menos un ámbito",
  "templates_Select": "Seleccione una plantilla",
  "templates_SelfieReverification": "Reverificación de selfies",
  "templates_SelfieReverificationDescription": "Los destinatarios deberán tomar una nueva selfie cada vez que completen esta solicitud, incluso si ya han verificado su identidad en el pasado. Esto se recomienda para transacciones de alto valor.",
  "templates_SelfieReverificationDisabled": "No, no requiere una selfie cada vez que se completa esta solicitud",
  "templates_SelfieReverificationEnabled": "Sí, requiere una selfie cada vez que se complete esta solicitud",
  "templates_Status": "Estado",
  "templates_StatusInfo": "Las plantillas se pueden activar y desactivar. Las plantillas que están desactivadas no aparecerán en la lista de solicitudes que los lectores pueden enviar en la página **Enviar una solicitud**.",
  "templates_Templates": "Plantillas",
  "templates_UpdateFailed": "No se pudo actualizar la plantilla",
  "templates_UpdateSuccess": "Se han guardado los cambios",
  "userdata_created_by_API": "Creado por API",
  "userdata_expiredScopesInfo": "Esta solicitud ha caducado y los datos ya no se almacenan aquí",
  "userdata_header_Label": "Etiqueta",
  "userdata_header_Status": "Estado",
  "userdata_header_last_updated": "Última actualización",
  "validate_AlmostDone": "Casi termino",
  "validate_BackToRequest": "Volver a la solicitud de %{envName}",
  "validate_ConfirmMyEmailAddress": "Confirmar mi dirección de correo electrónico",
  "validate_EmailVerified": "Correo verificado",
  "validate_GenericError": "No podemos confirmar su dirección de correo electrónico.",
  "validate_PressTheButtonBelowToConfirmYourEmailAddress": "Presione el botón de abajo para confirmar su dirección de correo electrónico.",
  "validate_ReturnToTheRequestToCompleteTheProcess": "Regrese a la solicitud de %{envName} para completar el proceso",
  "validate_ScanToReturnToRequest": "Escanear para volver a la solicitud de %{envName}",
  "validate_ScanToReturnToTheNametagApp": "Escanea para volver a la aplicación Nametag",
  "validate_ValidationFailedError": "Es posible que este enlace ya se haya utilizado.",
  "validate_VerifyEmail": "Verificar correo electrónico",
  "validate_ViewInTheNametagApp": "Ver en la aplicación Nametag",
  "validate_YourEmailHasBeenVerified": "Su correo electrónico ha sido verificado.",
  "validate_YoureAllSet": "¡Estás listo!",
  "webhook_DeliveryError": "error de entrega",
  "webhook_DeliveryError_graf": "Nametag encontró un error al intentar entregar este evento.",
  "welcomeBackFirstName": "Bienvenido de nuevo, %{firstName}",
  "welcomeToNametag": "Bienvenido a Nametag"
}
