/**
 * Copyright 2022 Nametag Inc.
 *
 * All information contained herein is the property of Nametag Inc.. The
 * intellectual and technical concepts contained herein are proprietary, trade
 * secrets, and/or confidential to Nametag, Inc. and may be covered by U.S.
 * and Foreign Patents, patents in process, and are protected by trade secret or
 * copyright law. Reproduction or distribution, in whole or in part, is
 * forbidden except by express written permission of Nametag, Inc.
 */

import { ReactElement } from "react";

export const EnvCustomizationIcon = (props: {
  className?: string;
}): ReactElement => {
  return (
    <svg
      className={props.className}
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.66667 14.5C3.95942 14.5 3.28115 14.219 2.78105 13.719C2.28095 13.2189 2 12.5406 2 11.8333V3.83333C2 3.47971 2.14048 3.14057 2.39052 2.89052C2.64057 2.64048 2.97971 2.5 3.33333 2.5H6C6.35362 2.5 6.69276 2.64048 6.94281 2.89052C7.19286 3.14057 7.33333 3.47971 7.33333 3.83333V11.8333C7.33333 12.5406 7.05238 13.2189 6.55229 13.719M4.66667 14.5C5.37391 14.5 6.05219 14.219 6.55229 13.719M4.66667 14.5H12.6667C13.0203 14.5 13.3594 14.3595 13.6095 14.1095C13.8595 13.8594 14 13.5203 14 13.1667V10.5C14 10.1464 13.8595 9.80724 13.6095 9.55719C13.3594 9.30714 13.0203 9.16667 12.6667 9.16667H11.1047M6.55229 13.719L12.2093 8.062C12.4593 7.81196 12.5997 7.47289 12.5997 7.11933C12.5997 6.76578 12.4593 6.4267 12.2093 6.17667L10.3233 4.29067C10.0733 4.04071 9.73422 3.90028 9.38067 3.90028C9.02711 3.90028 8.68804 4.04071 8.438 4.29067L7.33333 5.39533M4.66667 11.8333H4.67333"
        stroke="currentColor"
        strokeWidth="1.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
