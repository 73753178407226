/**
 * Copyright 2023 Nametag Inc.
 *
 * All information contained herein is the property of Nametag Inc.. The
 * intellectual and technical concepts contained herein are proprietary, trade
 * secrets, and/or confidential to Nametag, Inc. and may be covered by U.S.
 * and Foreign Patents, patents in process, and are protected by trade secret or
 * copyright law. Reproduction or distribution, in whole or in part, is
 * forbidden except by express written permission of Nametag, Inc.
 */

import { DialogStateReturn } from "reakit/Dialog";
import { classes, style } from "typestyle";
import { Dialog } from "../../../components/dialog/dialog";
import { Spinner } from "../../../components/spinner";
import { COLORS } from "../../../lib/colors";
import { pxToRem } from "../../../lib/utils";
import { t } from "../../../i18n";
import { DirectoryKind } from "../../../api/types";
import { Markdown } from "../../../lib/md";

export interface ResetModalProps {
  dialog: DialogStateReturn;
  directoryKind: DirectoryKind;
  directoryLogo: string;
  directoryName: string;
  externalIdentifier: string;
  isLoading: boolean;
  isError: boolean;
  loadingMessage?: string;
  errorMessage?: string;
  children?: React.ReactNode;
}

export const ResetModal = ({
  dialog,
  directoryKind,
  directoryLogo,
  directoryName,
  externalIdentifier,
  isLoading,
  isError,
  loadingMessage,
  errorMessage,
  children,
}: ResetModalProps) => {
  return (
    <Dialog
      dialog={dialog}
      ariaLabel={directoryName}
      className={css.dialogWrapper}
      modalContentsClassName={classes(css.dialog)}
      closeBtnClassName={css.closeBtn}
    >
      <div className={css.header}>
        <img
          src={directoryLogo}
          alt={`${directoryKind} logo`}
          className={css.logo}
        />
        <div>
          <h2 className={css.hed}>{directoryName}</h2>
          <div className={css.subhed}>{externalIdentifier}</div>
        </div>
      </div>

      {isLoading && (
        <div className={css.bodyBusy}>
          <Spinner className={css.spinner} />
          <p>{loadingMessage}</p>
        </div>
      )}

      {isError && (
        <div className={css.errorState}>
          <i className={classes("ri-error-warning-line", css.errorStateIcon)} />
          <div>
            <div>{t("microsite_GenericError")}</div>
            {errorMessage && (
              <div>
                {t("microsite_Details")}: {Markdown(errorMessage)}
              </div>
            )}
          </div>
        </div>
      )}

      {!isLoading && !isError && <div>{children}</div>}
    </Dialog>
  );
};

const css = {
  dialog: style({
    padding: "14px 32px",
    width: "min(582px, 90vw)",
    color: COLORS.text,
    fontSize: pxToRem(14),
    $nest: {
      a: {
        color: COLORS.actionPrimary,
      },
    },
  }),
  dialogWrapper: style({
    boxShadow: "0px 1px 3px 0px rgba(0, 0, 0, 0.25)",
    borderRadius: 4,
  }),
  closeBtn: style({
    color: "#54626A",
  }),
  header: style({
    display: "flex",
    alignItems: "center",
    gap: 12,
    paddingBottom: 14,
    borderBottom: "1px solid #E2E0E2",
    marginBottom: 14,
  }),
  logo: style({
    width: 40,
    height: 40,
    objectFit: "contain",
  }),
  hed: style({
    color: COLORS.text,
    fontSize: pxToRem(16),
    fontWeight: 500,
  }),
  subhed: style({
    color: COLORS.textLight,
    fontSize: pxToRem(14),
  }),
  bodyBusy: style({
    display: "flex",
    gap: 16,
    alignItems: "center",
    justifyContent: "center",
  }),
  spinner: style({
    height: "32px !important",
    width: "32px !important",
    borderWidth: "4px !important",
  }),
  errorState: style({
    color: COLORS.error,
    display: "flex",
    alignItems: "center",
    gap: 16,
  }),
  errorStateIcon: style({
    fontSize: 32,
  }),
};
