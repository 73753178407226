/**
 * Copyright 2024 Nametag Inc.
 *
 * All information contained herein is the property of Nametag Inc.. The
 * intellectual and technical concepts contained herein are proprietary, trade
 * secrets, and/or confidential to Nametag, Inc. and may be covered by U.S.
 * and Foreign Patents, patents in process, and are protected by trade secret or
 * copyright law. Reproduction or distribution, in whole or in part, is
 * forbidden except by express written permission of Nametag, Inc.
 */

import { style } from "typestyle";
import { COLORS } from "./lib/colors";
import { pxToRem } from "./lib/utils";
import { t } from "./i18n";
import { CloseIcon } from "./components/assets/close-icon";

export const SigninErrorPage = () => {
  return (
    <div className={css.page}>
      <div className={css.card}>
        <div className={css.left}>
          <CloseIcon color={COLORS.error} size={32} />
        </div>
        <div className={css.right}>
          <div className={css.title}>{t("signin_Error_Page")}</div>
          <div className={css.info}>{t("signin_Error_Page_info_1")}</div>
          <div className={css.info}>{t("signin_Error_Page_info_2")}</div>
        </div>
      </div>
    </div>
  );
};

const css = {
  page: style({
    height: "100vh",
    background: COLORS.background,
    position: "relative",
  }),
  card: style({
    width: "75%",
    background: COLORS.white,
    borderRadius: "30px",
    padding: "88px 120px",
    position: "absolute",
    top: "126px",
    left: "12.5%",
    display: "flex",
  }),
  left: style({
    background: COLORS.backgroundRed,
    height: pxToRem(172),
    width: pxToRem(204),
    borderRadius: 8,
    marginRight: pxToRem(32),
    alignContent: "center",
    $nest: {
      svg: {
        margin: "auto",
      },
    },
  }),
  right: style({
    alignContent: "center",
  }),
  title: style({
    color: COLORS.textDark,
    fontWeight: 600,
    fontSize: pxToRem(20),
    marginBottom: pxToRem(20),
  }),
  info: style({
    color: COLORS.text,
    fontWeight: 400,
    fontSize: pxToRem(16),
    $nest: {
      "&:first-child": {
        marginBottom: pxToRem(12),
      },
    },
  }),
};
