/**
 * Copyright 2022 Nametag Inc.
 *
 * All information contained herein is the property of Nametag Inc.. The
 * intellectual and technical concepts contained herein are proprietary, trade
 * secrets, and/or confidential to Nametag, Inc. and may be covered by U.S.
 * and Foreign Patents, patents in process, and are protected by trade secret or
 * copyright law. Reproduction or distribution, in whole or in part, is
 * forbidden except by express written permission of Nametag, Inc.
 */

import { detect } from "detect-browser";
import { detectIncognito } from "detectincognitojs";
import { useEffect, useState } from "react";

export const useBrowser = () => {
  const browser = detect();
  const isIOS = browser && browser.os === "iOS";
  const isChrome =
    browser && browser.type === "browser" && browser.name === "chrome";
  const isAndroid = browser && browser.os === "Android OS";
  const isIOSSafari = browser && browser.name === "ios"; // Firefox mobile is "fxios", Safari is just "ios"
  const isMobile = isAndroid || isIOS;
  const isDesktop = !isMobile;

  return {
    isIOS,
    isChrome,
    isAndroid,
    isIOSSafari,
    isMobile,
    isDesktop,
  };
};

export const useIsPrivate = () => {
  const [isPrivate, setIsPrivate] = useState<boolean | null>(null);

  useEffect(() => {
    (async () => {
      setIsPrivate((await detectIncognito()).isPrivate);
    })();
  }, []);

  return isPrivate;
};
