/**
 * Copyright 2022 Nametag Inc.
 *
 * All information contained herein is the property of Nametag Inc.. The
 * intellectual and technical concepts contained herein are proprietary, trade
 * secrets, and/or confidential to Nametag, Inc. and may be covered by U.S.
 * and Foreign Patents, patents in process, and are protected by trade secret or
 * copyright law. Reproduction or distribution, in whole or in part, is
 * forbidden except by express written permission of Nametag, Inc.
 */

import { ReactElement } from "react";

export const DangerIcon = (props: { className?: string }): ReactElement => {
  return (
    <svg
      width="14"
      height="12"
      viewBox="0 0 14 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={props.className}
    >
      <path
        d="M7 4.7499V6.83326M7 8.49996H7.00672M2.34569 11H11.6543C12.6889 11 13.3352 9.95811 12.8179 9.12497L8.16358 1.62485C7.64628 0.791715 6.35372 0.791715 5.83642 1.62485L1.18211 9.12497C0.664818 9.95811 1.3111 11 2.34569 11Z"
        stroke={"currentColor"}
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
