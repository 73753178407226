/**
 * Copyright 2023 Nametag Inc.
 *
 * All information contained herein is the property of Nametag Inc.. The
 * intellectual and technical concepts contained herein are proprietary, trade
 * secrets, and/or confidential to Nametag, Inc. and may be covered by U.S.
 * and Foreign Patents, patents in process, and are protected by trade secret or
 * copyright law. Reproduction or distribution, in whole or in part, is
 * forbidden except by express written permission of Nametag, Inc.
 */

import { FriendlyError } from "../api/error";
import { DirectoryKind } from "../api/types";

// This is the API intended for use by the recovery microsite.

// creates and stores JWT that is used for recovery?
export async function StartRecovery(
  identifier: string,
): Promise<RecoverStartResponse> {
  const req = new Request("/api/start", {
    method: "POST",
    body: JSON.stringify({ identifier } as RecoverStartRequest),
  });
  const resp = await fetch(req);
  if (resp.status !== 200) {
    throw new FriendlyError(
      `Failed to start recovery: ${
        resp.headers.get("X-Error-Message") ?? resp.status
      }`,
    );
  }
  const respBody = (await resp.json()) as RecoverStartResponse;
  return respBody;
}

// returns directory options available for recovery
export async function GetRecoveryOptions(req: GetRecoveryOptionsRequest) {
  const resp = await fetch(`/api/options`, {
    method: "POST",
    body: JSON.stringify(req),
  });
  if (resp.status !== 200) {
    throw new FriendlyError(
      `Failed to retrieve recovery options: ${
        resp.headers.get("X-Error-Message") ?? resp.status
      }`,
      resp.status,
    );
  }

  const respBody = (await resp.json()) as GetRecoveryOptionsResponse;
  return respBody;
}

// does either password or MFA recovery for a given directory
export async function DoRecovery(
  recoveryOptionID: string,
  recoveryType: RecoveryType,
  req: DoRecoveryRequest,
) {
  const resp = await fetch(
    `/api/option/${encodeURI(recoveryOptionID)}/recover/${recoveryType}`,
    {
      method: "POST",
      body: JSON.stringify(req),
    },
  );
  if (resp.status !== 200) {
    throw new FriendlyError(
      `Failed to recover account: ${
        resp.headers.get("X-Error-Message") ?? resp.status
      }`,
      resp.status,
    );
  }

  const respBody = (await resp.json()) as DoRecoveryResponse;
  return respBody;
}

export interface RecoverStartRequest {
  identifier: string;
}

export interface RecoverStartResponse {
  client_id: string;
  redirect_uri: string;
  server: string;
  scopes: string[];
  state: string;
}

export interface GetRecoveryOptionsRequest {
  state: string;
  id_token: string;
}

export interface GetRecoveryOptionsResponse {
  name: string;
  profile_photo: string;
  options: RecoveryOption[];
}

export interface RecoveryOption {
  id: string;
  title: string;
  logo: string;
  kind: DirectoryKind;
  external_identifier: string;
  reset_password: boolean;
  reset_mfa: boolean;
  unlock: boolean;
}

export interface DoRecoveryRequest {
  state: string;
  id_token: string;
  reset_mfa?: boolean;
  reset_password?: boolean;
}

export interface DoRecoveryResponse {
  new_password?: string;
  reset_password_link?: string;
  reset_mfa_link?: string;
  mfa_recovery_code?: string;
}

export enum RecoveryType {
  PASSWORD = "password",
  MFA = "mfa",
  UNLOCK = "unlock",
}
