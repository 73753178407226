/**
 * Copyright 2022 Nametag Inc.
 *
 * All information contained herein is the property of Nametag Inc.. The
 * intellectual and technical concepts contained herein are proprietary, trade
 * secrets, and/or confidential to Nametag, Inc. and may be covered by U.S.
 * and Foreign Patents, patents in process, and are protected by trade secret or
 * copyright law. Reproduction or distribution, in whole or in part, is
 * forbidden except by express written permission of Nametag, Inc.
 */

import { useAPI } from "../api/context";
import { useQuery } from "@tanstack/react-query";
import { IDResult } from "../api";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router";
import { QueryKeys } from "../api/querykey";
import { FriendlyError } from "../api/error";

export interface UseRequireLoginOptions {
  auth?: boolean;
}

// useRequireLogin is a hook that redirects the user to the OAuth 2.0
// authorize URL if they are not currently logged in.
//
// Returns the IDResult if they are logged it, or null if they are not
// logged in. Returns undefined until the state can be determined.
//
// For convenience, this hook can be disabled (i.e., it will return undefined)
// if opts is provided and opts.state is === false.
export function useRequireLogin(
  opts?: UseRequireLoginOptions,
): IDResult | undefined | null {
  const navigate = useNavigate();
  const location = useLocation();
  const options = opts ?? {};

  const { api } = useAPI();
  const idQuery = useQuery({
    queryKey: QueryKeys.ID,
    queryFn: async () => {
      try {
        const rv = await api.ID();
        if (rv === null) {
          const next = location.pathname + location.search + location.hash;
          const dest = "/signin?next=" + encodeURIComponent(next);
          console.info(
            `auth: id required but not present, redirecting to ${dest}`,
          );
          navigate(dest, { replace: true });
        }
        return rv;
      } catch (err) {
        if (
          err &&
          (err instanceof Response || err instanceof FriendlyError) &&
          err.status === 403
        ) {
          navigate("/signin/error", { replace: true });
        }
      }
    },
    enabled: options.auth !== false,
  });
  return idQuery.data;
}
