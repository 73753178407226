/**
 * Copyright 2024 Nametag Inc.
 *
 * All information contained herein is the property of Nametag Inc.. The
 * intellectual and technical concepts contained herein are proprietary, trade
 * secrets, and/or confidential to Nametag, Inc. and may be covered by U.S.
 * and Foreign Patents, patents in process, and are protected by trade secret or
 * copyright law. Reproduction or distribution, in whole or in part, is
 * forbidden except by express written permission of Nametag, Inc.
 */

import { classes } from "typestyle";
import { t } from "../i18n";
import { COLORS } from "../lib/colors";
import { typedStylesheet } from "../lib/style";
import { pxToRem } from "../lib/utils";
import { Button } from "./button/button";
import { UseQueryStateResult } from "./query-state";
import { Spinner } from "./spinner";

export type QueryStateViewProps = UseQueryStateResult & {
  errorHed?: string;
  loadingView?: JSX.Element;
  className?: string;
};

export const QueryStateView = (props: QueryStateViewProps) => {
  if (props.loading) {
    if (props.loadingView) {
      return props.loadingView;
    }
    return (
      <div className={classes(css.wrapper, props.className)}>
        <div
          className={classes(
            css.container,
            props.loading && css.containerLoading,
          )}
        >
          <Spinner className={classes(css.spinner)} />
        </div>
      </div>
    );
  }

  if (props.error || props.errorRetry || props.errorRetryBusy) {
    const subhed = props.errorRetryBusy
      ? t("request_ErrorSubhedRetryBusy")
      : props.errorRetryDelay
        ? t("request_ErrorSubhedRetryDelay")
        : t("request_ErrorSubhed");
    return (
      <div className={classes(css.wrapper, props.className)}>
        <div className={classes(css.container)}>
          <h1 className={classes(css.header)}>
            {props.errorHed ?? t("request_ErrorHed")}
          </h1>
          <div className={classes(css.subhed)}>{subhed}</div>

          {props.errorRetryBusy ? (
            <Spinner className={classes(css.spinner)} />
          ) : props.errorRetryDelay ? (
            <div className={css.count}>{props.errorRetryDelay}</div>
          ) : null}

          {props.error && !!props.onRetry && (
            <Button
              className={classes(
                css.retryButton,
                !(props.error && !!props.onRetry) && css.invisible,
              )}
              variant={"secondary"}
              onClick={props.onRetry}
            >
              Try again
            </Button>
          )}

          <div className={classes(css.caption)}>
            {t("request_ErrorCaption")}
          </div>
        </div>
      </div>
    );
  }
  return null;
};

const css = typedStylesheet({
  wrapper: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    transition: "opacity 0.5s ease",
  },
  container: {
    display: "flex",
    flexDirection: "column",
    width: "300px",
    alignItems: "center",
    textAlign: "center",

    background: COLORS.white,
    border: `1px solid ${COLORS.borderLight}`,
    borderRadius: 4,
    padding: "16px 20px 15px 20px",
    margin: 20,
  },
  containerLoading: {
    border: "none !important",
  },
  loadingWrapper: {
    paddingTop: 70,
  },
  header: {
    color: COLORS.textDark,
    fontSize: pxToRem(18),
    fontWeight: 600,
    marginBottom: 8,
  },
  subhed: {
    color: COLORS.text,
  },
  count: {
    fontSize: 62,
    fontWeight: 600,
    color: COLORS.actionPrimary,
  },
  retryButton: {
    margin: "26px auto",
  },
  caption: {
    color: COLORS.textLight,
    fontSize: pxToRem(12),
  },
  invisible: {
    opacity: 0,
  },
  spinner: {
    margin: "16px auto",
    height: 61,
    width: 61,
    borderWidth: 8,
  },
});
