/**
 * Copyright 2022 Nametag Inc.
 *
 * All information contained herein is the property of Nametag Inc.. The
 * intellectual and technical concepts contained herein are proprietary, trade
 * secrets, and/or confidential to Nametag, Inc. and may be covered by U.S.
 * and Foreign Patents, patents in process, and are protected by trade secret or
 * copyright law. Reproduction or distribution, in whole or in part, is
 * forbidden except by express written permission of Nametag, Inc.
 */

import { ReactElement } from "react";

export const BillingIcon = (props: { className?: string }): ReactElement => {
  return (
    <svg
      width="14"
      height="11"
      viewBox="0 0 14 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M1 4.16659H13M3.66667 7.49992H4.33333M7 7.49992H7.66667M3 10.1666H11C11.5304 10.1666 12.0391 9.95587 12.4142 9.5808C12.7893 9.20573 13 8.69702 13 8.16659V2.83325C13 2.30282 12.7893 1.79411 12.4142 1.41904C12.0391 1.04397 11.5304 0.833252 11 0.833252H3C2.46957 0.833252 1.96086 1.04397 1.58579 1.41904C1.21071 1.79411 1 2.30282 1 2.83325V8.16659C1 8.69702 1.21071 9.20573 1.58579 9.5808C1.96086 9.95587 2.46957 10.1666 3 10.1666Z"
        stroke="currentColor"
        strokeWidth="1.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
