/**
 * Copyright 2022 Nametag Inc.
 *
 * All information contained herein is the property of Nametag Inc.. The
 * intellectual and technical concepts contained herein are proprietary, trade
 * secrets, and/or confidential to Nametag, Inc. and may be covered by U.S.
 * and Foreign Patents, patents in process, and are protected by trade secret or
 * copyright law. Reproduction or distribution, in whole or in part, is
 * forbidden except by express written permission of Nametag, Inc.
 */

import React, {
  ChangeEventHandler,
  CSSProperties,
  FocusEventHandler,
} from "react";
import { Input as ReakitInput } from "reakit/Input";
import { classes, style } from "typestyle";
import { COLORS } from "../../lib/colors";
import { pxToRem } from "../../lib/utils";
import { Tooltip } from "../tooltip/tooltip";
import { InputHTMLProps } from "reakit/src/Input/Input";
import { DataAttributes, DataAttrs } from "../../lib/data_attr";

export type Props = {
  value?: string;
  disabled?: boolean;
  placeholder?: string;
  onChange?: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  className?: string;
  iconClassName?: string;
  wrapperClassName?: string;
  leadingIcon?: React.ReactElement;
  name?: string;
  prefix?: string;
  trailingIcon?: React.ReactElement;
  trailingButton?: React.ReactElement;
  as?: "input" | "textarea";
  error?: string;
  onFocus?: FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  onBlur?: FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  onKeyDown?: React.KeyboardEventHandler<
    HTMLInputElement | HTMLTextAreaElement
  >;
  onKeyPress?: React.KeyboardEventHandler<
    HTMLInputElement | HTMLTextAreaElement
  >;
  readOnly?: boolean;
  style?: CSSProperties;
  tooltip?: string;
  type?: InputHTMLProps["type"];
  id?: InputHTMLProps["id"];
  required?: InputHTMLProps["required"];
  maxLength?: InputHTMLProps["maxLength"];
  autoFocus?: boolean;
  label?: string;
} & DataAttributes;

export const Input = React.forwardRef<HTMLInputElement, Props>((props, ref) => {
  const {
    value,
    disabled,
    placeholder,
    onChange,
    className,
    leadingIcon,
    name,
    prefix,
    as,
    error,
    onFocus,
    onBlur,
    onKeyDown,
    onKeyPress,
    trailingIcon,
    trailingButton,
    readOnly,
    style,
    tooltip,
    iconClassName,
    type,
    id,
    required,
    maxLength,
    wrapperClassName,
    autoFocus,
    label,
  } = props;

  const inputProps = {
    value: value,
    disabled: disabled,
    placeholder: placeholder,
    className: classes(
      css.input,
      leadingIcon && css.inputWithLeadingIcon,
      trailingIcon && css.inputWithTrailingIcon,
      prefix && css.inputWithPrefix,
      error && css.inputError,
      trailingButton && css.inputWithTrailing,
      className,
    ),
    name: name,
    onChange: onChange,
    onFocus: onFocus,
    onBlur: onBlur,
    onKeyDown: onKeyDown,
    onKeyPress: onKeyPress,
    readOnly: readOnly,
    style: style,
    type: type,
    id: id,
    required: required,
    maxLength: maxLength,
    autoFocus: autoFocus,
    ...DataAttrs(props),
  };

  const InputComponent =
    as === "textarea" ? (
      <textarea {...inputProps} />
    ) : label ? (
      <div className={css.labelWrapper}>
        <label className={css.label}>
          {label}
          {required && <span className={css.requiredLabel}> * </span>}
        </label>
        <ReakitInput ref={ref} {...inputProps} />
      </div>
    ) : (
      <ReakitInput ref={ref} {...inputProps} />
    );
  return (
    <div className={classes(css.outerWrapper, wrapperClassName)}>
      <div className={css.wrapper}>
        {prefix && <span className={css.prefix}>{prefix}</span>}
        {!!leadingIcon && (
          <div className={classes(css.icon, iconClassName)}>{leadingIcon}</div>
        )}
        {tooltip ? (
          <Tooltip title={tooltip} gutter={2}>
            {InputComponent}
          </Tooltip>
        ) : (
          InputComponent
        )}
        {!!trailingIcon && (
          <div className={classes(css.trailingIcon, iconClassName)}>
            {trailingIcon}
          </div>
        )}
        {trailingButton && <div className={css.trailing}>{trailingButton}</div>}
      </div>
      {error && <div className={css.error}>{error}</div>}
    </div>
  );
});

const css = {
  input: style({
    borderRadius: 4,
    border: "1px solid",
    borderColor: COLORS.border,
    backgroundColor: "#FFF",
    fontSize: pxToRem(14),
    textOverflow: "ellipsis",
    color: COLORS.text,
    $nest: {
      "&:focus, &:focus-visible": {
        borderColor: COLORS.actionPrimary,
        outline: "none",
      },
      "&::placeholder": {
        color: COLORS.textPlaceholder,
      },
    },
    width: "100%",
    padding: 10,
  }),
  inputWithLeadingIcon: style({
    paddingLeft: "32px !important",
  }),
  inputWithTrailingIcon: style({
    paddingRight: "32px !important",
  }),
  inputWithPrefix: style({
    paddingLeft: "80px !important",
  }),
  trailing: style({
    borderTopRightRadius: 4,
    borderBottomRightRadius: 4,
    border: "1px solid",
    borderColor: COLORS.border,
    backgroundColor: "#FFF",
  }),
  inputWithTrailing: style({
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    borderRight: "none",
  }),

  inputError: style({
    borderColor: `${COLORS.error} !important`,
  }),
  wrapper: style({
    position: "relative",
    width: "100%",
    display: "flex",
    alignItems: "center",
  }),
  icon: style({
    position: "absolute",
    top: "50%",
    transform: "translateY(-50%)",
    left: "7px",
  }),
  trailingIcon: style({
    position: "absolute",
    top: "50%",
    transform: "translateY(-50%)",
    right: "7px",
  }),
  prefix: style({
    borderRadius: "6px 0 0 6px",
    backgroundColor: "#F7F7F7",
    position: "absolute",
    padding: 8,
    margin: 1,
  }),
  error: style({
    color: COLORS.error,
    fontSize: pxToRem(13),
    marginTop: 8,
  }),
  outerWrapper: style({
    width: "100%",
  }),
  labelWrapper: style({
    width: "100%",
  }),
  label: style({
    color: COLORS.text,
    fontSize: pxToRem(16),
    fontWeight: 600,
    marginBottom: 8,
    display: "block",
  }),
  requiredLabel: style({
    color: COLORS.required,
  }),
};
