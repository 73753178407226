"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Auth = void 0;
/**
 * Copyright 2021 Nametag Inc.
 *
 * Use of this source code is governed by a BSD-style
 * license that can be found in the LICENSE file or at
 * https://developers.google.com/open-source/licenses/bsd
 */
var pkce_1 = require("./pkce");
var storage_1 = require("./storage");
var button_1 = require("./button");
var detect_browser_1 = require("detect-browser");
var desktop_signin_button_1 = require("./desktop-signin-button");
var mobile_signin_button_1 = require("./mobile-signin-button");
var desktop_qr_code_1 = require("./desktop-qr-code");
// Auth implements pure client-side authentication for Nametag.
var Auth = /** @class */ (function () {
    function Auth(opts) {
        var _a, _b, _c;
        this.tokenLocalStorageKey = "__nametag_id_token";
        this.watches = [];
        // Nametag requires that your page be hosted via HTTPs (or on localhost)
        if (window.location.protocol !== "https:" &&
            window.location.hostname !== "localhost") {
            throw new Error("nametag: sign in with ID buttons only work when page is https");
        }
        this.client_id = opts.client_id;
        if (!this.client_id) {
            throw new Error("nametag: you must supply client_id");
        }
        this.redirect_uri = opts.redirect_uri;
        if (!this.redirect_uri) {
            throw new Error("nametag: must supply redirect_uri");
        }
        this.scopes = opts.scopes;
        this.template = opts.template;
        this.state = opts.state;
        this.pkce = (_a = opts.pkce) !== null && _a !== void 0 ? _a : true;
        this.email_hint = (_b = opts.email_hint) !== null && _b !== void 0 ? _b : undefined;
        this.storage = (_c = opts.localStorage) !== null && _c !== void 0 ? _c : (0, storage_1.getLocalOrMemoryStorage)();
        this._server = opts.server || "https://nametag.co";
    }
    Auth.prototype.IsCurrentOriginValid = function () {
        var url = new URL(this.redirect_uri);
        var redirectURIOrigin = url.origin;
        return redirectURIOrigin == window.origin;
    };
    Auth.prototype.AuthorizeURL = function (internalOptions) {
        return __awaiter(this, void 0, void 0, function () {
            var q, _a, pkce, storage, codeVerifierTTL, codeVerifierKey, verifier, browser, endpoint, authorizeURL;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        if (!this.template) {
                            if (!this.scopes || !this.scopes.length) {
                                throw new Error("nametag: you must supply scopes or template to call AuthorizeURL()");
                            }
                        }
                        else {
                            if (this.scopes && this.scopes.length) {
                                throw new Error("nametag: you must supply either scopes or template, but not both");
                            }
                        }
                        q = new URLSearchParams();
                        q.set("client_id", this.client_id);
                        if (this.template) {
                            q.set("template", this.template);
                        }
                        else {
                            q.set("scope", this.scopes.join(" "));
                        }
                        if (!!this.state) return [3 /*break*/, 2];
                        _a = this;
                        return [4 /*yield*/, this.randomState()];
                    case 1:
                        _a.state = _b.sent();
                        _b.label = 2;
                    case 2:
                        q.set("state", this.state);
                        q.set("redirect_uri", this.redirect_uri);
                        if (this.email_hint) {
                            q.set("email_hint", this.email_hint);
                        }
                        if (!this.pkce) return [3 /*break*/, 8];
                        q.set("response_mode", "fragment");
                        pkce = void 0;
                        storage = this.storage;
                        this.vaccumLocalStorage();
                        codeVerifierTTL = 24 * 60 * 60 * 1000;
                        return [4 /*yield*/, this.codeVerifierKey(this.state)];
                    case 3:
                        codeVerifierKey = _b.sent();
                        verifier = storage.getItem(codeVerifierKey);
                        if (!verifier) return [3 /*break*/, 5];
                        console.debug("nametag[".concat(this.state, "]: restoring stored verifier"));
                        storage.setItem(codeVerifierKey + "_expires", (Date.now() + codeVerifierTTL).toString());
                        return [4 /*yield*/, pkce_1.PKCE.FromStored(verifier)];
                    case 4:
                        pkce = _b.sent();
                        return [3 /*break*/, 7];
                    case 5:
                        console.debug("nametag[".concat(this.state, "]: generating new PKCE verifier"));
                        return [4 /*yield*/, pkce_1.PKCE.New()];
                    case 6:
                        pkce = _b.sent();
                        storage.setItem(codeVerifierKey, pkce.verifier);
                        storage.setItem(codeVerifierKey + "_expires", (Date.now() + codeVerifierTTL).toString());
                        _b.label = 7;
                    case 7:
                        q.set("code_challenge", pkce.challenge);
                        q.set("code_challenge_method", pkce.challengeMethod);
                        _b.label = 8;
                    case 8:
                        browser = (0, detect_browser_1.detect)();
                        switch (browser === null || browser === void 0 ? void 0 : browser.name) {
                            case "chrome": // chrome, android/desktop
                            case "crios": // chrome ios
                                q.set("return", "chrome");
                                break;
                            case "firefox":
                                q.set("return", "firefox");
                                break;
                            case "ios": // safari ios
                            case "safari": // safari desktop
                            default:
                                q.set("return", "https");
                                break;
                        }
                        endpoint = "/authorize";
                        if (internalOptions === null || internalOptions === void 0 ? void 0 : internalOptions.iframe) {
                            endpoint = "/authorize/iframe";
                        }
                        authorizeURL = this._server + endpoint + "?" + q.toString();
                        return [2 /*return*/, authorizeURL];
                }
            });
        });
    };
    Auth.prototype.vaccumLocalStorage = function () {
        var storage = this.storage;
        var keysToRemove = [];
        for (var i = 0, len = storage.length; i < len; i++) {
            try {
                var key = storage.key(i);
                var expires = storage.getItem(key + "_expires");
                if (Number.parseInt(expires) < Date.now()) {
                    keysToRemove.push(key);
                    keysToRemove.push(key + "_expires");
                }
            }
            catch (e) {
                // nop
            }
        }
        keysToRemove.map(function (key) { return storage.removeItem(key); });
    };
    Auth.prototype.randomState = function () {
        return __awaiter(this, void 0, void 0, function () {
            var alphabet, rv, i;
            return __generator(this, function (_a) {
                alphabet = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
                rv = "";
                for (i = 0; i < 20; i++) {
                    rv += alphabet.charAt(Math.floor(Math.random() * alphabet.length));
                }
                return [2 /*return*/, rv];
            });
        });
    };
    Auth.prototype.codeVerifierKey = function (state) {
        return __awaiter(this, void 0, void 0, function () {
            var digest, digestArr, digestStr, digestBase64;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, crypto.subtle.digest("SHA-256", new TextEncoder().encode(state))];
                    case 1:
                        digest = _a.sent();
                        digestArr = Array.from(new Uint8Array(digest));
                        digestStr = digestArr
                            .map(function (byte) { return String.fromCharCode(byte); })
                            .join("");
                        digestBase64 = btoa(digestStr);
                        return [2 /*return*/, "__nametag_code_verifier_" + digestBase64];
                }
            });
        });
    };
    Auth.prototype.exchangeCode = function (code) {
        return __awaiter(this, void 0, void 0, function () {
            var body, codeVerifierKey, codeVerifier, resp, err, _a, token;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        body = new FormData();
                        body.set("grant_type", "authorization_code");
                        body.set("client_id", this.client_id);
                        body.set("code", code);
                        body.set("redirect_uri", this.redirect_uri);
                        return [4 /*yield*/, this.codeVerifierKey(this.state)];
                    case 1:
                        codeVerifierKey = _b.sent();
                        codeVerifier = this.storage.getItem(codeVerifierKey);
                        if (codeVerifier) {
                            body.set("code_verifier", codeVerifier);
                        }
                        else {
                            console.error("didn't find code verifier in local storage");
                        }
                        return [4 /*yield*/, fetch(this._server + "/token", {
                                method: "POST",
                                body: body,
                            })];
                    case 2:
                        resp = _b.sent();
                        if (!(resp.status >= 400)) return [3 /*break*/, 5];
                        _a = resp.headers.get("X-Error-Message");
                        if (_a) return [3 /*break*/, 4];
                        return [4 /*yield*/, resp.text()];
                    case 3:
                        _a = (_b.sent());
                        _b.label = 4;
                    case 4:
                        err = _a;
                        throw new Error("(".concat(this.state, "): nametag: cannot exchange code for token: ") + err);
                    case 5: return [4 /*yield*/, resp.json()];
                    case 6:
                        token = (_b.sent());
                        return [2 /*return*/, token];
                }
            });
        });
    };
    Auth.prototype.HandleCallback = function () {
        return __awaiter(this, void 0, void 0, function () {
            var query, state, error, code, token;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!this.pkce) {
                            throw new Error("nametag: HandleCallback should only be called in PKCE mode");
                        }
                        query = new URLSearchParams(window.location.hash.replace(/^#/, ""));
                        state = query.get("state");
                        if (!state) {
                            console.log("nametag: HandleCallback: state not found in query");
                            return [2 /*return*/, {}];
                        }
                        this.state = state;
                        error = query.get("error");
                        if (error) {
                            console.error("nametag: HandleCallback: error found in query: ".concat(error));
                            return [2 /*return*/, { error: error }];
                        }
                        code = query.get("code");
                        if (!code) {
                            console.error("nametag: HandleCallback: code not found in query");
                            return [2 /*return*/, {}];
                        }
                        return [4 /*yield*/, this.exchangeCode(code)];
                    case 1:
                        token = _a.sent();
                        this.storage.setItem(this.tokenLocalStorageKey, JSON.stringify(token));
                        this.watches.map(function (w) { return w.callback(token); });
                        this.vaccumLocalStorage();
                        return [2 /*return*/, { token: token }];
                }
            });
        });
    };
    Auth.prototype.WatchToken = function (onToken) {
        if (!this.pkce) {
            throw new Error("nametag: WatchToken should only be called in PKCE mode");
        }
        var self = this;
        var eventHandler = function (event) {
            if (event.key == self.tokenLocalStorageKey) {
                var t = self.Token();
                onToken(t);
            }
        };
        window.addEventListener("storage", eventHandler);
        // Always fire an event that provides the initial value of the token
        var initialToken = this.Token();
        window.setTimeout(function () {
            onToken(initialToken);
        }, 0);
        var rv = {
            close: function () { },
            callback: onToken,
        };
        rv.close = function () {
            self.watches = self.watches.filter(function (f) { return f != rv; });
            window.removeEventListener("storage", eventHandler);
        };
        this.watches.push(rv);
        return rv;
    };
    Auth.prototype.SignOut = function () {
        if (!this.pkce) {
            throw new Error("nametag: SignOut should only be called in PKCE mode");
        }
        this.storage.removeItem(this.tokenLocalStorageKey);
        this.watches.map(function (w) { return w.callback(null); });
    };
    Auth.prototype.SignedIn = function () {
        if (!this.pkce) {
            throw new Error("nametag: SignedIn should only be called in PKCE mode");
        }
        return !!this.Token();
    };
    Auth.prototype.Token = function () {
        if (!this.pkce) {
            throw new Error("nametag: Token should only be called in PKCE mode");
        }
        var tokenStr = this.storage.getItem(this.tokenLocalStorageKey);
        if (!tokenStr) {
            return null;
        }
        return JSON.parse(tokenStr);
    };
    Auth.prototype.GetProperties = function (scopes) {
        return __awaiter(this, void 0, void 0, function () {
            var token, resp, respBody;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!this.pkce) {
                            throw new Error("nametag: GetProperties should only be called in PKCE mode");
                        }
                        token = this.Token();
                        if (!token) {
                            return [2 /*return*/, null];
                        }
                        return [4 /*yield*/, fetch(this._server +
                                "/people/me/properties/" +
                                scopes.join(",") +
                                "?token=" +
                                encodeURI(token.id_token))];
                    case 1:
                        resp = _a.sent();
                        if (resp.status >= 400) {
                            return [2 /*return*/, null];
                        }
                        return [4 /*yield*/, resp.json()];
                    case 2:
                        respBody = _a.sent();
                        return [2 /*return*/, Properties.fromData(respBody)];
                }
            });
        });
    };
    Auth.prototype.AuthorizeButton = function (element, options) {
        if ((0, button_1.isMobile)()) {
            var _ = new mobile_signin_button_1.MobileSigninButton(this, element, options);
        }
        else {
            var _ = new desktop_signin_button_1.DesktopSigninButton(this, element, options);
        }
    };
    Auth.prototype.QRCode = function (element, options) {
        new desktop_qr_code_1.DesktopQRCode(this, element, options);
    };
    return Auth;
}());
exports.Auth = Auth;
var Property = /** @class */ (function () {
    function Property() {
        this.scope = "";
        this.value = null;
        this.exp = new Date(0);
    }
    Property.fromData = function (data) {
        var rv = new Property();
        rv.scope = data.scope;
        rv.value = data.value;
        rv.exp = new Date(data.exp);
        return rv;
    };
    return Property;
}());
var Properties = /** @class */ (function () {
    function Properties() {
        this.subject = "";
        this.properties = [];
    }
    Properties.prototype.get = function (scope) {
        for (var _i = 0, _a = this.properties; _i < _a.length; _i++) {
            var prop = _a[_i];
            if (prop.scope === scope) {
                return prop;
            }
        }
        return null;
    };
    Properties.fromData = function (data) {
        var rv = new Properties();
        rv.subject = data.sub;
        rv.properties = [];
        if (data.properties) {
            for (var _i = 0, _a = data.properties; _i < _a.length; _i++) {
                var propData = _a[_i];
                var prop = Property.fromData(propData);
                rv.properties.push(prop);
            }
        }
        return rv;
    };
    return Properties;
}());
