/**
 * Copyright 2023 Nametag Inc.
 *
 * All information contained herein is the property of Nametag Inc.. The
 * intellectual and technical concepts contained herein are proprietary, trade
 * secrets, and/or confidential to Nametag, Inc. and may be covered by U.S.
 * and Foreign Patents, patents in process, and are protected by trade secret or
 * copyright law. Reproduction or distribution, in whole or in part, is
 * forbidden except by express written permission of Nametag, Inc.
 */

import { Env } from "../api/types";
import { NavigateFunction } from "react-router-dom";

// envURI returns a URL prefix for an env, e.g. /org/my_organization/env/live. envs and orgs are the
// list of all known orgs, respectively, for disambiguation.
export function envURI(
  env: Pick<Env, "id" | "name">,
  envs: Array<Pick<Env, "id" | "name">>,
) {
  const envID = friendlyIdentifier(env, envs);
  return `/env/${encodeURIComponent(envID)}`;
}

// friendlyIdentifier returns the friendly identifier for the Env or Org. If the slugified version
// of the name matches exactly one
export function friendlyIdentifier(
  item: Pick<Env, "id" | "name">,
  items: Pick<Env, "id" | "name">[],
): string {
  if (item.name === "") {
    return item.id;
  }
  const proposed = makeSlugFromName(item.name);
  if (items.filter((v) => makeSlugFromName(v.name) == proposed).length == 1) {
    return proposed;
  }
  return item.id;
}

export function changeEnvironment(
  env: Env,
  envs: Env[],
  navigate: NavigateFunction,
) {
  const newIdentifier = friendlyIdentifier(env, envs);
  const newPath = location.pathname.replace(
    /\/env\/[^/]+/,
    "/env/" + encodeURIComponent(newIdentifier),
  );

  window.sessionStorage.setItem("env", env.id);
  console.log("Navigating to " + newPath);
  navigate(newPath + location.search + location.hash, {
    state: {
      env: env.id,
    },
  });
}

// getEnvBySlugOrID returns the Env from envs that corresponds to slugOrID, or undefined
// if there is no matching env.
export function getEnvBySlugOrID(
  slugOrID: string,
  envs: Env[],
): Env | undefined {
  const env = envs.filter((env) => env.id == slugOrID).pop();
  if (env) {
    return env;
  }

  const matchingEnvs = envs.filter(
    (env) => makeSlugFromName(env.name) === makeSlugFromName(slugOrID),
  );
  if (matchingEnvs.length === 1) {
    return matchingEnvs[0];
  }

  return undefined;
}

function makeSlugFromName(name: string): string {
  let rv = name.toLowerCase();
  rv = rv.replace(/[^a-z0-9]/g, "_");
  if (rv.length > 15) {
    rv = rv.substring(0, 15);
  }
  return rv;
}
