/**
 * Copyright 2022 Nametag Inc.
 *
 * All information contained herein is the property of Nametag Inc.. The
 * intellectual and technical concepts contained herein are proprietary, trade
 * secrets, and/or confidential to Nametag, Inc. and may be covered by U.S.
 * and Foreign Patents, patents in process, and are protected by trade secret or
 * copyright law. Reproduction or distribution, in whole or in part, is
 * forbidden except by express written permission of Nametag, Inc.
 */

import { ReactElement } from "react";

export const RequestTemplatesIcon = (props: {
  className?: string;
}): ReactElement => {
  return (
    <svg
      className={props.className}
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.66667 5.83329H11.3333M4.66667 8.49996H7.33333M8 13.8333L5.33333 11.1666H3.33333C2.97971 11.1666 2.64057 11.0262 2.39052 10.7761C2.14048 10.5261 2 10.1869 2 9.83329V4.49996C2 4.14634 2.14048 3.8072 2.39052 3.55715C2.64057 3.3071 2.97971 3.16663 3.33333 3.16663H12.6667C13.0203 3.16663 13.3594 3.3071 13.6095 3.55715C13.8595 3.8072 14 4.14634 14 4.49996V9.83329C14 10.1869 13.8595 10.5261 13.6095 10.7761C13.3594 11.0262 13.0203 11.1666 12.6667 11.1666H10.6667L8 13.8333Z"
        stroke="currentColor"
        strokeWidth="1.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
