/**
 * Copyright 2021 Nametag Inc.
 *
 * All information contained herein is the property of Nametag Inc.. The
 * intellectual and technical concepts contained herein are proprietary, trade
 * secrets, and/or confidential to Nametag, Inc. and may be covered by U.S.
 * and Foreign Patents, patents in process, and are protected by trade secret or
 * copyright law. Reproduction or distribution, in whole or in part, is
 * forbidden except by express written permission of Nametag, Inc.
 */

export class FriendlyError {
  friendlyErrorMessage: string;
  status?: number;

  constructor(friendlyErrorMessage: string, statusCode?: number) {
    this.friendlyErrorMessage = friendlyErrorMessage;
    this.status = statusCode;
  }
}
