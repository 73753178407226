/**
 * Copyright 2022 Nametag Inc.
 *
 * All information contained herein is the property of Nametag Inc.. The
 * intellectual and technical concepts contained herein are proprietary, trade
 * secrets, and/or confidential to Nametag, Inc. and may be covered by U.S.
 * and Foreign Patents, patents in process, and are protected by trade secret or
 * copyright law. Reproduction or distribution, in whole or in part, is
 * forbidden except by express written permission of Nametag, Inc.
 */

import { classes, style } from "typestyle";
import { COLORS } from "../../lib/colors";
import { pxToRem } from "../../lib/utils";
import { Org } from "../../api/types";
import { Env } from "../../api/types";
import { t } from "../../i18n";
import { OrganizationPlaceholder } from "../assets/organization-placeholder";

export interface OrgMenuProps {
  org?: Org;
  env?: Env;
  envs?: Array<Env>;
  className?: string;
  initialVisible?: boolean;
}

export const OrgMenu = (props: OrgMenuProps) => {
  return (
    <div
      data-testid="org-menu"
      className={classes(props.className, css.container)}
    >
      <meta name={"org-id"} content={props.org?.id || ""} />
      <div className={classes(css.menuButton)}>
        <OrganizationPlaceholder className={css.logo} />
        <span className={css.menuButtonText}>
          {props.org?.name || t("Unnamed")}
        </span>
      </div>
    </div>
  );
};

const maxWidth = 300;

const css = {
  container: style({
    maxWidth: maxWidth,
    display: "flex",
    alignItems: "center",
  }),
  menuButton: style({
    display: "flex",
    alignItems: "center",
    color: COLORS.white,
    border: "none",
    backgroundColor: "rgba(0,0,0,0)",
    padding: "4px 12px",
    borderRadius: "4px",
    fontWeight: 600,
    fontSize: pxToRem(16),
  }),
  logo: style({
    height: 28,
    width: 28,
    minWidth: 28,
    maxWidth: 28,
    marginRight: 12,
  }),
  menuButtonText: style({
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    maxWidth:
      maxWidth -
      12 - // left padding
      (28 + 12) - // icon and padding
      12, // right padding

    display: "block",
    textAlign: "left",
  }),
};
