/**
 * Copyright 2023 Nametag Inc.
 *
 * All information contained herein is the property of Nametag Inc.. The
 * intellectual and technical concepts contained herein are proprietary, trade
 * secrets, and/or confidential to Nametag, Inc. and may be covered by U.S.
 * and Foreign Patents, patents in process, and are protected by trade secret or
 * copyright law. Reproduction or distribution, in whole or in part, is
 * forbidden except by express written permission of Nametag, Inc.
 */

import { classes, keyframes, style } from "typestyle";
import { COLORS } from "../lib/colors";

type Props = {
  className?: string;
};

export const Spinner = ({ className }: Props) => {
  return <div className={classes(css.spinner, className)} />;
};

const css = {
  spinner: style({
    border: `16px solid ${COLORS.border}`,
    borderTop: `16px solid ${COLORS.actionPrimary}`,
    borderRadius: "50%",
    animation: "spin 1s linear infinite",
    width: 140,
    height: 140,
    animationName: keyframes({
      "0%": { transform: "rotate(0deg)" },
      "100%": { transform: "rotate(360deg)" },
    }),
  }),
};
