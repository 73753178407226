/**
 * Copyright 2023 Nametag Inc.
 *
 * All information contained herein is the property of Nametag Inc.. The
 * intellectual and technical concepts contained herein are proprietary, trade
 * secrets, and/or confidential to Nametag, Inc. and may be covered by U.S.
 * and Foreign Patents, patents in process, and are protected by trade secret or
 * copyright law. Reproduction or distribution, in whole or in part, is
 * forbidden except by express written permission of Nametag, Inc.
 */

import { DialogStateReturn } from "reakit/src/Dialog/DialogState";
import { style } from "typestyle";
import { Link } from "../../../components/link/link";
import { ResetModal } from "./reset-modal";
import { t } from "../../../i18n";
import { DirectoryKind } from "../../../api/types";

export type OneLoginPasswordResetModalProps = {
  dialog: DialogStateReturn;
  directoryName: string;
  directoryLogo: string;
  externalIdentifier: string;
  isLoading: boolean;
  isError: boolean;
  errorMessage?: string;
  resetPasswordLink?: string;
};

export const OneLoginPasswordResetModal: React.FC<
  OneLoginPasswordResetModalProps
> = ({
  dialog,
  directoryName,
  directoryLogo,
  externalIdentifier,
  isLoading,
  isError,
  errorMessage,
  resetPasswordLink,
}) => {
  return (
    <ResetModal
      dialog={dialog}
      directoryKind={DirectoryKind.DirectoryKindOnelogin}
      directoryLogo={directoryLogo}
      directoryName={directoryName}
      externalIdentifier={externalIdentifier}
      isLoading={isLoading}
      isError={isError}
      errorMessage={errorMessage}
      loadingMessage={t("microsite_ResettingPassword")}
    >
      {resetPasswordLink && (
        <>
          <p className={css.graf}>{t("microsite_OneLoginPasswordResetHed")}</p>
          <Link
            to={resetPasswordLink}
            variant="primaryButton"
            className={css.proceedLink}
          >
            {t("microsite_ProceedToOneLogin")}
          </Link>
        </>
      )}
    </ResetModal>
  );
};

const css = {
  graf: style({
    marginBottom: 16,
  }),
  proceedLink: style({
    marginTop: 32,
    marginLeft: "auto",
    display: "block",
    width: "fit-content",
  }),
};
