/**
 * Copyright 2023 Nametag Inc.
 *
 * All information contained herein is the property of Nametag Inc.. The
 * intellectual and technical concepts contained herein are proprietary, trade
 * secrets, and/or confidential to Nametag, Inc. and may be covered by U.S.
 * and Foreign Patents, patents in process, and are protected by trade secret or
 * copyright law. Reproduction or distribution, in whole or in part, is
 * forbidden except by express written permission of Nametag, Inc.
 */

import ErrorPage from "../demo/ErrorPage";
import { RegisterRoute } from "../routes";
import { ResetPage } from "./reset/reset-page";
import { SignInPage } from "./signin/signin";
import { VerifyFinishPage } from "./signin/verify-finish";

RegisterRoute(() => {
  return [
    {
      path: "/",
      element: <SignInPage />,
    },
    {
      path: "/verify/finish",
      element: <VerifyFinishPage />,
    },
    {
      path: "/reset",
      element: <ResetPage />,
    },
    {
      path: "/*",
      element: <ErrorPage />,
    },
  ];
});
