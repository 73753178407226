/**
 * Copyright 2023 Nametag Inc.
 *
 * All information contained herein is the property of Nametag Inc.. The
 * intellectual and technical concepts contained herein are proprietary, trade
 * secrets, and/or confidential to Nametag, Inc. and may be covered by U.S.
 * and Foreign Patents, patents in process, and are protected by trade secret or
 * copyright law. Reproduction or distribution, in whole or in part, is
 * forbidden except by express written permission of Nametag, Inc.
 */

import { useState } from "react";
import { classes, style } from "typestyle";
import { Footer } from "../../components/page/footer";
import { t } from "../../i18n";
import { COLORS } from "../../lib/colors";
import { pxToRem } from "../../lib/utils";
import { Header } from "../header";
import {
  DoRecoveryResponse,
  RecoveryOption,
  RecoveryType,
} from "../recovery-api";
import { AccountRecoveryOption } from "./account-recovery-option";

export interface ResetViewProps {
  name: string;
  profilePicture: string;
  options: RecoveryOption[];
  doRecovery: (optionID: string, recoveryType: RecoveryType) => Promise<void>;
  doRecoveryIsLoading: boolean;
  doRecoveryIsError: boolean;
  doRecoveryIsSuccess: boolean;
  doRecoveryErrorMessage?: string;
  doRecoveryInfo?: DoRecoveryResponse;
  doRecoveryResetState: () => void;
}

export const ResetView = ({
  name,
  profilePicture,
  options,
  doRecovery,
  doRecoveryIsLoading,
  doRecoveryIsError,
  doRecoveryIsSuccess,
  doRecoveryErrorMessage,
  doRecoveryInfo,
  doRecoveryResetState,
}: ResetViewProps) => {
  const [showSuccessBanner, setShowSuccessBanner] = useState(false);
  return (
    <div className={css.root}>
      <Header userName={name} userProfilePicture={profilePicture} />
      <main className={css.content}>
        {showSuccessBanner && (
          <div className={css.successBanner}>
            <i
              className={classes("ri-checkbox-circle-line", css.successIcon)}
            />
            <span>{t("microsite_ResetSuccess")}</span>
            <button
              className={css.dismissBtn}
              onClick={() => setShowSuccessBanner(false)}
            >
              {t("Dismiss")}
            </button>
          </div>
        )}
        <h1 className={css.hed}>{t("microsite_Welcome", { name })}</h1>
        <p className={css.graf}>{t("microsite_ResetHed")}</p>
        {options.length === 0 && (
          <p className={css.emptyState}>{t("microsite_NoDirectories")}</p>
        )}
        <div className={css.options}>
          {options.map((option) => (
            <AccountRecoveryOption
              account={option}
              key={option.id}
              doRecovery={async (recoveryType) => {
                await doRecovery(option.id, recoveryType);
              }}
              doRecoveryIsLoading={doRecoveryIsLoading}
              doRecoveryIsError={doRecoveryIsError}
              doRecoveryIsSuccess={doRecoveryIsSuccess}
              doRecoveryErrorMessage={doRecoveryErrorMessage}
              doRecoveryInfo={doRecoveryInfo}
              doRecoveryResetState={doRecoveryResetState}
              setShowSuccessBanner={setShowSuccessBanner}
            />
          ))}
        </div>
      </main>
      <Footer />
    </div>
  );
};

const css = {
  root: style({
    minHeight: "100%",
    display: "grid",
    gridTemplateRows: "auto 1fr auto",
  }),
  content: style({
    margin: "60px 92px",
  }),
  successBanner: style({
    color: COLORS.successHovered,
    fontWeight: 500,
    padding: 16,
    backgroundColor: COLORS.backgroundGreen,
    borderRadius: 8,
    boxShadow: "0px 1px 4px 0px rgba(0, 0, 0, 0.10)",
    display: "flex",
    gap: 12,
    alignItems: "center",
    marginBottom: 32,
  }),
  successIcon: style({
    fontSize: 28,
    lineHeight: "100%",
  }),
  dismissBtn: style({
    color: COLORS.textLight,
    textDecoration: "underline",
    background: "none",
    border: "none",
    marginLeft: "auto",
  }),
  hed: style({
    fontSize: pxToRem(28),
    fontWeight: 500,
    color: COLORS.textDark,
    marginBottom: 16,
  }),
  graf: style({
    fontSize: pxToRem(20),
    color: COLORS.text,
    marginBottom: 32,
  }),
  options: style({
    display: "flex",
    flexDirection: "column",
    gap: 12,
  }),
  choice: style({
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    border: `1px solid ${COLORS.border}`,
    borderRadius: 6,
    background: COLORS.white,
    padding: `20px 24px`,
    gap: 16,
  }),
  logo: style({
    width: 40,
    height: 40,
  }),
  choiceInfo: style({
    flexGrow: 1,
  }),
  choiceTitle: style({
    color: COLORS.text,
    fontWeight: 500,
  }),
  choiceExternalID: style({
    color: COLORS.textLight,
    fontSize: pxToRem(14),
  }),
  choiceActions: style({
    display: "flex",
    flexDirection: "row",
    gap: 16,
  }),
  choiceAction: style({
    fontSize: pxToRem(14),
    color: COLORS.text,
  }),
  emptyState: style({
    color: COLORS.text,
    fontSize: pxToRem(20),
    fontStyle: "italic",
  }),
};
