/**
 * Copyright 2022 Nametag Inc.
 *
 * All information contained herein is the property of Nametag Inc.. The
 * intellectual and technical concepts contained herein are proprietary, trade
 * secrets, and/or confidential to Nametag, Inc. and may be covered by U.S.
 * and Foreign Patents, patents in process, and are protected by trade secret or
 * copyright law. Reproduction or distribution, in whole or in part, is
 * forbidden except by express written permission of Nametag, Inc.
 */

import { ReactNode, useRef } from "react";

import {
  useTooltipState,
  Tooltip as ReakitTooltip,
  TooltipReference,
  TooltipProps,
  TooltipArrow,
  TooltipInitialState,
} from "reakit/Tooltip";
import { style } from "typestyle";
import { COLORS } from "../../lib/colors";
import { pxToRem } from "../../lib/utils";

export interface Props extends Partial<TooltipProps> {
  children: ReactNode;
  placement?: TooltipInitialState["placement"];
  gutter?: number;
  className?: string;
}

export function Tooltip({
  children,
  title,
  placement = "bottom",
  gutter,
  ...props
}: Props) {
  const tooltip = useTooltipState({ placement, gutter });
  const tooltipRef = useRef(null);

  return (
    <>
      <TooltipReference
        {...tooltip}
        ref={tooltipRef}
        className={props.className}
      >
        {
          /* eslint-disable @typescript-eslint/no-explicit-any */
          ((referenceProps: any) => (
            // wrap children in a div to handle the ref, since function
            // components cannot take a ref
            <div ref={tooltipRef} {...referenceProps}>
              {children}
            </div>
          )) as any /* eslint-disable @typescript-eslint/no-explicit-any */
        }
      </TooltipReference>
      <ReakitTooltip {...tooltip} {...props}>
        <TooltipArrow {...tooltip} className={css.tooltipArrow} />
        <div className={css.tooltip}>{title}</div>
      </ReakitTooltip>
    </>
  );
}

const css = {
  tooltipArrow: style({
    fill: COLORS.text,
  }),
  tooltip: style({
    backgroundColor: COLORS.text,
    padding: "4px 8px 4px 8px",
    fontSize: pxToRem(14),
    lineHeight: pxToRem(19),
    fontWeight: 400,
    color: COLORS.white,
    borderRadius: "2px",
    maxWidth: 250,
  }),
};
