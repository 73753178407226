/**
 * Copyright 2022 Nametag Inc.
 *
 * All information contained herein is the property of Nametag Inc.. The
 * intellectual and technical concepts contained herein are proprietary, trade
 * secrets, and/or confidential to Nametag, Inc. and may be covered by U.S.
 * and Foreign Patents, patents in process, and are protected by trade secret or
 * copyright law. Reproduction or distribution, in whole or in part, is
 * forbidden except by express written permission of Nametag, Inc.
 */

import { ReactElement } from "react";

export const DangerZoneIcon = (props: { className?: string }): ReactElement => {
  return (
    <svg
      className={props.className}
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.99995 6.49996V7.83329M7.99995 10.5H8.00662M3.38128 13.1666H12.6186C13.6453 13.1666 14.2866 12.0553 13.7733 11.1666L9.15462 3.16662C8.64128 2.27796 7.35862 2.27796 6.84528 3.16662L2.22662 11.1666C1.71328 12.0553 2.35462 13.1666 3.38128 13.1666Z"
        stroke="currentColor"
        strokeWidth="1.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
