/**
 * Copyright 2022 Nametag Inc.
 *
 * All information contained herein is the property of Nametag Inc.. The
 * intellectual and technical concepts contained herein are proprietary, trade
 * secrets, and/or confidential to Nametag, Inc. and may be covered by U.S.
 * and Foreign Patents, patents in process, and are protected by trade secret or
 * copyright law. Reproduction or distribution, in whole or in part, is
 * forbidden except by express written permission of Nametag, Inc.
 */

import React, { ChangeEventHandler, useEffect, useState } from "react";
import { Checkbox } from "reakit/Checkbox";
import { classes, style } from "typestyle";
import { COLORS } from "../../lib/colors";
import { VisuallyHidden } from "reakit/VisuallyHidden";

interface Props {
  name?: string;
  label: string;
  onChange: ChangeEventHandler<HTMLInputElement>;
  defaultChecked?: boolean;
  disabled?: boolean;
  id?: string;
}

export const Toggle = ({
  name,
  label,
  defaultChecked,
  onChange,
  disabled,
  id,
}: Props) => {
  const [checked, setChecked] = useState(defaultChecked ?? false);
  const toggle: ChangeEventHandler<HTMLInputElement> = (e) => {
    onChange(e);
    setChecked(!checked);
  };
  if (!id) {
    [id] = useState(Math.random().toString());
  }

  useEffect(() => {
    if (defaultChecked) {
      setChecked(defaultChecked);
    }
  }, [defaultChecked]);

  return (
    <div className={css.toggleSwitch}>
      <Checkbox
        id={id}
        name={name}
        data-testid={"toggle"}
        className={css.checkbox}
        checked={checked}
        onChange={toggle}
        disabled={disabled}
      />
      <label className={css.label} htmlFor={id}>
        <span
          className={classes(
            css.inner,
            checked && css.innerChecked,
            disabled && css.innerDisabled,
          )}
        />
        <span className={classes(css.switch, checked && css.switchChecked)} />
        <VisuallyHidden>{label}</VisuallyHidden>
      </label>
    </div>
  );
};

const css = {
  toggleSwitch: style({
    position: "relative",
    width: 36,
    display: "inline-block",
  }),
  checkbox: style({
    display: "none",
  }),
  label: style({
    display: "block",
    overflow: "hidden",
    cursor: "pointer",
    borderRadius: 20,
    margin: 0,
  }),
  inner: style({
    display: "block",
    width: "200%",
    marginLeft: "-100%",
    transition: "margin 0.1s ease-in 0s",
    $nest: {
      "&::before, &::after": {
        float: "left",
        width: "50%",
        height: 18,
        padding: 0,
        lineHeight: 18,
        color: COLORS.white,
        fontWeight: "bold",
        boxSizing: "border-box",
      },
      "&::before": {
        content: "''",
        paddingLeft: 10,
        backgroundColor: "#0071E3",
      },
      "&::after": {
        content: "''",
        paddingRight: 10,
        backgroundColor: COLORS.textPlaceholder,
        textAlign: "right",
      },
    },
  }),
  innerChecked: style({
    marginLeft: 0,
  }),
  innerDisabled: style({
    opacity: 0.6,
  }),
  switch: style({
    display: "block",
    width: 12,
    margin: 3,
    background: COLORS.white,
    position: "absolute",
    top: 0,
    bottom: 0,
    right: 18,
    borderRadius: 20,
    transition: "all 0.1s ease-in 0s",
  }),
  switchChecked: style({
    right: 0,
  }),
};
