"use strict";
/**
 * Copyright 2023 Nametag Inc.
 *
 * All information contained herein is the property of Nametag Inc.. The
 * intellectual and technical concepts contained herein are proprietary, trade
 * secrets, and/or confidential to Nametag, Inc. and may be covered by U.S.
 * and Foreign Patents, patents in process, and are protected by trade secret or
 * copyright law. Reproduction or distribution, in whole or in part, is
 * forbidden except by express written permission of Nametag, Inc.
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MobileSigninButton = void 0;
var button_1 = require("./button");
var styles_1 = require("./styles");
var MobileSigninButton = /** @class */ (function () {
    function MobileSigninButton(auth, container, options) {
        this.auth = auth;
        this.container = container;
        this.options = options;
        var customButton = (0, button_1.hasCustomButton)(container);
        if (!customButton) {
            var button = new button_1.Button(options.variant || "blue", options.icon_position || "left");
            this.button = button.el;
        }
        else {
            this.button = container;
        }
        this.addStylesheetToDOM();
        if (!customButton) {
            this.addToDOM();
        }
        this.initButtonMobile();
    }
    MobileSigninButton.prototype.initButtonMobile = function () {
        return __awaiter(this, void 0, void 0, function () {
            var authorizeURL;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.auth.AuthorizeURL()];
                    case 1:
                        authorizeURL = _a.sent();
                        this.button.addEventListener("click", function () {
                            window.location.assign(authorizeURL);
                        });
                        return [2 /*return*/];
                }
            });
        });
    };
    MobileSigninButton.prototype.addStylesheetToDOM = function () {
        if (!document.getElementById("nt-style")) {
            var style = document.createElement("style");
            style.id = "nt-style";
            style.innerText = styles_1.styles;
            document.head.appendChild(style);
        }
    };
    MobileSigninButton.prototype.addToDOM = function () {
        while (this.container.firstChild) {
            this.container.removeChild(this.container.firstChild);
        }
        this.container.appendChild(this.button);
    };
    MobileSigninButton.prototype.removeFromDOM = function () {
        while (this.container.firstChild) {
            this.container.removeChild(this.container.firstChild);
        }
    };
    return MobileSigninButton;
}());
exports.MobileSigninButton = MobileSigninButton;
