/**
 * Copyright 2023 Nametag Inc.
 *
 * All information contained herein is the property of Nametag Inc.. The
 * intellectual and technical concepts contained herein are proprietary, trade
 * secrets, and/or confidential to Nametag, Inc. and may be covered by U.S.
 * and Foreign Patents, patents in process, and are protected by trade secret or
 * copyright law. Reproduction or distribution, in whole or in part, is
 * forbidden except by express written permission of Nametag, Inc.
 */

import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useState } from "react";
import { useNavigate } from "react-router";
import { useLocation } from "react-router-dom";
import { FriendlyError } from "../../api/error";
import { QueryState } from "../../components/query-state";
import { DoRecovery, GetRecoveryOptions, RecoveryType } from "../recovery-api";
import { ResetView } from "./reset-view";
import { t } from "../../i18n";

export const ResetPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { state, id_token } = (location.state ?? {}) as {
    state?: string;
    id_token?: string;
  };

  const recoveryOptionsQuery = useQuery({
    queryKey: ["recovery-options"],
    queryFn: async () => {
      if (!state || !id_token) {
        navigate("/");
        return;
      }
      try {
        return await GetRecoveryOptions({ state, id_token });
      } catch (err) {
        if (err instanceof FriendlyError && err.status === 401) {
          // If the person's authorization token has expired, we need to direct them back
          // to the start of the flow
          navigate("/");
          return;
        }
        throw err;
      }
    },
    retry: (_, error) => {
      // don't retry if the error is a 401; we want to log the user out immediately
      if (error instanceof FriendlyError && error.status === 401) {
        return false;
      }
      return true;
    },
  });

  const [doRecoveryErrorMessage, setDoRecoveryErrorMessage] = useState("");
  const doRecoveryMutation = useMutation({
    mutationKey: ["do-recovery"],
    mutationFn: async ({
      optionID,
      recoveryType,
    }: {
      optionID: string;
      recoveryType: RecoveryType;
    }) => {
      if (!state || !id_token) {
        navigate("/");
        return;
      }
      return await DoRecovery(optionID, recoveryType, { state, id_token });
    },
    onError: (err) => {
      if (err instanceof FriendlyError) {
        setDoRecoveryErrorMessage(err.friendlyErrorMessage);
      } else {
        setDoRecoveryErrorMessage(t("csr_GenericError"));
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["recovery-options"] });
    },
  });

  return (
    <>
      <QueryState query={recoveryOptionsQuery} />
      {recoveryOptionsQuery.data && (
        <ResetView
          name={recoveryOptionsQuery.data.name}
          profilePicture={recoveryOptionsQuery.data.profile_photo}
          options={recoveryOptionsQuery.data.options}
          doRecovery={async (optionID, recoveryType) => {
            await doRecoveryMutation.mutateAsync({
              optionID,
              recoveryType,
            });
          }}
          doRecoveryIsLoading={doRecoveryMutation.isPending}
          doRecoveryIsError={doRecoveryMutation.isError}
          doRecoveryIsSuccess={doRecoveryMutation.isSuccess}
          doRecoveryInfo={doRecoveryMutation.data}
          doRecoveryErrorMessage={doRecoveryErrorMessage}
          doRecoveryResetState={doRecoveryMutation.reset}
        />
      )}
    </>
  );
};
