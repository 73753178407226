/**
 * Copyright 2022 Nametag Inc.
 *
 * All information contained herein is the property of Nametag Inc.. The
 * intellectual and technical concepts contained herein are proprietary, trade
 * secrets, and/or confidential to Nametag, Inc. and may be covered by U.S.
 * and Foreign Patents, patents in process, and are protected by trade secret or
 * copyright law. Reproduction or distribution, in whole or in part, is
 * forbidden except by express written permission of Nametag, Inc.
 */

import type { components, operations } from "./schema";

export type DeliveryOptions = "link" | "text" | "whatsapp";

export {
  AppealRejectReason,
  DirectoryKind,
  EphemeralDataMode,
  OrgFeatureState,
  OrgPlan,
  OrgReportLastNTimeframeUnit,
  PathsApiAccountsGetParametersQuerySort,
  PathsApiEnvsEnvRequestsGetParametersQuerySort,
  PathsApiEnvsEnvRequestsGetParametersQueryUpdatedAt,
  RecoveryEligibility,
  RecoveryPolicy,
  RejectReason,
  RequestProgress,
  RequestStatus,
  Role,
  Scope,
  SigninMethod,
  RecoveryKind,
  WebhookEventType,
} from "./schema";

export type GetRequestsOpts = operations["listRequests"]["parameters"]["query"];
export type ListDirectoryEntriesRequest =
  operations["listAccounts"]["parameters"]["query"];

export type APIKey = components["schemas"]["APIKey"];
export type APIKeyUpdate = components["schemas"]["APIKeyUpdateRequest"];
export type AccountActivity = components["schemas"]["AccountActivity"];
export type AccountActivityFactor =
  components["schemas"]["AccountActivityFactor"];
export type AccountActivityResponse =
  components["schemas"]["AccountActivityResponse"];
export type AccountUpdate = components["schemas"]["AccountUpdateRequest"];
export type CreateAPIKeyResponse =
  components["schemas"]["CreateAPIKeyResponse"];
export type CreateDirectoryRequest =
  components["schemas"]["CreateDirectoryRequest"];
export type CreateDirectoryResponse =
  components["schemas"]["CreateDirectoryResponse"];
export type CreateOpts = components["schemas"]["CreateAPIKeyRequest"];
export type CreateRequestRequest =
  components["schemas"]["CreateRequestRequest"];
export type CreateRequestResponse =
  components["schemas"]["CreateRequestResponse"];
export type DeviceInfo = components["schemas"]["RequestMobileDevice"];
export type Directory = components["schemas"]["Directory"];
export type DirectoryAuthorizeResponse =
  components["schemas"]["OAuth2AuthorizeResponse"];
export type DirectoryCredentials = components["schemas"]["Credentials"];
export type DirectoryEntry = components["schemas"]["Entry"];
export type DirectoryGroup = components["schemas"]["DirectoryGroup"];
export type Env = components["schemas"]["Env"];
export type EnvUpdate = components["schemas"]["EnvUpdateRequest"];
export type GetRequestsResponse = components["schemas"]["GetRequestsResponse"];
export type GovtidDetailsValue = components["schemas"]["GovtidDetailsValue"];
export type GroupRecoveryPolicy = components["schemas"]["GroupRecoveryPolicy"];
export type ListDirectoryEntriesResponse =
  components["schemas"]["GetDirectoryEntriesResponse"];
export type NametagRequest = components["schemas"]["Request"];
export type NewOrgMember = components["schemas"]["InviteOrgMemberRequest"];
export type Org = components["schemas"]["Org"];
export type OrgMember = components["schemas"]["OrgMember"];
export type OrgMemberUpdate = components["schemas"]["UpdateOrgMemberRequest"];
export type OrgReportLastNTimeframe =
  components["schemas"]["OrgReportLastNTimeframe"];
export type OrgReportOpts = components["schemas"]["OrgReportRequest"];
export type OrgReportResponse = components["schemas"]["OrgReportResponse"];
export type OrgSAML = components["schemas"]["OrgSAML"];
export type OrgSAMLGroup = components["schemas"]["OrgSAMLGroup"];
export type OrgSAMLUpdate = components["schemas"]["OrgUpdateSAMLRequest"];
export type OrgUpdate = components["schemas"]["OrgUpdateRequest"];
export type RecoveryMicrositeCheckDNSRespoonse =
  components["schemas"]["RecoveryMicrositeCheckDNSResponse"];
export type RecoveryPolicyRules = components["schemas"]["RecoveryPolicyRules"];
export type RequestByTicketInfo =
  components["schemas"]["GetRequestByTicketResponse"];
export type RequestDetail = components["schemas"]["RequestDetails"];
export type RequestDetailAccountInfo =
  components["schemas"]["RequestDetailsAccountInfo"];
export type RequestDetailDevice = components["schemas"]["RequestDetailsDevice"];
export type RequestDetailDocument =
  components["schemas"]["RequestDetailsDocument"];
export type RequestDetailLocation = components["schemas"]["Location"];
export type RequestDetailPrivacy =
  components["schemas"]["RequestDetailsPrivacy"];
export type RequestDetailSelfie = components["schemas"]["RequestDetailsSelfie"];
export type RequestProperties = components["schemas"]["RequestProperties"];
export type Template = components["schemas"]["Template"];
export type TemplateCreate = components["schemas"]["CreateTemplateRequest"];
export type TemplateScopeDefinition =
  components["schemas"]["TemplateScopeDefinition"];
export type TemplateUpdate = components["schemas"]["UpdateTemplateRequest"];
export type Theme = components["schemas"]["Theme"];
export type UpdateDirectoryRequest =
  components["schemas"]["UpdateDirectoryRequest"];
export type UpdateRequestRequest =
  components["schemas"]["UpdateRequestRequest"];
export type Webhook = components["schemas"]["Webhook"];
export type WebhookAttempt = components["schemas"]["WebhookAttempt"];
export type WebhookDefinition = components["schemas"]["WebhookDefinition"];
export type WebhookDefinitionUpdate =
  components["schemas"]["WebhookDefinitionUpdate"];
export type WebhookHeader = components["schemas"]["WebhookHeader"];
