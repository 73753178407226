/**
 * Copyright 2023 Nametag Inc.
 *
 * All information contained herein is the property of Nametag Inc.. The
 * intellectual and technical concepts contained herein are proprietary, trade
 * secrets, and/or confidential to Nametag, Inc. and may be covered by U.S.
 * and Foreign Patents, patents in process, and are protected by trade secret or
 * copyright law. Reproduction or distribution, in whole or in part, is
 * forbidden except by express written permission of Nametag, Inc.
 */

class MemoryStorage implements Storage {
  private readonly data = new Map<string, string>();

  public get length() {
    return this.data.size;
  }

  public key(index: number): string | null {
    const keys = Array.from(this.data.keys()).sort();
    return keys[index] ?? null;
  }

  public getItem(key: string): string | null {
    const rv = this.data.get(key);
    return rv === undefined ? null : rv;
  }

  public removeItem(key: string): void {
    this.data.delete(key);
  }

  public setItem(key: string, value: string): void {
    this.data.set(key, value);
  }

  public clear(): void {
    this.data.clear();
  }
}

/**
 * Tests whether the provided storage storage is usable
 * If the storage is usable, that is returned; otherwise, in-memory storage is used instead
 */
function checkStorage(storage: Storage): Storage {
  try {
    const testKey = "__nametag_test";
    storage.setItem(testKey, "test");
    storage.getItem(testKey);
    storage.removeItem(testKey);
    return storage;
  } catch (_) {
    return new MemoryStorage();
  }
}

export const storage = checkStorage(window.localStorage);
export const sessionOrMemoryStorage = checkStorage(window.sessionStorage);
