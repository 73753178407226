/**
 * Copyright 2022 Nametag Inc.
 *
 * All information contained herein is the property of Nametag Inc.. The
 * intellectual and technical concepts contained herein are proprietary, trade
 * secrets, and/or confidential to Nametag, Inc. and may be covered by U.S.
 * and Foreign Patents, patents in process, and are protected by trade secret or
 * copyright law. Reproduction or distribution, in whole or in part, is
 * forbidden except by express written permission of Nametag, Inc.
 */

import { classes, style } from "typestyle";
import { EnvChooser } from "./env_chooser";
import { COLORS } from "../../lib/colors";
import { pxToRem } from "../../lib/utils";
import { SendIcon } from "../assets/send-icon";
import { GearIcon } from "../assets/gear-icon";
import { RoleGe } from "../../api/orgs";
import { Org, Env } from "../../api/types";
import { Link, useLocation } from "react-router-dom";
import { t } from "../../i18n";
import { IDResult } from "../../api";
import { envURI } from "../../console/url";
import { Tooltip } from "../tooltip/tooltip";
import { UserIcon } from "../assets/user-icon";
import { Role } from "../../api/types";

interface Props {
  id?: IDResult;
  org?: Org;
  env?: Env;
  envs?: Array<Env>;
  envSource?: string;
  auth?: boolean;
  setPageLoading: (l: boolean) => void;
}

export const SubNav = (props: Props) => {
  const sendRequestURI =
    props.env && props.envs ? envURI(props.env, props.envs) + "/request" : "/";
  const accountsURI =
    props.env && props.envs ? envURI(props.env, props.envs) + "/accounts" : "/";
  const configureURI =
    props.env && props.envs
      ? envURI(props.env, props.envs) + "/configure"
      : "/";

  const location = useLocation();
  const requestActive = location.pathname.match(/\/request/);
  const accountsActive = location.pathname.match(/\/accounts/);
  const configureActive =
    !requestActive && !accountsActive && configureURI !== "";
  return (
    <div className={css.container}>
      {props.auth === false ? (
        <div />
      ) : (
        <EnvChooser className={css.envChooser} {...props} />
      )}
      <div className={css.spacer} />
      <Link
        to={sendRequestURI}
        className={classes(css.navBtn, requestActive && css.navBtnSelected)}
      >
        <SendIcon className={css.navBtnIcon} />
        {t("Verifications")}
      </Link>
      {RoleGe(props.org?.role, Role.RoleUser) && (
        <Link
          to={accountsURI}
          className={classes(css.navBtn, accountsActive && css.navBtnSelected)}
        >
          <UserIcon className={css.navBtnIcon} />
          {t("Accounts")}
        </Link>
      )}
      {!RoleGe(props.org?.role, Role.RoleUser) ? (
        <Tooltip title={t("csr_ConfigureInsufficientPermissions")} gutter={2}>
          <div className={css.disabledCursor}>
            <div className={classes(css.navBtn, css.disabledBtn)}>
              <GearIcon className={css.navBtnIcon} />
              {t("Configure")}
            </div>
          </div>
        </Tooltip>
      ) : (
        <Link
          to={configureURI}
          className={classes(css.navBtn, configureActive && css.navBtnSelected)}
        >
          <GearIcon className={css.navBtnIcon} />
          {t("Configure")}
        </Link>
      )}
    </div>
  );
};

const css = {
  container: style({
    backgroundColor: COLORS.background,
    color: COLORS.textLight,
    height: 56,
    display: "flex",
    alignItems: "center",
    border: "1px solid " + COLORS.border,
  }),
  envChooser: style({
    margin: "9px 24px 9px 16px",
  }),
  navBtn: style({
    padding: "16px 24px",
    fontSize: pxToRem(16),
    lineHeight: pxToRem(22),
    fontWeight: 500,
    display: "flex",
    alignItems: "center",
    color: COLORS.textLight,
    whiteSpace: "nowrap",
    flexShrink: 0,
    $nest: {
      "&:hover": {
        backgroundColor: COLORS.fieldHover,
        textDecoration: "inherit",
        color: COLORS.text,
      },
    },
  }),
  navBtnSelected: style({
    backgroundColor: "#E3F3FF",
    color: COLORS.actionPrimary,
    fontWeight: 600,
    $nest: {
      "&:hover": {
        backgroundColor: "#E3F3FF",
        color: COLORS.actionPrimary,
        cursor: "default",
      },
    },
  }),
  navBtnIcon: style({
    marginRight: 4,
    height: 24,
    flexShrink: 0,
  }),
  disabledBtn: style({
    pointerEvents: "none",
  }),
  disabledCursor: style({
    cursor: "not-allowed",
  }),
  spacer: style({
    flexGrow: 1,
    minWidth: 0,
    paddingRight: "8px",
  }),
  invisible: style({
    visibility: "hidden",
  }),
};
