/**
 * Copyright 2023 Nametag Inc.
 *
 * All information contained herein is the property of Nametag Inc.. The
 * intellectual and technical concepts contained herein are proprietary, trade
 * secrets, and/or confidential to Nametag, Inc. and may be covered by U.S.
 * and Foreign Patents, patents in process, and are protected by trade secret or
 * copyright law. Reproduction or distribution, in whole or in part, is
 * forbidden except by express written permission of Nametag, Inc.
 */

// DataAttributes is a type that represents all data-* properties for a
// component. Usage:
//
//   const Props = type { /* usual stuff } & DataAttributes;
export type DataAttributes = {
  [key: `data-${string}`]: unknown;
};

// DataAttrs filters the properties passed to a component and returns only the data
// attributes.
//
// Example:
//
//   const Props = type { foo string } & DataAttributes;
//   const Component = (props: Props) => {
//      return <div {...DataAttrs(props)}>{props.foo}</div>
//   }
export function DataAttrs(props: DataAttributes): DataAttributes {
  const rest: DataAttributes = {};
  for (const propertyStr in props) {
    const property = propertyStr as keyof DataAttributes;
    if (property.indexOf("data-") === 0) {
      rest[property] = props[property];
    }
  }
  return rest;
}
