/**
 * Copyright 2021 Nametag Inc.
 *
 * All information contained herein is the property of Nametag Inc.. The
 * intellectual and technical concepts contained herein are proprietary, trade
 * secrets, and/or confidential to Nametag, Inc. and may be covered by U.S.
 * and Foreign Patents, patents in process, and are protected by trade secret or
 * copyright law. Reproduction or distribution, in whole or in part, is
 * forbidden except by express written permission of Nametag, Inc.
 */

import { ReactElement } from "react";

export const CheckIcon = (props: {
  color?: string;
  size?: number;
  className?: string;
}): ReactElement => {
  return (
    <svg
      width={props.size ?? "49"}
      height={props.size ?? "49"}
      viewBox="0 0 49 49"
      fill={props.color ?? "currentColor"}
      stroke={props.color ?? "currentColor"}
      className={props.className}
    >
      <path
        d="M44.502 13.1083C45.777 11.6143 45.5994 9.36965 44.1054 8.09471C42.6114 6.81977 40.3667 6.99737 39.0917 8.49138L18.8087 32.2596L10.3878 23.3032C9.04241 21.8723 6.79179 21.803 5.36087 23.1484C3.92996 24.4937 3.86061 26.7443 5.20598 28.1753L16.3461 40.0237C17.0387 40.7603 18.0119 41.1673 19.0226 41.143C20.0333 41.1186 20.9859 40.6653 21.6422 39.8962L44.502 13.1083Z"
        strokeWidth="1.1125"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
