"use strict";
/**
 * Copyright 2022 Nametag Inc.
 *
 * Use of this source code is governed by a BSD-style
 * license that can be found in the LICENSE file or at
 * https://developers.google.com/open-source/licenses/bsd
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.Popup = exports.Button = void 0;
exports.isMobile = isMobile;
exports.getSrcString = getSrcString;
exports.hasCustomButton = hasCustomButton;
var detect_browser_1 = require("detect-browser");
var svg_1 = require("./svg");
function isMobile() {
    var browser = (0, detect_browser_1.detect)();
    if (!browser) {
        // if browser detection fails, default to mobile because it's the simpler case
        return true;
    }
    return browser.os === "iOS" || browser.os === "Android OS";
}
function getSrcString(svg) {
    return "data:image/svg+xml;base64,".concat(svg);
}
var Button = /** @class */ (function () {
    function Button(variant, iconPosition) {
        this.el = document.createElement("div");
        this.el.classList.add("nt", "nt-btn-signin");
        this.imageLeft = document.createElement("img");
        this.imageLeft.classList.add("nt", "nt-btn-signin-icon");
        if (variant !== "white") {
            this.imageLeft.classList.add("nt-btn-inverted");
        }
        this.imageLeft.src = getSrcString(svg_1.logoSVGB64);
        this.el.appendChild(this.imageLeft);
        this.text = document.createElement("span");
        this.text.classList.add("nt", "nt-btn-signin-text");
        this.text.innerText = "Secure sign in with ID";
        this.el.appendChild(this.text);
        this.spacerRight = document.createElement("span");
        this.spacerRight.classList.add("nt", "nt-btn-signin-spacer");
        this.el.appendChild(this.spacerRight);
        switch (variant) {
            case "blue":
                this.el.classList.add("nt", "nt-btn-signin-blue");
                break;
            case "black":
                this.el.classList.add("nt", "nt-btn-signin-black");
                break;
            case "white":
                this.el.classList.add("nt", "nt-btn-signin-white");
                break;
        }
        switch (iconPosition) {
            case "left":
                this.el.classList.add("nt", "nt-btn-signin-icon-left");
                break;
            case "center":
                this.el.classList.add("nt", "nt-btn-signin-icon-center");
                break;
        }
    }
    return Button;
}());
exports.Button = Button;
var Popup = /** @class */ (function () {
    function Popup() {
        this.setPositionAndSize = this.setPositionAndSize.bind(this);
        this.el = document.createElement("div");
        this.el.classList.add("nt", "nt-popup");
        this.background = document.createElementNS("http://www.w3.org/2000/svg", "svg");
        this.background.classList.add("nt", "nt-popup-bg");
        this.background.setAttribute("viewBox", "0 0 176 276");
        this.background.setAttribute("fill", "none");
        this.el.appendChild(this.background);
        this.backgroundPath = document.createElementNS("http://www.w3.org/2000/svg", "path");
        this.backgroundPath.setAttribute("fill", "white");
        this.backgroundPath.setAttribute("d", this.makeBackgroundPath(176, 276));
        this.background.appendChild(this.backgroundPath);
        this.content = document.createElement("div");
        this.el.appendChild(this.content);
        this.qr = document.createElement("img");
        this.qr.classList.add("nt", "nt-popup-qr");
        this.content.appendChild(this.qr);
        this.errorMessage = document.createElement("div");
        this.errorMessage.classList.add("nt", "nt-popup-error-message");
        this.content.appendChild(this.errorMessage);
        this.caption = document.createElement("div");
        this.caption.classList.add("nt", "nt-popup-caption");
        this.content.appendChild(this.caption);
        this.captionImage = document.createElement("img");
        this.captionImage.classList.add("nt", "nt-popup-caption-image");
        this.captionImage.src = getSrcString(svg_1.captionSVGB64);
        this.caption.appendChild(this.captionImage);
        this.captionText = document.createElement("div");
        this.captionText.innerText = "Scan with your phone’s camera to continue";
        this.caption.appendChild(this.captionText);
        this.footer = document.createElement("div");
        this.footer.classList.add("nt", "nt-popup-footer");
        this.content.appendChild(this.footer);
        this.footerImage = document.createElement("img");
        this.footerImage.src = getSrcString(svg_1.logoSVGB64);
        this.footer.appendChild(this.footerImage);
        this.footerText = document.createElement("div");
        this.footerText.classList.add("nt", "nt-popup-footer-text");
        this.footerText.innerText = "Secured by Nametag";
        this.footerText.addEventListener("click", function () {
            window.location.assign("https://getnametag.com");
        });
        this.footer.appendChild(this.footerText);
    }
    Popup.prototype.addElement = function (parent) {
        this.parent = parent;
        parent.insertAdjacentElement("afterend", this.el);
        window.addEventListener("load", this.setPositionAndSize);
        window.addEventListener("resize", this.setPositionAndSize);
        window.addEventListener("orientationchange", this.setPositionAndSize);
        this.setPositionAndSize();
    };
    Popup.prototype.removeElement = function () {
        this.parent = undefined;
        window.removeEventListener("load", this.setPositionAndSize);
        window.removeEventListener("resize", this.setPositionAndSize);
        window.removeEventListener("orientationchange", this.setPositionAndSize);
        this.el.remove();
    };
    Popup.prototype.setPositionAndSize = function () {
        var h = this.content.clientHeight + 19;
        var w = this.content.clientWidth + 3;
        this.background.style.height = "".concat(h, "px");
        this.background.style.width = "".concat(w, "px");
        this.background.setAttribute("viewBox", "0 0 ".concat(w, " ").concat(h));
        this.backgroundPath.setAttribute("d", this.makeBackgroundPath(w, h));
        // position according to the parent
        if (this.parent) {
            var rect = this.parent.getBoundingClientRect();
            var top_1 = window.scrollY + rect.y + rect.height + 13;
            var left = window.scrollX + rect.x + rect.width / 2;
            this.el.style.position = "absolute";
            this.el.style.top = "".concat(top_1, "px");
            this.el.style.left = "".concat(left, "px");
            // TODO(ross): make sure that we aren't going to exceed the window width
        }
    };
    Popup.prototype.showQR = function (imgSrc) {
        this.qr.style.opacity = "1";
        this.qr.src = imgSrc;
        this.setPositionAndSize();
    };
    Popup.prototype.showError = function (message) {
        this.qr.style.opacity = "0";
        this.errorMessage.style.display = "block";
        this.errorMessage.innerText = message;
        this.setPositionAndSize();
    };
    Popup.prototype.makeBackgroundPath = function (w, h) {
        return "M".concat(w / 2, "\n                1L").concat(w / 2 - 14, "\n                19H24C11.2975\n                19\n                1\n                29.2975\n                1\n                42V").concat(h - 22, "C1\n                ").concat(h - 10.297, "\n                11.2975\n                ").concat(h, "\n                24\n                ").concat(h, "H").concat(w - 22, "C").concat(w - 11.297, "\n                ").concat(h, "\n                ").concat(w - 1, "\n                ").concat(h - 10.297, "\n                ").concat(w - 1, "\n                ").concat(h - 22, "V42C").concat(w - 1, "\n                29.2975\n                ").concat(w - 11.297, "\n                19\n                ").concat(w - 22, "\n                19H").concat(w / 2 + 14, "L").concat(w / 2, "\n                1Z");
    };
    return Popup;
}());
exports.Popup = Popup;
function hasCustomButton(container) {
    var rv = false;
    container.childNodes.forEach(function (node) {
        var _a;
        if ((_a = node.textContent) === null || _a === void 0 ? void 0 : _a.match(/^\s*$/)) {
            return; // doesn't count
        }
        rv = true;
    });
    return rv;
}
