/**
 * Copyright 2023 Nametag Inc.
 *
 * All information contained herein is the property of Nametag Inc.. The
 * intellectual and technical concepts contained herein are proprietary, trade
 * secrets, and/or confidential to Nametag, Inc. and may be covered by U.S.
 * and Foreign Patents, patents in process, and are protected by trade secret or
 * copyright law. Reproduction or distribution, in whole or in part, is
 * forbidden except by express written permission of Nametag, Inc.
 */

import { style } from "typestyle";
import { Spinner } from "../spinner";

export const LoadingPage = () => {
  return (
    <div className={css.container}>
      <Spinner />
    </div>
  );
};

const css = {
  container: style({
    position: "fixed",
    height: "100%",
    width: "100%",
    display: "grid",
    placeItems: "center",
  }),
};
