/**
 * Copyright 2022 Nametag Inc.
 *
 * All information contained herein is the property of Nametag Inc.. The
 * intellectual and technical concepts contained herein are proprietary, trade
 * secrets, and/or confidential to Nametag, Inc. and may be covered by U.S.
 * and Foreign Patents, patents in process, and are protected by trade secret or
 * copyright law. Reproduction or distribution, in whole or in part, is
 * forbidden except by express written permission of Nametag, Inc.
 */

import React from "react";
import { classes, style } from "typestyle";
import toast from "react-hot-toast";
import { COLORS } from "./colors";
import { CheckIcon } from "../components/assets/check-icon";
import { DangerIcon } from "../components/assets/danger-icon";
import { t } from "../i18n";

import { ValidationError } from "../components/savable-input";
import { FriendlyError } from "../api/error";

export function successToast(msg: string) {
  toast.custom(
    <div className={classes(css.toast, css.toastSuccess)}>
      <CheckIcon className={classes(css.icon, css.iconSuccess)} /> {msg}
    </div>,
    { position: "bottom-center" },
  );
}

export function errorString<TError>(e: TError) {
  try {
    if ((e as ValidationError)?.validationErrorMessage) {
      return (e as ValidationError)?.validationErrorMessage;
    } else if ((e as FriendlyError)?.friendlyErrorMessage) {
      return (e as FriendlyError)?.friendlyErrorMessage;
    } else {
      return t("csr_GenericError");
    }
  } catch {
    return t("csr_GenericError");
  }
}

export function showGenericError<TError>(e: TError) {
  if ((e as ValidationError)?.validationErrorMessage) {
    errorToast((e as ValidationError)?.validationErrorMessage);
  } else if ((e as FriendlyError)?.friendlyErrorMessage) {
    errorToast((e as FriendlyError)?.friendlyErrorMessage);
  } else {
    errorToast(t("csr_GenericError"));
  }
}

// a warningToast is yellow, at the top of the page, and persists until dismissed
//
// Use this for user errors, e.g. 400s.
export function warningToast(msg: string) {
  toast.custom(
    <div
      className={classes(css.toast, css.toastWarning)}
      onClick={() => toast.remove()}
    >
      <DangerIcon className={classes(css.icon, css.iconWarning)} /> {msg}
    </div>,
    {
      position: "top-center",
      duration: 24_60_60_000, // forever, basically.
    },
  );
}

// an errorToast is red, lives at the bottom of the page, and persists for 5 seconds.
// use this for server errors, e.g. 500s
export function errorToast(msg: string) {
  toast.custom(
    <div className={classes(css.toast, css.toastError)}>
      <DangerIcon className={classes(css.icon, css.iconError)} /> {msg}
    </div>,
    { position: "bottom-center" },
  );
}

const css = {
  toast: style({
    color: COLORS.white,
    boxShadow: "2px 4px 4px rgba(0, 0, 0, 0.15)",
    padding: "8px 12px",
    borderRadius: 4,
    display: "flex",
    alignItems: "center",
    gap: 8,
    fontWeight: 700,
    maxWidth: "50%",
  }),
  toastSuccess: style({
    background: COLORS.success,
  }),
  toastError: style({
    background: COLORS.error,
  }),
  icon: style({
    height: 20,
    width: 20,
    backgroundColor: COLORS.white,
    padding: 4,
    borderRadius: "50%",
    flexShrink: 0,
  }),
  iconSuccess: style({
    color: COLORS.success,
  }),
  iconError: style({
    color: COLORS.error,
  }),
  toastWarning: style({
    background: COLORS.warning,
    color: COLORS.text,
    cursor: "pointer",
    fontWeight: 500,
    padding: 16,
  }),
  iconWarning: style({
    color: COLORS.textDark,
    backgroundColor: COLORS.warning,
    height: 24,
    width: 24,
    marginTop: 0,
  }),
};
