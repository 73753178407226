/**
 * Copyright 2022 Nametag Inc.
 *
 * All information contained herein is the property of Nametag Inc.. The
 * intellectual and technical concepts contained herein are proprietary, trade
 * secrets, and/or confidential to Nametag, Inc. and may be covered by U.S.
 * and Foreign Patents, patents in process, and are protected by trade secret or
 * copyright law. Reproduction or distribution, in whole or in part, is
 * forbidden except by express written permission of Nametag, Inc.
 */

/**
 * Extends a hex code color to have opacity
 * @param color Should be a 7 character hex code, e.g. "#E715A0".
 * Does not verify input; garbage in will give garbage out
 * @param opacity Should be a value in the range [0, 1]
 * @returns
 */
export function withAlpha(color: string, opacity: number) {
  let hexOpacity = Math.round(opacity * 255).toString(16);
  if (hexOpacity.length === 1) {
    hexOpacity = "0" + hexOpacity;
  }
  return `${color}${hexOpacity}`;
}

export const COLORS = {
  // text colors
  textVeryDark: "#010202",
  textDark: "#2F2F32",
  text: "#3E3D42",
  textLight: "#57565D",
  textPlaceholder: "#706E77",
  textLink: "#005799",
  codePlaceholder: "#EC635E",

  // accent colors
  border: "#EBE8E8",
  borderLight: "#EBEBEB",
  background: "#F7F7F7",
  backgroundBlue: "#F4F8FB",
  backgroundLightPurple: "#FAF0FF",
  backgroundGreen: "#F2FAF8",
  backgroundRed: "#FDF2F1",
  backgroundDarkGrey: "#919191",
  backgroundGrey: "#C5C5C5",
  backgroundLightGrey: "#F5F5F5",
  fieldHover: "#EDEDED",

  disabled: "#C5C5C5",

  // button and card colors
  actionPrimary: "#0073CC",
  actionPrimaryHovered: "#005CA3",
  actionPrimaryDisabled: "#93C1E5",
  secondaryButton: "#E5E4E7",
  secondaryButtonHovered: "#CBC9CF",
  success: "#009E6A",
  successHovered: "#006B48",
  successDisabled: "#93D2BD",
  errorButton: "#EA3628",
  errorButtonHovered: "#841410",
  errorButtonDisabled: "#DA9E9B",
  error: "#B21B15",
  errorHovered: "#841410",
  requestedCard: "#403F4B",
  warning: "#FFFBEB",
  warningDark: "#F5BD00",
  required: "#B21B15",

  // nametag brand colors
  wordmarkGrey: "#333333",
  nametagGrey: "#9198AE",
  nametagBlue: "#123AB2",
  nametagGreen: "#00FFAA",

  verification: "#009E6A",
  verificationHover: "#028B5E",
  directory: "#A646E0",
  directoryHover: "#953FC9",

  white: "#FFFFFF",
  black: "#000000",
} as const;

const deterministicRandomColors = [
  "#b22323", // Red
  "#2323b2", // Blue-Violet
  "#23b240", // Jade
  "#b25c23", // Orange-Brown
  "#5c23b2", // Dark Violet
  "#23b279", // Sea Green
  "#b29523", // Gold
  "#9523b2", // Purple
  "#23b2b2", // Cyan
  "#95b223", // Lime Green
  "#b22395", // Deep Pink
  "#2379b2", // Bright Blue
  "#5cb223", // Green
  "#b2235c", // Hot Pink
  "#2340b2", // Royal Blue
  "#23b223", // Dark Green
  "#b24023", // Red-Orange
  "#4023b2", // Indigo
  "#23b25c", // Turquoise
  "#b27923", // Orange
  "#7923b2", // Violet
  "#23b295", // Mint
  "#b2b223", // Yellow
  "#b223b2", // Magenta
  "#2395b2", // Sky Blue
  "#79b223", // Green-Yellow
  "#b22379", // Bright Pink
  "#235cb2", // Blue
  "#40b223", // Shamrock Green
  "#b22340", // Reddish Pink
];

/**
 * Provides a consistent mapping of arbitrary input strings to valid CSS colors
 *
 * @param str input string to generate color from
 */
export function deterministicRandomColor(str: string): string {
  let hash = 0;

  for (let i = 0; i < str.length; i++) {
    hash += str.charCodeAt(i);
  }

  return deterministicRandomColors[hash % 30];
}
