"use strict";
/**
 * Copyright 2023 Nametag Inc.
 *
 * All information contained herein is the property of Nametag Inc.. The
 * intellectual and technical concepts contained herein are proprietary, trade
 * secrets, and/or confidential to Nametag, Inc. and may be covered by U.S.
 * and Foreign Patents, patents in process, and are protected by trade secret or
 * copyright law. Reproduction or distribution, in whole or in part, is
 * forbidden except by express written permission of Nametag, Inc.
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.DesktopSigninButton = void 0;
var button_1 = require("./button");
var styles_1 = require("./styles");
//   Page loads:
//     1. listen for messages from iframe
//     2. create iframe, start polling
//
//  Frame loaded:
//     3. receive QR code or initialization error
//
//  Button toggled on:
//     4. show popup
//
//  Status 200 message received:
//     - redirect
//
//  Status >= 400 message received:
//     - popup in error state
//
//  Button toggled off:
//     6. hide popup
//     8. remove iframe from DOM
//     9. re-create iframe
//
var DesktopSigninButton = /** @class */ (function () {
    function DesktopSigninButton(auth, container, options) {
        this.auth = auth;
        this.container = container;
        this.options = options;
        var customButton = (0, button_1.hasCustomButton)(container);
        if (customButton) {
            this.button = container;
        }
        else {
            var button = new button_1.Button(options.variant || "blue", options.icon_position || "left");
            this.button = button.el;
        }
        this.button.addEventListener("click", this.togglePopup.bind(this));
        window.addEventListener("message", this.onMessage.bind(this));
        this.initFrame();
        this.addStylesheetToDOM();
        if (!customButton) {
            this.addToDOM();
        }
    }
    DesktopSigninButton.prototype.togglePopup = function () {
        if (this.popup) {
            this.hidePopup();
        }
        else {
            this.showPopup();
        }
    };
    DesktopSigninButton.prototype.hidePopup = function () {
        var _a;
        (_a = this.popup) === null || _a === void 0 ? void 0 : _a.removeElement();
        this.popup = undefined;
        this.initFrame(); // reset the iframe
    };
    DesktopSigninButton.prototype.showPopup = function () {
        this.popup = new button_1.Popup();
        this.popup.addElement(this.button);
        if (this.errorMessage) {
            this.popup.showError(this.errorMessage);
        }
        if (this.qr) {
            this.popup.showQR(this.qr);
        }
    };
    DesktopSigninButton.prototype.initFrame = function () {
        return __awaiter(this, void 0, void 0, function () {
            var iframeURL;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        // reset state
                        this.qr = undefined;
                        this.errorMessage = undefined;
                        if (this.iframe !== undefined) {
                            this.container.removeChild(this.iframe);
                            this.iframe = undefined;
                        }
                        if (!this.auth.IsCurrentOriginValid()) {
                            console.error("nametag[".concat(this.auth.state, "]: the origin of the redirect_uri must equal the current page's origin"));
                            return [2 /*return*/];
                        }
                        return [4 /*yield*/, this.auth.AuthorizeURL({ iframe: true })];
                    case 1:
                        iframeURL = _a.sent();
                        this.iframe = document.createElement("iframe");
                        this.iframe.style.display = "none";
                        this.iframe.src = iframeURL;
                        this.container.appendChild(this.iframe);
                        return [2 /*return*/];
                }
            });
        });
    };
    // called when we receive a message from the iframe
    DesktopSigninButton.prototype.onMessage = function (evt) {
        var _a, _b, _c, _d, _e, _f, _g;
        if (evt.origin !== this.auth._server) {
            return;
        }
        if (evt.source !== ((_a = this.iframe) === null || _a === void 0 ? void 0 : _a.contentWindow)) {
            return;
        }
        var data = evt.data;
        if (data.state != this.auth.state) {
            return;
        }
        if (data.qr) {
            console.log("nametag[".concat(this.auth.state, "]: received QR code"));
            this.qr = data.qr;
            (_b = this.popup) === null || _b === void 0 ? void 0 : _b.showQR(data.qr);
        }
        switch (data.status) {
            case 100:
                if (data.waiting_for_review) {
                    console.error("nametag[".concat(this.auth.state, "]: review requested"));
                    this.errorMessage = "Your ID is being reviewed.";
                    (_c = this.popup) === null || _c === void 0 ? void 0 : _c.showError(this.errorMessage);
                    return;
                }
                return; // keep waiting
            case 400: // developer error
                console.error("nametag[".concat(this.auth.state, "]: developer error: ").concat((_d = data.error_message) !== null && _d !== void 0 ? _d : "(unknown)"));
                this.errorMessage = (_e = data.error_message) !== null && _e !== void 0 ? _e : "Something went wrong";
                (_f = this.popup) === null || _f === void 0 ? void 0 : _f.showError(this.errorMessage);
                return;
            case 403: // rejected
                console.log("nametag[".concat(this.auth.state, "]: user rejected the request"));
                this.errorMessage = "You choose not to accept the request";
                (_g = this.popup) === null || _g === void 0 ? void 0 : _g.showError(this.errorMessage);
                return;
            case 200:
                console.log("nametag[".concat(this.auth.state, "]: user accepted the request"));
                this.hidePopup();
                if (!data.redirect_uri) {
                    console.warn("nametag[".concat(this.auth.state, "]: internal error: expected redirect_uri when status is 200"));
                }
                else {
                    window.location.assign(data.redirect_uri);
                }
                return;
        }
    };
    DesktopSigninButton.prototype.addStylesheetToDOM = function () {
        if (!document.getElementById("nt-style")) {
            var style = document.createElement("style");
            style.id = "nt-style";
            style.innerText = styles_1.styles;
            document.head.appendChild(style);
        }
    };
    DesktopSigninButton.prototype.addToDOM = function () {
        while (this.container.firstChild) {
            this.container.removeChild(this.container.firstChild);
        }
        this.container.appendChild(this.button);
    };
    DesktopSigninButton.prototype.removeFromDOM = function () {
        while (this.container.firstChild) {
            this.container.removeChild(this.container.firstChild);
        }
    };
    return DesktopSigninButton;
}());
exports.DesktopSigninButton = DesktopSigninButton;
