/**
 * Copyright 2020 Nametag Inc.
 *
 * All information contained herein is the property of Nametag Inc.. The
 * intellectual and technical concepts contained herein are proprietary, trade
 * secrets, and/or confidential to Nametag, Inc. and may be covered by U.S.
 * and Foreign Patents, patents in process, and are protected by trade secret or
 * copyright law. Reproduction or distribution, in whole or in part, is
 * forbidden except by express written permission of Nametag, Inc.
 */

import { storage } from "./storage";

// The file is a placeholder for how we'll eventually give ourselves
// the ability to enable and disable features using flags

export interface Features {
  i18n?: boolean;
}

export const features = loadFeatures();

export function loadFeatures(): Features {
  const featuresStr = storage.getItem("__feature_flags");
  if (!featuresStr) {
    return {};
  }
  return JSON.parse(featuresStr);
}

export function saveFeatures() {
  storage.setItem("__feature_flags", JSON.stringify(features));
}
