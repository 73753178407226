/**
 * Copyright 2024 Nametag Inc.
 *
 * All information contained herein is the property of Nametag Inc.. The
 * intellectual and technical concepts contained herein are proprietary, trade
 * secrets, and/or confidential to Nametag, Inc. and may be covered by U.S.
 * and Foreign Patents, patents in process, and are protected by trade secret or
 * copyright law. Reproduction or distribution, in whole or in part, is
 * forbidden except by express written permission of Nametag, Inc.
 */

import { UseQueryResult } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { Sleep } from "../lib/sleep";
import { QueryStateView } from "./query-state-view";

export interface UseQueryStateOptions<T> {
  query: Pick<
    UseQueryResult<T, Error>,
    "data" | "isLoading" | "isError" | "refetch" | "failureCount"
  >;
  retryDelay?: (attemptIndex: number) => number; // the retry delay function. (Default: `retryDelay`)
  loadingDelay?: number; // how long to wait (in ms) before showing the initial loading spinner (default: 800ms)
}

export interface UseQueryStateResult {
  error?: boolean; // show the final error state
  loading?: boolean; // show the loading state
  errorRetry?: boolean;
  errorRetryBusy?: boolean;
  errorRetryDelay?: number;

  onRetry?: () => void; // the user pressed the retry button
}

export const retryDelay = (attemptIndex: number) =>
  Math.min(1000 * 2 ** attemptIndex, 30000);

export const useQueryState = <T,>(
  opts: UseQueryStateOptions<T>,
): UseQueryStateResult => {
  // the time when the next refresh will happen
  const [refreshAt, setRefreshAt] = useState<number | null>(0);

  // the failure count of the most recent retry attempt
  const [lastAttemptFailureCount, setLastAttemptFailureCount] = useState<
    number | null
  >(null);

  // track failures
  // we need to implement our own tracking for the retry delay because react-query doesn't
  // tell us when it's retrying. The only way to know is to watch the failureCount and
  // guess how long before it tries again.
  useEffect(() => {
    if (opts.query.failureCount == 0) {
      setRefreshAt(null);
      setLastAttemptFailureCount(null);
      return;
    }

    const failureCount = opts.query.failureCount;
    const delay = (opts.retryDelay ?? retryDelay)(opts.query.failureCount);
    setRefreshAt(new Date().getTime() + delay);
    setLastAttemptFailureCount(null);
    const f = async () => {
      // estimate the time when the next refresh will happen
      await Sleep(delay);
      setLastAttemptFailureCount(failureCount);
    };
    f();
  }, [opts.query.failureCount]);

  // refresh every second so we can update the returned delaySeconds
  const [tick, setTick] = useState(0);
  useEffect(() => {
    const t = setInterval(() => {
      setTick(tick + 1);
    }, 1000);
    return () => clearInterval(t);
  }, [tick]);

  // track the time we start loading, so we can defer showing the spinner until
  // after an initial delay. This prevents jank for fast queries.
  const [loadingSince, setLoadingSince] = useState<number | null>(null);
  useEffect(() => {
    if (opts.query.isLoading) {
      setLoadingSince(new Date().getTime());
    } else {
      setLoadingSince(null);
    }
  }, [opts.query.isLoading]);

  // manualRetry is set to true when we're busy retrying after the user presses the
  // retry button. This shows the progress spinner immediately, avoiding the
  const [manualRetryBusy, setManualRetryBusy] = useState(false);

  if (opts.query.data) {
    // this is a reload, the data are already here
    return {};
  }

  if (opts.query.isError) {
    return {
      error: true,
      onRetry: async () => {
        setManualRetryBusy(true);
        await opts.query.refetch();
        setManualRetryBusy(true);
      },
    };
  }

  if (opts.query.failureCount > 0) {
    return {
      error: false,
      loading: false,
      errorRetry: true,
      errorRetryBusy: lastAttemptFailureCount === opts.query.failureCount,
      errorRetryDelay: Math.round((refreshAt! - new Date().getTime()) / 1000),
    };
  }

  if (opts.query.isLoading) {
    if (manualRetryBusy) {
      return {
        error: true,
        errorRetryBusy: true,
      };
    }

    // don't show the loading elapsed timer until after a short initial delay
    // (to prevent jank)
    const loadingElapsedTime = new Date().getTime() - loadingSince!;
    return {
      loading: loadingElapsedTime > (opts.loadingDelay ?? 800),
    };
  }
  return {};
};

type QueryStateProps<T> = UseQueryStateOptions<T> & {
  loading?: JSX.Element;
};

export const QueryState = <T,>(props: QueryStateProps<T>) => {
  const queryState = useQueryState({
    ...props,
    loadingDelay: (props.loadingDelay ?? props.loading) ? 0 : undefined,
  });
  return <QueryStateView {...queryState} loadingView={props.loading} />;
};
