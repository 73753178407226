/**
 * Copyright 2022 Nametag Inc.
 *
 * All information contained herein is the property of Nametag Inc.. The
 * intellectual and technical concepts contained herein are proprietary, trade
 * secrets, and/or confidential to Nametag, Inc. and may be covered by U.S.
 * and Foreign Patents, patents in process, and are protected by trade secret or
 * copyright law. Reproduction or distribution, in whole or in part, is
 * forbidden except by express written permission of Nametag, Inc.
 */

export const DownArrow = (props: { color?: string; className?: string }) => (
  <svg
    className={props.className}
    width="12"
    height="9"
    viewBox="0 0 12 9"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.861278 2.02925C1.12163 1.7689 1.54374 1.7689 1.80409 2.02925L5.99935 6.22451L10.1946 2.02925C10.455 1.7689 10.8771 1.7689 11.1374 2.02925C11.3978 2.2896 11.3978 2.71171 11.1374 2.97206L6.47075 7.63872C6.2104 7.89907 5.78829 7.89907 5.52794 7.63872L0.861278 2.97206C0.600928 2.71171 0.600928 2.2896 0.861278 2.02925Z"
      fill={props.color ?? "currentColor"}
    />
  </svg>
);
