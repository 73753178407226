"use strict";
/**
 * Copyright 2021 Nametag Inc.
 *
 * Use of this source code is governed by a BSD-style
 * license that can be found in the LICENSE file or at
 * https://developers.google.com/open-source/licenses/bsd
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.PKCE = void 0;
var verifierLenth = 43;
var PKCE = /** @class */ (function () {
    function PKCE() {
        this.verifier = "";
        this.challenge = "";
        this.challengeMethod = "plain";
    }
    PKCE.FromStored = function (verifier) {
        return __awaiter(this, void 0, void 0, function () {
            var rv;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        rv = new PKCE();
                        rv.verifier = verifier;
                        return [4 /*yield*/, rv.setChallenge()];
                    case 1:
                        _a.sent();
                        return [2 /*return*/, rv];
                }
            });
        });
    };
    PKCE.New = function () {
        return __awaiter(this, void 0, void 0, function () {
            var rv, alphabet, i;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        rv = new PKCE();
                        alphabet = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
                        for (i = 0; i < verifierLenth; i++) {
                            rv.verifier += alphabet.charAt(Math.floor(Math.random() * alphabet.length));
                        }
                        return [4 /*yield*/, rv.setChallenge()];
                    case 1:
                        _a.sent();
                        return [2 /*return*/, rv];
                }
            });
        });
    };
    PKCE.prototype.setChallenge = function () {
        return __awaiter(this, void 0, void 0, function () {
            var digest, digestArr, digestStr, digestBase64, err_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.challengeMethod = "plain";
                        this.challenge = this.verifier;
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, crypto.subtle.digest("SHA-256", new TextEncoder().encode(this.verifier))];
                    case 2:
                        digest = _a.sent();
                        digestArr = Array.from(new Uint8Array(digest));
                        digestStr = digestArr
                            .map(function (byte) { return String.fromCharCode(byte); })
                            .join("");
                        digestBase64 = btoa(digestStr);
                        this.challenge = digestBase64;
                        this.challengeMethod = "S256";
                        return [3 /*break*/, 4];
                    case 3:
                        err_1 = _a.sent();
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    return PKCE;
}());
exports.PKCE = PKCE;
