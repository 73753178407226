/**
 * Copyright 2023 Nametag Inc.
 *
 * All information contained herein is the property of Nametag Inc.. The
 * intellectual and technical concepts contained herein are proprietary, trade
 * secrets, and/or confidential to Nametag, Inc. and may be covered by U.S.
 * and Foreign Patents, patents in process, and are protected by trade secret or
 * copyright law. Reproduction or distribution, in whole or in part, is
 * forbidden except by express written permission of Nametag, Inc.
 */

import { classes, style } from "typestyle";
import { COLORS } from "../lib/colors";
import { t } from "../i18n";
import moment from "moment";
import { Link } from "react-router-dom";
import { pxToRem } from "../lib/utils";
import { Org } from "../api/types";
import { Env } from "../api/types";
import { Moment } from "moment/moment";
import { Markdown } from "../lib/md";
import { OrgPlan } from "../api/types";

export interface TrialWarningProps {
  className?: string;
  org?: Pick<Org, "id" | "name" | "plan" | "trial_expires_at">;
  env?: Pick<Env, "name" | "hide_trial_banner">;
}

export const TrialWarning = (props: TrialWarningProps) => {
  if (!props.org) {
    return null;
  }

  // for unpaid orgs, we always show a trial warning. We may or may not have a trial expiration date.
  if (props.org.plan === OrgPlan.OrgPlanFree) {
    return (
      <TrialWarningView
        className={props.className}
        expires={
          props.org.trial_expires_at
            ? moment(props.org.trial_expires_at)
            : undefined
        }
        plan={props.org.plan}
        expiresLink={"/billing"}
      >
        {t("TrialWarning1")} <Link to={"/billing"}>{t("TrialWarning2")}</Link>
      </TrialWarningView>
    );
  }

  // If the org is paid, but the environment is not brand verified, then we
  // show a special trial warning.
  if (props.env && !props.env.hide_trial_banner) {
    return (
      <TrialWarningView
        className={props.className}
        expires={undefined}
        expiresLink={"/billing"}
      >
        {Markdown(
          t("TrialWarningEnv", {
            env: props.env.name,
          }),
        )}
      </TrialWarningView>
    );
  }

  return null;
};

export interface TrialWarningViewProps {
  className?: string;
  children?: React.ReactNode;
  expires?: Moment;
  expiresLink?: string;
  plan?: string;
}

export const TrialWarningView = (props: TrialWarningViewProps) => {
  return (
    <div className={classes(css.container, props.className)}>
      <p className={css.text}>{props.children}</p>
      {props.expires && !moment(props.expires).isBefore(moment()) ? (
        <p className={css.expireText}>
          {t("TrialDaysLeft", {
            n: moment(props.expires).diff(moment(), "days"),
          })}{" "}
          {props.expiresLink ? (
            <Link to={props.expiresLink}>{t("TrialLeftInTrial")}</Link>
          ) : (
            <span>{t("TrialLeftInTrial")}</span>
          )}
        </p>
      ) : props.plan === OrgPlan.OrgPlanFree ? (
        <p className={css.expireText}>{t("TrialExpired")}</p>
      ) : null}
    </div>
  );
};

const css = {
  container: style({
    background: "#F4E5E4",
    border: `1px solid ${COLORS.error}`,
    borderRadius: 4,
    display: "flex",
    alignItems: "center",
    padding: "16px 20px 15px 20px",
    marginBottom: "20px",
  }),
  text: style({
    fontSize: pxToRem(14),
    color: COLORS.textDark,
    fontWeight: 500,
    flexGrow: 1,
    $nest: {
      a: {
        color: COLORS.textLink,
      },
    },
  }),
  expireText: style({
    marginLeft: 32,
    fontSize: pxToRem(14),
    color: COLORS.textDark,
    fontWeight: 500,
    $nest: {
      a: {
        color: COLORS.textLink,
      },
    },
  }),
};
