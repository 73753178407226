/**
 * Copyright 2022 Nametag Inc.
 *
 * All information contained herein is the property of Nametag Inc.. The
 * intellectual and technical concepts contained herein are proprietary, trade
 * secrets, and/or confidential to Nametag, Inc. and may be covered by U.S.
 * and Foreign Patents, patents in process, and are protected by trade secret or
 * copyright law. Reproduction or distribution, in whole or in part, is
 * forbidden except by express written permission of Nametag, Inc.
 */

import { useState } from "react";
import { classes, style } from "typestyle";
import { t } from "../i18n";
import { COLORS } from "../lib/colors";
import { pxToRem } from "../lib/utils";
import { Button } from "./button/button";
import { Input } from "./input/input";

interface Props {
  value: string;
  disabled?: boolean;
  didCopy?: () => void;
  className?: string;
  inputClassName?: string;
  buttonClassName?: string;
  copyText?: string;
  copiedText?: string;
}

export const CopyableInput = (props: Props) => {
  const [copied, setCopied] = useState(false);

  const copy = () => {
    navigator.clipboard.writeText(props.value);
    setCopied(true);
    if (props.didCopy) {
      props.didCopy();
    }
    window.setTimeout(() => {
      setCopied(false);
    }, 5000);
  };

  return (
    <div className={classes(css.inputGroup, props.className)}>
      <Input
        value={props.value}
        disabled={props.disabled}
        className={classes(css.input, props.inputClassName)}
        readOnly
      />
      <Button
        className={classes(
          css.baseBtn,
          css.copyButton,
          copied ? css.copiedButton : null,
          props.buttonClassName,
        )}
        LeadingIcon={() => (
          <i
            className={classes(
              copied
                ? "ri-checkbox-multiple-line"
                : "ri-checkbox-multiple-blank-line",
              css.icon,
            )}
          />
        )}
        onClick={copy}
      >
        {copied
          ? (props.copiedText ?? t("envs_Copied"))
          : (props.copyText ?? t("envs_Copy"))}
      </Button>
    </div>
  );
};
const css = {
  baseBtn: style({
    borderRadius: 6,
    padding: "2px 14px",
    fontSize: pxToRem(14),
    fontWeight: 400,

    backgroundColor: COLORS.secondaryButton,
    $nest: {
      "&:hover": {
        backgroundColor: COLORS.secondaryButtonHovered,
      },
    },
  }),
  icon: style({
    fontSize: 22,
  }),

  copyButton: style({
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    background: COLORS.actionPrimary,
    color: COLORS.white,
    flexShrink: 0,
    $nest: {
      "&:hover": {
        background: COLORS.actionPrimaryHovered,
      },
    },
  }),
  copiedButton: style({
    background: COLORS.success,
    color: COLORS.white,
    $nest: {
      "&:hover": {
        background: COLORS.successHovered,
      },
    },
  }),
  inputGroup: style({
    display: "flex",
  }),
  input: style({
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
  }),
};
