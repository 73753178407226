/**
 * Copyright 2022 Nametag Inc.
 *
 * All information contained herein is the property of Nametag Inc.. The
 * intellectual and technical concepts contained herein are proprietary, trade
 * secrets, and/or confidential to Nametag, Inc. and may be covered by U.S.
 * and Foreign Patents, patents in process, and are protected by trade secret or
 * copyright law. Reproduction or distribution, in whole or in part, is
 * forbidden except by express written permission of Nametag, Inc.
 */

import React, { ReactNode } from "react";
import { Button as ReakitButton } from "reakit/Button";
import { classes, keyframes, style } from "typestyle";
import { COLORS } from "../../lib/colors";
import { pxToRem } from "../../lib/utils";

export type ButtonVariant =
  | "primary"
  | "secondary"
  | "outline"
  | "success"
  | "error"
  | "purple"
  | "warning"
  | "rounded-outline";

export type ButtonProps = {
  id?: string;
  onClick?: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  disabled?: boolean;
  loading?: boolean;
  className?: string;
  children?: ReactNode;
  ariaLabel?: string;
  LeadingIcon?: React.FunctionComponent<{ className?: string }>;
  variant?: ButtonVariant;
  type?: "button" | "submit" | "reset";
};

export const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (props, ref) => {
    const {
      id,
      onClick,
      disabled = false,
      loading,
      className,
      children,
      ariaLabel,
      LeadingIcon,
      variant,
      type,
    } = props;
    return (
      <ReakitButton
        id={id}
        onClick={onClick}
        disabled={disabled || loading}
        className={classes(
          variant === "primary" && css.primary,
          variant === "secondary" && css.secondary,
          variant === "success" && css.success,
          variant === "error" && css.error,
          variant === "outline" && css.outline,
          variant === "purple" && css.purple,
          variant === "warning" && css.warning,
          variant === "rounded-outline" && css.roundedOutline,
          css.button,
          variant !== undefined && css.base,
          loading && css.buttonLoading,
          disabled && css.buttonDisabled,
          className,
        )}
        aria-label={ariaLabel}
        type={type}
        ref={ref}
      >
        {LeadingIcon && <LeadingIcon />}
        {children}
      </ReakitButton>
    );
  },
);

const css = {
  button: style({
    background: "none",
    color: "inherit",
    border: "none",
    padding: 0,
    font: "inherit",
    cursor: "pointer",
    outline: "inherit",
    display: "flex",
    gap: 6,
    alignItems: "center",
    whiteSpace: "nowrap",
  }),
  buttonLoading: style({
    $nest: {
      "&::before": {
        content: "''",
        border: "1px solid #f3f3f3",
        borderTop: "1px solid #b7b7b7",
        borderRadius: "50%",
        width: 16,
        height: 16,
        marginRight: 8,
        marginBottom: -1,
        animation: "spin 2s linear infinite",
        animationName: keyframes({
          "0%": { transform: "rotate(0deg)" },
          "100%": { transform: "rotate(360deg)" },
        }),
        display: "inline-block",
      },
    },
  }),
  buttonDisabled: style({
    cursor: "not-allowed !important",
    opacity: 0.6,
  }),
  base: style({
    borderRadius: 4,
    fontWeight: 500,
    padding: "8px 12px",
  }),
  primary: style({
    backgroundColor: COLORS.actionPrimary,
    color: COLORS.white,
    $nest: {
      "&:hover": {
        backgroundColor: COLORS.actionPrimaryHovered,
      },
    },
  }),
  secondary: style({
    backgroundColor: COLORS.secondaryButton,
    color: COLORS.textDark,
    $nest: {
      "&:hover": {
        backgroundColor: COLORS.secondaryButtonHovered,
      },
    },
  }),
  outline: style({
    backgroundColor: "inherit",
    borderColor: COLORS.borderLight,
    borderWidth: 1,
    borderStyle: "solid",
    color: COLORS.text,
  }),
  success: style({
    backgroundColor: COLORS.success,
    color: COLORS.white,
    $nest: {
      "&:hover": {
        backgroundColor: COLORS.successHovered,
      },
    },
  }),
  error: style({
    backgroundColor: COLORS.errorButton,
    color: COLORS.white,
    $nest: {
      "&:hover": {
        backgroundColor: COLORS.errorButtonHovered,
      },
    },
  }),
  purple: style({
    backgroundColor: "#BB26FF",
    color: COLORS.white,
    $nest: {
      "&:hover": {
        backgroundColor: "#8200BD",
      },
    },
  }),
  warning: style({
    backgroundColor: COLORS.warningDark,
    color: COLORS.textDark,
    $nest: {
      "&:hover": {
        backgroundColor: "#E5AD00",
      },
    },
  }),
  roundedOutline: style({
    height: 32,
    borderRadius: 9999,
    border: "1px solid #CCD4E4",
    fontSize: pxToRem(13),
    fontWeight: 600,
    $nest: {
      "&.active": {
        backgroundColor: "#202449",
        color: "#F3F6FC",
      },
      "&:hover": {
        borderColor: "#8D95A4",
      },
    },
  }),
};
