/**
 * Copyright 2023 Nametag Inc.
 *
 * All information contained herein is the property of Nametag Inc.. The
 * intellectual and technical concepts contained herein are proprietary, trade
 * secrets, and/or confidential to Nametag, Inc. and may be covered by U.S.
 * and Foreign Patents, patents in process, and are protected by trade secret or
 * copyright law. Reproduction or distribution, in whole or in part, is
 * forbidden except by express written permission of Nametag, Inc.
 */

import { Dialog } from "../dialog/dialog";
import { t } from "../../i18n";
import { Button } from "../button/button";
import { DialogStateReturn } from "reakit/Dialog";
import { Toggle } from "../toggle/toggle";
import { features, saveFeatures } from "../../lib/features";
import React from "react";
import { Link } from "react-router-dom";
import { style } from "typestyle";
import { pxToRem } from "../../lib/utils";
import { COLORS } from "../../lib/colors";
import { useFromURL } from "../../console/use_from_url";
import { GetTag } from "../../lib/tags";

type Props = {
  dialog: DialogStateReturn;
};

export const FeaturesDialog: React.FC<Props> = ({ dialog }) => {
  const { org } = useFromURL();
  const opsconOrgBaseURL = GetTag("url") + "/opscon/orgs/";

  return (
    <Dialog dialog={dialog} ariaLabel={"Features"}>
      <h2 className={css.modalHeader}>Features</h2>

      <div className={css.modalBody}>
        <div>
          <Toggle
            defaultChecked={features.i18n}
            label={"i18n"}
            onChange={(evt) => {
              features.i18n = evt.target.checked;
            }}
          />
          <label className={css.toggleLabel}>Enable translations</label>
        </div>

        {org && <Link to={opsconOrgBaseURL + org.id}>Opscon org</Link>}
      </div>

      <div className={css.buttonsContainer}>
        <Button
          variant="primary"
          className={css.btn}
          onClick={() => {
            saveFeatures();
            dialog.hide();
            window.location.reload();
          }}
        >
          {t("envs_Done")}
        </Button>
      </div>
    </Dialog>
  );
};

const css = {
  modalHeader: style({
    fontSize: "1.5rem",
    fontWeight: 700,
    padding: 16,
    borderBottom: "1px solid #E5E5E5",
  }),
  modalBody: style({
    padding: "16px 32px 16px 16px",
  }),
  buttonsContainer: style({
    display: "flex",
    justifyContent: "flex-end",
    gap: 8,
    padding: 16,
  }),
  btn: style({
    borderRadius: 6,
    padding: "8px 14px",
    whiteSpace: "nowrap",
    fontSize: pxToRem(15),
    fontWeight: 700,
    border: "1px solid #CFDCE6",
  }),
  deleteBtn: style({
    backgroundColor: "#FF0D0D",
    color: COLORS.white,
    border: "none",
  }),
  toggleLabel: style({
    marginLeft: 12,
    position: "relative",
    top: "-3px",
  }),
};
