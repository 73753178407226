/**
 * Copyright 2022 Nametag Inc.
 *
 * All information contained herein is the property of Nametag Inc.. The
 * intellectual and technical concepts contained herein are proprietary, trade
 * secrets, and/or confidential to Nametag, Inc. and may be covered by U.S.
 * and Foreign Patents, patents in process, and are protected by trade secret or
 * copyright law. Reproduction or distribution, in whole or in part, is
 * forbidden except by express written permission of Nametag, Inc.
 */

import { ReactElement } from "react";

export const MembersIcon = (props: { className?: string }): ReactElement => {
  return (
    <svg
      className={props.className}
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.3334 13.8333H14.6667V12.5C14.6667 12.0843 14.5372 11.679 14.2961 11.3404C14.0551 11.0017 13.7146 10.7466 13.3219 10.6105C12.9292 10.4743 12.5038 10.4639 12.1049 10.5806C11.706 10.6974 11.3534 10.9355 11.096 11.262M11.3334 13.8333H4.66671M11.3334 13.8333V12.5C11.3334 12.0626 11.2494 11.6446 11.096 11.262M11.096 11.262C10.8485 10.6433 10.4212 10.1129 9.86937 9.73937C9.31753 9.36581 8.66643 9.16616 8.00004 9.16616C7.33365 9.16616 6.68255 9.36581 6.13071 9.73937C5.57887 10.1129 5.15161 10.6433 4.90404 11.262M4.66671 13.8333H1.33337V12.5C1.3334 12.0843 1.46293 11.679 1.70395 11.3404C1.94497 11.0017 2.2855 10.7466 2.67821 10.6105C3.07092 10.4743 3.49629 10.4639 3.8952 10.5806C4.29411 10.6974 4.64673 10.9355 4.90404 11.262M4.66671 13.8333V12.5C4.66671 12.0626 4.75071 11.6446 4.90404 11.262M10 5.16663C10 5.69706 9.78933 6.20577 9.41425 6.58084C9.03918 6.95591 8.53047 7.16663 8.00004 7.16663C7.46961 7.16663 6.9609 6.95591 6.58583 6.58084C6.21075 6.20577 6.00004 5.69706 6.00004 5.16663C6.00004 4.63619 6.21075 4.12749 6.58583 3.75241C6.9609 3.37734 7.46961 3.16663 8.00004 3.16663C8.53047 3.16663 9.03918 3.37734 9.41425 3.75241C9.78933 4.12749 10 4.63619 10 5.16663ZM14 7.16663C14 7.52025 13.8596 7.85939 13.6095 8.10943C13.3595 8.35948 13.0203 8.49996 12.6667 8.49996C12.3131 8.49996 11.9739 8.35948 11.7239 8.10943C11.4739 7.85939 11.3334 7.52025 11.3334 7.16663C11.3334 6.813 11.4739 6.47387 11.7239 6.22382C11.9739 5.97377 12.3131 5.83329 12.6667 5.83329C13.0203 5.83329 13.3595 5.97377 13.6095 6.22382C13.8596 6.47387 14 6.813 14 7.16663ZM4.66671 7.16663C4.66671 7.52025 4.52623 7.85939 4.27618 8.10943C4.02613 8.35948 3.687 8.49996 3.33337 8.49996C2.97975 8.49996 2.64061 8.35948 2.39056 8.10943C2.14052 7.85939 2.00004 7.52025 2.00004 7.16663C2.00004 6.813 2.14052 6.47387 2.39056 6.22382C2.64061 5.97377 2.97975 5.83329 3.33337 5.83329C3.687 5.83329 4.02613 5.97377 4.27618 6.22382C4.52623 6.47387 4.66671 6.813 4.66671 7.16663Z"
        stroke="currentColor"
        strokeWidth="1.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
