/**
 * Copyright 2023 Nametag Inc.
 *
 * All information contained herein is the property of Nametag Inc.. The
 * intellectual and technical concepts contained herein are proprietary, trade
 * secrets, and/or confidential to Nametag, Inc. and may be covered by U.S.
 * and Foreign Patents, patents in process, and are protected by trade secret or
 * copyright law. Reproduction or distribution, in whole or in part, is
 * forbidden except by express written permission of Nametag, Inc.
 */

import { Auth } from "@nametag/browser";
import { useEffect } from "react";
import { useMutation } from "@tanstack/react-query";
import { useNavigate } from "react-router";
import { useLocation } from "react-router-dom";
import { style } from "typestyle";
import { showGenericError } from "../../lib/feedback";
import { ProgressBar } from "../../lib/progress_bar";
import { GetTag } from "../../lib/tags";
import { Footer } from "../../components/page/footer";

export interface VerifyFinishPageProps {}

export const VerifyFinishPage = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const exchangeMutation = useMutation({
    mutationKey: ["exchange"],
    mutationFn: async () => {
      const auth = new Auth({
        client_id: GetTag("oauth2_client_id")!,
        redirect_uri: GetTag("oauth2_redirect_uri")!,
        server: GetTag("oauth2_server")!,
        template: GetTag("oauth2_template")!,
      });
      const res = await auth.HandleCallback();
      if (res?.token?.id_token) {
        navigate("/reset", {
          state: {
            state: auth.state,
            id_token: res.token.id_token,
          },
          replace: true,
        });
      }
      return res;
    },
    onError: showGenericError,
  });
  useEffect(() => {
    if (location.hash) {
      exchangeMutation.mutate();
    }
  }, [location.hash]);

  return <VerifyFinishView />;
};

export interface VerifyFinishViewProps {}

export const VerifyFinishView = () => {
  return (
    <div className={css.root}>
      <main className={css.content}>
        <ProgressBar estimated={2000} className={css.progress} />
      </main>
      <Footer />
    </div>
  );
};

const css = {
  root: style({
    minHeight: "100vh",
    display: "flex",
    flexDirection: "column",
  }),
  content: style({
    flexGrow: 1,
  }),
  progress: style({
    height: 1,
  }),
};
