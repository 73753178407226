/**
 * Copyright 2021 Nametag Inc.
 *
 * All information contained herein is the property of Nametag Inc.. The
 * intellectual and technical concepts contained herein are proprietary, trade
 * secrets, and/or confidential to Nametag, Inc. and may be covered by U.S.
 * and Foreign Patents, patents in process, and are protected by trade secret or
 * copyright law. Reproduction or distribution, in whole or in part, is
 * forbidden except by express written permission of Nametag, Inc.
 */

import React, { useContext } from "react";
import { API } from "./index";
import assert from "assert";
import { QueryClientProvider } from "@tanstack/react-query";

// ContextT represents the context object we attach and pass to our
// child elements to provide them access to the side menu.
interface ContextT {
  api: API;
}

const Context = React.createContext<ContextT | null>(null);

interface Props {
  api: API;
  children?: JSX.Element;
}

export const APIProvider = ({ api, children }: Props) => {
  const ctxValue: ContextT = { api };
  return (
    <Context.Provider value={ctxValue}>
      <QueryClientProvider client={api.queryClient}>
        {children}
      </QueryClientProvider>
    </Context.Provider>
  );
};

export const useAPI = (): ContextT => {
  const rv = useContext(Context);
  assert(rv !== null);
  return rv;
};
