/**
 * Copyright 2020 Nametag Inc.
 *
 * All information contained herein is the property of Nametag Inc.. The
 * intellectual and technical concepts contained herein are proprietary, trade
 * secrets, and/or confidential to Nametag, Inc. and may be covered by U.S.
 * and Foreign Patents, patents in process, and are protected by trade secret or
 * copyright law. Reproduction or distribution, in whole or in part, is
 * forbidden except by express written permission of Nametag, Inc.
 */

export const Sleep = (timeout: number) => {
  return new Promise<void>((resolve) => {
    window.setTimeout(resolve, timeout);
  });
};

export const SleepForever = () => {
  return new Promise<void>(() => {});
};

// There some cases where a button is in a busy state and then the page navigates away
// as a result of the button press. After we navigate, we should sleep for a while
// before completing the promise, so that the button (or whatever) doesn't go back to
// idle while the page is navigating away. This is how long we wait for that to happen,
// essentially, how long we think the ~ P80 page navigation takes.
export const NavigationAntiJankSleepTimeMs = 800;
