/**
 * Copyright 2022 Nametag Inc.
 *
 * All information contained herein is the property of Nametag Inc.. The
 * intellectual and technical concepts contained herein are proprietary, trade
 * secrets, and/or confidential to Nametag, Inc. and may be covered by U.S.
 * and Foreign Patents, patents in process, and are protected by trade secret or
 * copyright law. Reproduction or distribution, in whole or in part, is
 * forbidden except by express written permission of Nametag, Inc.
 */

import { ReactElement } from "react";

export const OrganizationPlaceholder = (props: {
  className?: string;
}): ReactElement => (
  <svg
    className={props.className}
    width={28}
    height={28}
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx={14} cy={14} r={14} fill="#E3F3FF" />
    <path
      d="M18.6667 20V9.33333C18.6667 8.97971 18.5262 8.64057 18.2761 8.39052C18.0261 8.14048 17.687 8 17.3333 8H10.6667C10.313 8 9.97391 8.14048 9.72386 8.39052C9.47381 8.64057 9.33333 8.97971 9.33333 9.33333V20M18.6667 20H9.33333M18.6667 20H20M18.6667 20H15.3333M9.33333 20H8M9.33333 20H12.6667M15.3333 20V16.6667C15.3333 16.4899 15.2631 16.3203 15.1381 16.1953C15.013 16.0702 14.8435 16 14.6667 16H13.3333C13.1565 16 12.987 16.0702 12.8619 16.1953C12.7369 16.3203 12.6667 16.4899 12.6667 16.6667V20M15.3333 20H12.6667M12 10.6667H12.6667M12 13.3333H12.6667M15.3333 10.6667H16M15.3333 13.3333H16"
      stroke="#57565D"
      strokeWidth="1.33333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
