/**
 * Copyright 2022 Nametag Inc.
 *
 * All information contained herein is the property of Nametag Inc.. The
 * intellectual and technical concepts contained herein are proprietary, trade
 * secrets, and/or confidential to Nametag, Inc. and may be covered by U.S.
 * and Foreign Patents, patents in process, and are protected by trade secret or
 * copyright law. Reproduction or distribution, in whole or in part, is
 * forbidden except by express written permission of Nametag, Inc.
 */

import { classes, style } from "typestyle";
import { COLORS } from "../../lib/colors";
import { LinkProps, NavLink } from "react-router-dom";
import { pxToRem } from "../../lib/utils";
import { EnvCustomizationIcon } from "../assets/sidenav/env-customization";
import { RequestTemplatesIcon } from "../assets/sidenav/request-templates";
import { OAuthIcon } from "../assets/sidenav/oauth";
import { WebhooksIcon } from "../assets/sidenav/webhooks";
import { DangerZoneIcon } from "../assets/sidenav/danger";
import { OrganizationIcon } from "../assets/sidenav/organization";
import { MembersIcon } from "../assets/sidenav/members";
import { APIKeysIcon } from "../assets/sidenav/apikeys";
import { RoleGe } from "../../api/orgs";
import { Org, Env } from "../../api/types";
import { t } from "../../i18n";
import { envURI } from "../../console/url";
import { Tooltip } from "../tooltip/tooltip";
import { HelpIcon } from "../assets/sidenav/help";
import { BillingIcon } from "../assets/sidenav/billing-icon";
import { ReportsIcon } from "../assets/sidenav/reports";
import { OrgFeatureState, OrgPlan, Role } from "../../api/types";

interface Props {
  org?: Org;
  env?: Env;
  envs?: Array<Env>;
}

export const SideNav = (props: Props) => {
  const envBaseURI =
    props.env && props.envs ? envURI(props.env, props.envs) : "";

  return (
    <div className={css.sideNav}>
      <div className={css.top}>
        <SideNavLink
          label={t("envs_Configure")}
          icon={EnvCustomizationIcon}
          to={envBaseURI + "/configure"}
        />
        <SideNavLink
          to={envBaseURI + "/templates"}
          icon={RequestTemplatesIcon}
          label={t("sidenav_RequestTemplates")}
        />
        <div className={css.spacer} />
        <SideNavLink
          to={envBaseURI + "/oauth"}
          icon={OAuthIcon}
          label={t("sidenav_OAuth")}
        />
        <SideNavLink
          to={envBaseURI + "/webhooks"}
          icon={WebhooksIcon}
          label={t("sidenav_Webhooks")}
        />
        <SideNavLink
          to={envBaseURI + "/self-service"}
          icon={({ className }) => (
            <i className={classes("ri-window-line", className)} />
          )}
          label={t("dir_SelfService")}
          disabled={!RoleGe(props.org?.role, Role.RoleOwner)}
          disabledTooltip={t("sidenav_Directories_Disabled")}
        />
        <SideNavLink
          to={envBaseURI + "/integrations"}
          icon={({ className }) => (
            <i className={classes("ri-plug-line", className)} />
          )}
          label={t("dir_Integrations")}
          disabled={!RoleGe(props.org?.role, Role.RoleOwner)}
          disabledTooltip={t("sidenav_Directories_Disabled")}
        />

        <SideNavLink
          to={envBaseURI + "/danger"}
          icon={DangerZoneIcon}
          label={t("sidenav_DangerZone")}
        />
      </div>
      <div className={css.bottom}>
        <SideNavLink
          to={"/configure"}
          icon={OrganizationIcon}
          label={t("sidenav_Organization")}
        />
        <SideNavLink
          label={t("sidenav_Reports")}
          icon={ReportsIcon}
          to={"/reports"}
          hidden={
            !props.org ||
            props.org.features.console_reports_v0 !==
              OrgFeatureState.OrgFeatureStateEnabled
          }
        />
        <SideNavLink
          to={"/members"}
          icon={MembersIcon}
          label={t("sidenav_Members")}
        />
        <SideNavLink
          to={"/billing"}
          icon={BillingIcon}
          label={t("sidenav_Billing")}
          hidden={props.org && props.org.plan === OrgPlan.OrgPlanEnterprise}
          disabled={
            props.org &&
            props.org.plan !== OrgPlan.OrgPlanEnterprise &&
            props.org.role !== Role.RoleOwner
          }
          disabledTooltip={t("sidenav_Billing_Disabled")}
        />
        <SideNavLink
          to={"/apikeys"}
          icon={APIKeysIcon}
          label={t("sidenav_APIKeys")}
        />
        <SideNavLink
          to={"https://getnametag.com/help"}
          icon={HelpIcon}
          label={t("sidenav_Help")}
        />
      </div>
    </div>
  );
};

interface SideNavLinkProps {
  label: string;
  icon: (props: { className?: string }) => React.ReactElement;
  to: LinkProps["to"];
  hidden?: boolean;
  disabled?: boolean;
  disabledTooltip?: string;
}

const SideNavLink = (props: SideNavLinkProps) => {
  if (props.hidden) {
    return null;
  }
  if (props.disabled) {
    return (
      <Tooltip
        placement="auto-end"
        title={props.disabledTooltip ?? ""}
        gutter={2}
      >
        <div className={classes(css.menuItem, css.menuItemDisabled)}>
          {props.icon({ className: css.icon })}
          {props.label}
        </div>
      </Tooltip>
    );
  }

  const itemClassName = (props: { isActive: boolean }) => {
    return classes(css.menuItem, props.isActive && css.menuItemActive);
  };

  return (
    <NavLink to={props.to} className={itemClassName}>
      {props.icon({ className: css.icon })}
      {props.label}
    </NavLink>
  );
};

const css = {
  sideNav: style({
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "left",

    backgroundColor: COLORS.white,
    height: "100%",
    paddingTop: 8,
  }),
  top: style({
    flex: 1,
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
    borderBottom: "1px solid #E5E5E5",
  }),
  spacer: style({
    flexGrow: 1,
  }),
  bottom: style({
    marginTop: "4px",
    paddingBottom: 8,
  }),
  menuItem: style({
    color: COLORS.text,
    display: "flex",
    alignItems: "center",
    margin: "4px 16px 4px 16px",
    borderRadius: "4px",
    padding: "8px 8px",
    fontSize: pxToRem(14),
    fontWeight: 400,
    $nest: {
      "&:hover": {
        backgroundColor: COLORS.fieldHover,
        textDecoration: "none",
      },
    },

    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  }),
  menuItemDisabled: style({
    opacity: 0.6,
    cursor: "not-allowed",
    $nest: {
      "&:hover": {
        backgroundColor: "inherit",
      },
    },
  }),
  menuItemActive: style({
    backgroundColor: COLORS.actionPrimary,
    color: COLORS.white,
    $nest: {
      "&:hover": {
        backgroundColor: COLORS.actionPrimary,
        color: COLORS.white,
      },
    },
  }),
  icon: style({
    marginRight: 8,
    height: 17,
    fontSize: 16,
  }),
};
