/**
 * Copyright 2020 Nametag Inc.
 *
 * All information contained herein is the property of Nametag Inc.. The
 * intellectual and technical concepts contained herein are proprietary, trade
 * secrets, and/or confidential to Nametag, Inc. and may be covered by U.S.
 * and Foreign Patents, patents in process, and are protected by trade secret or
 * copyright law. Reproduction or distribution, in whole or in part, is
 * forbidden except by express written permission of Nametag, Inc.
 */

import { useEffect, useState } from "react";

let __tags: Map<string, Array<string>> | undefined;

export function SetTags(tags: Map<string, Array<string>>) {
  __tags = tags;
}

// GetTags returns a map of name -> Array<value> for each metadata tag
export function GetTags(): Map<string, Array<string>> {
  if (!__tags) {
    const elements = Array.prototype.slice.call(
      document.getElementsByTagName("meta"),
    );
    const rv: Map<string, Array<string>> = new Map();
    elements.forEach((el: HTMLMetaElement) => {
      const name = el.name;
      const values = rv.get(name);
      if (!values) {
        rv.set(name, [el.content]);
      } else {
        values.push(el.content);
      }
    });
    __tags = rv;
  }
  return __tags;
}

// GetTag returns the single value of the meta tag given by `name` or null if
// there is not exactly one value.
export function GetTag(name: string): string | undefined {
  const tags = GetTags();
  const values = tags.get(name);
  if (!values || values.length !== 1) {
    return undefined;
  }
  return values[0];
}

// useMetaTagValue is a hook that returns the content of a meta tag with the
// given name. Unlike GetTag, this hook ties into React's lifecycle and will
// update if the meta tag's content changes.
export function useMetaTagValue(metaTagName: string): string | null {
  const [content, setContent] = useState<string | null>(null);

  useEffect(() => {
    const fetchMetaTagContent = () => {
      const metaTag = document.querySelector(`meta[name="${metaTagName}"]`);
      return metaTag ? metaTag.getAttribute("content") : null;
    };

    const metaContent = fetchMetaTagContent();
    setContent(metaContent);
  }, [metaTagName]);

  return content;
}
