/**
 * Copyright 2022 Nametag Inc.
 *
 * All information contained herein is the property of Nametag Inc.. The
 * intellectual and technical concepts contained herein are proprietary, trade
 * secrets, and/or confidential to Nametag, Inc. and may be covered by U.S.
 * and Foreign Patents, patents in process, and are protected by trade secret or
 * copyright law. Reproduction or distribution, in whole or in part, is
 * forbidden except by express written permission of Nametag, Inc.
 */

import { useEffect, useState } from "react";
import { DialogDisclosure, useDialogState } from "reakit/Dialog";
import { Menu, MenuButton, MenuItem, useMenuState } from "reakit/Menu";
import { classes, style } from "typestyle";
import { t } from "../../i18n";
import { COLORS } from "../../lib/colors";
import { useDimensions } from "../../lib/use-dimensions";
import { pxToRem } from "../../lib/utils";
import { UserPlaceholder } from "../assets/user-placeholder";
import { FeaturesDialog } from "./features_dialog";

interface Props {
  profilePicture?: string;
  name?: string;
  className?: string;
  signOut: () => Promise<void>;
  enableFeatureFlagsMenu?: boolean;
}

export const UserMenu = (props: Props) => {
  const menu = useMenuState({
    unstable_offset: [0, 16],
    placement: "bottom-end",
  });

  const [buttonRef, buttonRect] = useDimensions();
  const [menuRef, menuRect] = useDimensions();
  const width =
    buttonRect && menuRect
      ? Math.max(buttonRect.width, menuRect.width)
      : undefined;

  const [showFeatures, setShowFeatures] = useState(false);

  // pressing ctrl+shift+f enables the features menu until the next
  // page load
  function downHandler(event: KeyboardEvent) {
    if (event.ctrlKey && event.shiftKey && event.key == "F") {
      setShowFeatures(true);
    }
  }
  useEffect(() => {
    if (props.enableFeatureFlagsMenu) {
      window.addEventListener("keydown", downHandler);
      return () => {
        window.removeEventListener("keydown", downHandler);
      };
    }
  }, [props.enableFeatureFlagsMenu]);

  const featuresDialog = useDialogState();

  return (
    <div
      data-testid="user-menu"
      className={classes(props.className, css.container)}
      id="user_menu"
    >
      <MenuButton
        ref={buttonRef}
        {...menu}
        className={classes(
          css.menuButton,
          menu.visible && css.menuButtonVisible,
        )}
        style={{ width: width }}
      >
        {props.profilePicture ? (
          <img
            alt={"Profile picture"}
            src={props.profilePicture}
            className={css.logo}
          />
        ) : (
          <UserPlaceholder className={css.logo} />
        )}
        <span className={css.menuButtonText} id="user_name">
          {props.name || t("Anonymous")}
        </span>
      </MenuButton>
      <Menu
        {...menu}
        ref={menuRef}
        className={css.menu}
        style={{ width: width }}
        aria-label={"User"}
      >
        <MenuItem
          {...menu}
          className={classes(css.menuItem)}
          onClick={props.signOut}
        >
          {t("SignOut")}
        </MenuItem>
        {showFeatures && (
          <>
            <DialogDisclosure
              as={MenuItem}
              {...featuresDialog}
              className={classes(css.menuItem)}
            >
              Features
            </DialogDisclosure>
          </>
        )}
      </Menu>
      {props.enableFeatureFlagsMenu && (
        <FeaturesDialog dialog={featuresDialog} />
      )}
    </div>
  );
};

const width = 179;

const css = {
  container: style({
    width,
  }),
  menuButton: style({
    display: "flex",
    color: COLORS.white,
    border: "none",
    alignItems: "center",
    backgroundColor: "rgba(0,0,0,0)",
    padding: "8px 12px",
    borderRadius: "4px",
    fontWeight: 700,
    fontSize: pxToRem(16),
    $nest: {
      "&:hover": {
        backgroundColor: "#005799",
      },
    },
    width: 179,
  }),
  menuButtonVisible: style({
    backgroundColor: COLORS.actionPrimary,
    $nest: {
      "&:hover": {
        backgroundColor: COLORS.actionPrimary,
      },
    },
  }),
  menuButtonText: style({
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    maxWidth: width - 29,
  }),
  logo: style({
    height: 28,
    width: 28,
    minHeight: 28,
    minWidth: 28,
    maxHeight: 28,
    maxWidth: 28,
    marginRight: 12,
    borderRadius: 28,
    objectFit: "cover",
  }),

  menu: style({
    display: "flex",
    flexDirection: "column",
    backgroundColor: COLORS.white,
    border: "1px solid " + COLORS.border,
    boxShadow: "0px 1px 3px rgba(0, 0, 0, 0.15)",
    borderRadius: 4,
  }),

  menuItem: style({
    display: "flex",
    flexDirection: "row",
    color: COLORS.text,
    border: "none",
    alignItems: "center",
    justifyContent: "left",
    backgroundColor: COLORS.white,
    padding: "10px 32px 12px 12px",
    fontWeight: 400,
    fontSize: pxToRem(16),
    borderTop: "1px solid " + COLORS.border + " !important",
    $nest: {
      "&:hover": {
        backgroundColor: COLORS.fieldHover,
      },
    },
  }),
};
