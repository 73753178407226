/**
 * Copyright 2023 Nametag Inc.
 *
 * All information contained herein is the property of Nametag Inc.. The
 * intellectual and technical concepts contained herein are proprietary, trade
 * secrets, and/or confidential to Nametag, Inc. and may be covered by U.S.
 * and Foreign Patents, patents in process, and are protected by trade secret or
 * copyright law. Reproduction or distribution, in whole or in part, is
 * forbidden except by express written permission of Nametag, Inc.
 */

import { DialogDisclosure, useDialogState } from "reakit/Dialog";
import { style } from "typestyle";
import { Button } from "../../components/button/button";
import { COLORS } from "../../lib/colors";
import { pxToRem } from "../../lib/utils";
import {
  DoRecoveryResponse,
  RecoveryOption,
  RecoveryType,
} from "../recovery-api";
import { AzurePasswordResetModal } from "./reset-modal/azure-password-reset-modal";
import { SimpleMFAResetModal } from "./reset-modal/simple-mfa-reset-modal";
import { OktaPasswordResetModal } from "./reset-modal/okta-password-reset-modal";
import { DuoMFAResetModal } from "./reset-modal/duo-mfa-reset-modal";
import { t } from "../../i18n";
import { useEffect } from "react";
import { OneLoginPasswordResetModal } from "./reset-modal/onelogin-password-reset-modal";
import { SimpleUnlockModal } from "./reset-modal/simple-unlock-modal";
import { DirectoryKind } from "../../api/types";

type AccountRecoveryOptionProps = {
  account: RecoveryOption;
  doRecovery: (recoveryType: RecoveryType) => Promise<void>;
  doRecoveryIsLoading: boolean;
  doRecoveryIsError: boolean;
  doRecoveryIsSuccess: boolean;
  doRecoveryErrorMessage?: string;
  doRecoveryInfo?: DoRecoveryResponse;
  doRecoveryResetState: () => void;
  setShowSuccessBanner: (show: boolean) => void;
};

export const AccountRecoveryOption: React.FC<AccountRecoveryOptionProps> = ({
  account,
  doRecovery,
  doRecoveryIsLoading,
  doRecoveryIsError,
  doRecoveryIsSuccess,
  doRecoveryErrorMessage,
  doRecoveryInfo,
  doRecoveryResetState,
  setShowSuccessBanner,
}) => {
  const passwordDialog = useDialogState();
  const mfaDialog = useDialogState();
  const unlockDialog = useDialogState();

  useEffect(() => {
    // we only want to show the success banner after the user closes the dialog
    if (
      !passwordDialog.visible &&
      !mfaDialog.visible &&
      !unlockDialog.visible
    ) {
      if (doRecoveryIsSuccess) {
        setShowSuccessBanner(true);
      }
      // reset the state after the dialog is closed, so error messages are cleared
      doRecoveryResetState();
    }
  }, [passwordDialog.visible, mfaDialog.visible, unlockDialog.visible]);

  const directoryKindName = {
    [DirectoryKind.DirectoryKindAzureAD]: "Microsoft Entra ID",
    [DirectoryKind.DirectoryKindOkta]: "Okta",
    [DirectoryKind.DirectoryKindDuo]: "Duo",
    [DirectoryKind.DirectoryKindOnelogin]: "OneLogin",
  }[account.kind];

  return (
    <>
      <div className={css.account}>
        <img
          src={account.logo}
          alt={`${account.kind} logo`}
          className={css.logo}
        />
        <div className={css.accountInfo}>
          <div className={css.accountTitle}>
            {directoryKindName} • {account.title}
          </div>
          <div className={css.accountExternalID}>
            {account.external_identifier}
          </div>
        </div>
        <div className={css.accountActions}>
          {account.reset_mfa && (
            <DialogDisclosure
              {...mfaDialog}
              as={Button}
              variant="secondary"
              // if Duo, do the reset now. otherwise it is handled in the modal.
              onClick={
                account.kind === DirectoryKind.DirectoryKindDuo
                  ? () => doRecovery(RecoveryType.MFA)
                  : undefined
              }
            >
              {account.kind === DirectoryKind.DirectoryKindDuo
                ? t("microsite_ResetMFADuo")
                : t("microsite_ResetMFA")}
            </DialogDisclosure>
          )}
          {account.reset_password && (
            <DialogDisclosure
              {...passwordDialog}
              as={Button}
              onClick={() => {
                doRecovery(RecoveryType.PASSWORD);
              }}
              variant="secondary"
            >
              {t("microsite_ResetPassword")}
            </DialogDisclosure>
          )}
          {account.unlock && (
            <DialogDisclosure
              {...unlockDialog}
              as={Button}
              onClick={() => {
                doRecovery(RecoveryType.UNLOCK);
              }}
              variant="secondary"
            >
              {t("microsite_Unlock")}
            </DialogDisclosure>
          )}
        </div>
      </div>
      {account.kind === DirectoryKind.DirectoryKindAzureAD && (
        <AzurePasswordResetModal
          dialog={passwordDialog}
          directoryLogo={account.logo}
          directoryName={account.title}
          externalIdentifier={account.external_identifier}
          isLoading={doRecoveryIsLoading}
          isError={doRecoveryIsError}
          errorMessage={doRecoveryErrorMessage}
          newPassword={doRecoveryInfo?.new_password}
        />
      )}
      {account.kind === DirectoryKind.DirectoryKindOkta && (
        <OktaPasswordResetModal
          dialog={passwordDialog}
          directoryLogo={account.logo}
          directoryName={account.title}
          externalIdentifier={account.external_identifier}
          isLoading={doRecoveryIsLoading}
          isError={doRecoveryIsError}
          errorMessage={doRecoveryErrorMessage}
          resetPasswordLink={doRecoveryInfo?.reset_password_link}
        />
      )}
      {(account.kind === DirectoryKind.DirectoryKindAzureAD ||
        account.kind === DirectoryKind.DirectoryKindOkta ||
        account.kind === DirectoryKind.DirectoryKindOnelogin) && (
        <SimpleMFAResetModal
          dialog={mfaDialog}
          directoryLogo={account.logo}
          directoryName={account.title}
          externalIdentifier={account.external_identifier}
          isLoading={doRecoveryIsLoading}
          isError={doRecoveryIsError}
          errorMessage={doRecoveryErrorMessage}
          doRecovery={() => doRecovery(RecoveryType.MFA)}
          directoryKind={account.kind}
          resetMFALink={doRecoveryInfo?.reset_mfa_link}
        />
      )}
      {account.kind === DirectoryKind.DirectoryKindDuo && (
        <DuoMFAResetModal
          dialog={mfaDialog}
          directoryLogo={account.logo}
          directoryName={account.title}
          externalIdentifier={account.external_identifier}
          isLoading={doRecoveryIsLoading}
          isError={doRecoveryIsError}
          errorMessage={doRecoveryErrorMessage}
          mfaRecoveryCode={doRecoveryInfo?.mfa_recovery_code}
        />
      )}
      {account.kind === DirectoryKind.DirectoryKindOnelogin && (
        <OneLoginPasswordResetModal
          dialog={passwordDialog}
          directoryLogo={account.logo}
          directoryName={account.title}
          externalIdentifier={account.external_identifier}
          isLoading={doRecoveryIsLoading}
          isError={doRecoveryIsError}
          errorMessage={doRecoveryErrorMessage}
          resetPasswordLink={doRecoveryInfo?.reset_password_link}
        />
      )}
      <SimpleUnlockModal
        dialog={unlockDialog}
        directoryLogo={account.logo}
        directoryName={account.title}
        externalIdentifier={account.external_identifier}
        isLoading={doRecoveryIsLoading}
        isError={doRecoveryIsError}
        isSuccess={doRecoveryIsSuccess}
        errorMessage={doRecoveryErrorMessage}
        doRecovery={() => doRecovery(RecoveryType.UNLOCK)}
        directoryKind={account.kind}
      />
    </>
  );
};

const css = {
  account: style({
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    border: `1px solid ${COLORS.border}`,
    borderRadius: 6,
    background: COLORS.white,
    padding: `20px 24px`,
    gap: 16,
  }),
  logo: style({
    width: 40,
    height: 40,
    objectFit: "contain",
  }),
  accountInfo: style({
    flexGrow: 1,
  }),
  accountTitle: style({
    color: COLORS.text,
    fontWeight: 500,
  }),
  accountExternalID: style({
    color: COLORS.textLight,
    fontSize: pxToRem(14),
  }),
  accountActions: style({
    display: "flex",
    flexDirection: "row",
    gap: 16,
    fontSize: pxToRem(14),
    color: COLORS.text,
  }),
};
