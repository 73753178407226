/**
 * Copyright 2022 Nametag Inc.
 *
 * All information contained herein is the property of Nametag Inc.. The
 * intellectual and technical concepts contained herein are proprietary, trade
 * secrets, and/or confidential to Nametag, Inc. and may be covered by U.S.
 * and Foreign Patents, patents in process, and are protected by trade secret or
 * copyright law. Reproduction or distribution, in whole or in part, is
 * forbidden except by express written permission of Nametag, Inc.
 */

import { useState, useLayoutEffect, useCallback } from "react";

export type UseDimensionsHook<T> = [(node: T) => void, DOMRect | null];

export function useDimensions<T>(): UseDimensionsHook<T> {
  const [dimensions, setDimensions] = useState<DOMRect | null>(null);
  const [node, setNode] = useState<T | null>(null);

  /* eslint-disable @typescript-eslint/no-explicit-any */
  const ref = useCallback((node: any) => {
    setNode(node);
  }, []);

  useLayoutEffect(() => {
    if (node) {
      const measure = () =>
        window.requestAnimationFrame(() =>
          setDimensions(
            (node as unknown as HTMLElement).getBoundingClientRect(),
          ),
        );
      measure();

      //if (liveMeasure) {
      //    window.addEventListener("resize", measure);
      //    window.addEventListener("scroll", measure);
      //
      //    return () => {
      //        window.removeEventListener("resize", measure);
      //        window.removeEventListener("scroll", measure);
      //    };
      //}
    }
  }, [node]);

  return [ref, dimensions];
}
