/**
 * Copyright 2022 Nametag Inc.
 *
 * All information contained herein is the property of Nametag Inc.. The
 * intellectual and technical concepts contained herein are proprietary, trade
 * secrets, and/or confidential to Nametag, Inc. and may be covered by U.S.
 * and Foreign Patents, patents in process, and are protected by trade secret or
 * copyright law. Reproduction or distribution, in whole or in part, is
 * forbidden except by express written permission of Nametag, Inc.
 */

import Polyglot, { InterpolationOptions } from "node-polyglot";
import { GetTag } from "../lib/tags";

import phrases from "./en.json";
import phrases_es from "./es.json";

export type Phrases = typeof phrases;
export type TranslationKey = keyof Phrases;

const lang = (() => {
  for (const l of (GetTag("lang") ?? "en").split(",")) {
    switch (l) {
      case "es":
        return "es";
      case "en":
        return "en";
    }
  }
  return "en";
})();

const polyglot = new Polyglot({
  locale: lang,
  phrases: lang == "es" ? phrases_es : phrases,
});

export function t(key: TranslationKey, opts?: InterpolationOptions): string {
  return polyglot.t(key, opts);
}
