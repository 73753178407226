/**
 * Copyright 2022 Nametag Inc.
 *
 * All information contained herein is the property of Nametag Inc.. The
 * intellectual and technical concepts contained herein are proprietary, trade
 * secrets, and/or confidential to Nametag, Inc. and may be covered by U.S.
 * and Foreign Patents, patents in process, and are protected by trade secret or
 * copyright law. Reproduction or distribution, in whole or in part, is
 * forbidden except by express written permission of Nametag, Inc.
 */

import { ReactElement } from "react";

export const OrganizationIcon = (props: {
  className?: string;
}): ReactElement => {
  return (
    <svg
      className={props.className}
      width="14"
      height="15"
      viewBox="0 0 14 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.6667 13.5V2.83333C11.6667 2.47971 11.5262 2.14057 11.2761 1.89052C11.0261 1.64048 10.687 1.5 10.3333 1.5H3.66667C3.31304 1.5 2.97391 1.64048 2.72386 1.89052C2.47381 2.14057 2.33333 2.47971 2.33333 2.83333V13.5M11.6667 13.5H2.33333M11.6667 13.5H13M11.6667 13.5H8.33333M2.33333 13.5H1M2.33333 13.5H5.66667M8.33333 13.5V10.1667C8.33333 9.98986 8.2631 9.82029 8.13807 9.69526C8.01305 9.57024 7.84348 9.5 7.66667 9.5H6.33333C6.15652 9.5 5.98695 9.57024 5.86193 9.69526C5.7369 9.82029 5.66667 9.98986 5.66667 10.1667V13.5M8.33333 13.5H5.66667M5 4.16667H5.66667M5 6.83333H5.66667M8.33333 4.16667H9M8.33333 6.83333H9"
        stroke="currentColor"
        strokeWidth="1.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
