/**
 * Copyright 2022 Nametag Inc.
 *
 * All information contained herein is the property of Nametag Inc.. The
 * intellectual and technical concepts contained herein are proprietary, trade
 * secrets, and/or confidential to Nametag, Inc. and may be covered by U.S.
 * and Foreign Patents, patents in process, and are protected by trade secret or
 * copyright law. Reproduction or distribution, in whole or in part, is
 * forbidden except by express written permission of Nametag, Inc.
 */

import React, { ComponentProps, ReactNode } from "react";
import { Link as ReactRouterLink } from "react-router-dom";

import { classes, style } from "typestyle";
import { COLORS } from "../../lib/colors";

type LinkVariant = "primaryButton";

export type LinkProps = {
  to: string;
  variant?: LinkVariant;
  children?: ReactNode;
  className?: string;
} & ComponentProps<typeof ReactRouterLink>;

export const Link: React.FC<LinkProps> = ({
  to,
  variant,
  children,
  className,
  ...rest
}) => {
  return (
    <ReactRouterLink
      to={to}
      className={classes(
        variant === "primaryButton" && css.primaryButton,
        className,
      )}
      {...rest}
    >
      {children}
    </ReactRouterLink>
  );
};

const css = {
  primaryButton: style({
    backgroundColor: COLORS.actionPrimary,
    color: `${COLORS.white} !important`,
    textDecoration: "none !important",
    borderRadius: 4,
    padding: "8px 12px",
    fontWeight: 500,
    $nest: {
      "&:hover": {
        backgroundColor: COLORS.actionPrimaryHovered,
      },
    },
  }),
};
