{
  "AcceptInvitation": "Accept invitation",
  "Access_control": "Access control",
  "Accounts": "Accounts",
  "Add a birthday": "Add a birthday",
  "AddALogo": "Add a logo",
  "AddAPaymentMethod": "Add a payment method to remove this warning",
  "Add_a_group": "Add a group",
  "All_accounts": "All accounts",
  "All_other_accounts": "All other accounts",
  "Allowed": "Allowed",
  "AlreadyInvited": "It looks like you’ve already been invited to join a Nametag organization. Check your inbox for the invitation.",
  "Anonymous": "Anonymous",
  "AppealRejectReason_Blurry": "Rejected because one or more images are too blurry to accept",
  "AppealRejectReason_Damaged": "Rejected because the document is damaged",
  "AppealRejectReason_Glare": "Rejected because there is too much glare to accept the document",
  "AppealRejectReason_InjectionFraud": "Rejected due to injection fraud",
  "AppealRejectReason_MiscFraud": "Rejected due to fraud",
  "AppealRejectReason_NonCompliant": "Rejected because person was non-compliant, i.e. they did not take a photo of an ID document or face",
  "AppealRejectReason_Occluded": "Rejected because the document or selfie is obscured",
  "AppealRejectReason_OutOfFrame": "Rejected because the document or selfie is not completely in the frame",
  "AppealRejectReason_Paper": "Rejected because document is a paper copy or printout",
  "AppealRejectReason_PresentationFraud": "Rejected due to presentation fraud",
  "AppealRejectReason_Redacted": "Rejected because person intentionally redacted parts of the document or their face",
  "AppealRejectReason_RepeatFraud": "Rejected due to repeat fraud",
  "AppealRejectReason_Screen": "Rejected because document or selfie is a screen capture",
  "AppealRejectReason_SelfieLiveness": "Rejected because selfie is not of a live person",
  "AppealRejectReason_SelfieMismatch": "Rejected because the selfie does not match",
  "AppealRejectReason_UnacceptableDocType": "Rejected because the document type is not acceptable",
  "Back": "Back",
  "BackTo": "Back to %{envName}",
  "Begin_typing": "Begin typing...",
  "Button": "Button",
  "CallbackURL": "Callback URL",
  "Cancel": "Cancel",
  "CannotEditInSamlModeToast_graf": "You can view the configuration on this page, but you cannot edit it because SAML single sign on is enabled.",
  "CannotEditInSamlModeToast_hed": "Read only when SAML SSO is enabled",
  "Catch-all rule for others": "Catch-all rule for others",
  "CautionBanner": "Caution Banner",
  "CloseModal": "Close modal",
  "Configure": "Configure",
  "Configure self-service recovery permissions": "Configure self-service recovery permissions",
  "CopiedToClipboard": "Copied to clipboard",
  "CopyHTML": "Copy HTML",
  "CopyToClipboard": "Copy to clipboard",
  "CreateEnvironmentSuccess": "Created new environment",
  "CreateOrganization": "Create organization",
  "Create_environment": "Create environment",
  "CustomizeBilling": "Remove this banner by **selecting a plan**",
  "CustomizeLogo": "Update your logo in **Customization**",
  "Days": "Days",
  "Delete": "Delete",
  "Delete_members": "Delete members",
  "Delete_members_graf": "Are you sure you’d like to delete %{n} members? This cannot be reverted, but you can always send a new invitation.",
  "Delete_n_members": "Delete %{n} members",
  "DiscardChanges": "Discard changes",
  "Dismiss": "Dismiss",
  "EmailAuthError_graf": "The link you clicked is invalid. Please try again.",
  "EmailAuthError_hed": "Invalid link",
  "EmailPending_graf": "We’ve sent you an email with a link to verify your email address. Click the link in the email to continue.",
  "EmailPending_hed": "Check your email",
  "Enabled": "Enabled",
  "EntityID": "Entity ID",
  "EntityIDGraf": "Copy this information from your identity provider.",
  "EnvironmentInTrialMode": "This environment is in trial mode. Anyone sent a request will see our",
  "FirstGradient": "First gradient",
  "GeneralPreviewHed": "Mobile request preview",
  "GeneralPreviewSignup": "Sign up to remove the caution banner",
  "GeneralPreviewSubhed": "This is the first thing your recipients will see when they begin your request",
  "GenericError": "Something went wrong. Please try again.",
  "Group_Name": "Group Name",
  "Hellothere": "Hello there!",
  "Hours": "Hours",
  "InstallLogoTrial": "You are currently in trial mode. This logo cannot be used until this environment is verified by Nametag.",
  "InviteCode": "Invite code",
  "InviteRequired": "Your organization is using Nametag, but you have not yet been invited. Ask your organization's Nametag administrator to send you an email invitation.",
  "Invite_another_member": "Invite another member",
  "Invite_members": "Invite new members",
  "Invite_members_graf_1": "Select the appropriate role for this member, as well as the environments you’d like them to be able to access.",
  "Invite_pending": "Invite pending",
  "InvitedBy": "Invited by: %{inviter}",
  "Loading_groups": "Loading groups...",
  "MFA": "MFA",
  "MFA_Resets": "MFA Resets",
  "Minutes": "Minutes",
  "MobileBanner": "This website is optimized for desktop use. Please visit this page on a desktop computer for the best experience.",
  "Move_down": "Move down",
  "Move_up": "Move up",
  "MustBeAdminToEditBanner_graf": "You can view the configuration on this page, but you cannot edit it because you are not an admin.",
  "MustBeAdminToEditBanner_hed": "Admin access required to edit",
  "MustBeOwnerToEditBanner_graf": "You can view the configuration on this page, but you cannot edit it because you are not an owner.",
  "MustBeOwnerToEditBanner_hed": "Owner access required to edit",
  "NametagEntityIDGraf": "Also sometimes called **Audience URI** or an **Service Provider Entity ID**, you may need to provide this value to your identity provider.",
  "NametagEntityIDHed": "Nametag's Entity ID",
  "NineDigitCode": "Please enter the 9-digit invite code to proceed.",
  "No": "No",
  "No_preference": "No preference",
  "None": "None",
  "Not_allowed": "Not allowed",
  "OrganizationDomain": "Organization domain",
  "OrganizationDomainGraf": "Specify your organization's email domain. Anyone who provides an email address ending with your domain will be sent to your identity provider for authentication.",
  "Password": "Password",
  "Password_Resets": "Password Resets",
  "PermissionsView_hed": "Self-service recovery policy",
  "PermissionsView_introGraf": "Use this access control list to disable resets or set minimum assurance levels for the different groups in your directory.",
  "PermissionsView_introGraf2": "Please note: we evaluate each individual user against the highest-assurance security factor available. If a user account has a higher-assurance factor available than the minimum set for the group they’re in, it’s automatically checked. [Learn more.](https://getnametag.com/docs/configuring-account-binding/)",
  "PermissionsView_mfa_hed": "MFA reset rules",
  "PermissionsView_password_hed": "Password reset rules",
  "PermissionsView_unlock_hed": "Locked account unlock rules",
  "Preview": "Preview",
  "PreviewTrialWarning": "Proceed with caution. This company has not been validated by Nametag yet.",
  "Priority": "Priority",
  "ProceedWithCaution": "Proceed with caution. This company has not been validated by Nametag yet.",
  "RecoveryEligibility_AZURE_OAUTH_PRINCIPAL_CANNOT_RESET_graf": "This user is not currently eligible for %{factor} because this account was used to authorize Nametag's access to Entra ID. This is an Entra ID limitation.",
  "RecoveryEligibility_COMMON_NAME_graf": "This user is not currently eligible for %{factor} because %{groupExpr} in **%{dir_name}** allows name matching but the name is very common so a birth date or matching photo is required.",
  "RecoveryEligibility_DIR_ERROR_graf": "We were unable to determine if this user is eligible for %{factor} because of an error with **%{dir_name}**.",
  "RecoveryEligibility_ELIGIBLE_graf": "This user is eligible for %{factor} because %{groupExpr} in **%{dir_name}** allows it%{policyExpr}",
  "RecoveryEligibility_ERROR_graf": "We were unable to determine if this user is eligible for %{factor} because of an internal error. Please try again later.",
  "RecoveryEligibility_GroupExpr": "the group ‘%{group}’",
  "RecoveryEligibility_GroupExpr_Default": "the default policy",
  "RecoveryEligibility_NAME_AND_BIRTH_DATE": " and the user has a birth date or photo on file.",
  "RecoveryEligibility_NAME_MATCH": ".",
  "RecoveryEligibility_NEED_BIRTH_DATE_OR_PHOTO_graf": "This user is not currently eligible for %{factor} because %{groupExpr} in **%{dir_name}** requires a birth date or matching photo.",
  "RecoveryEligibility_NEED_ENROLLED_MFA_graf": "This user is not currently eligible for %{factor} because the account is not enrolled in MFA.",
  "RecoveryEligibility_NEED_PHOTO_graf": "This user is not currently eligible for %{factor} because %{groupExpr} in **%{dir_name}** requires a matching photo.",
  "RecoveryEligibility_NON_PERSON_NAME_graf": "This user is not currently eligible for %{factor} because %{groupExpr} in **%{dir_name}** allows name matching but the name appears to be not a person so a birth date or matching photo is required.",
  "RecoveryEligibility_NOT_LOCKED_graf": "This user cannot unlock their account because the account is not locked.",
  "RecoveryEligibility_PHOTO": " and the user has a photo on file.",
  "RecoveryEligibility_PROHIBITED_graf": "This user is not currently eligible for %{factor} because %{groupExpr} in **%{dir_name}** prohibits it.",
  "RecoveryPolicy_BIRTH_DATE_MISMATCH_graf": "The user is not currently eligible for %{factor} because ID verification produced a verified birthdate that doesn't match the expected birthdate in **%{dir_name}**.",
  "RecoveryPolicy_DISABLED": "disabled",
  "RecoveryPolicy_NAME_AND_BIRTH_DATE": "a name & birthday",
  "RecoveryPolicy_NAME_AND_BIRTH_DATE_description": "Eligibility requires admin to import a birthday for that user – boosting security.",
  "RecoveryPolicy_NAME_MATCH": "a name",
  "RecoveryPolicy_NAME_MATCH_description": "name on the ID must match the account. Disabled automatically for highly-common names. May not work for users with different chosen and legal names.",
  "RecoveryPolicy_NAME_MISMATCH_graf": "The user is not currently eligible for %{factor} because ID verification produced a verified legal name that is not similar enough to the name in **%{dir_name}**.",
  "RecoveryPolicy_NEED_BIRTH_DATE_FOR_BIRTH_DATE_MATCH_graf": "The user is not currently eligible for %{factor} because their ID verification did not produce a verified birthdate.",
  "RecoveryPolicy_NEED_NAME_FOR_MATCH_graf": "The user is not currently eligible for %{factor} because their ID verification did not produce a verified legal name.",
  "RecoveryPolicy_NEED_SELFIE_FOR_PHOTO_MATCH_graf": "This user is not currently eligible for %{factor} because their ID verification did not produce a verified selfie.",
  "RecoveryPolicy_PHOTO": "a profile photo",
  "RecoveryPolicy_PHOTO_MISMATCH_graf": "The user is not currently eligible for %{factor} because ID verification produced a verified selfie that doesn't match the expected profile photo in **%{dir_name}**.",
  "RecoveryPolicy_PHOTO_description": "Eligibility requires admin to import a profile photo for that user – boosting security and enabling users with different chosen and legal names to be matched.",
  "RecoveryPolicy_SAML": "done preemptive SAML binding",
  "RecoveryPolicy_SAML_description": "Eligibility requires enrollment. User must visit a URL (see self-service tab) to sign into the directory and then verify their identity – binding the accounts together – before they get locked out.",
  "RemoveLogo": "Remove logo",
  "ReplaceLogo": "Replace logo",
  "Required": "Required",
  "Revert to current settings": "Revert to current settings",
  "SamlNametagACSURLGraf": "Also sometimes called the **ACS URL** or **Single sign-on URL**, this is where your users will be directed after they've completed the SAML process with your identity provider and ready to be sent back to Nametag. You may need to provide this URL to your identity provider.",
  "SamlNametagACSURLHed": "Nametag's sign in URL",
  "Sandbox": "Sandbox",
  "Save": "Save",
  "SaveError": "We were unable to save the callback URL",
  "SaveSignInMethod": "Save sign in method",
  "SecondGradient": "Second gradient",
  "Seconds": "Seconds",
  "Select_a_group": "Select a group...",
  "Send a request": "Send a request",
  "Send_n_invitations": "Send %{n} invitations",
  "Set permissions for a group": "Set permissions for a group",
  "Set recovery rules for specific people by creating a group rule and selecting one of your imported Entra groups from the dropdown menu.": "Set recovery rules for specific people by creating a group rule and selecting one of your imported Entra groups from the dropdown menu.",
  "SignIn": "Sign in",
  "SignInToNametag": "Sign in to Nametag",
  "SignInWithEmail": "Sign in with email",
  "SignOut": "Sign out",
  "SigninURL": "Sign in URL",
  "SigninURLGraf": "Where members will be directed when signing in. This URL is provided by your identity provider.",
  "SigninWithID": "Sign in",
  "SigninwithNametag": "Sign in with Nametag",
  "SigninwithSAMLSSO": "Sign in with SAML SSO",
  "TestConfiguration": "Test Configuration",
  "TrialDaysLeft": "%{n} days",
  "TrialExpired": "Your trial has expired; contact sales@nametag.co to extend it.",
  "TrialLeftInTrial": "left in trial",
  "TrialWarning1": "You are currently in trial mode, and all outgoing requests will have a caution banner.",
  "TrialWarning2": "Select a plan to remove this banner.",
  "TrialWarningEnv": "Your brand has not been verified in the **%{env}** environment, so users will see a warning. You won’t be charged for requests in this environment.",
  "Try again": "Try again",
  "Unknown": "Unknown",
  "Unlock": "Unlock",
  "Unnamed": "(Unnamed)",
  "User may only reset if account has...": "User may only reset if account has...",
  "UserData_ShowExpiredAndRevoked": "Show expired and revoked",
  "Verifications": "Verifications",
  "View rules": "View rules →",
  "WaitingForReview": "Your ID has been submitted for review. Once it’s been approved, you’ll need to return to %{envName} to sign in.",
  "WrongInviteCode": "The invite code you entered is not correct",
  "Yes": "Yes",
  "account unlock": "account unlock",
  "account_AccountBound": "**%{name}** has successfully verified their identity and been bound to their **%{directoryName}** %{directoryKind} directory account",
  "account_ConnectDirectory": "Connect a directory",
  "account_Created": "**%{name}** was created automatically from the **%{directoryName}** %{directoryKind} directory",
  "account_CreatedBy": "Verification created by %{creator}",
  "account_Header": "Account",
  "account_ResetMFA": "**%{name}** reset their %{directoryKind} MFA (%{directoryName})",
  "account_ResetPassword": "**%{name}** reset their %{directoryKind} password (%{directoryName})",
  "account_SelfServiceDisabled": "Not eligible for selfie service recovery",
  "account_SelfServiceEnabled": "self-service recovery enabled",
  "account_Unlock": "**%{name}** unlocked their %{directoryKind} account (%{directoryName})",
  "account_Verified": "Identity verified successfully",
  "accounts_AllDirectories": "All directories",
  "accounts_Directory": "Directory",
  "accounts_EligibleForReset": "Eligible for reset",
  "accounts_Email": "Email",
  "accounts_EmptyState": "We recommend connecting a directory to get started. Once you’re connected, you’ll see the accounts populate here and can review self-service resets by clicking on any row.",
  "accounts_EmptyStateTitle": "Connect a directory to get started",
  "accounts_Error": "Something went wrong loading your accounts. Please try again later.",
  "accounts_LastVerified": "Last verified",
  "accounts_Name": "Name",
  "accounts_Never": "Never",
  "accounts_NoAccountsFound": "No accounts found. If you have filters set, try removing them.",
  "accounts_Only": "Only",
  "accounts_PageCount": "%{page} of %{total}",
  "accounts_SearchForAnything": "Search for anything...",
  "accounts_SelectAllDirectories": "Select all directories",
  "accounts_UserCount": "%{count} members",
  "accounts_UserCountOne": "1 member",
  "accounts_VerifiedAll": "Verified or not",
  "accounts_VerifiedNo": "Only accounts without verified info",
  "accounts_VerifiedTooltip": "Name and photo verified via ID",
  "accounts_VerifiedYes": "Only accounts with verified info",
  "all_environments": "All environments",
  "all_envs": "All environments",
  "apikey_disable_key": "Disable API key",
  "apikey_disabled_success": "API key was disabled",
  "apikey_enable_key": "Enable API key",
  "apikey_enabled_success": "API key was enabled",
  "apikeys_all_envs": "All environments",
  "apikeys_new_key_graf_1": "Name this key so you can identify it later, and then copy the API key.",
  "apikeys_new_key_graf_2": "It will only be shown once.",
  "apikeys_new_key_hed": "New API Key",
  "apikeys_new_key_name_hint": "Add a unique name",
  "apikeys_new_key_no_copy_warning": "It looks like you haven’t copied this key yet",
  "apikeys_new_key_secret_hed": "API Key",
  "apikeys_select_env": "Select environment",
  "apikeys_th_Created": "Created date",
  "apikeys_th_Enabled": "Enabled?",
  "apikeys_th_Env": "Environment",
  "apikeys_th_Name": "Name",
  "apikeys_th_Prefix": "Prefix",
  "apikeys_th_Role": "Role",
  "apikeys_unnamed_key": "Unnamed key",
  "billing_Amount": "Amount",
  "billing_Cancel": "Cancel",
  "billing_ContactUs": "Contact our team for pricing",
  "billing_CopilotPlus": "Copilot plus",
  "billing_CurrentPlan": "Current plan",
  "billing_CurrentTotal": "Current total for billing cycle ending %{date}",
  "billing_CustomerSupport": "Dedicated customer support",
  "billing_Date": "Date",
  "billing_Description": "Description",
  "billing_DownloadPDF": "Download PDF",
  "billing_EnterPayment": "Enter a payment method to sign up",
  "billing_EnterPaymentSubhed": "This card will be charged **$100** today",
  "billing_Enterprise": "Enterprise",
  "billing_EnterpriseInfo": "Get access to Copilot’s premium features, including:",
  "billing_EnterpriseSaml": "SAML single sign-on",
  "billing_Expires": "Expires %{date}",
  "billing_Failed": "Failed",
  "billing_Header": "Billing",
  "billing_Labels": "Unique request labels",
  "billing_LoadPaymentMethodFailed": "Unable to load payment method",
  "billing_MultipleEnvs": "Multiple environments",
  "billing_MultipleTemplates": "Multiple request templates",
  "billing_Onboarding": "Guided onboarding & training",
  "billing_Overdue": "Overdue",
  "billing_Paid": "Paid",
  "billing_PayAsYouGo": "Pay as you go",
  "billing_PayAsYouGoInfo1": "The $100 startup fee includes brand validation and removal of the warning banner in your outgoing requests.",
  "billing_PayAsYouGoInfo2": "From then on, only pay for the verifications you send. Your account will be billed on a 30-day cycle, beginning as soon as you sign up.",
  "billing_PayAsYouGoUsage": "Pay As You Go Usage",
  "billing_PayNow": "Pay now",
  "billing_PaymentMethod": "Payment Method",
  "billing_Pending": "Processing",
  "billing_PendingBalance": "Pending balance",
  "billing_PerVerification": "per verification",
  "billing_ProofOfVerification": "Advanced proof of verification",
  "billing_SignUpForPayAsYouGo": "Sign up for Pay as you go",
  "billing_SignUpNow": "Sign up now",
  "billing_StartupFee": "startup fee",
  "billing_Status": "Status",
  "billing_Subheader": "Review the number of verifications you’ve sent, update your Copilot plan and billing information, and download receipts and invoices for all payments.",
  "billing_UnexpectedError": "An unexpected error occurred.",
  "billing_UpdatePaymentMethod": "Update payment method",
  "billing_Verifications": "Verifications",
  "billing_VerificationsBalanceError": "Error loading plan usage",
  "billing_VerificationsBalanceInfo": "Only pay for the verifications you’ve sent. Your account will be billed on a 30-day cycle beginning %{date}.",
  "billing_ViewReceipt": "View receipt",
  "billing_VolumeBasedPricing": "Volume-based pricing",
  "choose_allowed_envs": "Choose allowed environments",
  "choose_report_timeframe": "Choose report timeframe",
  "countDays": "%{smart_count} day |||| %{smart_count} days",
  "countHours": "%{smart_count} hour |||| %{smart_count} hours",
  "countMinutes": "%{smart_count} minute |||| %{smart_count} minutes",
  "countSeconds": "%{smart_count} second |||| %{smart_count} seconds",
  "createNewAccount": "We weren’t able to find an existing account with that email address. Would you like to create one now?",
  "csr_AllTeamMembers": "All team members",
  "csr_BornAt": "Born %{birthDate}",
  "csr_CancelRequest": "Cancel request",
  "csr_CancelledInfo": "This request has been cancelled and can no longer be accepted by the user.",
  "csr_CompletedAt": "Completed %{relativeTime}",
  "csr_CompletedRequestInfo": "This request has been successfully completed.",
  "csr_Configure": "Configure",
  "csr_ConfigureInsufficientPermissions": "You do not have permission to view this page.",
  "csr_ConfirmCancel": "Confirm request cancellation",
  "csr_ConfirmCancelMsg": " Are you sure you want to cancel this request? It will no longer be valid and the recipient will not be able to complete the verification process.",
  "csr_Copied": "copied",
  "csr_Copy": "Copy one-time link",
  "csr_CopyTrackingLink": "Copy tracking link",
  "csr_CreateNewLink": "Create new link",
  "csr_DeleteRequest": "Cancel request",
  "csr_Description": "This person will be prompted to launch our App Clip (or to download the Nametag app) and quickly go through the steps to verify their identity",
  "csr_EmailProvidedUnverified": "Email address provided (unverified)",
  "csr_EmailVerified": "Email address verified",
  "csr_EmptyState": "TIP: Try sending one to your own phone number to preview the recipient’s experience.",
  "csr_EmptyStateTitle": "You haven’t sent any verification requests yet.",
  "csr_EnsureMatch": "Make sure the information they’ve provided matches who they claim to be. ",
  "csr_EnterLabel": "Enter a short label to help you find this request later (optional)",
  "csr_EnterLabelPlaceholder": "Enter a short label",
  "csr_ErrorState": "Something went wrong loading your requests. Please try again later.",
  "csr_ExpiredAt": "Expired %{relativeTime}",
  "csr_ExpiredFilterHide": "Hide expired requests",
  "csr_ExpiredFilterInclude": "Include expired requests",
  "csr_ExpiredFilterOnly": "Only show expired requests",
  "csr_ExpiredRequest": "Expired",
  "csr_ExpiredRequestInfo": "This request has expired and can no longer be accepted.",
  "csr_ExpiredScopes": "Expired",
  "csr_ExpiredScopesInfo": "This request has expired and the recipient’s data is no longer accessible.",
  "csr_FilterByAllActivity": "All activity",
  "csr_FilterByMyActivity": "My activity",
  "csr_FilterByRequestor": "Custom",
  "csr_FilterByRequestorAPI": "Self-service resets",
  "csr_FilterByRequestorAPIShort": "Self-service",
  "csr_FilterByRequestorSelectAll": "Select all users",
  "csr_GenericError": "Something went wrong. Please try again.",
  "csr_Header": "Status of Requests",
  "csr_Hello": "Hi!",
  "csr_HelloName": "Hi, %{name}!",
  "csr_Hide": "Hide",
  "csr_IdDetails": "ID details",
  "csr_IdPending": "ID is pending",
  "csr_IdVerified": "ID verified, selfie matched to ID photo",
  "csr_InAppTitle": "In-app title",
  "csr_InAppTitleDisclaimer": "(shown above):",
  "csr_InProgressRequestInfo": "The user is currently in the process of completing this request.",
  "csr_InReviewInfo": "The user's ID scan has been rejected and is undergoing review.",
  "csr_InvalidPhoneNumber": "Please enter a valid phone number",
  "csr_ItemsRequested": "Items Requested:",
  "csr_LabelTooLong": "The label cannot be more than 64 characters",
  "csr_LastUpdated": "Last updated %{relativeTime}",
  "csr_LegalName": "Legal name",
  "csr_LinkButton": "Create one-time link",
  "csr_LinkCopied": "Link copied to clipboard",
  "csr_LinkCreated": "A link has been successfully created and is ready to be sent.",
  "csr_LinkNote": "NOTE: This is a single-use link and should only be sent to one customer.",
  "csr_LoadMore": "Load more",
  "csr_NeverMind": "Never mind",
  "csr_NewVerification": "New verification",
  "csr_NewVerificationRequest": "New verification request",
  "csr_No": "No",
  "csr_NoResultsFound": "No content matches these filters.",
  "csr_NoResultsFoundClear": "Clear filters",
  "csr_NoSMSToCountry1": "SMS to %{country} is not supported.",
  "csr_NoSMSToCountry2": "Please use a one-time link or WhatsApp.",
  "csr_NotOver18": "Not over 18 years old",
  "csr_NotOver21": "Not over 21 years old",
  "csr_OneTimeLinkButton": "Copy one-time link",
  "csr_Or": "or",
  "csr_Over18": "Over 18 years old",
  "csr_Over21": "Over 21 years old",
  "csr_PendingRequestInfo": "This request has been created and is ready for the user to accept.",
  "csr_PersonDeletedInfo": "The person associated with this request has been deleted",
  "csr_PhoneButton": "Text a link",
  "csr_PhoneButtonSending": "Sending",
  "csr_PhoneEntered": "Phone number entered:",
  "csr_PhonePending": "Phone number is pending",
  "csr_PhoneVerified": "Phone number verified",
  "csr_PreferredName": "Preferred name",
  "csr_ProfilePicVerified": "Profile photo matches ID photo",
  "csr_Progress": "Progress",
  "csr_ProgressMessage": "You'll see their progress below",
  "csr_ProgressStatusAndroidApp": "Android app opened",
  "csr_ProgressStatusAndroidInstantApp": "Android instant app opened",
  "csr_ProgressStatusApp": "App opened",
  "csr_ProgressStatusDataShared": "Data shared",
  "csr_ProgressStatusIDScanned": "ID scanned",
  "csr_ProgressStatusIOSApp": "iOS app opened",
  "csr_ProgressStatusIOSAppClip": "iOS app clip opened",
  "csr_ProgressStatusLinkClicked": "Link clicked",
  "csr_ProgressStatusRequestSent": "Request sent",
  "csr_ProgressStatusSelfieScanned": "Selfie scanned",
  "csr_Recipient": "Recipient",
  "csr_RejectedAt": "Rejected %{relativeTime}",
  "csr_RejectedFraudInfo": "The user's ID scan has been rejected and identified as a fraud attempt.",
  "csr_RejectedInfo": "This user has failed to verify themself",
  "csr_RequestActionsMenu": "Request actions menu",
  "csr_RequestIssuerExplanation": "Use this screen to see recent verification requests sent by you and your teammates, view results, and send new verification requests to verify users.",
  "csr_RequestLabel": "Request label",
  "csr_RequestLabelDisclaimer": "(included if sent via text or Whatsapp):",
  "csr_RequestProof": "Request Proof of Identity",
  "csr_RequestStatusCancelled": "Cancelled",
  "csr_RequestStatusCompleted": "Completed",
  "csr_RequestStatusExpired": "Expired",
  "csr_RequestStatusInProgress": "In progress",
  "csr_RequestStatusPending": "Pending",
  "csr_RequestStatusPersonDeleted": "Person deleted",
  "csr_RequestStatusRejectedFraud": "Rejected - fraud",
  "csr_RequestStatusRejectedInReview": "In review",
  "csr_RequestStatusRejectedUnusable": "Rejected - bad image",
  "csr_RequestStatusRevoked": "Revoked",
  "csr_RequestTemplate": "Request template",
  "csr_RequestedAt": "Requested %{relativeTime}",
  "csr_RequestedBy": "Requested by",
  "csr_Requests": "Requests",
  "csr_RequestsInfo": "Review all of the requests your organization has sent in the table below, and click any row to see the identifying data for the person who completed the request.",
  "csr_RevokedAt": "Revoked %{relativeTime}",
  "csr_RevokedInfo": "This request has been revoked by the recipient and their data is no longer accessible.",
  "csr_Search": "Search for anything...",
  "csr_SelectTemplate": "Select request template",
  "csr_Send": "Send",
  "csr_SignInTo": "Sign in to %{name}",
  "csr_SmsSent": "An SMS request has been successfully sent.",
  "csr_SortUpdated": "Recently updated",
  "csr_SortCreated": "Recently created",
  "csr_StatusInfo": "You’ll see a request appear below once you’ve created a one-time link or sent a text message. The request status will update once your recipient has tried to verify their identity. ",
  "csr_TextButton": "Send via text",
  "csr_TextButtonTooltip": "This feature is not enabled for your organization.",
  "csr_TimeFrameFilterAll": "All time frames",
  "csr_TimeFrameFilterToday": "Updated today",
  "csr_TimeFrameFilterWeek": "Updated this week",
  "csr_TimeFrameFilterMonth": "Updated this month",
  "csr_TimeFrameFilterLastMonth": "Updated last month",
  "csr_TimeFrameFilterYear": "Updated year-to-date",
  "csr_TrackingLinkCopied": "Tracking link copied",
  "csr_TrySending": "Try sending one now",
  "csr_UnusableInfo": "The user's ID scan has been rejected.",
  "csr_UserDataLink": "Click here to view all requests",
  "csr_ValidFor": "Valid for %{duration}",
  "csr_VarificationCountOne": "1 verification",
  "csr_VarificationCount": "%{count} verifications",
  "csr_ViewStatus": "View the status of this request below",
  "csr_WelcomeBack": "Welcome back!",
  "csr_WelcomeBackName": "Welcome back, %{name}!",
  "csr_WhatsappButton": "Send via WhatsApp",
  "csr_WhatsappSent": "A WhatsApp message has been successfully sent.",
  "csr_Yes": "Yes",
  "csr_requestIssuerTitle": "You're viewing **Verifications** in **%{envName}**",
  "delete_members": "Delete members",
  "delete_n_members": "Delete %{n} members",
  "delete_one_member": "Delete member",
  "devNotes": "Dev notes",
  "developerOAuthConfigurationError": "It appears that the developer in charge of your organization’s OAuth configuration has made an error.",
  "dir_AddFirstIntegration": "Add your first directory",
  "dir_AddIntegration": "Add a directory",
  "dir_AddIntegrationSubhed": "Choose which type of directory you’d like to connect. Integrating a directory will create its members as people in Nametag and allow them to conduct their own account recoveries.",
  "dir_AllowMfaResets": "Allow multi-factor authentication resets",
  "dir_AllowPasswordResets": "Allow password resets",
  "dir_Authorize": "Authorize",
  "dir_AzureAD": "Entra ID",
  "dir_ConnectAzureBtn": "Connect to Microsoft Entra ID",
  "dir_ConnectAzureHed": "Connect to Microsoft Entra ID",
  "dir_ConnectAzureSubhed": "Set permissions and click **Connect to Entra ID** to be connected to Microsoft, where you’ll enable self-service account recovery for all directory members",
  "dir_ConnectOktaSubhed": "Set permissions and collect two things from Okta to enable self-service account recovery for all directory members",
  "dir_ConnectToOkta": "Connect to Okta",
  "dir_Connect_Duo": "Connect Duo",
  "dir_Connect_Okta": "Connect Okta",
  "dir_Connected": "Connected",
  "dir_Connecting": "Connecting...",
  "dir_DeleteDirectory": "Delete directory",
  "dir_DeleteDirectoryConfirmGraf": "Are you sure you want to delete this directory?",
  "dir_DeleteDirectoryConfirmHed": "Confirm delete directory",
  "dir_Disconnected": "Disconnected",
  "dir_DuoHostInvalid": "Make sure you've entered a valid API hostname (e.g. api-33091yc9.duosecurity.com)",
  "dir_EmptyStateHed": "You haven’t set up any directories yet",
  "dir_EmptyStateSubhed": "Connect Nametag to your company directories so that your team members can perform their own account recoveries after lockouts — in less than 30 seconds",
  "dir_Error": "Oops! Something went wrong while loading your integrations. Please try again later.",
  "dir_ExampleReset": "Alice Smith reset their Okta password",
  "dir_ExampleResetTime": "Completed: Yesterday, 5:01am",
  "dir_FailedToRetrieveRedirectUrl": "Failed to retrieve redirect url",
  "dir_Integrations": "Directories",
  "dir_IntegrationsSubhed": "Connect Nametag with your directories to provide your employees with smooth, self-service account recovery. Directory members will be able to reset their own passwords and multi-factor authentication.",
  "dir_LastSyncAt": "Last synced %{time}",
  "dir_NotSyncedYet": "Not synced yet",
  "dir_OktaURL": "Okta URL",
  "dir_OktaURLInvalid": "Make sure you've entered a valid Okta URL (e.g. https://example.okta.com)",
  "dir_OneLoginHostInvalid": "Make sure you've entered a valid hostname (e.g. example.onelogin.com)",
  "dir_Reconnect": "Reconnect",
  "dir_RecoveryEnabled": "self-service recovery enabled",
  "dir_Remove": "Remove directory",
  "dir_RemoveConfirmHed": "Are you sure you’d like to remove this directory?",
  "dir_RemoveConfirmSubhed": "self-service account recovery will no longer be possible. This will also delete all the accounts, directory members, and self-service recovery records from Nametag. ",
  "dir_RemoveConfirmSubhed2": "**This cannot be reversed.** Are you sure you’d like to proceed?",
  "dir_RemoveSubhed": "Removing this will delete all directory members and their data from Nametag. self-service account recovery will no longer be possible.",
  "dir_RemoveThisIntegration": "Remove this directory",
  "dir_SelfService": "Self-service",
  "dir_SelfServicePermissions": "Self-service permissions",
  "dir_SelfServicePermissionsSubhed": "Choose the self-service resets that can be performed for accounts in this directory",
  "dir_Settings": "Settings",
  "dir_SyncFailedAt": "Sync failed %{relTime}",
  "dir_SyncFailedExplanation": "Reconfiguring authentication for this directory may resolve this issue.",
  "dir_SyncNeedsReconnectInfo1": "New:",
  "dir_SyncNeedsReconnectInfo2": "We’ve made directory sync even better with smarter photo matching. To enable, reconfigure in a few clicks.",
  "dir_SyncNow": "Sync now",
  "dir_SyncReconfigure": "Reconfigure directory",
  "dir_SyncStatus": "Sync status",
  "dir_UnnamedDirectory": "Unnamed directory",
  "dir_UpdateFailed": "Something went wrong while updating your directory. Please try again later.",
  "dir_UsersCount": "%{count} members",
  "dir_UsersCountOne": "1 member",
  "dir_duo_authorize_graf": "Set permissions and collect three things from Duo to enable self-service account recovery for all directory members",
  "dir_duo_authorize_title": "Connect to Duo",
  "dir_invalid_credentials": "Your configuration is not valid",
  "dir_okta_authorize_graf": "To connect to Okta, you'll need to know your Okta URL and an API token.",
  "dir_okta_authorize_title": "Connect to Okta",
  "dir_onelogin_authorize_graf": "Set permissions and collect three things from OneLogin to enable self-service account recovery for all directory members",
  "dir_onelogin_authorize_title": "Connect to OneLogin",
  "directory_MicrositeConfigure_certificate": "Certificate is configured correctly",
  "directory_MicrositeConfigure_certificate_error": "Certificate is not configured correctly",
  "directory_MicrositeConfigure_check_again": "Check again",
  "directory_MicrositeConfigure_checking": "Checking your configuration",
  "directory_MicrositeConfigure_dns": "DNS is configured correctly",
  "directory_MicrositeConfigure_dns_error": "DNS is not configured correctly",
  "directory_MicrositeConfigure_dns_graf": "To enable self-service point the DNS record for %{domain} to *nametaghosted.com*. For example:",
  "directory_MicrositeConfigure_dns_theSite": "the site",
  "directory_MicrositeConfigure_graf": "Enable your users to reset their passwords and multi-factor authentication after validating their identity with Nametag. The recovery microsite is hosted by Nametag under a domain you control.",
  "directory_MicrositeConfigure_hed": "Configure self-service recovery",
  "dismissAlert": "Dismiss alert",
  "duo_api_instructions_1": "Log in to the Duo admin console at https://admin.duosecurity.com/",
  "duo_api_instructions_2": "Navigate to Applications > **Protect an Application**",
  "duo_api_instructions_3": "Click **Protect an Application** and locate the **Duo Admin API**",
  "duo_api_instructions_4": "Enable all of the permissions to allow Nametag access",
  "duo_api_instructions_5": "Copy **Integration key**, **Secret key**, and **API hostname** from Duo and paste below",
  "duo_api_instructions_hed": "Instructions for connecting to Duo",
  "duo_api_instructions_intro": "To connect to Duo, provide the following information from the Duo Admin Panel",
  "duo_host": "API Hostname",
  "duo_host_placeholder": "Paste the API hostname from the Duo Admin Panel",
  "duo_ikey": "Integration key",
  "duo_ikey_placeholder": "Paste the Integration key from the Duo Admin Panel",
  "duo_skey": "Secret key",
  "duo_skey_placeholder": "Paste the Secret key from the Duo admin console",
  "edit_members": "Edit members",
  "edit_n_members": "Edit %{n} members",
  "edit_one_member": "Edit member",
  "enterYourEmailAddress": "Enter your email address",
  "env_TimeBetween": "Enter a time between 1 and %{max}",
  "envs_3DLiveness": "3D liveness detection",
  "envs_API": "API Keys",
  "envs_Acceptverifiedemailsonlyfrom": "Accept verified emails only from:",
  "envs_Activeusers": "Active users",
  "envs_Activeusersgraph": "Active users graph",
  "envs_AddAScope": "Add a scope",
  "envs_AddDomain": "Add domain",
  "envs_AddNew": "Add new",
  "envs_AddScope": "Add scope",
  "envs_AddScopeError": "Sorry, we were unable to create a property",
  "envs_Address": "Address",
  "envs_AddressDetails": "The person's address, as printed on their ID (not all IDs contain this information)",
  "envs_AgeOver18": "Age over 18",
  "envs_AgeOver18Details": "Confirm that a person is over 18 years of age",
  "envs_AgeOver21": "Age over 21",
  "envs_AgeOver21Details": "Confirm that a person is over 21 years of age",
  "envs_AnyEmailAddress": "Any email address",
  "envs_Billing": "Billing",
  "envs_BiometricConsent": "Person consented to biometrics data",
  "envs_BirthDate": "Date of birth",
  "envs_BirthDateDetails": "The person's date of birth",
  "envs_BrandColors": "Brand colors",
  "envs_BrandColorsGuidance": "Customize the look of your requests by including your brand colors. These will appear on the initial mobile screen when people begin completing a request.",
  "envs_Branding": "Branding",
  "envs_CallbackURL": "Callback URL",
  "envs_CallbackURLHelp": "This is the URL that a user is redirected to after authentication",
  "envs_Cancel": "Cancel",
  "envs_ClientID": "Client ID",
  "envs_ClientIDHelp": "The client ID is the public part of the API credentials that authenticate your client to the API and authorize uses of the API by your client",
  "envs_ClientID_graf": "This is the public part of the API credentials that authenticate your client to the API and authorize that client’s uses of the API",
  "envs_ClientSecret": "Client Secret",
  "envs_ClientSecret_graf": "This is the private part of the API credentials. Create a new API key or use an existing one as the client secret.",
  "envs_ClientSecret_view_keys": "View existing API keys",
  "envs_Confidence": "Confidence %{confidence}",
  "envs_Configure": "Customization",
  "envs_Configure_Subhed": "Create a unique look for your environment and include your brand logo so that your recipients know exactly where your requests are coming from.",
  "envs_ConfirmDeleteAccept": "Delete scope",
  "envs_ConfirmDeleteCancel": "Keep scope",
  "envs_ConfirmDeleteGraf": "Are you sure you want to remove the scope %{scope}? You may lose access to data.",
  "envs_ConfirmDeleteHed": "Confirm delete",
  "envs_ConfirmDeleteWebhookGraf": "Are you sure you want to remove the webhook?",
  "envs_ConfirmUpdate": "Confirm update",
  "envs_Copied": "Copied",
  "envs_Copy": "Copy",
  "envs_CreateAPIKey": "Create an API key",
  "envs_CsrLink": "Click here to create a new request",
  "envs_DangerZone": "Danger zone",
  "envs_Dangerzone": "Danger zone",
  "envs_Datarequests": "Data requests",
  "envs_Delete": "Delete",
  "envs_DeleteConfirmGraph": "Are you sure you want to remove this environment? You may lose access to data.",
  "envs_DeleteDescription1": "This will delete the entire environment and all of its activities, history, and configurations. ",
  "envs_DeleteDescription2": "This action cannot be undone. ",
  "envs_DeleteDescription3": "If you only have one environment, it cannot be deleted.",
  "envs_DeleteEnv": "Delete environment",
  "envs_DeleteError": "Sorry, we were unable to delete this environment",
  "envs_DeleteNamedEnv": "Delete '%{envName}' Environment",
  "envs_DeliveryURL": "Delivery URL",
  "envs_Description": "Description",
  "envs_DescriptionHelp": "This is the caption text that appears below the app.",
  "envs_DeviceCryptographicCoprocessor": "Cryptographic co-processor",
  "envs_DeviceIntegrity": "Device integrity",
  "envs_DeviceNotRooted": "Not rooted",
  "envs_DevicePublicKey": "Public key",
  "envs_DeviceRooted": "Rooted",
  "envs_DeviceUserAgent": "User agent",
  "envs_DeviceVerificationFailed": "Device verification failed",
  "envs_DeviceVerified": "Trusted device verified",
  "envs_DigitalManipulationCheck": "Digital manipulation check",
  "envs_DocumentNumber": "Document number",
  "envs_DocumentVerificationFailed": "Could not verify authentic government-issued ID",
  "envs_DocumentVerified": "Authentic government-issued ID verified",
  "envs_Done": "Done",
  "envs_DriverLicense": "Driver's license",
  "envs_Edit": "Edit",
  "envs_EditBranding": "Edit branding",
  "envs_Email": "Email",
  "envs_EmailDetails": "The email address of the person. For this value to be present the person will have validated the address by clicking on a link we sent them, or by proving they can sign in to the account.",
  "envs_EmailDomains": "Restrict Email Domains",
  "envs_EmailUnverified": "Email (Unverified)",
  "envs_EmailUnverifiedDetails": "The email address that the person claims to have. They may or may not actually have proven that they own the email address. You should not rely on this value, and certainly not send email using this address, unless and until you have validated it yourself.",
  "envs_Empty": "(empty body)",
  "envs_Enabled": "enabled",
  "envs_Env": "Environment",
  "envs_EnvID": "Environment ID",
  "envs_EnvIDHelp": "The environment ID is used in some API requests to indicate which environment in the organization the request applies to. It is not part of authentication or authorization.",
  "envs_EnvLogo": "Environment logo",
  "envs_EnvName": "Environment name",
  "envs_Errors": "Errors",
  "envs_Errorsgraph": "Errors graph",
  "envs_ExpirationDate": "Expiration date",
  "envs_Expires": "Expires",
  "envs_Expiresin": "Expires in",
  "envs_FaceMatched": "Face matched to ID",
  "envs_FirstName": "First Name",
  "envs_FirstNameDetails": "The first (given) name of the person. This name may be initially derived from an identity document, but each person has the opportunity to customize this value. If possible, you should prefer using the \"Name\" scope over this one because not all cultures compose names of a first name followed by a last name. This value may be present even when the identity document from which it is derived has not yet been authenticated. To validate that the person has presented a valid identity document, use \"Legal Name\".",
  "envs_GenericError": "Failed to save changes",
  "envs_Genuine": "Genuine",
  "envs_GenuineApp": "Genuine app",
  "envs_GovtidData": "Government ID Data",
  "envs_GovtidDetails": "Government ID Document Details",
  "envs_GovtidDetailsDetails": "Details about the person's government ID document. The value is a JSON document containing the document type, issuing authority and document number.",
  "envs_GovtidDetailsShort": "Govt ID Details",
  "envs_Graf1": "To request data from a user, you'll need to define a set of *scopes* which define the particular data you need and how long you need it. The user consents to each requested scope.",
  "envs_Graf2": "Here you are defining the **maximum** set of scopes you can request. When you make an authorization request, you'll need to specify the subset of scopes that you need for a particular person.",
  "envs_Header": "Header",
  "envs_Home": "Home",
  "envs_IPLocation": "IP location",
  "envs_IdType": "ID Type",
  "envs_IdVerifiedAt": "Government-issued ID verified at %{time}",
  "envs_IdentityValidated": "Identity validated successfully",
  "envs_ImpersonationProtection": "Impersonation protection",
  "envs_InternalName": "Environment name",
  "envs_InternalNameHelp": "The internal name of this environment. (Not shown to users)",
  "envs_IntroGraf": "Webhooks allow you to receive notifications of events related to your users in your application. When you register a URL, Nametag will make a `POST` request when certain events occur.",
  "envs_IntroGrafUserData": "Review all of the requests your organization has sent in the table below. You’ll be able to see their outcomes and all of the verified data shared by your users. Sort this table by a certain piece of data by clicking the column headers. Click and drag to resize any columns.",
  "envs_InvalidUrl": "Please enter a valid URL",
  "envs_InviteError": "Cannot invite a new member",
  "envs_Issuer": "Issuer",
  "envs_Items": "%{count} items",
  "envs_KeepEnv": "Keep environment",
  "envs_LastName": "Last Name",
  "envs_LastNameDetails": "The last name (surname) of the person. This name may be initially derived from an identity document, but each person has the opportunity to customize this value. If possible, you should prefer using the \"Name\" scope over this one because not all cultures compose names of a first name followed by a last name. This value may be present even when the identity document from which it is derived has not yet been authenticated. To validate that the person has presented a valid identity document, use \"Legal Name\".",
  "envs_LegalFirstName": "Legal First Name",
  "envs_LegalLastName": "Legal Last Name",
  "envs_LegalName": "Legal Name",
  "envs_LegalNameDetails": "The legal name of the person as derived from an identity document (driver license, passport, etc). This value will not be present unless and until we have validated the authenticity of the identity document. This value may differ from the person's preferred name. You should use this value when it is necessary for a transaction, but prefer \"Name\" for greeting a person or displaying their name in your app.",
  "envs_Log": "Logs",
  "envs_LoginDetails": "Allow a person to login without sharing any more information than a Nametag subject",
  "envs_Logo": "Logo",
  "envs_LogoGuidance": "We prefer a plain logo without the name of your company or app. The logo will be rendered with circular clipping as shown. The image must be either a PNG or JPEG file, and under 5MB.",
  "envs_LogoQRGuidance": "The logo will be shown inside the scan-to-sign-in QR code; the logo should have a transparent background.",
  "envs_LogoTooLarge": "Invalid logo: file must be under 5MB",
  "envs_Match": "Match %{confidence}",
  "envs_Matched": "Matched",
  "envs_Material": "Material",
  "envs_Members": "Members",
  "envs_Name": "Name",
  "envs_NameDetails": "The full name of the person. This name may be initially derived from an identity document, but each person has the opportunity to customize this value. If possible, you should prefer using this scope over \"First Name\" and \"Last name\" because not all cultures have names composed of a first name followed by a last name. This value may be present even when the identity document from which it is derived has not yet been authenticated. To validate that the person has presented a valid identity document, use \"Legal Name\".",
  "envs_Nametag": "Nametag",
  "envs_NotGenuine": "Not genuine",
  "envs_NotMatched": "Not matched",
  "envs_Notifications": "Notifications",
  "envs_Notifications_Subhed": "Select the email notifications that each team member will receive as their verification requests progress.",
  "envs_OAuth": "OAuth",
  "envs_OAuth_callback_delete_accept": "Delete callback URL",
  "envs_OAuth_callback_delete_graf": "Are you sure you want to delete this callback URL? Any services using this URL will stop working.",
  "envs_OAuth_callback_delete_hed": "Confirm callback URL deletion",
  "envs_OAuth_callback_invalid": "Invalid URL",
  "envs_OAuth_create_new_api_key": "Create new API key",
  "envs_OAuth_description": "Nametag can act as an OAuth 2.0-compliant identity provider. Use these settings to implement \"sign in with ID\" with an OAuth 2.0 compliant library or package.",
  "envs_OAuth_save": "Save changes",
  "envs_OIDC": "Required for configuring OpenID Connect (OIDC)",
  "envs_OIDCDetails": "You must enable this scope if you are configuring Nametag as an OpenID Connect Identity Provider (OIDC IdP); there is no other reason to enable this scope",
  "envs_OnlyTheSpecifiedDomains": "Only the specified domains",
  "envs_Org": "Organization",
  "envs_Other": "Other",
  "envs_Overview": "Overview",
  "envs_Passport": "Passport",
  "envs_Payload": "Payload",
  "envs_PersonConsented": "Person consented to share data",
  "envs_Phone": "Phone",
  "envs_PhoneDetails": "The person's phone number. For this value to be present the person will have proven that they can receive text messages at this number by typing a confirmation code we send. The value will not be present unless and until the person has entered a confirmation code.",
  "envs_ProfilePicture": "Profile picture",
  "envs_ProfilePictureDetails": "The person's verified profile picture. This is represented as a URL to an image of the person's choosing. The image must show their face, and their face must match their government-issued ID.",
  "envs_Provided": "Provided",
  "envs_PublicName": "Public name",
  "envs_PublicNameHelp": "This is the public name of your organization, as exposed to users. This should be the name of your company or organization.",
  "envs_QRLogo": "Scan to sign in logo",
  "envs_RecentDeliveries": "Recent deliveries",
  "envs_Region": "Region",
  "envs_RegionIreland": "Ireland",
  "envs_RegionProcessing": "Processing: %{region}",
  "envs_RegionStorage": "Storage: %{region}",
  "envs_RegionUS": "United States",
  "envs_Replace": "Replace",
  "envs_Request": "Request",
  "envs_RequestCreated": "Request created",
  "envs_RequestCreatedBy": "Request created by %{name}",
  "envs_RequestCreatedSelfService": "Request initiated from self-service site",
  "envs_RequestLog": "Request log",
  "envs_RequestMethod": "Method",
  "envs_RequestTemplates": "Request templates",
  "envs_RequestURL": "URL",
  "envs_Response": "Response",
  "envs_Save": "Save changes",
  "envs_SaveButton": "Save",
  "envs_SaveError": "We couldn't save your changes",
  "envs_SaveError2": "Cannot save changes",
  "envs_SaveSuccess": "Saved",
  "envs_Saving": "Saving...",
  "envs_Scope": "Scope",
  "envs_SeeScopeDetails": "See scope details",
  "envs_SelfieMatched": "Person’s selfie matched to ID photo",
  "envs_SelfieNotMatched": "Person’s selfie not matched to ID photo",
  "envs_SendCompletedEmailHelp": "When a verification request is completed by the end user, the team member who sent the request will receive an email with some relevant, but not personally identifiable, information.",
  "envs_SendCompletedEmailName": "When a verification request is completed",
  "envs_SendInProgressEmailHelp": "When the end user starts the verification process, the team member who sent the request will be notified via email.",
  "envs_SendInProgressEmailName": "When a verification request is in progress",
  "envs_SendLinkOpenedEmailHelp": "When a verification request link is opened by the end user, the team member who sent the request will be notified via email.",
  "envs_SendLinkOpenedEmailName": "When a verification request link is opened",
  "envs_SendRequestCancelledEmailHelp": "When a verification request is cancelled, the team member who sent the request will be notified via email.",
  "envs_SendRequestCancelledEmailName": "When a verification request is cancelled",
  "envs_SendRequestRejectedFraudEmailHelp": "When a request is rejected due to suspected fraud, the team member who sent the request will be notified via email. Admins and owners will also receive this notification.",
  "envs_SendRequestRejectedFraudEmailName": "When a verification request is rejected due to suspected fraud",
  "envs_SendRequestRejectedUnusableEmailHelp": "When a request is rejected due to poor image quality or unusable information, the team member who sent the request will be notified via email.",
  "envs_SendRequestRejectedUnusableEmailName": "When a verification request is rejected due to unusable information",
  "envs_SendRevokedEmailHelp": "When consent for a verification request is revoked by the end user, the team member who sent the request will be notified.",
  "envs_SendRevokedEmailName": "When verification request consent has been revoked",
  "envs_SharedSecret": "Shared Secret",
  "envs_SharedSecretGraf": "Each webhook request will contain an `X-Nametag-Signature` request header. This header is a hex-encoded HMAC generated using the SHA-256 hash function and the above secret. Your server should validate that the request body matches the provided secret to verify that the request actually came from Nametag.",
  "envs_Signins": "Signins",
  "envs_Signinsgraph": "Signins graph",
  "envs_Status": "Status",
  "envs_Structure": "Structure",
  "envs_TemplateConsistency": "Template consistency",
  "envs_TermsOfServiceURL": "Terms of service URL",
  "envs_TermsOfServiceURLGuidance": "Provide a link to your terms of service. This will appear to your users on the sign in screen.",
  "envs_UnnamedEnv": "(Unnamed environment)",
  "envs_UnnamedOrg": "Unnamed organization",
  "envs_UploadPlaceholder": "Choose a PNG or JPEG image",
  "envs_UserData": "User Data",
  "envs_Verified": "Verified",
  "envs_VerifiedAt": "Verified %{time}",
  "envs_VisitURL": "Visit URL",
  "envs_VisitURLGuidance": "A URL of an account page or similar, to show to users who have already shared information with your app.",
  "envs_Webhooks": "Webhooks",
  "envs_WebhooksHelp": "Webhooks allow you to receive notifications of events related to your users in your application.",
  "envs_acceptanyverifiedemailaddress": "Accept any verified email address",
  "envs_addCallbackButtonText": "Create a callback URL",
  "envs_addWebhookButtonText": "Add a webhook",
  "envs_configureInstallLogo_graf": "We recommend using a rectangular logo with a transparent background. This version of the logo appears at the top left of your install page. The image must be under 5 MB and either a PNG or JPEG.",
  "envs_configureInstallLogo_hed": "Sign-in page logo",
  "envs_debugWebhooks": "Debug webhooks",
  "envs_descriptionMissing": "(no description)",
  "envs_emailDomainPlaceholder": "example.com",
  "envs_noScope": "(choose a scope)",
  "envs_publicNameMissing": "(no public name)",
  "envs_recoverDescription": "A user completes self-service account recovery",
  "envs_rejectDescription": "Data shared with you is revoked",
  "envs_removeCallbackURL": "Remove callback URL",
  "envs_removeWebhook": "Remove webhook",
  "envs_requestDescription": "A request is created or updated",
  "envs_shareDescription": "Data is shared with you",
  "envs_termsOfServiceMissing": "(no terms of service)",
  "envs_viewDetails": "View details",
  "footer_Company": "Company",
  "footer_Copyright": "© 2024 Nametag All Rights Reserved",
  "footer_Privacy": "Privacy policy",
  "footer_TermsOfService": "Terms of service",
  "invite_member": "Invite member",
  "justAddYourOrganizationNameToProceed": "Just add your **Organization** name to proceed.",
  "lastAccessedRelTime": "Last accessed %{relTime}",
  "learn_more": "Learn more",
  "memberOfMultipleOrganizations": "It looks like you are a member of multiple organizations. Which one would you like to access?",
  "microsite_AreYouSure": "Are you sure you’d like to proceed?",
  "microsite_Details": "Details",
  "microsite_DoneForNow": "Done for now",
  "microsite_DuoMFAResetHed": "Your authentication credentials have been successfully reset. Use the code below to sign in to Duo and set up new MFA options.",
  "microsite_DuoResetCode": "Duo reset code",
  "microsite_DuoResetCodeTooltip": "Please copy the reset code to your clipboard to proceed",
  "microsite_EnrollInMFA": "Enroll in MFA at %{link}",
  "microsite_EnterEmail": "Enter your work email address",
  "microsite_GenericError": "Something went wrong. Please try again later.",
  "microsite_MFAResetHed": "This will remove all of your authenticator tokens (SMS messages, authenticator apps, security keys, etc.) so that you can access your account and create new ones.",
  "microsite_MFAResetSuccessful": "Your authentication credentials have been successfully reset. Next time you sign in to your %{directoryProvider} account, you’ll need to enroll in MFA again.",
  "microsite_NoDirectories": "It looks like you don’t have any directories connected right now. Please ask your admin for help.",
  "microsite_OktaPasswordResetHed": "Your password has been reset. Please proceed to Okta and create a new password for your account. This process won’t be finished until you set a new password.",
  "microsite_OneLoginPasswordResetHed": "Your password has been reset. Please proceed to OneLogin and create a new password for your account. This process won’t be finished until you set a new password.",
  "microsite_ProceedToOkta": "Proceed to Okta",
  "microsite_ProceedToOneLogin": "Proceed to OneLogin",
  "microsite_ResetCodeMissing": "Failed to retrieve reset code.",
  "microsite_ResetHed": "Reset passwords and multi-factor authentication (MFA) for your work accounts. If you have any trouble during this process, contact your team administrator.",
  "microsite_ResetMFA": "Reset multi-factor authentication",
  "microsite_ResetMFADuo": "Generate bypass code",
  "microsite_ResetMFANo": "No, don’t reset tokens",
  "microsite_ResetMFAYes": "Yes, reset tokens",
  "microsite_ResetPassword": "Reset your password",
  "microsite_ResetSuccess": "Success! You’ve reset your credentials and should be able to access your account now.",
  "microsite_ResettingMFA": "Resetting your MFA...",
  "microsite_ResettingPassword": "Resetting your password...",
  "microsite_ScanQR": "Scan this QR code",
  "microsite_SecuredByNametag": "Secured by Nametag",
  "microsite_SigninSubhed": "Please verify your identity with Nametag to proceed.",
  "microsite_TempPassword": "Temporary password",
  "microsite_TempPasswordHed": "Use this temporary password to log in to your account. Once you gain access, please create a secure password immediately.",
  "microsite_TempPasswordMissing": "Failed to retrieve new password.",
  "microsite_TempPasswordTooltip": "Please copy the temporary password to your clipboard to proceed",
  "microsite_Unlock": "Unlock your account",
  "microsite_UnlockSuccess": "Success! You’ve unlocked your account and should be able to access your account now.",
  "microsite_Unlocking": "Unlocking your account...",
  "microsite_UpdatePassword": "Update your password at %{link}",
  "microsite_Welcome": "Welcome, %{name}",
  "multi-factor authentication reset": "multi-factor authentication reset",
  "n_envs": "%{n} environments",
  "navbar_configure": "Configure",
  "navbar_request_proof": "Request Proof",
  "neverAccessed": "Never accessed",
  "next": "Next",
  "next_page": "Next page",
  "no_envs": "No environments",
  "not_found_Graph": "We are sorry but the page you are looking for was not found.",
  "not_found_Headline": "Not Found",
  "not_found_Title": "404",
  "oauth_AlreadyInstalled": "Already installed Nametag?",
  "oauth_BackHed": "Back to %{envName}",
  "oauth_BackSubhed": "Cancels request",
  "oauth_Cancel": "Cancel",
  "oauth_CancelRequestConfirm_Cancel": "Never mind",
  "oauth_CancelRequestConfirm_Confirm": "Cancel request",
  "oauth_CancelRequestConfirm_Graf": "Are you sure you want to cancel this request? The company that sent it will receive a message that you have declined to respond.",
  "oauth_CancelRequestConfirm_Hed": "Leaving this page will cancel this request",
  "oauth_DesktopHed": "Scan this QR code with your phone.",
  "oauth_DesktopHelpDialog_Android": "Android",
  "oauth_DesktopHelpDialog_Android_0": "Don’t want to install an app? Use the Nametag Instant App.",
  "oauth_DesktopHelpDialog_Android_1": "Make sure that your phone uses Android 8 or higher to use the Nametag Instant App.",
  "oauth_DesktopHelpDialog_Android_2": "(Note: Not all Android devices support InstantApps; if yours doesn't, please download the Nametag app from the Play Store)",
  "oauth_DesktopHelpDialog_Android_3": "You may also need to adjust some of your device settings:",
  "oauth_DesktopHelpDialog_Android_4": "Open the Google Play Store app",
  "oauth_DesktopHelpDialog_Android_5": "At the top right, tap the profile icon",
  "oauth_DesktopHelpDialog_Android_6": "Tap **Settings > General  > Google Play Instant**",
  "oauth_DesktopHelpDialog_Android_7": "Turn **Upgrade web links** or **Google Play Instant** on",
  "oauth_DesktopHelpDialog_Close": "I'm all set",
  "oauth_DesktopHelpDialog_Hed": "Need some help?",
  "oauth_DesktopHelpDialog_Subhed": "Different devices have slightly different sets of instructions",
  "oauth_DesktopHelpDialog_iPhone": "iPhone",
  "oauth_DesktopHelpDialog_iPhone_1": "Make sure that your phone uses iOS 14 or higher to use the Nametag App Clip.",
  "oauth_DesktopHelpDialog_iPhone_2": "If you are not using this version, access **Settings > General > Software Update** on your phone and download any available updates.",
  "oauth_DesktopSubhed2": "%{envName} would like to verify your identity.",
  "oauth_EnvLogo": "Environment logo",
  "oauth_Explanation": "%{envName} uses Nametag to request proof it’s really you. Nametag is a secure way to quickly prove your identity and protect your personal information.",
  "oauth_GetTheApp": "Get the Nametag app to verify your identity",
  "oauth_HelpPrompt": "Need some help?",
  "oauth_InfoText": "When you first open the app, you'll be launched directly into %{envName}'s request.",
  "oauth_LaunchingAppClip": "Launching app clip...",
  "oauth_LearnMore": "Learn more",
  "oauth_NametagDescription": "Nametag is an identity-based authenticator that secures your accounts and helps you protect your personal information online.",
  "oauth_OpenApp": "I already have the app",
  "oauth_ProductName": "Nametag",
  "oauth_QrAlt": "Scan this QR code to continue",
  "oauth_QrCaption": "Scan with your phone’s camera to continue",
  "oauth_SecuredBy": "Secured by ",
  "oauth_SigningIn": "Signing in...",
  "oauth_TapToSignIn": "Tap 'OPEN' to sign in",
  "oauth_genericErrorMessage": "Something went wrong",
  "oauth_oauthErrorDetail": "Reason: %{message}.",
  "oauth_oauthErrorHed": "Developer error",
  "oauth_oauthErrorSubhed": "Due to a misconfiguration by the developer, we cannot continue. Please notify the developer, or try again in a few minutes.",
  "oauth_rejectedHed": "Rejected",
  "oauth_rejectedSubhed": "It seems like you have rejected the request from %{envName}",
  "oauth_requestExpired": "The request has expired",
  "oauth_requestRejected": "The request was rejected",
  "oauth_theEnvironment": "the app",
  "okta_HowToConnect": "Instructions for connecting to Okta",
  "okta_api_instructions_1": "Sign in to the Okta admin portal",
  "okta_api_instructions_2": "Navigate to **Security** &gt; **API**",
  "okta_api_instructions_3": "Select the **Tokens** tab",
  "okta_api_instructions_4": "Click **Create token**",
  "okta_api_instructions_5": "Name the token **Nametag** and click **Create token**",
  "okta_api_instructions_6": "Copy the token and paste above",
  "okta_api_instructions_intro": "To create an API token:",
  "okta_api_token": "API Token",
  "okta_api_token_placeholder": "Paste your Okta API token",
  "okta_url": "Okta URL",
  "okta_url_placeholder": "https://your-company.okta.com",
  "one_env": "One environment",
  "onelogin_api_instructions_1": "Log in to the OneLogin admin console at https://onelogin.com/",
  "onelogin_api_instructions_2": "Navigate to Developers > **API Credentials**",
  "onelogin_api_instructions_3": "Click **New Credential**",
  "onelogin_api_instructions_4": "Enter **Nametag** for the name, select **Manage all**, and press **Save**",
  "onelogin_api_instructions_5": "Copy **Client ID**, **Client Secret** and paste below",
  "onelogin_api_instructions_hed": "Instructions for connecting to OneLogin",
  "onelogin_api_instructions_intro": "To connect to OneLogin, provide the following information from the OneLogin console",
  "onelogin_client_id": "Client ID",
  "onelogin_client_id_placeholder": "Paste the Client ID from the OneLogin admin console",
  "onelogin_client_secret": "Client Secret",
  "onelogin_client_secret_placeholder": "Paste the Client Secret from the OneLogin admin console",
  "onelogin_host": "OneLogin Hostname",
  "onelogin_host_placeholder": "Paste the hostname from the OneLogin admin console",
  "organizationNameExample": "e.g. ACME Corporation",
  "orgs_APIKeys": "API Keys",
  "orgs_APIKeys_description": "Use API keys for integrations with other services, OAuth 2.0, or your own code.",
  "orgs_APIKeys_link": "API Reference",
  "orgs_AcceptInvitation": "Accept Invitation",
  "orgs_Admin": "Admin",
  "orgs_AdminDescription": "Full access to their environments and their configuration",
  "orgs_AlreadyAMemberError": "You are already a member of this organization",
  "orgs_Cancel": "Cancel",
  "orgs_CancelInvitation": "Cancel invitation",
  "orgs_CloseConfirmRemoveMember": "Close remove member confirmation modal",
  "orgs_ConfirmCancelGraf": "Are you sure you want to cancel this person's invitation to the organization?",
  "orgs_ConfirmCancelInvitation": "Confirm cancel invitation",
  "orgs_ConfirmDeleteAbort": "Keep member",
  "orgs_ConfirmDeleteAccept": "Remove member",
  "orgs_ConfirmDeleteGraf": "Are you sure you want to remove this person from the organization?",
  "orgs_ConfirmDeleteHed": "Confirm delete",
  "orgs_ConfirmRemoveMember": "Confirm remove member",
  "orgs_DeleteConfirmGraph": "Are you sure you want to delete this organization and all of its environments? You will lose access to this data and this cannot be undone. ",
  "orgs_DeleteConfirmTitle": "Confirm delete",
  "orgs_DeleteDescription1": "This will delete the entire organization, all of its environments, and the API keys. Members will no longer be able to access this organization. ",
  "orgs_DeleteDescription2": "This action cannot be undone. ",
  "orgs_DeleteError": "Sorry, we were unable to delete this person",
  "orgs_DeleteOrg": "Delete organization",
  "orgs_DeleteThisOrg": "Delete this organization",
  "orgs_Emailaddress": "Enter email address",
  "orgs_Error": "Something went wrong while accepting the invitation. If you haven't already accepted it, please contact the person who sent you the invitation",
  "orgs_Invite": "Send invitation",
  "orgs_InviteAction": "Add new member",
  "orgs_InviteError": "Cannot invite a new member",
  "orgs_InviteGraf": "You've been invited to join a Nametag organization:",
  "orgs_InviteSuccess": "Invitation sent",
  "orgs_Limited": "Limited",
  "orgs_LimitedDescription": "Access to only their own requests and simplified results in their environments",
  "orgs_LimitedPlus": "Limited+",
  "orgs_LimitedPlusDescription": "Can issue requests see simplified results for all requests",
  "orgs_Manage": "Manage",
  "orgs_Members": "Members",
  "orgs_MembersSubtitle": "Invite members to your organization and edit the permissions for existing members. Owners have access to the organization and all environments. Admins have full access to their environments, users only see requests, and limited users see simplified results for only their own requests.",
  "orgs_MembersSubtitleSAML": "Members of your organization will be able to view and edit all release stages and activities. Because SAML single sign on is enabled, membership is controlled by your identity provider.",
  "orgs_Name": "Name",
  "orgs_NameHelp": "The name of your organization. Only visible to organization members",
  "orgs_Neworg": "New organization",
  "orgs_Optional": "optional",
  "orgs_Org": "Organization",
  "orgs_Orgs": "Organizations",
  "orgs_Owner": "Owner",
  "orgs_OwnerDescription": "Full access to the organization, all environments, and their configuration",
  "orgs_Remove": "Remove",
  "orgs_RemoveMember": "Remove member",
  "orgs_RestrictAdd": "Add IP address range",
  "orgs_RestrictAllow": "Allow Nametag access to the following IP address ranges...",
  "orgs_RestrictInfo": "Restrict console.nametag.co access to certain IP addresses. Your team members will only be able to sign into Nametag from IP addresses that you’ve entered below. Anyone that signs in from outside the specified IP ranges will receive a generic sign-in failure message.",
  "orgs_RestrictInstructions": "Instructions: Input either IPv4 or IPv6 addresses or CIDR blocks. If the list is empty, all IP addresses will be allowed.",
  "orgs_RestrictInvalidIp": "One or more IP address is invalid.",
  "orgs_RestrictTitle": "Restrict Nametag logins by IP address",
  "orgs_Save": "Save",
  "orgs_SaveError": "Failed to save organization",
  "orgs_SaveError2": "Cannot save changes",
  "orgs_SaveSuccess": "Saved",
  "orgs_SetRoleError": "Cannot set role",
  "orgs_SignInCTA": "Sign in with ID",
  "orgs_Success": "Invitation accepted",
  "orgs_User": "User",
  "orgs_UserDescription": "Access to all requests and detailed results in their environments",
  "orgs_confirmDeleteAbort": "Never mind",
  "orgs_confirmDeleteAccept": "Delete API key",
  "orgs_confirmDeleteGraf": "Are you sure you want to delete this API key? Any apps or integrations that are connected with this key will be disconnected.",
  "orgs_confirmDeleteHead": "Confirm API key deletion",
  "orgs_copied": "Copied",
  "orgs_copy": "Copy",
  "orgs_createAPIKey": "Create new API key",
  "orgs_createdTime": "Created At",
  "orgs_deleteError": "Sorry, we were unable to delete this API key",
  "orgs_onlyShownOnce": "This will only be shown once",
  "orgs_prefix": "Prefix",
  "orgs_remove": "Remove",
  "orgs_secretMessage": "Your new API Key is %{secret}",
  "orgs_unnamedorg": "(Unnamed Organization)",
  "orgs_you": "you",
  "orgs_youWillOnlySeeThisOnce": "You will only see this once",
  "page_n_of_c": "Page %{n} of %{c}",
  "parens_optional": "(optional)",
  "password reset": "password reset",
  "past_n_days": "Past %{n} days",
  "past_n_months": "Past %{n} months",
  "pasteYourX509CertificateHere": "Paste your x509 certificate here",
  "pending": "Pending",
  "pleaseContactYourDeveloperForHelp": "Please contact your developer for help.",
  "previous_page": "Previous page",
  "reports_Header": "Reporting",
  "reports_Subheader": "Review your team’s overall usage of Nametag and see the outcomes of the requests that have been sent over a given time. Customize the time frame and click into certain reports to see additional details.",
  "request_AcceptedManually": "Audit confirmed that identity was validated successfully",
  "request_AddressBusiness": "Business address",
  "request_AddressPoBox": "PO Box",
  "request_AddressResidential": "Residential address",
  "request_BarcodeChecks": "%{count} checks",
  "request_BarcodeDetails": "Barcode details",
  "request_BarcodeViewDetails": "View additional details",
  "request_Beta": "Beta",
  "request_Cancelled": "Request was cancelled",
  "request_Cancelled2": "request cancelled",
  "request_CancelledAt": "This request was cancelled at %{time}",
  "request_CreatedAt": "Request created at %{time}",
  "request_DataPoint": "Data point",
  "request_DeviceInfoUnavailable": "Additional information is not available for this device",
  "request_DeviceLocation": "Device location",
  "request_DeviceLocationInfo": "The location of the device used to scan the document, as determined by the device's IP address.",
  "request_Distance": "Distance",
  "request_DistanceInfo": "The distance between the device location and the ID document address.",
  "request_DistanceValue": "%{distance} miles",
  "request_DocumentAddress": "ID document address",
  "request_DocumentAddressInfo": "The validated address on the ID document, if present.",
  "request_ErrorCaption": "Still having trouble? Contact help@nametag.co",
  "request_ErrorHed": "Oops, something went wrong!",
  "request_ErrorSubhed": "Let’s try again.",
  "request_ErrorSubhedRetryBusy": "Trying again...",
  "request_ErrorSubhedRetryDelay": "Let’s try again. Refreshing in...",
  "request_Expired": "Request has expired",
  "request_Expired2": "Request expired",
  "request_ExpiredAt": "Request expired at %{time}",
  "request_ExpiredScopes": "Request expired and data was deleted",
  "request_ExpiredScopesAt": "Data was automatically deleted %{time}",
  "request_GeolocationHeader": "Location information calculated",
  "request_IdInfoUnavailable": "Additional information is not available for this government-issued ID",
  "request_IdLabel": "Request Identifier",
  "request_InProgress": "Request is in progress",
  "request_PersonDeleted": "Person was deleted",
  "request_PersonDeletedExplanation": "The person associated with this requested has deleted their data",
  "request_ProofOfVerification": "Proof of verification",
  "request_RejectReasonDevice": "Rejected because mobile device could not be verified",
  "request_RejectReasonDocument": "Rejected because the identity document presented could not be verified",
  "request_RejectReasonSelfie": "Rejected because the selfie doesn't match the photo on the ID",
  "request_Rejected": "Identity could not be verified",
  "request_RejectedAt": "Rejected %{reason} at %{time}",
  "request_RejectedFraud": "Rejected because automated fraud detection detected a high risk of fraud",
  "request_RejectedInReview": "Rejected because automated fraud detection detected a high risk of fraud. The use has appealed the decision and it is under review.",
  "request_RejectedManually": "Audit confirmed that identity cannot be verified",
  "request_RejectedUnusable": "Rejected because automated detection determined that the document was unusable.",
  "request_RejectedWithReason": "Request rejected %{reason}",
  "request_Revoked": "Request was revoked",
  "request_Revoked2": "Recipient revoked request and stopped sharing data",
  "request_RevokedAt": "Recipient revoked this request %{time}",
  "request_SelfieInfoUnavailable": "Additional information is not available for this selfie",
  "request_String": "String",
  "request_TooltipAppVersion": "This is the version of the Nametag app being used to complete the request.",
  "request_TooltipBarcodeDetails": "This is the barcode data that is read from the document, which helps to verify the authenticity of the document.",
  "request_TooltipBiometricConsentRegion": "This is the region where the biometric data is stored and processed.",
  "request_TooltipDataConsentRegion": "This is the region where the data is stored and processed.",
  "request_TooltipDeviceIntegrity": "This ensures that the ID scan process is secure by confirming that Nametag is using a legitimately installed app on a genuine mobile device and not on a simulator, jailbroken device, or otherwise compromised environment.",
  "request_TooltipDeviceUserAgent": "This is the user agent of the device that this person is using to complete the request. This is how the device identifies itself to the Nametag app.",
  "request_TooltipDocumentAppAttestation": "This ensures that the document image is coming from the user's device and is not a manipulated image.",
  "request_TooltipDocumentMaterial": "This is how confident Nametag is that this document consists of the expected material, which helps to verify that the document is not a forgery.",
  "request_TooltipDocumentStructure": "This is how confident Nametag is that this document has the expected structure, which helps to verify that the document is not a forgery.",
  "request_TooltipDocumentTemplate": "This is how confident Nametag is that this document has the expected format, which helps to verify that the document is not a forgery.",
  "request_TooltipPublicKey": "This is the public key of the device's cryptographic coprocessor, which helps to verify the authenticity of the device.",
  "request_TooltipRequestId": "This request ID can be used to cross-reference data from API clients and webhooks",
  "request_TooltipSelfieAppAttestation": "This ensures that the selfie is coming from a camera on the user's phone, and not a manipulated image.",
  "request_TooltipSelfieChain": "This ensures that the same real person is linked with one government identity, even over multiple enrollments and ID types.",
  "request_TooltipSelfieDepthMap": "This is the confidence that the face in the selfie is a real person, and not an image.",
  "request_TooltipSelfieFaceMatch": "This is the confidence that the face in the selfie is the same as the face in the document.",
  "request_completed": "Request completed",
  "request_rejected": "Request rejected",
  "samlCertHelpGraf": "This certificate allows Nametag to verify the credentials issued by your identity provider. This value is provided by your identity provider.",
  "samlCertLabel": "Identity provider certificate",
  "samlCheckOkGraf1": "Your organization can now use SAML SSO to sign into Nametag.",
  "samlCheckOkGraf2": "This will turn all Members controls over to your SAML identity provider. This includes invitations, removals, and roles.",
  "samlCheckOkHed": "Congratulations! Your test passed successfully.",
  "samlErrorGraf": "It appears that the developer in charge of your organization’s SAML configuration has made an error.",
  "samlErrorHed": "Something’s wrong with SAML",
  "samlUpdatePending": "This update is pending until it passes a test successfully.",
  "saml_group_name": "SAML group name",
  "saml_groups_graf": "Your SAML identity provider will tell us a group for each user. You must map these groups on to Nametag permissions.",
  "saml_groups_hed": "Group mapping",
  "saveThisSignInMethod": "Save this sign-in method",
  "search_all_members": "Search all members",
  "shared_empty": "No one has shared data in this environment yet.",
  "shared_empty_with_query": "No matching records found",
  "shared_error": "Failed to load data",
  "shared_reload": "Reload",
  "shared_search": "Search all fields...",
  "sidenav_APIKeys": "API keys",
  "sidenav_Billing": "Billing",
  "sidenav_Billing_Disabled": "You must be an owner to access billing.",
  "sidenav_DangerZone": "Danger Zone",
  "sidenav_Directories": "Directories",
  "sidenav_Directories_Disabled": "You must be an owner to access directories.",
  "sidenav_Help": "Need help?",
  "sidenav_Members": "Members",
  "sidenav_OAuth": "OAuth",
  "sidenav_Organization": "Organization",
  "sidenav_Reports": "Reporting",
  "sidenav_RequestTemplates": "Request templates",
  "sidenav_Scopes": "Data requests",
  "sidenav_Webhooks": "Webhooks",
  "signIn": "Sign in",
  "signInWithSSO": "Sign in with SSO",
  "signOut_Header": "You’ve been signed out.",
  "signOut_SignBackIn": "Sign back in to access Nametag.",
  "signinEmailGraf1": "This will require your team members to click a link in an email to access your organization.",
  "signinEmailGraf2": "You will need to invite each person with their work email address. If you need to add a large number of team members at once, we suggest using SAML SSO.",
  "signinEmailHed": "Sign in with Nametag",
  "signinNametagGraf1": "This will require your team members to scan a QR code and verify their identity using Nametag to access your organization. We recommend using this sign in method.",
  "signinNametagGraf2": "You will need to invite each person with their work email address. If you need to add a large number of team members at once, we suggest using SAML SSO.",
  "signinNametagHed": "Sign in with Nametag",
  "signin_Email_NoOrg_Graf1": "We couldn’t find an account with this email address.",
  "signin_Email_NoOrg_Graf2": "Sign up to send ID requests.",
  "signin_Email_NoOrg_Graf3": "Are you trying to respond to a company’s ID request? [Click here](https://getnametag.com/respond-to-a-request).",
  "signin_Error_Page": "Sign in failed",
  "signin_Error_Page_info_1": "It doesn’t look like you’re eligible to sign in to this account.",
  "signin_Error_Page_info_2": "Think this is a mistake? Reach out to your team administrator for more info.",
  "signin_graf": "Select a sign in method for your organization. Members will be required to sign in this way.",
  "signin_hed": "Sign in method",
  "signin_marketing_graf_1": "Send verification requests to your customers and review detailed analysis",
  "signin_marketing_graf_2": "Customers tap to share their ID in a privacy-preserving way",
  "signin_marketing_graf_3": "Receive validation confirmation and customer data securely and efficiently",
  "signin_marketing_hed_1": "AI-Powered Fraud Prevention",
  "signin_marketing_hed_2": "Seamless mobile experience",
  "signin_marketing_hed_3": "Empower and inform CSRs",
  "signup1_autopilot1": "Self-service account recovery for your workforce ",
  "signup1_autopilot2": "Provide self-service password & MFA resets to employees",
  "signup1_autopilot3": "Integrate with your company directory and sync accounts",
  "signup1_birdseed": "By continuing, you hereby acknowledge and agree to Nametag’s [Privacy Policy](https://getnametag.com/privacy) and [Terms of Service](https://getnametag.com/terms).",
  "signup1_caption": "Sign up for a free 14-day trial to see Nametag in action",
  "signup1_click_to_signin": "Click here to sign in",
  "signup1_copilot1": "Helpdesk verification for your support agents",
  "signup1_copilot2": "Empower agents to verify people who contact the helpdesk",
  "signup1_copilot3": "Integrate directly with your ticketing system",
  "signup1_email_already_exists": "It looks like you already have a Nametag account!",
  "signup1_email_label": "Work email",
  "signup1_email_placeholder": "Enter your email address",
  "signup1_footer_bullet1": "Works on any iOS or Android device",
  "signup1_footer_bullet2": "Users can control and revoke their own data",
  "signup1_footer_bullet3": " Add team members and customize branding",
  "signup1_fullname_label": "Full name",
  "signup1_hed": "What brings you here? (optional)",
  "signup1_info": "We’re asking so that we can point you in the right direction while you’re getting started. Your trial includes both Autopilot and Copilot!",
  "signup1_org_label": "Company name",
  "signup1_submit": "Sign up",
  "signup2_did_not_get_the_email": "Didn’t get the email?",
  "signup2_email_verification": "Verify your email address",
  "signup2_email_verification_info": "Just one more thing! Please check your inbox for an email from Nametag and click the Activate your Nametag trial button to sign in.",
  "signup2_send_it_again": "Send it again",
  "signup3_create_nametag": "Create your Nametag",
  "signup3_footer": "No app required! You’ll be able to complete the entire verification process in seconds. [Need some help?](https://getnametag.com/help)",
  "signup3_graf1": "You’ll use it to sign into Nametag. It’s totally private, impossible to lose, and only requires scanning your ID the first time.",
  "signup3_graf2": "Scan QR code to continue.",
  "signup3_graf2_mobile": "Click to continue.",
  "signup3_h1": "Lastly create your own Nametag",
  "signup_existing_account_nosaml": "You already have an account. Please sign in.",
  "signup_existing_account_saml": "Your organization is already configured. Please sign in.",
  "somethingWrongWithOAuth": "Something’s wrong with OAuth",
  "template_CreateFailed": "Failed to create template",
  "templates_AcceptTextHed": "Share button copy",
  "templates_AcceptTextLengthError": "Share button copy must be under %{maxLength} characters",
  "templates_AcceptTextPlaceholder": "Enter a short message",
  "templates_AcceptTextSubhed": "What users see on the button they click in order to complete the request",
  "templates_AcceptedTextHed": "Completed request message",
  "templates_AcceptedTextLengthError": "Accepted text must be under %{maxLength} characters",
  "templates_AcceptedTextPlaceholder": "Enter a short message",
  "templates_AcceptedTextSubhed": "What users see after they complete the request",
  "templates_ActionsMenu": "Template actions menu",
  "templates_AddSmartField": "Add a smart field",
  "templates_BlankHed": "Start from scratch",
  "templates_BlankSubhed": "Create a blank template and customize a request based on your unique needs",
  "templates_CivHed": "Comprehensive Identity Verification",
  "templates_CivSubhed": "Collect revealing data points like date of birth and address, require reverification every time",
  "templates_ConfirmDelete": "Confirm template deletion",
  "templates_ConfirmDeleteGraf": "Are you sure you want to delete this template? You will not be able to recover it later.",
  "templates_Create": "Create template",
  "templates_CreateHed": "Create a request template",
  "templates_CreateSubhed": "Each template can be customized to collect specific data points, store that data for a given amount of time, and provide instructions to the recipient. Begin with a pre-made template or start from scratch.",
  "templates_CreateTemplate": "Create a template",
  "templates_CsrHed": "Customer Support Request",
  "templates_CsrSubhed": "Quickly verify a customer’s identity over phone or email, easy reverification without selfie",
  "templates_DefaultTemplate": "Default template",
  "templates_Delete": "Delete",
  "templates_DeleteDefaultTooltip": "Your default template cannot be deleted.",
  "templates_DeleteTemplate": "Delete template",
  "templates_Disabled": "Template disabled",
  "templates_Duplicate": "Duplicate",
  "templates_Edit": "Edit",
  "templates_Editor": "Template editor",
  "templates_Enabled": "Template enabled",
  "templates_EnabledOff": "This template is off",
  "templates_EnabledOn": "This template is on",
  "templates_ErrorSummaryHed": "Please fix the following errors before saving:",
  "templates_EvHed": "Employee Verification",
  "templates_EvSubhed": "Confirm identity of new employee using government data and a profile photo",
  "templates_Expiration": "Expiration time",
  "templates_ExpirationDetails": "All of the data in your request will expire at this time and you will lose access to the data. This helps us protect the people who are sharing their private information with you.",
  "templates_ExpirationInfo": "Expires in %{duration}",
  "templates_ExpirationInvalidError": "Templates must have an expiration time",
  "templates_ExpirationUnitSelect": "Select expiration time unit",
  "templates_HeadlineHed": "Request headline on user's mobile device",
  "templates_HeadlineLengthError": "Headline must be under %{maxLength} characters",
  "templates_HeadlineMissingTagError": "Headline must include Public name smart field",
  "templates_HeadlinePlaceholder": "Explain your request in the mobile app",
  "templates_HeadlineSubhed": "What users see when they begin the process",
  "templates_HvtHed": "High Value Transactions",
  "templates_HvtSubhed": "Gather key demographics, require reverification every time the transaction takes place",
  "templates_Info": "Create customized templates in order to send tailored requests for verification. Choose the types of data you’re collecting to confirm identities, write your own messaging to maintain your brand voice, and turn templates off to remove them from your organization’s template lists.",
  "templates_InstallMessageSMSHed": "Text message request for user data",
  "templates_InstallMessageSMSLengthError": "Text message must be under %{maxLength} characters",
  "templates_InstallMessageSMSMissingTagError": "Text message requests must include Public name and Request link smart fields",
  "templates_InstallMessageSMSPlaceholder": "Explain your request in a text message and send along a link",
  "templates_InstallMessageSMSSubhed": "What users see when they open the text message",
  "templates_LoadFailed": "Failed to load template",
  "templates_MakeDefault": "Make default",
  "templates_Messaging": "Messaging",
  "templates_MessagingSubhed": "Write custom messaging for unique types of requests to ensure that people know what they are consenting to when they compelete a request from you.",
  "templates_OtherTemplates": "Other templates",
  "templates_PublicName": "Public name",
  "templates_QrHeadlineHed": "One-time link request for user data",
  "templates_QrHeadlineLengthError": "One-time link requests must be under %{maxLength} characters",
  "templates_QrHeadlineMissingTagError": "One-time link requests must include the Public name smart field",
  "templates_QrHeadlinePlaceholder": "Enter a short message",
  "templates_QrHeadlineSubhed": "What users see when they click the one-time link",
  "templates_Rename": "Rename",
  "templates_RequestLink": "Request link",
  "templates_RequestorLabel": "Request label",
  "templates_Save": "Save template",
  "templates_ScopeCount": "%{count} scopes",
  "templates_ScopeCountSingular": "1 scope",
  "templates_Scopes": "Scopes",
  "templates_ScopesInfo": "Templates must have **at least one** scope. Scopes represent the types of identifying information you’re asking for in each request.",
  "templates_ScopesMissingError": "Templates must have at least one scope",
  "templates_Select": "Select a template",
  "templates_SelfieReverification": "Selfie reverification",
  "templates_SelfieReverificationDescription": "Recipients will be required to take a new selfie every time they complete this request, even if they have already verified their identity in the past. This is recommended for high-value transactions.",
  "templates_SelfieReverificationDisabled": "No, do not require a selfie every time this request is completed",
  "templates_SelfieReverificationEnabled": "Yes, require a selfie every time this request is completed",
  "templates_Status": "Status",
  "templates_StatusInfo": "Templates can be turned on and off. Templates that are off will not appear in the list of requests that readers can send on the **Send a request** page. ",
  "templates_Templates": "Templates",
  "templates_UpdateFailed": "Failed to update template",
  "templates_UpdateSuccess": "Your changes have been saved",
  "userdata_created_by_API": "Created by API",
  "userdata_expiredScopesInfo": "This request has expired and the data is no longer stored here",
  "userdata_header_Label": "Label",
  "userdata_header_Status": "Status",
  "userdata_header_last_updated": "Last Updated",
  "validate_AlmostDone": "Almost done",
  "validate_BackToRequest": "Back to %{envName}’s request",
  "validate_ConfirmMyEmailAddress": "Confirm my email address",
  "validate_EmailVerified": "Email verified",
  "validate_GenericError": "We are unable to confirm your email address.",
  "validate_PressTheButtonBelowToConfirmYourEmailAddress": "Press the button below to confirm your email address.",
  "validate_ReturnToTheRequestToCompleteTheProcess": "Return to %{envName}’s request to complete the process",
  "validate_ScanToReturnToRequest": "Scan to return to %{envName}’s request",
  "validate_ScanToReturnToTheNametagApp": "Scan to return to the Nametag app",
  "validate_ValidationFailedError": "This link may have already been used.",
  "validate_VerifyEmail": "Verify email",
  "validate_ViewInTheNametagApp": "View in the Nametag app",
  "validate_YourEmailHasBeenVerified": "Your email has been verified.",
  "validate_YoureAllSet": "You’re all set!",
  "webhook_DeliveryError": "Delivery Error",
  "webhook_DeliveryError_graf": "Nametag encountered an error while attempting to deliver this event.",
  "welcomeBackFirstName": "Welcome back, %{firstName}",
  "welcomeToNametag": "Welcome to Nametag"
}
