/**
 * Copyright 2022 Nametag Inc.
 *
 * All information contained herein is the property of Nametag Inc.. The
 * intellectual and technical concepts contained herein are proprietary, trade
 * secrets, and/or confidential to Nametag, Inc. and may be covered by U.S.
 * and Foreign Patents, patents in process, and are protected by trade secret or
 * copyright law. Reproduction or distribution, in whole or in part, is
 * forbidden except by express written permission of Nametag, Inc.
 */

import { ReactElement } from "react";

export const APIKeysIcon = (props: { className?: string }): ReactElement => {
  return (
    <svg
      className={props.className}
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 5.1666C10.3536 5.1666 10.6928 5.30707 10.9428 5.55712C11.1929 5.80717 11.3333 6.14631 11.3333 6.49993M14 6.49993C14.0002 7.12471 13.854 7.74085 13.5732 8.29896C13.2924 8.85707 12.8847 9.34165 12.3829 9.71385C11.8811 10.086 11.2991 10.3355 10.6835 10.4423C10.0679 10.549 9.43584 10.5101 8.838 10.3286L7.33333 11.8333H6V13.1666H4.66667V14.4999H2.66667C2.48986 14.4999 2.32029 14.4297 2.19526 14.3047C2.07024 14.1796 2 14.0101 2 13.8333V12.1093C2.00004 11.9325 2.0703 11.7629 2.19533 11.6379L6.17133 7.66193C6.00497 7.11194 5.95904 6.53256 6.0367 5.96323C6.11435 5.39389 6.31375 4.84797 6.62133 4.36263C6.92891 3.87728 7.33744 3.46389 7.81913 3.15061C8.30082 2.83732 8.84434 2.6315 9.41272 2.54713C9.9811 2.46277 10.561 2.50185 11.1129 2.66172C11.6648 2.82159 12.1758 3.09849 12.6111 3.47357C13.0464 3.84866 13.3958 4.31312 13.6354 4.83535C13.8751 5.35759 13.9994 5.92533 14 6.49993Z"
        stroke="currentColor"
        strokeWidth="1.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
