/**
 * Copyright 2022 Nametag Inc.
 *
 * All information contained herein is the property of Nametag Inc.. The
 * intellectual and technical concepts contained herein are proprietary, trade
 * secrets, and/or confidential to Nametag, Inc. and may be covered by U.S.
 * and Foreign Patents, patents in process, and are protected by trade secret or
 * copyright law. Reproduction or distribution, in whole or in part, is
 * forbidden except by express written permission of Nametag, Inc.
 */

import { style } from "typestyle";
import { IDResult } from "../../api";
import { NametagLogo } from "../assets/nametag-logo";
import { COLORS } from "../../lib/colors";
import { pxToRem } from "../../lib/utils";
import { OrgMenu } from "./org_menu";
import { UserMenu } from "./user_menu";
import { Org } from "../../api/types";
import { Env } from "../../api/types";
import { WarningIcon } from "../assets/warning-icon";
import { envURI } from "../../console/url";
import { Link, useNavigate } from "react-router-dom";
import { useAPI } from "../../api/context";
import { useQueryClient } from "@tanstack/react-query";

export type Props = {
  org?: Org;
  env?: Env;
  envs?: Array<Env>;
  id?: IDResult;
};

export const TopNav = (props: Props) => {
  const sendRequestURI =
    props.env && props.envs ? envURI(props.env, props.envs) + "/request" : "/";

  const { api } = useAPI();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const signOut = async () => {
    api.State.idToken = null;
    queryClient.getQueryCache().clear();
    navigate("/signout");
  };

  return (
    <div className={css.container}>
      <Link to={sendRequestURI} className={css.logoWrapper}>
        <NametagLogo className={css.logo} />
      </Link>
      <div className={css.containerRight}>
        {props.id?.banner && (
          <span className={css.banner}>
            <WarningIcon /> {props.id.banner}
          </span>
        )}
        {props.org && (
          <OrgMenu org={props.org} envs={props.envs} env={props.env} />
        )}
        {props.id && <div className={css.separator} />}
        {props.id && (
          <UserMenu
            profilePicture={props.id.profile_picture}
            name={props.id.name}
            signOut={signOut}
            enableFeatureFlagsMenu={true}
          />
        )}
      </div>
    </div>
  );
};

const css = {
  container: style({
    backgroundColor: "#0E1E4E",
    color: "#FFFFFF",
    height: 52,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "0 10px 0 18px",
  }),
  banner: style({
    display: "flex",
    alignItems: "center",
    gap: 8,
    backgroundColor: COLORS.errorButton,
    padding: "4px 16px",
    fontWeight: 600,
    borderRadius: 4,
  }),
  containerRight: style({
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  }),
  logoWrapper: style({
    flexShrink: 0,
    height: pxToRem(24),
  }),
  logo: style({
    height: "100%",
  }),
  centerTabs: style({
    display: "flex",
    gap: 36,
    height: "100%",
    flexShrink: 0,
  }),
  tab: style({
    display: "flex",
    gap: 8,
    alignItems: "center",
    color: "unset !important",
    textDecoration: "unset !important",
    paddingTop: 8,
    $nest: {
      "&:hover": {
        filter: "brightness(85%)",
      },
    },
  }),
  activeTab: style({
    borderBottom: `5px solid ${COLORS.actionPrimary}`,
    fontWeight: 700,
  }),
  inactiveTab: style({
    borderBottom: "5px solid transparent", // invisible border to keep text aligned
  }),
  separator: style({
    height: "26px",
    backgroundColor: COLORS.border,
    minWidth: "2px",
    maxWidth: "2px",
    margin: "0 6px",
  }),
};
