/**
 * Copyright 2020 Nametag Inc.
 *
 * All information contained herein is the property of Nametag Inc.. The
 * intellectual and technical concepts contained herein are proprietary, trade
 * secrets, and/or confidential to Nametag, Inc. and may be covered by U.S.
 * and Foreign Patents, patents in process, and are protected by trade secret or
 * copyright law. Reproduction or distribution, in whole or in part, is
 * forbidden except by express written permission of Nametag, Inc.
 */

import * as React from "react";

import { WarningIcon } from "./components/assets/warning-icon";
import { t } from "./i18n";
import { Page } from "./components/page/page";

export const NotFoundPage = () => {
  return (
    <Page auth={false} title={t("not_found_Headline")}>
      <h2>
        <WarningIcon /> {t("not_found_Title")}
      </h2>
      <h1>{t("not_found_Headline")}</h1>
      <p>{t("not_found_Graph")}</p>
    </Page>
  );
};
