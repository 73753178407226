import { classes, style } from "typestyle";
import { t } from "../../i18n";

type FooterProps = {
  className?: string;
};
export const Footer = ({ className }: FooterProps) => (
  <footer className={classes(css.container, className)}>
    <div className={css.left}>
      <a className={css.link} href={"https://getnametag.com/about"}>
        {t("footer_Company")}
      </a>
      <a className={css.link} href={"https://getnametag.com/terms"}>
        {t("footer_TermsOfService")}
      </a>
      <a className={css.link} href={"https://getnametag.com/privacy"}>
        {t("footer_Privacy")}
      </a>
      <span className={css.text}>{t("footer_Copyright")}</span>
    </div>
    <div className={css.right}></div>
  </footer>
);

const css = {
  container: style({
    fontWeight: 700,
    background: "#403f4b",
    padding: "21px 32px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    fontSize: "12px",
    position: "relative",
    color: "#ffffff",
  }),
  left: style({}),
  right: style({}),
  link: style({
    color: "#ffffff",
    textDecoration: "none",
    marginRight: "16px",
    $nest: {
      "&:hover": {
        textDecoration: "underline",
        color: "#ffffff",
      },
    },
  }),
  text: style({}),
};
