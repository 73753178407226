/**
 * Copyright 2023 Nametag Inc.
 *
 * All in
 * formation contained herein is the property of Nametag Inc.. The
 * intellectual and technical concepts contained herein are proprietary, trade
 * secrets, and/or confidential to Nametag, Inc. and may be covered by U.S.
 * and Foreign Patents, patents in process, and are protected by trade secret or
 * copyright law. Reproduction or distribution, in whole or in part, is
 * forbidden except by express written permission of Nametag, Inc.
 */

import { useNavigate } from "react-router-dom";
import { style } from "typestyle";
import { NametagLogo } from "../components/assets/nametag-logo";
import { UserMenu } from "../components/page/user_menu";
import { GetTag } from "../lib/tags";
import { COLORS } from "../lib/colors";
import { useQueryClient } from "@tanstack/react-query";

type HeaderProps = {
  userName: string;
  userProfilePicture: string;
};

export const Header = ({ userName, userProfilePicture }: HeaderProps) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const envName = GetTag("env_public_name");
  const envLogo = GetTag("env_logo_url");

  const signOut = async () => {
    window.localStorage.clear();
    queryClient.clear();
    navigate("/");
  };

  return (
    <header className={css.header}>
      <NametagLogo className={css.nametagLogo} />
      {envName && envLogo && (
        <div className={css.env}>
          <img src={envLogo} alt={`${envName} logo`} className={css.envLogo} />
          {envName}
        </div>
      )}
      <UserMenu
        name={userName}
        profilePicture={userProfilePicture}
        signOut={signOut}
      />
    </header>
  );
};

export const css = {
  header: style({
    display: "flex",
    width: "100%",
    padding: "4px 24px",
    alignItems: "center",
    gap: "6px",
    background: "#0E1E4E",
  }),
  nametagLogo: style({
    maxWidth: 108,
    marginRight: "auto",
  }),
  env: style({
    color: COLORS.white,
    fontWeight: 700,
    borderRight: `2px solid ${COLORS.white}`,
    display: "flex",
    gap: 8,
    alignItems: "center",
    paddingRight: 20,
  }),
  envLogo: style({
    height: 28,
    width: 28,
    borderRadius: "50%",
    objectFit: "cover",
  }),
};
