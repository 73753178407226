/**
 * Copyright 2022 Nametag Inc.
 *
 * All information contained herein is the property of Nametag Inc.. The
 * intellectual and technical concepts contained herein are proprietary, trade
 * secrets, and/or confidential to Nametag, Inc. and may be covered by U.S.
 * and Foreign Patents, patents in process, and are protected by trade secret or
 * copyright law. Reproduction or distribution, in whole or in part, is
 * forbidden except by express written permission of Nametag, Inc.
 */

// A script injected into the HTML assigns window['cspNonce'] and then
// removes itself. We copy that value into cspNonce, which is
// safely within our scope and remove it from window. It is not entirely
// clear to me if it is safe to expose the nonce to the DOM, but I noticed
// that chrome hides it, so we're hiding it too.

interface WindowT {
  cspNonce?: string;
}
/* eslint-disable @typescript-eslint/no-explicit-any */
export const cspNonce = (window as any as WindowT)["cspNonce"] || "";

// @ts-ignore
delete window["cspNonce"];

import { setStylesTarget } from "typestyle";
const style = document.createElement("style");
style.setAttribute("nonce", cspNonce);
if (document.head) {
  document.head.appendChild(style);
}
setStylesTarget(style);
