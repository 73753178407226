/**
 * Copyright 2023 Nametag Inc.
 *
 * All information contained herein is the property of Nametag Inc.. The
 * intellectual and technical concepts contained herein are proprietary, trade
 * secrets, and/or confidential to Nametag, Inc. and may be covered by U.S.
 * and Foreign Patents, patents in process, and are protected by trade secret or
 * copyright law. Reproduction or distribution, in whole or in part, is
 * forbidden except by express written permission of Nametag, Inc.
 */

import { QueryKey } from "@tanstack/react-query";
import {
  GetRequestsOpts,
  ListDirectoryEntriesRequest,
  OrgReportOpts,
} from "./types";

class AccountQueryKeys {
  AccountActivity(envID: string, accountID: string): QueryKey {
    return ["env", envID, "accountID", accountID];
  }
}

class EnvQueryKeys {
  List: QueryKey = ["envs"];
  ListDirectories(envID: string | undefined): QueryKey {
    return ["env", envID ?? "<unknown>", "directories"];
  }
  GetDirectory(envID: string | undefined, dirID: string | undefined): QueryKey {
    return ["env", envID ?? "<unknown>", "directories", dirID];
  }
  GetDirectoryGroups(
    envID: string | undefined,
    dirID: string | undefined,
    groupQuery: string | undefined,
  ): QueryKey {
    return [
      "env",
      envID ?? "<unknown>",
      "directories",
      dirID,
      "groups",
      groupQuery || "",
    ];
  }
  ListDirectoryEntries(
    envID: string | undefined,
    opts?: Omit<ListDirectoryEntriesRequest, "env">,
  ): QueryKey {
    return ["env", envID ?? "<unknown>", "directory-entries", opts];
  }
  RecoveryMicrositeCheckDNS(envID: string | undefined): QueryKey {
    return ["env", envID ?? "<unknown>", "recovery-microsite-check-dns"];
  }
  ListWebhooks(envID: string | undefined): QueryKey {
    return ["env", envID ?? "<unknown>", "webhooks"];
  }
  GetRequests(
    envID: string | undefined,
    opts: GetRequestsOpts | null,
  ): QueryKey {
    if (opts === null) return ["env", envID ?? "<unknown>", "requests"];
    return ["env", envID ?? "<unknown>", "requests", opts];
  }
}

class OrgQueryKeys {
  Get: QueryKey = ["org"];
  ListMembers: QueryKey = ["org", "members"];
  Report(opts: OrgReportOpts | null): QueryKey {
    if (opts === null) return ["org", "report", "nullOpts"];
    return [
      "org",
      "report",
      "lastN",
      opts.timeframe.last_n?.n,
      opts.timeframe.last_n?.unit,
      "envs",
      opts.envs.toSorted().join("|"),
    ];
  }
}

class APIKeyQueryKeys {
  List: QueryKey = ["apikeys"];
}
class RequestQueryKeys {
  List: QueryKey = ["requests"];
  Get(id?: string): QueryKey {
    return ["request", id ?? "null"];
  }
}
class BillingQueryKeys {
  GetPaymentHistory: QueryKey = ["billing", "payment-history"];
  GetVerificationsBalance: QueryKey = ["billing", "verifications-balance"];
  GetPaymentMethod: QueryKey = ["billing", "payment-method"];
}

export class QueryKeys {
  static Accounts = new AccountQueryKeys();
  static Env = new EnvQueryKeys();
  static Org = new OrgQueryKeys();
  static APIKey = new APIKeyQueryKeys();
  static Request = new RequestQueryKeys();
  static Billing = new BillingQueryKeys();
  static ID: QueryKey = ["id"];
}
