/**
 * Copyright 2023 Nametag Inc.
 *
 * All information contained herein is the property of Nametag Inc.. The
 * intellectual and technical concepts contained herein are proprietary, trade
 * secrets, and/or confidential to Nametag, Inc. and may be covered by U.S.
 * and Foreign Patents, patents in process, and are protected by trade secret or
 * copyright law. Reproduction or distribution, in whole or in part, is
 * forbidden except by express written permission of Nametag, Inc.
 */

import { useRef, useEffect } from "react";

export function useDocumentTitle(title: string, prevailOnUnmount = false) {
  // ref: https://dev.to/luispa/how-to-add-a-dynamic-title-on-your-react-app-1l7k
  const defaultTitle = useRef(document.title);

  useEffect(() => {
    document.title = title + " | Nametag";
  }, [title]);

  useEffect(
    () => () => {
      if (!prevailOnUnmount) {
        document.title = defaultTitle.current;
      }
    },
    [],
  );
}
