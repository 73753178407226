/**
 * Copyright 2023 Nametag Inc.
 *
 * All information contained herein is the property of Nametag Inc.. The
 * intellectual and technical concepts contained herein are proprietary, trade
 * secrets, and/or confidential to Nametag, Inc. and may be covered by U.S.
 * and Foreign Patents, patents in process, and are protected by trade secret or
 * copyright law. Reproduction or distribution, in whole or in part, is
 * forbidden except by express written permission of Nametag, Inc.
 */

import { DialogStateReturn } from "reakit/src/Dialog/DialogState";
import { style } from "typestyle";
import { Button } from "../../../components/button/button";
import { CopyableInput } from "../../../components/copyable-input";
import { pxToRem } from "../../../lib/utils";
import { ResetModal } from "./reset-modal";
import { t } from "../../../i18n";
import { Markdown } from "../../../lib/md";
import { DirectoryKind } from "../../../api/types";
import { useEffect, useState } from "react";
import { Tooltip } from "../../../components/tooltip/tooltip";

export type AzurePasswordResetModalProps = {
  dialog: DialogStateReturn;
  directoryName: string;
  directoryLogo: string;
  externalIdentifier: string;
  isLoading: boolean;
  isError: boolean;
  errorMessage?: string;
  newPassword?: string;
};

export const AzurePasswordResetModal: React.FC<
  AzurePasswordResetModalProps
> = ({
  dialog,
  directoryName,
  directoryLogo,
  externalIdentifier,
  isLoading,
  isError,
  errorMessage,
  newPassword,
}) => {
  const [doneBtnDisabled, setDoneBtnDisabled] = useState<boolean>(true);

  useEffect(() => {
    setDoneBtnDisabled(true);
  }, [newPassword]);

  const DontBtn = () => {
    return (
      <Button
        onClick={dialog.hide}
        variant="secondary"
        className={css.doneBtn}
        disabled={doneBtnDisabled}
      >
        {t("envs_Done")}
      </Button>
    );
  };
  return (
    <ResetModal
      dialog={dialog}
      directoryKind={DirectoryKind.DirectoryKindAzureAD}
      directoryLogo={directoryLogo}
      directoryName={directoryName}
      externalIdentifier={externalIdentifier}
      isLoading={isLoading}
      isError={isError}
      errorMessage={errorMessage}
      loadingMessage={t("microsite_ResettingPassword")}
    >
      {newPassword ? (
        <>
          <p className={css.graf}>{t("microsite_TempPasswordHed")}</p>

          <label className={css.label}>{t("microsite_TempPassword")}</label>
          <CopyableInput
            className={css.newPassword}
            value={newPassword}
            copyText="Copy to clipboard"
            copiedText="Copied to clipboard"
            didCopy={() => {
              setDoneBtnDisabled(false);
            }}
          />
          <p className={css.graf}>
            {Markdown(
              t("microsite_UpdatePassword", {
                link: "[aka.ms/mysecurityinfo](https://aka.ms/mysecurityinfo)",
              }),
            )}
          </p>
        </>
      ) : (
        t("microsite_TempPasswordMissing")
      )}
      {doneBtnDisabled ? (
        <Tooltip
          title={t("microsite_TempPasswordTooltip")}
          placement="top"
          className={css.btnTooltip}
        >
          <DontBtn />
        </Tooltip>
      ) : (
        <DontBtn />
      )}
    </ResetModal>
  );
};

const css = {
  graf: style({
    marginBottom: 16,
  }),
  label: style({
    display: "block",
    color: "#383F43",
    fontSize: pxToRem(14),
    fontWeight: 400,
    marginBottom: 8,
  }),
  newPassword: style({
    marginBottom: 12,
    width: "100%",
    color: "#383F43",
  }),
  doneBtn: style({
    marginTop: 32,
    marginLeft: "auto",
  }),
  btnTooltip: style({
    marginLeft: "auto",
    width: "fit-content",
  }),
};
