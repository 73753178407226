/**
 * Copyright 2022 Nametag Inc.
 *
 * All information contained herein is the property of Nametag Inc.. The
 * intellectual and technical concepts contained herein are proprietary, trade
 * secrets, and/or confidential to Nametag, Inc. and may be covered by U.S.
 * and Foreign Patents, patents in process, and are protected by trade secret or
 * copyright law. Reproduction or distribution, in whole or in part, is
 * forbidden except by express written permission of Nametag, Inc.
 */

import { useRequireLogin, UseRequireLoginOptions } from "./auth";
import { useAPI } from "../api/context";
import { useLocation, useParams } from "react-router-dom";
import { useEffect } from "react";
import { Org } from "../api/types";
import { useQuery, UseQueryResult } from "@tanstack/react-query";
import { Env } from "../api/types";
import { IDResult } from "../api";
import { getEnvBySlugOrID } from "./url";
import { storage } from "../lib/storage";
import { QueryKeys } from "../api/querykey";

interface UseFromURLReturn {
  id?: IDResult;
  org?: Org;
  orgQuery: UseQueryResult<Org>;
  env?: Env;
  envs?: Array<Env>;
  envSource?: string;
  envsQuery: UseQueryResult<Array<Env>>;
}

interface LocationState {
  env?: string;
}

interface UseFromURLOptions extends UseRequireLoginOptions {}

// useFromURL queries for envs and orgs and selects the appropriate env or
// org based on the current URL and state.
//
// To determine the env, the precedence is:
//
//   1. the env name or ID in the URL
//   2. the env in location state
//   3. the env in local storage
//   4. the first env in the list
//
// This function requires that the user be logged in via `useRequireLogin` and
// returns the current users `id`. See that function for details.
export function useFromURL(opts?: UseFromURLOptions): UseFromURLReturn {
  const options: UseFromURLOptions = opts || {};
  const { api } = useAPI();

  const id = useRequireLogin(options);
  const { env: envParam } = useParams();
  const location = useLocation();
  const locationState = (location.state || {}) as LocationState;

  const orgQuery = useQuery({
    queryKey: QueryKeys.Org.Get,
    queryFn: () => api.Orgs.Get(),
    enabled: !!id,
  });
  const envsQuery = useQuery({
    queryKey: QueryKeys.Env.List,
    queryFn: () => api.Envs.List(),
    enabled: !!id,
  });
  const org = orgQuery.data;

  // Try to find an env.
  const [env, envSource]: [Env | undefined, string] = (() => {
    if (envsQuery.data === undefined || envsQuery.data === null) {
      return [undefined, ""];
    }

    if (envParam) {
      const env = getEnvBySlugOrID(envParam, envsQuery.data);
      if (env) {
        return [env, "url"];
      }
    }

    if (locationState.env) {
      const env = getEnvBySlugOrID(locationState.env, envsQuery.data);
      if (env) {
        return [env, "locationState"];
      }
    }

    const envFromLocalStorage = localStorage.getItem("env");
    if (envFromLocalStorage) {
      const env = getEnvBySlugOrID(envFromLocalStorage, envsQuery.data);
      if (env) {
        return [env, "localStorage"];
      }
    }

    const envs = envsQuery.data;
    if (envs.length > 0) {
      return [envs[0], "first"];
    }

    return [undefined, ""];
  })();

  useEffect(() => {
    if (env) {
      storage.setItem("env", env.id);
    }
  }, [env]);

  return {
    id: id ?? undefined,
    org: org,
    orgQuery: orgQuery,
    env: env,
    envSource: envSource,
    envs: envsQuery.data,
    envsQuery: envsQuery,
  };
}
