/**
 * Copyright 2023 Nametag Inc.
 *
 * All information contained herein is the property of Nametag Inc.. The
 * intellectual and technical concepts contained herein are proprietary, trade
 * secrets, and/or confidential to Nametag, Inc. and may be covered by U.S.
 * and Foreign Patents, patents in process, and are protected by trade secret or
 * copyright law. Reproduction or distribution, in whole or in part, is
 * forbidden except by express written permission of Nametag, Inc.
 */

import { Auth } from "@nametag/browser";
import { useEffect, useRef, useState } from "react";
import { useMutation } from "@tanstack/react-query";
import { style } from "typestyle";
import { NametagShortLogo } from "../../components/assets/nametag-short-logo";
import { BackgroundVector } from "../../components/background";
import { Button } from "../../components/button/button";
import { Input } from "../../components/input/input";
import { Footer } from "../../components/page/footer";
import { t } from "../../i18n";
import { COLORS } from "../../lib/colors";
import { GetTag } from "../../lib/tags";
import { pxToRem } from "../../lib/utils";
import { useBrowser } from "../../lib/browser";
import { StartRecovery } from "../recovery-api";

export interface SignInPageProps {}

export const SignInPage = () => {
  const [identifier, setIdentifier] = useState(
    window.localStorage.getItem("identifier") ?? "",
  );

  const [nametagAuth, setNametagAuth] = useState<Auth | null>(null);
  const [view, setView] = useState<"enterEmail" | "scanQR">("enterEmail");

  const initMutation = useMutation({
    mutationKey: ["start-recovery"],
    mutationFn: async () => {
      const recoverStartResp = await StartRecovery(identifier);
      setView("scanQR");

      const auth = new Auth({
        client_id: GetTag("oauth2_client_id")!,
        redirect_uri: GetTag("oauth2_redirect_uri")!,
        server: GetTag("oauth2_server")!,
        template: GetTag("oauth2_template")!,
        state: recoverStartResp.state,
      });

      setNametagAuth(auth);
    },
  });

  const envName = GetTag("env_public_name");
  const envLogo = GetTag("env_logo_url");

  return (
    <SignInView
      nametagAuth={nametagAuth}
      identifier={identifier}
      setIdentifier={setIdentifier}
      initNametagAuth={initMutation.mutateAsync}
      envName={envName}
      envLogo={envLogo}
      view={view}
    />
  );
};

export interface SignInViewProps {
  nametagAuth: Auth | null;
  identifier: string;
  setIdentifier: (identifier: string) => void;
  initNametagAuth: () => Promise<void>;
  envName?: string;
  envLogo?: string;
  view: "enterEmail" | "scanQR";
}

export const SignInView = ({
  nametagAuth,
  identifier,
  setIdentifier,
  initNametagAuth,
  envName,
  envLogo,
  view,
}: SignInViewProps) => {
  const qrRef = useRef<HTMLDivElement>(null);
  const { isMobile } = useBrowser();

  const onSubmit = async () => {
    window.localStorage.setItem("identifier", identifier);
    await initNametagAuth();
  };

  useEffect(() => {
    if (!nametagAuth) {
      return;
    }
    if (isMobile) {
      nametagAuth.AuthorizeURL().then((url) => {
        window.location.assign(url);
      });
      return;
    }

    if (!qrRef.current) {
      return;
    }
    nametagAuth.QRCode(qrRef.current, { variant: "dark" });
  }, [qrRef.current, nametagAuth]);

  return (
    <div className={css.root}>
      <BackgroundVector />
      <div /> {/* spacer */}
      <div>
        <div className={css.wrapper}>
          {envName && envLogo && (
            <div className={css.envLogo}>
              <img src={envLogo} alt={`${envName} logo`} className={css.logo} />
              {envName}
            </div>
          )}
          {view === "enterEmail" && (
            <>
              <form
                onSubmit={(evt) => {
                  evt.preventDefault();
                  onSubmit();
                }}
              >
                <label htmlFor={"email"}>
                  <div className={css.hed}>{t("microsite_EnterEmail")}</div>
                  <div className={css.subhed}>
                    {t("microsite_SigninSubhed")}
                  </div>
                </label>
                <Input
                  type={"email"}
                  id={"email"}
                  value={identifier}
                  required
                  autoFocus
                  onChange={(evt) => setIdentifier(evt.target.value)}
                />
                <Button
                  type={"submit"}
                  variant="primary"
                  className={css.nextBtn}
                >
                  {t("next")}
                </Button>
              </form>
              <div className={css.securedByNametag}>
                <NametagShortLogo fill="black" className={css.ntLogo} />{" "}
                {t("microsite_SecuredByNametag")}
              </div>
            </>
          )}
          {view === "scanQR" && (
            <>
              <div className={css.hed}>{t("microsite_ScanQR")}</div>
              <div className={css.subhed}>{t("microsite_SigninSubhed")}</div>
              {/* TODO: loading state for QR code
                maybe we can do this by updating the NT SDK to only mount the QR code elements
                once the QR is ready
              */}
              <div ref={qrRef} className={css.qr} />
            </>
          )}
        </div>

        {envName && (
          <div className={css.explanationText}>
            {t("oauth_Explanation", { envName: envName })}{" "}
            <a
              href={"https://getnametag.com"}
              className={css.explanationTextLink}
            >
              {t("oauth_LearnMore")}
            </a>
          </div>
        )}
      </div>
      <div /> {/* spacer */}
      <Footer className={css.footer} />
    </div>
  );
};

const css = {
  root: style({
    minHeight: "100%",
    backgroundColor: COLORS.fieldHover,
    position: "relative",
    display: "grid",
    gridTemplateColumns: "1fr",
    placeItems: "center",
    placeContent: "space-between",
  }),
  wrapper: style({
    backgroundColor: COLORS.white,
    padding: "48px 40px 32px",
    borderRadius: 8,
    width: "min(430px, 90%)",
    zIndex: 0,
    margin: "32px auto 12px",
    position: "relative",
  }),
  envLogo: style({
    display: "flex",
    alignItems: "center",
    gap: 12,
    paddingBottom: 20,
    borderBottom: `1px solid ${COLORS.borderLight}`,
    marginBottom: 20,
    fontSize: pxToRem(24),
    fontWeight: 500,
    color: COLORS.textDark,
  }),
  enterEmailLabel: style({}),
  hed: style({
    color: COLORS.textDark,
    fontSize: pxToRem(18),
  }),
  subhed: style({
    color: COLORS.textLight,
    fontSize: pxToRem(16),
    marginTop: 8,
    marginBottom: 32,
  }),
  logo: style({
    height: 34,
    width: 34,
    borderRadius: "50%",
    objectFit: "cover",
  }),
  nextBtn: style({
    width: "100%",
    marginTop: 16,
    marginBottom: 48,
    display: "flex",
    justifyContent: "center",
  }),
  ntLogo: style({
    width: 14,
  }),
  securedByNametag: style({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: 8,
    color: COLORS.textDark,
    fontSize: pxToRem(14),
  }),
  footer: style({
    width: "100%",
    marginTop: 16,
  }),
  qr: style({
    width: 240,
    minHeight: 279, // expected height once the QR code shows up
    margin: "0 auto",
    $nest: {
      "& .nt-qr-code-container": {
        backgroundColor: COLORS.background,
      },
    },
  }),
  explanationText: style({
    width: "min(396px, 80%)",
    color: COLORS.textDark,
    fontSize: pxToRem(11),
    margin: "auto",
  }),
  explanationTextLink: style({
    color: COLORS.textDark,
    textDecoration: "underline",
  }),
};
