"use strict";
/**
 * Copyright 2023 Nametag Inc.
 *
 * All information contained herein is the property of Nametag Inc.. The
 * intellectual and technical concepts contained herein are proprietary, trade
 * secrets, and/or confidential to Nametag, Inc. and may be covered by U.S.
 * and Foreign Patents, patents in process, and are protected by trade secret or
 * copyright law. Reproduction or distribution, in whole or in part, is
 * forbidden except by express written permission of Nametag, Inc.
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.DesktopQRCode = void 0;
var button_1 = require("./button");
var styles_1 = require("./styles");
var svg_1 = require("./svg");
var DesktopQRCode = /** @class */ (function () {
    function DesktopQRCode(auth, container, _a) {
        var variant = _a.variant;
        var _this = this;
        this.auth = auth;
        this.container = container;
        this.el = document.createElement("div");
        this.qrContainer = document.createElement("div");
        this.qrImg = document.createElement("img");
        this.errorMessage = document.createElement("div");
        this.caption = document.createElement("div");
        this.captionImage = document.createElement("img");
        this.captionText = document.createElement("p");
        this.iframe = document.createElement("iframe");
        // called when we receive a message from the iframe
        this.onMessage = function (evt) {
            var _a, _b;
            if (evt.origin !== _this.auth._server ||
                evt.source !== _this.iframe.contentWindow) {
                return;
            }
            var data = evt.data;
            if (data.state != _this.auth.state) {
                return;
            }
            if (data.qr) {
                console.log("nametag[".concat(_this.auth.state, "]: received QR code"));
                _this.qrImg.style.opacity = "1";
                _this.qrImg.src = data.qr;
            }
            if (data.install_ticket) {
                var el = document.createElement("meta");
                el.name = "install_ticket";
                el.content = data.install_ticket;
                document.head.appendChild(el);
            }
            switch (data.status) {
                case 100:
                    return; // keep waiting
                case 400: // developer error
                    console.error("nametag[".concat(_this.auth.state, "]: developer error: ").concat((_a = data.error_message) !== null && _a !== void 0 ? _a : "(unknown)"));
                    _this.qrImg.style.opacity = "0";
                    _this.errorMessage.innerText =
                        (_b = data.error_message) !== null && _b !== void 0 ? _b : "Something went wrong";
                    return;
                case 403: // rejected
                    console.log("nametag[".concat(_this.auth.state, "]: user rejected the request"));
                    _this.qrImg.style.opacity = "0";
                    _this.errorMessage.innerText = "You choose not to accept the request";
                    return;
                case 200:
                    console.log("nametag[".concat(_this.auth.state, "]: user accepted the request"));
                    if (!data.redirect_uri) {
                        console.warn("nametag[".concat(_this.auth.state, "]: internal error: expected redirect_uri when status is 200"));
                    }
                    else {
                        window.location.assign(data.redirect_uri);
                    }
                    return;
            }
        };
        this.errorMessage.classList.add("nt-qr-code-error", "nt-qr-code-container-item");
        this.qrContainer.appendChild(this.errorMessage);
        this.qrImg.classList.add("nt-qr-code-qr", "nt-qr-code-container-item");
        this.qrImg.style.opacity = "0";
        this.qrContainer.appendChild(this.qrImg);
        this.qrContainer.classList.add("nt-qr-code-container");
        this.el.appendChild(this.qrContainer);
        this.captionImage.src = (0, button_1.getSrcString)(svg_1.logoSVGB64);
        this.captionText.innerText = "Secured by Nametag";
        this.captionText.classList.add("nt-qr-code-caption-text");
        if (variant === "light") {
            this.captionImage.classList.add("nt-qr-code-caption-icon-light");
            this.captionText.classList.add("nt-qr-code-caption-text-light");
        }
        else {
            this.captionImage.classList.add("nt-qr-code-caption-icon-dark");
            this.captionText.classList.add("nt-qr-code-caption-text-dark");
        }
        this.caption.classList.add("nt-qr-code-caption");
        this.caption.appendChild(this.captionImage);
        this.caption.appendChild(this.captionText);
        this.el.classList.add("nt-qr-code");
        this.el.appendChild(this.caption);
        this.addStylesheetToDOM();
        this.addToDOM();
        window.addEventListener("message", this.onMessage);
        this.initFrame();
    }
    DesktopQRCode.prototype.initFrame = function () {
        return __awaiter(this, void 0, void 0, function () {
            var iframeURL;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!this.auth.IsCurrentOriginValid()) {
                            console.error("nametag[".concat(this.auth.state, "]: the origin of the redirect_uri must equal the current page's origin"));
                            return [2 /*return*/];
                        }
                        return [4 /*yield*/, this.auth.AuthorizeURL({ iframe: true })];
                    case 1:
                        iframeURL = _a.sent();
                        this.iframe = document.createElement("iframe");
                        this.iframe.style.display = "none";
                        this.iframe.src = iframeURL;
                        this.container.appendChild(this.iframe);
                        return [2 /*return*/];
                }
            });
        });
    };
    DesktopQRCode.prototype.addStylesheetToDOM = function () {
        if (!document.getElementById("nt-style")) {
            var style = document.createElement("style");
            style.id = "nt-style";
            style.innerText = styles_1.styles;
            document.head.appendChild(style);
        }
    };
    DesktopQRCode.prototype.addToDOM = function () {
        this.removeFromDOM();
        this.container.appendChild(this.el);
    };
    DesktopQRCode.prototype.removeFromDOM = function () {
        while (this.container.firstChild) {
            this.container.removeChild(this.container.firstChild);
        }
    };
    return DesktopQRCode;
}());
exports.DesktopQRCode = DesktopQRCode;
