"use strict";
/**
 * Copyright 2021 Nametag Inc.
 *
 * Use of this source code is governed by a BSD-style
 * license that can be found in the LICENSE file or at
 * https://developers.google.com/open-source/licenses/bsd
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.MemoryStorage = void 0;
exports.getLocalOrMemoryStorage = getLocalOrMemoryStorage;
var MemoryStorage = /** @class */ (function () {
    function MemoryStorage() {
        this.data = new Map();
    }
    Object.defineProperty(MemoryStorage.prototype, "length", {
        get: function () {
            return this.data.size;
        },
        enumerable: false,
        configurable: true
    });
    MemoryStorage.prototype.key = function (index) {
        var _a;
        var keys = Array.from(this.data.keys()).sort();
        return (_a = keys[index]) !== null && _a !== void 0 ? _a : null;
    };
    MemoryStorage.prototype.getItem = function (key) {
        var rv = this.data.get(key);
        console.log("getItem: ", key, "->", rv);
        return rv === undefined ? null : rv;
    };
    MemoryStorage.prototype.removeItem = function (key) {
        console.log("removeItem: ", key);
        this.data.delete(key);
    };
    MemoryStorage.prototype.setItem = function (key, value) {
        console.log("setItem: ", key, value);
        this.data.set(key, value);
    };
    MemoryStorage.prototype.clear = function () {
        this.data.clear();
    };
    return MemoryStorage;
}());
exports.MemoryStorage = MemoryStorage;
/**
 * Tests whether local storage is usable
 * If local storage is usable, that is returned; otherwise, in-memory storage is used instead
 */
function getLocalOrMemoryStorage() {
    try {
        var testKey = "__nametag_test";
        window.localStorage.setItem(testKey, "test");
        window.localStorage.getItem(testKey);
        window.localStorage.removeItem(testKey);
        return window.localStorage;
    }
    catch (_) {
        return new MemoryStorage();
    }
}
