/**
 * Copyright 2022 Nametag Inc.
 *
 * All information contained herein is the property of Nametag Inc.. The
 * intellectual and technical concepts contained herein are proprietary, trade
 * secrets, and/or confidential to Nametag, Inc. and may be covered by U.S.
 * and Foreign Patents, patents in process, and are protected by trade secret or
 * copyright law. Reproduction or distribution, in whole or in part, is
 * forbidden except by express written permission of Nametag, Inc.
 */

import { ReactElement } from "react";

export const UserPlaceholder = (props: {
  className?: string;
}): ReactElement => (
  <svg
    className={props.className}
    width={28}
    height={28}
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx={14} cy={14} r={14} fill="#E3F3FF" />
    <g clipPath="url(#clip0_46_206)">
      <path
        d="M8.66669 20.6667C8.66669 19.2523 9.22859 17.8957 10.2288 16.8955C11.229 15.8953 12.5855 15.3334 14 15.3334C15.4145 15.3334 16.7711 15.8953 17.7713 16.8955C18.7715 17.8957 19.3334 19.2523 19.3334 20.6667H18C18 19.6059 17.5786 18.5885 16.8284 17.8383C16.0783 17.0882 15.0609 16.6667 14 16.6667C12.9392 16.6667 11.9217 17.0882 11.1716 17.8383C10.4214 18.5885 10 19.6059 10 20.6667H8.66669ZM14 14.6667C11.79 14.6667 10 12.8767 10 10.6667C10 8.45675 11.79 6.66675 14 6.66675C16.21 6.66675 18 8.45675 18 10.6667C18 12.8767 16.21 14.6667 14 14.6667ZM14 13.3334C15.4734 13.3334 16.6667 12.1401 16.6667 10.6667C16.6667 9.19341 15.4734 8.00008 14 8.00008C12.5267 8.00008 11.3334 9.19341 11.3334 10.6667C11.3334 12.1401 12.5267 13.3334 14 13.3334Z"
        fill="#57565D"
      />
    </g>
    <defs>
      <clipPath id="clip0_46_206">
        <rect width={16} height={16} fill="white" transform="translate(6 6)" />
      </clipPath>
    </defs>
  </svg>
);
