/**
 * Copyright 2023 Nametag Inc.
 *
 * All information contained herein is the property of Nametag Inc.. The
 * intellectual and technical concepts contained herein are proprietary, trade
 * secrets, and/or confidential to Nametag, Inc. and may be covered by U.S.
 * and Foreign Patents, patents in process, and are protected by trade secret or
 * copyright law. Reproduction or distribution, in whole or in part, is
 * forbidden except by express written permission of Nametag, Inc.
 */

import { DialogStateReturn } from "reakit/src/Dialog/DialogState";
import { style } from "typestyle";
import { Button } from "../../../components/button/button";
import { CopyableInput } from "../../../components/copyable-input";
import { Link } from "../../../components/link/link";
import { t } from "../../../i18n";
import { useMetaTagValue } from "../../../lib/tags";
import { pxToRem } from "../../../lib/utils";
import { ResetModal } from "./reset-modal";
import { DirectoryKind } from "../../../api/types";
import { useEffect, useState } from "react";
import { Tooltip } from "../../../components/tooltip/tooltip";

// we return a customized version of this component for Reddit
const REDDIT_ORG_ID = "5v0dp9cft9ekzce";
const REDDIT_RESET_URL =
  "https://sso-e66a9458.sso.duosecurity.com/devices?link_clicked=true";

export type DuoMFAResetModalProps = {
  dialog: DialogStateReturn;
  directoryName: string;
  directoryLogo: string;
  externalIdentifier: string;
  isLoading: boolean;
  isError: boolean;
  errorMessage?: string;
  mfaRecoveryCode?: string;
};

export const DuoMFAResetModal: React.FC<DuoMFAResetModalProps> = ({
  dialog,
  directoryName,
  directoryLogo,
  externalIdentifier,
  isLoading,
  isError,
  errorMessage,
  mfaRecoveryCode,
}) => {
  let enableRedditHacks = false;
  const orgID = useMetaTagValue("org_id");
  if (orgID === REDDIT_ORG_ID) {
    enableRedditHacks = true;
  }

  const [doneBtnDisabled, setDoneBtnDisabled] = useState<boolean>(true);

  useEffect(() => {
    setDoneBtnDisabled(true);
  }, [mfaRecoveryCode]);

  const DontBtn = () => {
    return (
      <Button
        onClick={dialog.hide}
        variant="secondary"
        className={css.doneBtn}
        disabled={doneBtnDisabled}
      >
        {t("envs_Done")}
      </Button>
    );
  };

  return (
    <ResetModal
      dialog={dialog}
      directoryKind={DirectoryKind.DirectoryKindDuo}
      directoryLogo={directoryLogo}
      directoryName={directoryName}
      externalIdentifier={externalIdentifier}
      isLoading={isLoading}
      isError={isError}
      errorMessage={errorMessage}
      loadingMessage={t("microsite_ResettingMFA")}
    >
      {mfaRecoveryCode ? (
        <>
          <p className={css.graf}>
            {enableRedditHacks ? (
              <>
                We've generated a bypass code for you. To use the code click
                "Copy to clipboard" then navigate to{" "}
                <a href={REDDIT_RESET_URL}>Duo Device Manager</a>, sign in with
                your password and when prompted by Duo select "other option"
                then bypass code. Paste the code and continue. You will then be
                able to manage your Yubikeys and other assigned devices. If you
                still have problems please open a ticket using RSnooDSnoo.
              </>
            ) : (
              t("microsite_DuoMFAResetHed")
            )}
          </p>
          <label className={css.label}>{t("microsite_DuoResetCode")}</label>
          <CopyableInput
            className={css.recoveryCode}
            value={mfaRecoveryCode}
            copyText={t("CopyToClipboard")}
            copiedText={t("CopiedToClipboard")}
            didCopy={() => {
              setDoneBtnDisabled(false);
            }}
          />
          {enableRedditHacks ? (
            <Link
              to={REDDIT_RESET_URL}
              variant="primaryButton"
              className={css.doneBtn}
              // open in new tab
              target="_blank"
              rel="noopener noreferrer"
            >
              Continue
            </Link>
          ) : doneBtnDisabled ? (
            <Tooltip
              title={t("microsite_DuoResetCodeTooltip")}
              placement="top"
              className={css.btnTooltip}
            >
              <DontBtn />
            </Tooltip>
          ) : (
            <DontBtn />
          )}
        </>
      ) : (
        t("microsite_ResetCodeMissing")
      )}
    </ResetModal>
  );
};

const css = {
  graf: style({
    marginBottom: 16,
  }),
  label: style({
    display: "block",
    color: "#383F43",
    fontSize: pxToRem(14),
    marginBottom: 8,
  }),
  recoveryCode: style({
    marginBottom: 12,
    width: "100%",
    color: "#383F43",
  }),
  doneBtn: style({
    marginTop: 32,
    marginLeft: "auto",
    display: "block",
    width: "fit-content",
  }),
  btnTooltip: style({
    marginLeft: "auto",
    width: "fit-content",
  }),
};
