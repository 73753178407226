"use strict";
/**
 * Copyright 2023 Nametag Inc.
 *
 * Use of this source code is governed by a BSD-style
 * license that can be found in the LICENSE file or at
 * https://developers.google.com/open-source/licenses/bsd
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.captionSVGB64 = exports.logoSVGB64 = void 0;
exports.logoSVGB64 = "PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTkiIHZpZXdCb3g9IjAgMCA0MiA1NCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHJlY3Qgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIvPgo8cGF0aCBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGNsaXAtcnVsZT0iZXZlbm9kZCIgZD0iTTEuOTM2MzUgMS45NzYxMkMwIDMuOTA3ODggMCA3LjAxNjk5IDAgMTMuMjM1MlY0MC4zOTI4QzAgNDYuNjExIDAgNDkuNzIwMSAxLjkzNjM1IDUxLjY1MTlDMy44NzI3IDUzLjU4MzYgNi45ODkyMSA1My41ODM2IDEzLjIyMjIgNTMuNTgzNkgyOC43Nzc4QzM1LjAxMDggNTMuNTgzNiAzOC4xMjczIDUzLjU4MzYgNDAuMDYzNiA1MS42NTE5QzQyIDQ5LjcyMDEgNDIgNDYuNjExIDQyIDQwLjM5MjhWMTMuMjM1MkM0MiA3LjAxNjk5IDQyIDMuOTA3ODggNDAuMDYzNiAxLjk3NjEyQzM4LjEyNzMgMC4wNDQzNzI2IDM1LjAxMDggMC4wNDQzNzI2IDI4Ljc3NzggMC4wNDQzNzI2SDEzLjIyMjJDNi45ODkyMSAwLjA0NDM3MjYgMy44NzI3IDAuMDQ0MzcyNiAxLjkzN" +
    "jM1IDEuOTc2MTJaTTE4LjI1ODIgNC4xMTc5OEMxNy4xOTUxIDQuMTE3OTggMTYuMzMzMiA0Ljk3Nzc5IDE2LjMzMzIgNi4wMzg0MUMxNi4zMzMyIDcuMDk5MDQgMTcuMTk1MSA3Ljk1ODg0IDE4LjI1ODIgNy45NTg4NEgyMy43NDE2QzI0LjgwNDcgNy45NTg4NCAyNS42NjY2IDcuMDk5MDQgMjUuNjY2NiA2LjAzODQxQzI1LjY2NjYgNC45Nzc3OSAyNC44MDQ3IDQuMTE3OTggMjMuNzQxNiA0LjExNzk4SDE4LjI1ODJaTTI3Ljc0MiAzNC41NTVMMjcuNzQyOCAzNC41NTM0QzI3Ljg4NjIgMzQuMjgzOSAyOC4wMzcgMzQuMDAwNSAyOC4xODM2IDMzLjc0NzJMMjguNTQzIDMzLjEyNThMMjkuMjIxNCAzMi44NTg3QzMwLjM1MzQgMzIuNDEzMSAzMS43MzEyIDMwLjg5MTUgMzEuNzMxMiAyOC40MzlDMzEuNzMxMiAyNy4yODQyIDMxLjQ4OTkgMjYuNzc2OSAzMS4yMTY5IDI2LjU0NzhDMzAuOTQxNSAyNi4zMTY2IDMwLjU4NTUgMjYuMzIxNSAzMC4yMDI5IDI2LjMzNDJMMzAuMTk4MiAyNi4zMzQzQzMwLjA4NSAyNi4zMzgxIDI5Ljk2NzMgMjYuMzQyIDI5Ljg1ODYgMjYuMzM1N0MyOS43NDg3IDI2LjMyOTUgMjku" +
    "NjMzNyAyNi4zMTIzIDI5LjUzIDI2LjI2NThDMjkuNDIxNCAyNi4yMTcxIDI5LjMyOTMgMjYuMTM3OSAyOS4yNjg1IDI2LjAxODZDMjkuMjEgMjUuOTAzOCAyOS4xODY2IDI1Ljc2NCAyOS4xODk3IDI1LjYwMTRDMjkuMTk0NSAyNS4zNDM1IDI5LjI0MzEgMjUuMDUzNSAyOS4yNjc5IDI0LjkwNDhMMjkuMjc1MiAyNC44NjE1TDI5LjI3NjYgMjQuODUzQzI5LjcwOTQgMjIuMjIxNyAyOC43Mjk1IDIwLjE3NDMgMjcuMTAxOSAxOC43NzgxQzI1LjQ2OCAxNy4zNzY0IDIzLjE3ODIgMTYuNjI5OSAyMS4wMDg5IDE2LjYyOTlDMTguODM4IDE2LjYyOTkgMTYuNTQ3MyAxNy4zNzYgMTQuOTEyNCAxOC43Nzc0QzEzLjI4MzcgMjAuMTczNiAxMi4zMDI1IDIyLjIyMSAxMi43MzMyIDI0Ljg1MzZMMTIuNzM0NSAyNC44NjE3TDEyLjc0MTMgMjQuOTAyM0wxMi43NDE4IDI0LjkwNThDMTIuNzY2NyAyNS4wNTQ4IDEyLjgxNDkgMjUuMzQ0NyAxMi44MTk2IDI1LjYwMjVDMTIuODIyNSAyNS43NjUzIDEyLjc5ODkgMjUuOTA1MyAxMi43NDAxIDI2LjAyMDJDMTIuNjc4OSAyNi4xMzk1IDEyLjU4NjQgMjYuMjE4NiAxMi40Nzc" +
    "1IDI2LjI2NzJDMTIuMzczNSAyNi4zMTM2IDEyLjI1ODIgMjYuMzMwNiAxMi4xNDggMjYuMzM2N0MxMi4wMzg5IDI2LjM0MjggMTEuOTIwOCAyNi4zMzg4IDExLjgwNzEgMjYuMzM0OUwxMS44MDI1IDI2LjMzNDdDMTEuNDE4NSAyNi4zMjE2IDExLjA2MTEgMjYuMzE2MiAxMC43ODQ3IDI2LjU0NzNDMTAuNTEwOSAyNi43NzYzIDEwLjI2ODkgMjcuMjgzNCAxMC4yNjg5IDI4LjQzODlDMTAuMjY4OSAzMC44ODU1IDExLjY1MjQgMzIuNDEwOSAxMi43ODk2IDMyLjg1ODdMMTMuNDY1OCAzMy4xMjQ5TDEzLjgyNTMgMzMuNzQzNUMxMy45Nzg0IDM0LjAwNjkgMTQuMTM0OCAzNC4zMDEzIDE0LjI4MjkgMzQuNTgwM0wxNC4yOTM4IDM0LjYwMDhDMTQuOTg4IDM1LjkwNzggMTUuODkyMSAzNy41OTc2IDE3Ljc0NzMgMzkuMzExN0wxOC4zOTQxIDM5LjkwOTRMMTguMzk5OCA0MC43Nzg0QzE4LjQwMjggNDEuMjQ2MyAxOC40MDQ1IDQxLjU3NjkgMTguNDA0NSA0MS42Njg3QzE4LjQwMyA0Mi4yODAyIDE4LjAyNDggNDIuNzk1IDE3LjQzMjMgNDMuMjMyQzE2LjgzODYgNDMuNjY5OCAxNi4wMDA5IDQ0LjA1MjQgMTUuMD" +
    "ExOCA0NC4zOTk0QzEzLjAzNDUgNDUuMDkyOSAxMC4zOTgzIDQ1LjY2MTUgNy43NTk0OCA0Ni4yMzA3TDcuNzUwMzggNDYuMjMyN0M2LjU2NDUzIDQ2LjQ4ODUgNS4zNzcxNyA0Ni43NDQ2IDQuMjQ0MjggNDcuMDEyOEw0LjIzMTk3IDQ3LjAxNTdDNC4xMDUzOCA0Ny4wNDU3IDMuOTkxNTQgNDcuMDcyNyAzLjg5ODMzIDQ3LjA4MDNDMy43OTY0NyA0Ny4wODg3IDMuNjgzODUgNDcuMDc3NSAzLjU4ODA1IDQ2Ljk5NTRDMy40OTM3MiA0Ni45MTQ1IDMuNDY0NTEgNDYuODA4MiAzLjQ1NzAxIDQ2LjcwNzNDMy40NTAyNiA0Ni42MTY2IDMuNDU5NTggNDYuNTA0IDMuNDY5ODIgNDYuMzgwNUMzLjYzNzc0IDQ0LjM1MTIgNi4wODY1OSA0My41NTM2IDcuNzk4NTUgNDIuOTk2MUM3LjkyNTcxIDQyLjk1NDcgOC4wNDg4MSA0Mi45MTQ2IDguMTY2NjEgNDIuODc1NEMxMC4zMDQ1IDQyLjE2NDUgMTMuNjgyNCA0MS4yNTM3IDEzLjY4MjQgNDEuMjUzN0MxMy43MjggNDEuMjM5MiAxMy43NzE0IDQxLjIyNDkgMTMuODEyOSA0MS4yMTA5QzEyLjEyNzQgMzkuMzg0NCAxMS4yMDY5IDM3LjY1MTMgMTAuNTk0MyAzNi40OTc4T" +
    "DEwLjQ4NzggMzYuMjk3NUM3Ljk3MzkzIDM0Ljk1NDcgNi4wODkyOCAzMi4wMzQgNi4wODkyOCAyOC40Mzg5QzYuMDg5MjggMjcuMTE1MyA2LjQ3MDA1IDI1LjcyODUgNy4zNjA5OCAyNC43NDM5QzcuNjQzMzggMjQuNDMxOCA3Ljk5ODgxIDI0LjEzOTUgOC40MjYwNyAyMy45MjM0QzguMDYzMjggMTYuOTUwOSAxNC4wMiAxMS45MTYgMjEuMDA4OSAxMS45MTZDMjcuOTk2OCAxMS45MTYgMzMuOTUwOCAxNi45NTQzIDMzLjU4NDMgMjMuOTI1NUMzNC4wMTExIDI0LjE0MjMgMzQuMzY1NyAyNC40MzUzIDM0LjY0NjkgMjQuNzQ3N0MzNS41MzQzIDI1LjczMzEgMzUuOTEwOCAyNy4xMTkyIDM1LjkxMDggMjguNDM5QzM1LjkxMDggMzIuMDI4NiAzNC4wMzk2IDM0Ljk1MjEgMzEuNTI0MSAzNi4yOTcxTDMxLjQzMDYgMzYuNDcyNUMzMC44MTYyIDM3LjYyNzEgMjkuODg3NSAzOS4zNzIzIDI4LjE5MzIgNDEuMjEzQzI4LjIzMjkgNDEuMjI2MyAyOC4yNzQzIDQxLjIzOTkgMjguMzE3NyA0MS4yNTM3QzI4LjMxNzcgNDEuMjUzNyAzMS42OTUyIDQyLjE2NDUgMzMuODMzMSA0Mi44NzU0QzMzLjk1MDkgNDIuOTE0NiAz" +
    "NC4wNzQgNDIuOTU0NyAzNC4yMDExIDQyLjk5NjFDMzUuOTEzMiA0My41NTM2IDM4LjM2MjkgNDQuMzUxNSAzOC41MzAzIDQ2LjM4MUMzOC41NDA2IDQ2LjUwNDYgMzguNTQ5OSA0Ni42MTcyIDM4LjU0MzEgNDYuNzA3OUMzOC41MzU2IDQ2LjgwODggMzguNTA2NCA0Ni45MTUgMzguNDEyMSA0Ni45OTU5QzM4LjMxNjMgNDcuMDc4MSAzOC4yMDM3IDQ3LjA4OTIgMzguMTAxOCA0Ny4wODA5QzM4LjAwODYgNDcuMDczMiAzNy44OTQ3IDQ3LjA0NjMgMzcuNzY4MSA0Ny4wMTYzTDM3Ljc1NTkgNDcuMDEzNEMzNi42MjI0IDQ2Ljc0NTEgMzUuNDM0NCA0Ni40ODg5IDM0LjI0NzkgNDYuMjMzTDM0LjIzODYgNDYuMjMxQzMxLjU5ODkgNDUuNjYxOCAyOC45NjE4IDQ1LjA5MzEgMjYuOTg0NyA0NC4zOTk2QzI1Ljk5NTYgNDQuMDUyNiAyNS4xNTgxIDQzLjY3IDI0LjU2NSA0My4yMzIxQzIzLjk3MjkgNDIuNzk1IDIzLjU5NTYgNDIuMjgwNCAyMy41OTU2IDQxLjY2ODdDMjMuNTk1NiA0MS41NzYyIDIzLjU5NzYgNDEuMjQ4MiAyMy42MDExIDQwLjc4NjdMMjMuNjA3NyAzOS45MjE0TDI0LjI1MDYgMzkuMzI1QzI2LjE" +
    "xOTcgMzcuNTkxMyAyNy4wMzYyIDM1Ljg4MTEgMjcuNzMyNSAzNC41NzI4TDI3Ljc0MiAzNC41NTVaIiBmaWxsPSJibGFjayIvPgo8L3N2Zz4K";
exports.captionSVGB64 = "PHN2ZyB3aWR0aD0iMTQiIGhlaWdodD0iMjEiIHZpZXdCb3g9IjAgMCAxNCAyMSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTcgMTcuODg4OUg3LjAxTTMgMjBIMTFDMTEuNTMwNCAyMCAxMi4wMzkxIDE5Ljc3NzYgMTIuNDE0MiAxOS4zODE3QzEyLjc4OTMgMTguOTg1OCAxMyAxOC40NDg4IDEzIDE3Ljg4ODlWMy4xMTExMUMxMyAyLjU1MTIxIDEyLjc4OTMgMi4wMTQyNCAxMi40MTQyIDEuNjE4MzNDMTIuMDM5MSAxLjIyMjQyIDExLjUzMDQgMSAxMSAxSDNDMi40Njk1NyAxIDEuOTYwODYgMS4yMjI0MiAxLjU4NTc5IDEuNjE4MzNDMS4yMTA3MSAyLjAxNDI0IDEgMi41NTEyMSAxIDMuMTExMTFWMTcuODg4OUMxIDE4LjQ0ODggMS4yMTA3MSAxOC45ODU4IDEuNTg1NzkgMTkuMzgxN0MxLjk2MDg2IDE5Ljc3NzYgMi40Njk1NyAyMCAzIDIwWiIgc3Ryb2tlPSIjNjY2NjY2IiBzdHJva2Utd2lkdGg9IjEuMjUiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIvPgo8L3N2Zz4K";
