/**
 * Copyright 2023 Nametag Inc.
 *
 * All information contained herein is the property of Nametag Inc.. The
 * intellectual and technical concepts contained herein are proprietary, trade
 * secrets, and/or confidential to Nametag, Inc. and may be covered by U.S.
 * and Foreign Patents, patents in process, and are protected by trade secret or
 * copyright law. Reproduction or distribution, in whole or in part, is
 * forbidden except by express written permission of Nametag, Inc.
 */

import { useEffect } from "react";
import { DialogStateReturn } from "reakit/src/Dialog/DialogState";
import { style } from "typestyle";
import { Button } from "../../../components/button/button";
import { COLORS } from "../../../lib/colors";
import { ResetModal } from "./reset-modal";
import { t } from "../../../i18n";
import { DirectoryKind } from "../../../api/types";

export type SimpleUnlockModalProps = {
  dialog: DialogStateReturn;
  directoryName: string;
  directoryLogo: string;
  externalIdentifier: string;
  isLoading: boolean;
  isError: boolean;
  isSuccess: boolean;
  errorMessage?: string;
  doRecovery: () => Promise<void>;
  directoryKind: DirectoryKind;
};

export const SimpleUnlockModal: React.FC<SimpleUnlockModalProps> = ({
  dialog,
  directoryName,
  directoryLogo,
  externalIdentifier,
  isLoading,
  isError,
  isSuccess,
  errorMessage,
  doRecovery,
  directoryKind,
}) => {
  let directoryProvider = "";
  switch (directoryKind) {
    case DirectoryKind.DirectoryKindOkta:
      directoryProvider = "Okta";
      break;
    case DirectoryKind.DirectoryKindAzureAD:
      directoryProvider = "Microsoft";
      break;
    case DirectoryKind.DirectoryKindOnelogin:
      directoryProvider = "OneLogin";
      break;
    default:
      // unsupported
      break;
  }

  useEffect(() => {
    if (isSuccess || isError || isLoading) {
      return;
    }
    if (!dialog.visible) {
      return;
    }
    doRecovery();
  }, [dialog.visible, isSuccess, isError, isLoading]);

  return (
    <ResetModal
      dialog={dialog}
      directoryKind={directoryKind}
      directoryLogo={directoryLogo}
      directoryName={directoryName}
      externalIdentifier={externalIdentifier}
      isLoading={isLoading}
      isError={isError}
      errorMessage={errorMessage}
      loadingMessage={t("microsite_Unlocking")}
    >
      {isSuccess && (
        <>
          <p className={css.graf}>
            {t("microsite_UnlockSuccess", {
              directoryProvider,
            })}
          </p>
          <Button
            onClick={() => {
              dialog.hide();
            }}
            variant="secondary"
            className={css.doneBtn}
          >
            {t("envs_Done")}
          </Button>
        </>
      )}
    </ResetModal>
  );
};

const css = {
  graf: style({
    marginBottom: 12,
  }),
  buttons: style({
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    gap: 8,
    marginTop: 32,
  }),
  doneBtn: style({
    marginTop: 32,
    marginLeft: "auto",
  }),
  errorState: style({
    display: "flex",
    gap: 8,
    alignItems: "center",
    color: COLORS.error,
  }),
};
