/**
 * Copyright 2023 Nametag Inc.
 *
 * All information contained herein is the property of Nametag Inc.. The
 * intellectual and technical concepts contained herein are proprietary, trade
 * secrets, and/or confidential to Nametag, Inc. and may be covered by U.S.
 * and Foreign Patents, patents in process, and are protected by trade secret or
 * copyright law. Reproduction or distribution, in whole or in part, is
 * forbidden except by express written permission of Nametag, Inc.
 */

import { style, stylesheet } from "typestyle";
import { CSSProperties } from "typestyle/lib/types";

/**
 * Wrapper function for `stylesheet` from the typestyle library.
 * Improves typing for clarity and better IDE support
 */
export function typedStylesheet<T extends Record<string, CSSProperties>>(
  sheet: T,
): { [Property in keyof T]: string } {
  return stylesheet(sheet) as { [Property in keyof T]: string };
}

export const commonCSS = {
  hidden: style({
    display: "none",
  }),
  srOnly: style({
    position: "absolute",
    width: 1,
    height: 1,
    padding: 0,
    margin: -1,
    overflow: "hidden",
    clip: "rect(0,0,0,0)",
    border: 0,
  }),
};
