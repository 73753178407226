/**
 * Copyright 2022 Nametag Inc.
 *
 * All information contained herein is the property of Nametag Inc.. The
 * intellectual and technical concepts contained herein are proprietary, trade
 * secrets, and/or confidential to Nametag, Inc. and may be covered by U.S.
 * and Foreign Patents, patents in process, and are protected by trade secret or
 * copyright law. Reproduction or distribution, in whole or in part, is
 * forbidden except by express written permission of Nametag, Inc.
 */

import React, { ReactNode, useState } from "react";
import { useFromURL } from "../../console/use_from_url";
import { classes, style } from "typestyle";
import { TopNav } from "./top_nav";
import { SubNav } from "./subnav";
import { SideNav } from "./sidenav";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { Footer } from "./footer";
import { LoadingPage } from "./loading-page";
import { useDocumentTitle } from "../../lib/use-document-title";
import { AuthCheck } from "./auth_check";
import { TrialWarning } from "../trial-warning";
import { HEADER_Z_INDEX } from "../../lib/layers";
import { COLORS } from "../../lib/colors";
import { t } from "../../i18n";
import { useBrowser } from "../../lib/browser";

export interface PageProps {
  className?: string;
  sideNavVisible?: boolean; // set to === false to hide the side nav
  subNavVisible?: boolean; // set to === false to hide the side nav
  auth?: boolean; // set to === false to disable authentication
  children?: ReactNode;
  title?: string;
}

// Page wraps `children` in the default frame for a console page.
export const Page: React.FunctionComponent<PageProps> = (props: PageProps) => {
  const { isMobile } = useBrowser();
  const state = useFromURL({ auth: props.auth });
  const [pageLoading, setPageLoading] = useState(false);
  useDocumentTitle(props.title ?? "Nametag");

  const checkingAuth = props.auth && !state.id;

  return (
    <>
      <AuthCheck hidden={!checkingAuth} />
      <div className={classes(css.container, props.className)}>
        {!pageLoading && (
          <>
            <header className={css.header}>
              {isMobile && (
                <div className={css.mobileBanner}>{t("MobileBanner")}</div>
              )}
              <TopNav {...state} />
              {props.subNavVisible !== false && (
                <SubNav
                  {...state}
                  auth={props.auth}
                  setPageLoading={setPageLoading}
                />
              )}
            </header>
            <div className={css.main}>
              {props.sideNavVisible === false ? (
                <div className={css.content}>
                  <TrialWarning {...state} />
                  {props.children}
                </div>
              ) : (
                <>
                  <div className={css.sideNav}>
                    <SideNav {...state} />
                  </div>
                  <div className={css.content}>
                    <TrialWarning {...state} />
                    {props.children}
                  </div>
                </>
              )}
            </div>
            <Footer />
          </>
        )}
        {pageLoading && <LoadingPage />}
        <ReactQueryDevtools />
      </div>
    </>
  );
};

const css = {
  header: style({
    position: "sticky",
    top: 0,
    zIndex: HEADER_Z_INDEX,
  }),
  mobileBanner: style({
    padding: "8px 16px",
    backgroundColor: "#BD4C0F",
    color: COLORS.white,
    fontWeight: 600,
  }),
  container: style({
    minHeight: "100vh",
    display: "flex",
    flexDirection: "column",
    isolation: "isolate",
  }),
  main: style({
    display: "flex",
    flex: "1 1 auto",
  }),
  sideNav: style({
    flex: 2,
    maxWidth: 208,
    minWidth: 208,
    maxHeight: "calc(100vh - 108px)",
    borderRight: "1px solid " + COLORS.borderLight,
    position: "sticky",
    top: 60,
    overflowY: "auto",
  }),
  content: style({
    flex: 4.5,
    padding: 24,
    overflowX: "auto",
  }),
};
