/**
 * Copyright 2022 Nametag Inc.
 *
 * All information contained herein is the property of Nametag Inc.. The
 * intellectual and technical concepts contained herein are proprietary, trade
 * secrets, and/or confidential to Nametag, Inc. and may be covered by U.S.
 * and Foreign Patents, patents in process, and are protected by trade secret or
 * copyright law. Reproduction or distribution, in whole or in part, is
 * forbidden except by express written permission of Nametag, Inc.
 */

import { useAPI } from "../../api/context";
import { useMutation } from "@tanstack/react-query";
import { Menu, MenuButton, MenuItem, useMenuState } from "reakit/Menu";
import { useNavigate } from "react-router-dom";
import { classes, style } from "typestyle";
import { DownArrow } from "../assets/down-arrow";
import { COLORS } from "../../lib/colors";
import { pxToRem } from "../../lib/utils";
import { PlusCircleOutlined } from "../assets/plus-circle-outlined";
import assert from "assert";
import { successToast } from "../../lib/feedback";
import { t } from "../../i18n";
import { changeEnvironment, envURI } from "../../console/url";
import { RoleGe } from "../../api/orgs";
import { Env } from "../../api/types";
import { QueryKeys } from "../../api/querykey";
import { Org, Role } from "../../api/types";

interface Props {
  org?: Org;
  env?: Env;
  envs?: Array<Env>;
  className?: string;
  setPageLoading: (l: boolean) => void;
}

export const EnvChooser = (props: Props) => {
  const menu = useMenuState({
    unstable_offset: [0, 16],
    placement: "bottom-end",
  });

  const { api } = useAPI();
  const navigate = useNavigate();
  const onClick = (env: Env) => {
    assert(props.envs);
    menu.hide();
    changeEnvironment(env, props.envs, navigate);
  };

  const createEnv = useMutation({
    mutationFn: async () => {
      props.setPageLoading(true);
      menu.hide();

      const envID = await api.Envs.Create();
      const newEnvs = api.queryClient.getQueryData(QueryKeys.Env.List) as Env[];
      const newEnv = newEnvs.filter((a) => a.id == envID).pop()!;

      console.log("EnvChooser: navigate to new env " + envID);
      const newEnvURI = envURI(newEnv, newEnvs) + "/configure";
      navigate(newEnvURI);
    },
    onSuccess: () => {
      successToast(t("CreateEnvironmentSuccess"));
    },
    onSettled: () => {
      props.setPageLoading(false);
    },
  });

  const showSandboxPlaceholder =
    RoleGe(props.org?.role, Role.RoleOwner) &&
    props.envs &&
    props.envs.length === 1 &&
    props.envs[0].name === "Live";
  const createSandboxEnv = useMutation({
    mutationFn: async () => {
      props.setPageLoading(true);
      assert(props.org);
      menu.hide();

      const envID = await api.Envs.CreateSandbox();
      const newEnvs = api.queryClient.getQueryData(QueryKeys.Env.List) as Env[];
      const newEnv = newEnvs.filter((a) => a.id == envID).pop()!;

      console.log("EnvChooser: navigate to new env " + envID);
      const newEnvURI = envURI(newEnv, newEnvs) + "/configure";
      navigate(newEnvURI);
    },
    onSettled: () => {
      props.setPageLoading(false);
    },
  });

  return (
    <div
      data-testid={"env-chooser"}
      className={classes(props.className, css.container)}
    >
      <MenuButton
        {...menu}
        className={classes(css.menuButton)}
        disabled={props.envs === undefined}
      >
        <div className={css.menuButtonText}>
          {props.env?.name || t("Unnamed")}
        </div>
        <DownArrow className={css.downArrow} />
      </MenuButton>
      <Menu {...menu} aria-label="Environment" className={css.menu}>
        {props.envs?.map((env) => (
          <MenuItem
            key={env.id}
            {...menu}
            className={css.menuItem}
            onClick={() => onClick(env)}
          >
            <span className={css.menuItemText}>{env.name || t("Unnamed")}</span>
          </MenuItem>
        ))}
        {showSandboxPlaceholder ? (
          <MenuItem
            key={"sandbox"}
            {...menu}
            className={css.menuItem}
            onClick={() => createSandboxEnv.mutate()}
          >
            <span className={css.menuItemText}>{t("Sandbox")}</span>
          </MenuItem>
        ) : null}

        {RoleGe(props.org?.role, Role.RoleOwner) && (
          <MenuItem
            {...menu}
            className={classes(css.menuItem, css.menuItemAdd)}
            onClick={() => {
              createEnv.mutate();
            }}
          >
            <PlusCircleOutlined className={css.icon} />
            <div>{t("Create_environment")}</div>
          </MenuItem>
        )}
      </Menu>
    </div>
  );
};

const minWidth = 179;
const maxWidth = 300;

const downArrowWidth = 10.5;
const downArrowHeight = 6;

const css = {
  container: style({
    maxWidth: maxWidth,
    minWidth: minWidth,
    display: "flex",
    alignItems: "center",
  }),
  menuButton: style({
    display: "flex",
    alignItems: "center",
    color: COLORS.textDark,
    border: "1px solid " + COLORS.border,
    backgroundColor: COLORS.white,
    padding: "8px 12px",
    borderRadius: "4px",
    fontWeight: 600,
    fontSize: pxToRem(16),
    $nest: {
      "&:hover": {
        backgroundColor: COLORS.fieldHover,
      },
    },
  }),
  menuButtonText: style({
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    minWidth:
      minWidth -
      12 - // left padding
      (downArrowWidth + 12) - // down arrow and padding
      12, // right padding
    maxWidth:
      maxWidth -
      12 - // left padding
      (downArrowWidth + 12) - // down arrow and padding
      12, // right padding

    display: "block",
    textAlign: "left",
  }),
  downArrow: style({
    height: downArrowHeight,
    width: downArrowWidth,
    minHeight: downArrowHeight,
    minWidth: downArrowWidth,
    maxHeight: downArrowHeight,
    maxWidth: downArrowWidth,
    marginLeft: "12px",
  }),

  menu: style({
    display: "flex",
    flexDirection: "column",
    backgroundColor: COLORS.white,
    border: "1px solid " + COLORS.border,
    boxShadow: "0px 1px 3px rgba(0, 0, 0, 0.15)",
    borderRadius: 4,
    minWidth: minWidth,
    maxWidth: maxWidth,
    maxHeight: "calc(100vh - 128px)",
    overflowY: "scroll",
  }),

  menuItem: style({
    display: "flex",
    flexDirection: "row",
    color: COLORS.text,
    border: "none",
    alignItems: "center",
    justifyContent: "left",
    backgroundColor: COLORS.white,
    padding: "8px 32px 8px 12px",
    fontWeight: 400,
    fontSize: pxToRem(16),
    $nest: {
      "&:hover": {
        backgroundColor: COLORS.fieldHover,
      },
    },
  }),
  icon: style({
    height: 17,
    width: 17,
    marginRight: 8,
    marginBottom: 2, // so it doesn't look misaligned. Ugh.
  }),
  menuItemText: style({
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  }),

  menuItemAdd: style({
    borderTop: "1px solid " + COLORS.border + " !important",
    color: COLORS.actionPrimary + " !important",
    fontWeight: 400,
    fontSize: pxToRem(16),
    lineHeight: pxToRem(22),
    padding: "10px 32px 12px 12px",
  }),
};
