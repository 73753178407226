/**
 * Copyright 2022 Nametag Inc.
 *
 * All information contained herein is the property of Nametag Inc.. The
 * intellectual and technical concepts contained herein are proprietary, trade
 * secrets, and/or confidential to Nametag, Inc. and may be covered by U.S.
 * and Foreign Patents, patents in process, and are protected by trade secret or
 * copyright law. Reproduction or distribution, in whole or in part, is
 * forbidden except by express written permission of Nametag, Inc.
 */

import { ReactElement } from "react";

export const OAuthIcon = (props: { className?: string }): ReactElement => {
  return (
    <svg
      className={props.className}
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.99996 10.5V11.8333M3.99996 14.5H12C12.3536 14.5 12.6927 14.3595 12.9428 14.1095C13.1928 13.8594 13.3333 13.5203 13.3333 13.1667V9.16667C13.3333 8.81304 13.1928 8.47391 12.9428 8.22386C12.6927 7.97381 12.3536 7.83333 12 7.83333H3.99996C3.64634 7.83333 3.3072 7.97381 3.05715 8.22386C2.8071 8.47391 2.66663 8.81304 2.66663 9.16667V13.1667C2.66663 13.5203 2.8071 13.8594 3.05715 14.1095C3.3072 14.3595 3.64634 14.5 3.99996 14.5ZM10.6666 7.83333V5.16667C10.6666 4.45942 10.3857 3.78115 9.88558 3.28105C9.38548 2.78095 8.7072 2.5 7.99996 2.5C7.29272 2.5 6.61444 2.78095 6.11434 3.28105C5.61424 3.78115 5.33329 4.45942 5.33329 5.16667V7.83333H10.6666Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
